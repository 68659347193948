<template>
  <main class="owner_container">
    <Form v-if="screen_name == 'create'" ref="restaurantAdministrators" :restaurant-administrators="restaurantAdministrators" 
          @change_screen="onChangeScreenName"
    />
    <confirm v-if="screen_name == 'confirm'" ref="restaurantAdministrators" :restaurant-administrators="restaurantAdministrators" 
             @created="onCreate" @change_screen="onChangeScreenName"
    />
    <complete v-if="screen_name == 'complete'" :restaurant="restaurant" @change_screen="onChangeScreenName" />
  </main>
</template>

<script>
import Form from './components/Form.vue';
import Confirm from './components/Confirm.vue';
import Complete from './components/Complete.vue';
import { OwnersService } from '../../../services/Owner/owners.service';
import { RestaurantAdministratorsService } from '../../../services/Owner/restaurant_administrators.service';

export default {
  components: {
    Form,
    Confirm,
    Complete,
  },
  data() {
    return {
      restaurant: {},
      screen_name: 'create',
      owner: {},
      restaurantAdministrators: [],
    };
  },
  watch: {
    'screen_name': {
      handler() {
        this.scrollToTop();
        switch (this.screen_name) {
        case 'confirm':
          document.title = '店舗の新規登録';
          break;
        case 'complete':
          document.title = '店舗登録完了';
          break;
        default:
          document.title = '店舗の新規登録';
          break;
        }
      }
    },
  },
  async created(){
    await this.getOwners();
    if(this.owner.status != 'active' || this.owner.contract_end_at != null) {
      window.location.href = '/owner';
    }
    this.getShops();
  },

  beforeDestroy() {
    this.$store.commit('CLEAR_STATE_RESTAURANTS_OWNER');
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    onCreate(value) {
      this.restaurant = value;
    },
    onChangeScreenName(value) {
      this.screen_name = value;
    },
    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getShops() {
      try {
        const { response }  = await RestaurantAdministratorsService.index();
        this.restaurantAdministrators = response.data;
      }catch(error){
        this.$loading(false);
      }
    },
  },
};
</script>