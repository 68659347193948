<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">改定履歴管理</h1>
      <a class="btn_admin admin_primary mt-28 mb_medium ml_medium" href="#" @click="updateSelectedRevisionHistory(null, true); $openModal('revisionHistoryForm')">改定履歴新規作成</a>
    </div>
    <Table :revision-histories="revisionHistories" :pagination-params="paginationParams" :selected-id="selectedId"
           @refreshTable="getRevisionHistories" @updateCurrentPage="updateCurrentPage"
           @updateSelectedRevisionHistory="updateSelectedRevisionHistory"
    />
    <FormModal :revision-history-id="selectedId"
               @refreshTable="getRevisionHistories" @updateSelectedRevisionHistory="updateSelectedRevisionHistory"
    />
  </div>
</template>

<script>
import { RevisionHistoriesService } from '../../../services/admin/revision_histories.service';
import Table from './components/Table.vue';
import FormModal from './components/FormModal.vue';

export default {
  components: {
    Table,
    FormModal,
  },
  data() {
    return {
      revisionHistories: [],
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      searchParams: {},
      selectedId: null,
    };
  },
  async mounted() {
    this.getRevisionHistories();
  },
  methods: {
    async getRevisionHistories() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams, ...this.searchParams};

        const {response} = await RevisionHistoriesService.index(params);
        this.revisionHistories = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedRevisionHistory(id) {
      this.selectedId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
