<template>
  <div class="main_area_pc wrapper_password-reset owner_main_area">
    <div class="wrapper_owner_have_button">
      <div class="password-reset">
        <div class="title">パスワードをお忘れの方</div>
        <div class="content note_sp">登録の際に設定したオーナーIDとメールアドレスを入力してください。
          入力いただいたメールアドレス宛に、認証コードをお送りいたします。</div>
        <div class="form_row vertical mb_medium form_reset">
          <label for="" class="owner_form_label">
            オーナーID<span class="require">必須</span>
          </label>
          <div class="input_wrapper">
            <input v-model="loginid" type="text" placeholder="">
            <span v-for="error in errors['loginid']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
          <label for="" class="owner_form_label">
            メールアドレス<span class="require">必須</span>
          </label>
          <div class="input_wrapper">
            <input v-model="email" type="text" placeholder="">
            <span v-for="error in errors['email']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn_area_wrapper">
        <div class="button_wrapper">
          <router-link :to="{}" class="btn_area_owner btn_area-owner primary" @click.native="sendMail">
            送信する
          </router-link>
          <router-link :to="{}" class="btn_area_owner btn_area-owner btn_redirect-login" @click.native="login">
            ログイン画面へ
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../../validator';
import { PasswordResetService } from '../../../services/Owner/password_reset.service';
import { PopupDisplayError } from '../../../common/util';

export default {
  data () {
    return {
      loginid: '',
      email: '',
      errors: {}
    };
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    async sendMail() {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = {
        login_id: this.loginid,
        email: this.email,
      };
      try {
        await PasswordResetService.password_reset(params);
        return this.$router.push({ path: '/password_reset_auth' });
      } catch (error) {
        this.validate();
        this.errors['email'] = ['オーナーIDまたはメールアドレスが正しくありません。'];
        console.log(error);
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('loginid', this.loginid, 'オーナーID') &&
        validator.checkMaxLength('loginid', this.loginid, 255, 'オーナーID');
      validator.checkRequire('email', this.email, 'メールアドレス') &&
        validator.checkEmail('email', this.email, 'メールアドレス');
      this.errors = validator.errors;
    },
    login() {
      return this.$router.push('/');
    },
  },
};
</script>
<style lang="scss" scoped>
 
</style>
