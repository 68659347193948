<template>
  <div id="cancelOwnerModal" class="modal modal-owner">
    <div class="modal-backdrop" />
    <div class="modal-content owner">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <div class="modal-title">法人情報管理 解約</div>
          <div class="icon close" @click="error=null" />
        </div>
      </div>
      <div class="modal-body">
        <div class="admin_content">
          <div>法人情報を削除し、アカウントを削除します。</div>
          <div class="admin_color-alert">解約後、法人の情報・請求書情報は削除されます。復旧はできませんのでご注意ください。</div>
          <div>また、紐づいている店舗情報も削除されます。</div>
          <div>問題ない場合は、「解約する」ボタンを推して続けてください。</div>

          <div class="form-row">
            <span v-if="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <button class="btn_admin delete" @click="handleCancel">解約する</button>
      </div>
    </div>
  </div>
</template>
<script>
import {OwnersService} from '../../../../services/admin/owners.service';

export default {
  props: {
    cancelOwnerId: {
      type: Number,
      require: true,
    }
  },
  data() {
    return {
      error: null
    };
  },
  methods: {
    handleCancel: async function () {
      this.$loading(true);
      try {
        await OwnersService.cancel(this.cancelOwnerId);
        this.$emit('refreshTable');
        this.$closeModal('cancelOwnerModal');
      } catch (error) {
        this.error = '契約中の店舗がございますので、削除できません。';
      }
      this.$loading(false);
    },
  }
};
</script>
<style scoped lang="scss">
#cancelOwnerModal{
  .modal-content{
    width: 700px;
    min-width: 700px;
  }
}
</style>