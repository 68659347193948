<template>
  <div id="itemImport" class="modal">
    <div class="modal-backdrop" />
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <h1 class="title_admin">CSVアップロード</h1>
          <div class="icon close" />
        </div>
      </div>
      <!--body-->
      <div class="modal-body">
        <div v-for="(v, k) in { '店舗名': ocrDetail.restaurant_name, '発注先名': ocrDetail.supplier_name }"
             :key="k" class="form-row mb_medium"
        >
          <label class="form_label form-20">{{ k }}</label>
          <div class="form_label form-50">{{ v }}</div>
        </div>
        <div class="csv-template-container">
          <button
            class="btn_admin admin_primary mr_medium"
            type="button" @click="exportTemplateToCsv()"
          >CSVテンプレート
          </button>
        </div>
        <div class="form-row mb_medium">
          <label class="form_label form-20" for="fileUpload">ファイルを選択</label>
          <div class="flex-column form-50">
            <label for="fileUpload" class="button_upload_img">
              <div v-show="fileName" class="upload_filename">{{ fileName }}</div>
              <div v-show="!fileName" class="upload_placeholder">
                <p class="icon add_image admin" />
                <p class="mt_small">こちらをクリックまたはドラッグ＆ドロップで<br>ファイルをアップロード</p>
              </div>
            </label>
            <input id="fileUpload" ref="file" type="file"
                   accept="text/csv, .csv" hidden @change="onChangeFile"
            >
            <div v-show="isImportSuccess" class="import-result mt_medium">
              <p class="success mb_small">CSVのアップロードに成功しました。</p>
              <p>データ件数: {{ countSuccess }}件</p>
              <p>成功: {{ countSuccess }}件</p>
            </div>
            <div v-show="errors.length" class="import-result mt_medium">
              <p class="error mb_small">CSVアップロードが失敗しました。エラー内容をご確認の上、再度アップロードしてください。</p>
              <p v-if="countAll" class="error-message">データ件数：{{ countAll }}件</p>
              <p v-if="countAll" class="error-message">成功：{{ countSuccess }}件</p>
              <p v-if="countAll" class="error-message">失敗：{{ countFail }}件</p>
              <p v-for="(error, index) in errors" :key="index" class="error-message"
                 v-html="error"
              />
            </div>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <a v-show="!isImportSuccess" class="btn_admin admin_primary" href="#"
           :class="!fileName || !isCsv ? 'disabled' : ''"
           @click="upload"
        >登録</a>
        <a v-show="isImportSuccess" class="btn_admin admin_primary" href="#"
           @click="onImportDone"
        >閉じる</a>
      </div>
    </div>
  </div>
</template>

<script>
import { OcrService } from '../../../../services/admin/ocr.service';

export default {
  props: {
    ocrDetail: {
      type: Object,
      default: () => {},
    },
    selectedIndex: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      file: null,
      errors: [],
      countAll: 0,
      countSuccess: 0,
      countFail: 0,
    };
  },
  computed: {
    isCsv() {
      return this.file?.name?.split('.')?.pop() === 'csv';
    },
    fileName() {
      return this.file?.name;
    },
    isImportSuccess() {
      return !this.errors.length && this.countSuccess;
    },
  },
  methods: {
    onChangeFile() {
      this.file = this.$refs.file.files[0];
    },
    onModalClose() {
      this.file = '';
      document.getElementById('fileUpload').value = '';
      this.errors = [];
      this.countFail = 0;
      this.countAll = 0;
      this.countSuccess = 0;
    },
    onImportDone() {
      this.$closeModal('itemImport');
      this.$emit('refreshTable');
    },
    async upload() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('restaurant_upload_files_id', this.ocrDetail.id);
      this.$loading(true);
      this.errors = [];
      this.countFail = 0;
      this.countAll = 0;
      this.countSuccess = 0;
      try {
        const { response } = await OcrService.csvItemImport(this.ocrDetail.restaurant_id, this.ocrDetail.supplier_id, formData);
        this.countSuccess = response.data.count;
        this.$emit('activeOcr', this.selectedIndex);
      } catch (error) {
        this.file = '';
        document.getElementById('fileUpload').value = '';
        if(error.response.result.message.length > 1) {
          this.errors = error.response.result.message[0];
          this.countFail = error.response.result.message[1];
          this.countAll = error.response.result.message[2];
          this.countSuccess = error.response.result.message[3];
        } else {
          this.errors = error.response.result.message;
        }
      }
      this.$loading(false);
    },

    async exportTemplateToCsv() {
      try {
        const {response} = await OcrService.csvExportTemplate();
        this.csvTemplateExport('template', response);
      } catch (error) {
        console.log(error);
      }

      this.$loading(false);
    },

    csvTemplateExport(filename, data){
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(data));
      element.setAttribute('download', `${filename}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
    }
  },
};
</script>
<style lang="scss" scoped>
.import-result {
  font-size: 12px;
  max-height: 300px;
  overflow-y: auto;
  .success {
    font-weight: bold;
  }
  .error {
    font-weight: bold;
  }
}
.error-message {
  font-size: unset;
  word-break: break-all;
}
.csv-template-container {
  position: absolute;
  right: 0;
  top: 25%;
}
</style>