<template>
  <header class="header">
    <div class="icon_area icon_area-left" @click="openSideBar">
      <div id="btn_menu" class="btn_menu-trigger">
        <span />
        <span />
        <span />
      </div>
      <div v-if="hasNewMemos == true" class="count count_memo" />
      <div class="icon_text">
        メニュー
      </div>
    </div>
    <div class="logo" />
    <div class="icon_area icon_area_cart" @click="toOrderCart">
      <div class="icon for_header order_list" />
      <div class="icon_text icon_text-header">
        発注予定リスト
      </div>
      <div v-if="counterCartItem > 0">
        <div v-if="counterCartItem <= 99" class="count count_qty count_md">
          <span>{{ counterCartItem }}</span>
        </div>
        <div v-else class="count count_qty">
          <span class="">99+</span>
        </div>
      </div>
    </div>
    <div class="icon_area notify_margin" @click="redirectNotification">
      <router-link to="/notification">
        <div class="icon for_header notification" />
        <div class="icon_text icon_text-header">
          お知らせ
        </div>
        <div v-if="getUnreadNotificationCount>0" class="count position_count">
          <span>{{ getUnreadNotificationCount }}</span>
        </div>
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { CartsService } from '../services/carts.service';
export default {
  props: {
    hanldeSideBar: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      orders: 'getAllOrders',
      getUnreadNotificationCount: 'getUnreadNotificationCount',
      hasOrdersFlag: 'getHasOrdersFlag',
      hasNewMemos: 'hasNewMemos',
      counterCartItem: 'getCountItemSelected'
    })
  },
  async mounted() {
    await this.cartItem();
    await this.$store.dispatch('getNotifications');
  },
  methods: {
    hanldeShowCart() {
      this.isShowCart();
    },
    redirectNotification() {
      return this.$router.push('/notification', () => {});
    },
    async toOrderCart() {
      if ((this.$router.history.current.path.includes('/order/item_select') ||
            this.$router.history.current.path.includes('/order/order_cart')) &&
            this.hasOrdersFlag) {
        const res = await this.$confirmRedirect();
        if(!res) return;
      }

      window.location.href = "/order/order_cart";

      // return this.$router.push('/order/order_cart', () => {});
    },
    async cartItem() {
      const { response } = await CartsService.index();
      let count = 0;

      for (const cart_item of response.data) {
        count += cart_item.cart_items.length;
      }

      this.$store.dispatch('setCountItemSelected', count);
    },
    openSideBar() {
      this.hanldeSideBar();
    }
  }
};
</script>
<style scoped lang="scss">
  .count {
    background-color: #D52C2C !important;
    span {
      font-size: 12px;
    }
    &_md {
      width: 20px !important;
      height: 20px !important;
      top: -12px;
      right: -12px;
    }
  }
  .icon_text {
    font-size: 10px;
  }
  .position_count {
    right: 1px !important;
  }
  @media screen and (max-width: 480px) {
    .count {
      top: -10px;
      right: -10px;
      span {
        font-size: 10px;
      }
      &_qty {
        width: 16px !important;
        height: 16px !important;
        span {
          font-size: 9px;
        }
      }
      &_md {
        width: 16px !important;
        height: 16px !important;
        top: -10px;
        right: -10px;
      }
      &_minus {
        &::after {
          content: "+";
          font-size: 9px;
        }
      }
    }
  }
</style>
