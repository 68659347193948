<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_large padding_text">発注履歴詳細</h1>
      <div class="flex alignStart mb_medium chanel_text_padding">
        <div :class="['status_label mr_medium', statusLabel[order.status] || 'success']">
          <div :class="['icon', statusIcon[order.status] || 'check']" />
          {{ statusName[order.status] }}
        </div>
        <div v-if="order.check_date != null" class="status_label mr_medium" :class="order.is_check_qty ? 'success' : 'fail'">
          <div class="icon" :class="order.is_check_qty ? 'check' : 'attention'" /> 検品済
        </div>
        <div :class="['is-pc-type status_text alignSelfCenter status_text-mb', statusLabel[order.status] || 'success']" v-html="statusMessage[order.status]" />
      </div>
      <div class="flex alignStart mb_medium chanel_text_padding is-mobile">
        <div :class="['status_text alignSelfCenter status_text-mb', statusLabel[order.status] || 'success']" v-html="statusMessage[order.status]" />
      </div>
      <div v-if="order.status == 'line_completed' || order.status == 'line_doing' " class="form_row mb_medium chanel_text_padding">
        <a v-if="line_share_status == 'OK'" :href="line_share_url" class="btn line_share"
           style="max-width: 120px;" target="_blank" @click="updateLineCompleted"
        >LINEで送る</a>
        <a v-else class="btn line_share" style="max-width: 120px;"
           @click="openDialogErr"
        >LINEで送る</a>
      </div>
      <div class="form_row mb_medium chanel_text_padding">
        <div class="form_label">発注ID</div>
        <div class="form_text">{{ order.id }}</div>
      </div>
      <div class="form_row mb_medium chanel_text_padding">
        <div class="form_label">発注日付</div>
        <div class="form_text">{{ order.order_date }}</div>
      </div>
      <div class="form_row mb_medium chanel_text_padding">
        <div class="form_label">発注業者</div>
        <div class="form_text">{{ order.supplier_name }}</div>
      </div>
      <div v-if="order.scheduled_time" class="form_row mb_medium chanel_text_padding">
        <div class="form_label">発注時間</div>
        <div class="form_text">日時指定：{{ showDateTime(order.scheduled_time) }}</div>
      </div>
      <div class="form_row mb_medium chanel_text_padding">
        <div class="form_label">納品日付</div>
        <div class="form_text">{{ order.check_date }}</div>
      </div>
      <paging
        v-if="order_items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th class="large sr_table-name">商品名</th>
            <th class="sr_table-r sr_table-price">単価</th>
            <th class="small sr_table-r sr_table-qty">数量</th>
            <th class="small sr_table-r sr_table-price">納品数</th>
            <th class="sr_table-price th-item-center">納品</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order_item, index) in order_items" :key="index">
            <td>{{ order_item.name }}</td>
            <td class="sr_table-r">{{ showPrice(order_item.unit_price) }}</td>
            <td class="sr_table-r">{{ order_item.item_qty }}</td>
            <td class="sr_table-r" :class="order_item.is_check_qty ? '' : 'text_deficient'">
              {{ order_item.item_received_qty }}
            </td>
            <td class="item-center">
              <div v-if="order_item.item_received_qty !== null"
                  class="status-check-label status-check-label-small" 
                  :class="order_item.is_check_qty ? 'success' : 'fail'"
              >
                <div class="icon" :class="order_item.is_check_qty ? 'check' : 'attention'"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="order_items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <div class="table_summary mb_large text_total chanel_text_padding_right">
        合計金額
        <div class="ml_large">
          {{ showPrice(order.total_amount) }}円<span class="summary_small">（税抜：{{ showPrice(order.tax_amount) }}円）</span>
        </div>
      </div>

      <div class="form_row mb_large chanel_text_padding">
        <label for="" class="form_label">届け先</label>
        <div class="form_text">
          <div class="bold">{{ order.restaurant_name }}</div>
        </div>
      </div>

      <div class="form_row center mb_large chanel_text_padding">
        <label for="staff" class="form_label">発注担当者</label>
        <div class="form_text">{{ order.order_staff_name }}</div>
      </div>

      <div class="form_row center mb_large chanel_text_padding">
        <label for="date" class="form_label">納品希望日</label>
        <div class="form_text">{{ showDate(order.delivery_date) }}</div>
      </div>

      <div class="form_row chanel_text_padding">
        <label for="comment" class="form_label">備考欄</label>
        <div class="form_text form_text-mess">{{ order.message }}</div>
      </div>
    </div>
    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <div class="mb_xxlarge pre-formatted text-center">
        再注文対象の発注先、もしくは商品が全て無効となっているため、再注文が出来ません。
      </div>
      <div class="btn_area control">
        <router-link :to="{}" class="btn primary" @click.native="closeDialog">OK</router-link>
      </div>
    </div>
    <div class="control_area">
      <div class="icon_circle" @click="redirectToOrderHistory();">
        <div class="icon back for_controlArea" />
      </div>
      <div class="btn_area control two_items">
        <router-link
          v-if="order.status === 'scheduled'"
          :to="{}"
          class="btn delete"
          @click.native="confirmCancelDialog = true"
        >送信予約キャンセル</router-link>
        <router-link
          v-if="order.status !== 'scheduled' && totalItem > countItemDelete && !order.supplier_deleted"
          :to="{}"
          class="btn primary"
          @click.native="handleConfirm(order.id)"
        >再発注する</router-link>
        <router-link
          v-if="(order.status == 'completed' || order.status == 'line_completed' || order.status == 'mail_completed') &&
                totalItem > countItemDelete && !order.supplier_deleted"
          :to="{}"
          class="btn primary ml_medium"
          @click.native="redirectCheckOrder(order.id)"
        >検品する</router-link>
        <router-link v-if="order.supplier_deleted" :to="{}" class="order_warning">
          <p class="order_warning">発注先が削除されているため再発注や検品ができません。</p>
        </router-link>
        <router-link v-else-if="totalItem === countItemDelete" :to="{}" class="order_warning">
          <p class="order_warning">一部の検品が削除されているため再発注や検品ができません。</p>
        </router-link>
      </div>
    </div>
    <!-- popup err -->
    <div class="bottom_menu" :class="dialogErr ? 'open' : ''">
      <h2 class="bottom_menu_title text_color-reb">
        エラー
      </h2>
      <div class="mb_medium">
        <p class="mb_small-left">発注書をアップロードしているか、共有期限が過ぎています。</p>
        <p class="mb_small mb_small-left mb_small-md">時間を置いてお試しいただくか、再度発注を行ってください。</p>
      </div> 
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary_border" @click.native="closeDialogErr">閉じる</router-link>
      </div>
    </div>
    <div class="bottom_menu" :class="confirmCancelDialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_large">
        <label for="from_date" class="mb_small mb_small-left mb_small-md">送信予約をキャンセルし、発注を取り消します。よろしいですか？</label>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn delete" @click.native="cancelOrder(order.id)">キャンセルする</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="confirmCancelDialog = false">閉じる</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog || dialogErr || confirmCancelDialog ? 'open' : ''" />
  </main>
</template>

<script>
import { OrdersService } from '../../services/orders.service';
import { RestaurantsService } from '../../services/restaurants.service.js';
import { SupplierItemsService } from '../../services/supplier_items.service';
import util from '../../common/util';
import moment from 'moment';
import Paging from '../../components/Pager';

export default {
  components: {
    Paging
  },
  data() {
    return {
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
      order: {},
      order_items: [],
      countItemDelete: null,
      totalItem: null,
      restaurant: {},
      dialog: false,
      dialogErr: false,
      confirmCancelDialog: false,
      supplierItems: [],
      line_share_status: '',
      line_share_url: '',
      statusName: {
        fail: 'FAX送信失敗',
        scheduled: 'FAX送信予約',
        cancelled: 'FAX送信キャンセル',
        doing: 'FAX送信中',
        completed: 'FAX送信成功',
        mail_doing: 'メール送信中',
        mail_completed: 'メール送信済',
        checking: 'FAXステータス取得中',
        bounced: 'メール送信失敗',
        line_doing: 'LINE共有(未)',
        line_completed: 'LINE共有(済)',
      },
      statusLabel: {
        scheduled: 'info',
        cancelled: 'fail',
        fail: 'fail',
        bounced: 'fail',
        line_doing: 'fail',
      },
      statusIcon: {
        scheduled: 'check_primary',
        cancelled: 'attention',
        fail: 'attention',
        bounced: 'attention',
        line_doing: 'attention',
      },
      checkOrder: false
    };
  },
  computed: {
    statusMessage() {
      return {
        fail: '再度送信してください。',
        scheduled: '',
        doing: '',
        completed: '発注書は正常に送信されました。',
        mail_doing: '',
        mail_completed: '発注書は正常に送信されました。',
        checking: '',
        bounced: `発注先のメールアドレスをご確認ください。<a href="/order_company/${this.order?.supplier_id}/detail">>>メールアドレスの確認・修正へ</a>`,
        line_doing: '',
        line_completed: '',
      };
    },
  },
  created() {
    this.getOrder();
    this.getRestaurant();
  },
  methods: {
    ...util,
    getRestaurant: async function() {
      this.$loading(true);
      try {
        const { response } = await RestaurantsService.get();
        this.restaurant = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    getOrder: async function() {
      this.$loading(true);
      try {
        const params = {
          id: this.$router.history.current.params.id,
          page: this.pager.page,
          page_size: this.pager.pageSize,
        };
        const { response } = await OrdersService.getDetails(params);
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.countItemDelete = response.count_item_delete;
        this.totalItem = response.pager.item_count;
        this.order = response.data;
        this.order_items = this.order.order_items;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
      this.lineLink();
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getOrder();
      this.scrollTopScreen();
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    async lineLink () {
      try {
        const { response } = await OrdersService.lineFileExits(this.$router.history.current.params.id);
        this.line_share_status = response.result.status;
        this.line_share_url = 'http://line.me/R/msg/text/?' + encodeURI('発注のご依頼 (' + this.order.supplier_name + ' 様)発注書を添付いたします。\n' + this.order.share_url + '\n'), '_blank';
      } catch (error) {
        this.line_share_status = 'NG';
        console.log(error);
      }
    },

    async updateLineCompleted() {
      if (this.order.status  == 'line_doing') {
        try {
          await OrdersService.updateStatus(this.$router.history.current.params.id);
          location.reload();
        } catch (error) {
          console.log(error);
        }
      }
    },
    redirectToOrderHistory() {
      this.$router.push({ path: `/order_history` });
    },

    handleConfirm(orderId) {
      if (this.totalItem === this.countItemDelete || this.order.supplier_deleted) {
        this.dialog = true;
      } else {
        this.$router.push({ path: `/order/order_cart?reorder_id=${orderId}` });
      }
    },

    showDate(date) {
      return moment(date).locale('ja').format('YYYY/MM/DD（dd）');
    },

    showDateTime(date) {
      return moment(date).locale('ja').format('YYYY/MM/DD（dd）HH:mm');
    },

    openDialogErr() {
      this.dialogErr = true;
    },

    closeDialogErr() {
      this.dialogErr = false;
      location.reload();
    },

    closeDialog() {
      this.dialog = false;
    },

    async cancelOrder(orderId) {
      this.$loading(true);
      try {
        await OrdersService.cancel(orderId);
        this.$loading(false);
        this.$router.push({ path: '/order_history' });
      } catch (error) {
        console.log(error);
        this.$loading(false);
        this.confirmCancelDialog = false;
        const errorMessage = error.response.result.message[0];
        await this.$popupMessage('エラー', errorMessage, { type: 'error' });
      }
    },

    redirectCheckOrder(id) {
      this.$router.push({ path: `/order_history/${id}/check` });
    }
  },
};
</script>
<style scoped>
.form_main {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  vertical-align: text-bottom;
}

.disabled {
  cursor: pointer !important;
  pointer-events: unset !important;
}

.sr_table-r {
  text-align: right;
}
.sr_table-name {
  width: 55% !important;
}
.sr_table-price {
  width: 25% !important;
}
.sr_table-qty {
  width: 20% !important;
}
.form_text-mess {
  word-break: break-all;
}
.order_warning {
  color: #D52C2C !important;
  font-weight: bolder;
}
.text_deficient {
  color: #D52C2C;
  font-weight: bold;
}
@media screen and (max-width: 480px) {
  .main_area {
    padding: 0px;
  }
  .padding_text {
    margin-bottom: 8px;
  }
  .status_text-mb {
    margin-left: 8px;
  }
  .order_warning {
    font-size: 14px;
  }
  .status-check-label {
    padding-top: 2px;
  }
}
</style>
