<template>
  <div class="body_owner">
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Vue from 'vue';
import Footer from './Footer.vue';
export default Vue.extend({
  components: {
    Footer,
  },
  data() {
    return {
    };
  },
  methods: {
  },
});
</script>
<style scoped>
</style>
