import { BaseService } from './base.service';
import { ResponseWrapper, ApiError } from './http';

export class SuppliersService extends BaseService {
  static get resourceEndpoint() {
    return 'suppliers';
  }

  static async ocrItemImport(id, data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/${id}/items/ocr-import`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      return error;
    }
  }

  static async csvItemImport(id, data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/${id}/items/csv-import`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async csvExport(data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/csv_export`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
