import { BaseService } from './base.service';
import {ApiError, ResponseWrapper} from './http';

export class RestaurantUploadFilesService extends BaseService {
  static get resourceEndpoint() {
    return 'restaurant_upload_files';
  }

  static async checkDuplicate(data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/check-duplicate`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
