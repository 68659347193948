<template>
  <div id="supplierForm" class="modal modal-supplier">
    <div class="modal-backdrop" />
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <h1 class="title_admin">{{ restaurantAdministrator.shop_name }} 発注先登録</h1>
          <div class="icon close" />
        </div>
      </div>
      <!--body-->
      <div class="modal-body">
        <div v-if="isEditing" class="admin_content">
          <form action="">
            <div class="form-row">
              <label for="official_name" class="form_label form-20">発注先企業名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="official_name" v-model="officialName" type="text"
                       placeholder="発注先企業名を入力"
                >
                <span v-for="error in errors['officialName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="name" class="form_label form-20">発注先企業名（略称）</label>
              <div class="flex-column form-50">
                <input id="name" v-model="name" type="text"
                       placeholder="発注先企業名（略称）を入力"
                >
                <span v-for="error in errors['name']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="tel" class="form_label form-20">電話番号</label>
              <div class="flex-column form-50">
                <input id="tel" v-model="tel" type="text"
                       placeholder="電話番号を入力"
                >
                <span v-for="error in errors['tel']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="fax" class="form_label form-20">FAX番号<span v-if="orderType === 'fax'" class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="fax" v-model="fax" type="text"
                       placeholder="FAX番号を入力"
                >
                <span v-for="error in errors['fax']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="email" class="form_label form-20">メールアドレス<span v-if="orderType === 'email'" class="require">必須</span></label>
              <div class="flex-column form-50">
                <SupplierEmailsInputAdmin
                  v-on:getInputs="getEmailInputs"
                  :supplier-emails="supplierEmails"
                  :change-value-emails="changeValueEmails"
                  :supplier-id="supplierId">
                </SupplierEmailsInputAdmin>
                <span v-for="error in errors['email']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="orderType" class="form_label form-20">発注方法<span class="require">必須</span></label>
              <div class="radio_position">
                <label v-for="(v, k) in orderTypes" :key="k" class="radio_btn width_120">{{ v }}
                  <input v-model="orderType" type="radio" :value="k">
                  <span class="checkmark" />
                </label>
                <span v-for="error in errors['orderType']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div v-if="orderType == 'fax'" class="form-row">
              <label for="order_template" class="form_label form-20">発注書テンプレート</label>
              <div class="flex-column form-50">
                <div class="select-wrapper">
                  <select id="order_template" v-model="mediaSheetClass">
                    <option value="MediaSheet:Smartrequest">SmartRequestデフォルト</option>
                    <option value="MediaSheet:Ryque">RYQUE（リクエ）フォーマット</option>
                  </select>
                </div>
                <p class="small_text mt_small">発注書テンプレートをRYQUEに変更する場合、既存商品の商品番号も登録必要です。</p>
              </div>
            </div>
            <div v-if="orderType == 'fax' && mediaSheetClass == 'MediaSheet:Ryque'" class="form-row">
              <label for="orderNo" class="form_label form-20">注文用紙番号<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="orderNo" v-model="orderNo" type="tel" placeholder="数字3桁">
                <span v-for="error in errors['orderNo']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div v-if="orderType == 'fax' && mediaSheetClass == 'MediaSheet:Ryque'" class="form-row">
              <label for="customerNo" class="form_label form-20">お客様NO<span class="require">必須</span></label>
              <div class="input_wrapper medium">
                <input id="customerNo" v-model="customerNo" type="tel" placeholder="数字6桁">
                <span v-for="error in errors['customerNo']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="min_order_amount" class="form_label form-20">最低発注金額</label>
              <div class="flex-column form-50">
                <input id="min_order_amount" v-model="minOrderAmount" type="text"
                       placeholder="最低発注金額を入力"
                >
                <span v-for="error in errors['minOrderAmount']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="note" class="form_label form-20">備考欄</label>
              <div class="flex-column form-50">
                <textarea id="note" v-model="note"
                          rows="6" placeholder="何か記載内容があればご記載ください。"
                />
                <span v-for="error in errors['note']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </form>
        </div>
        <div v-if="!isEditing" class="admin_content">
          <div class="form-row">
            <label class="form_label form-20">発注先企業名</label>
            <div class="form_label form-50">{{ officialName }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">発注先企業名（略称）</label>
            <div class="form_label form-50">{{ name }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">電話番号</label>
            <div class="form_label form-50">{{ tel }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">FAX番号</label>
            <div class="form_label form-50">{{ fax }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">メールアドレス</label>
            <div class="form_label form-50">{{ emailDetail }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">発注方法</label>
            <div class="form_label form-50">{{ orderTypes[orderType] }}</div>
          </div>
          <div class="form-row" v-if="orderType == 'fax'">
            <label class="form_label form-20">発注書テンプレート</label>
            <div class="form_label form-50">{{ mediaSheetClass == 'MediaSheet:Ryque' ? "RYQUE（リクエ）フォーマット" : "SmartRequestデフォルト" }}</div>
          </div>
          <div class="form-row" v-if="orderType == 'fax' && mediaSheetClass == 'MediaSheet:Ryque'">
            <label class="form_label form-20">注文用紙番号</label>
            <div class="form_label form-50">{{ orderNo }}</div>
          </div>
          <div class="form-row" v-if="orderType == 'fax' && mediaSheetClass == 'MediaSheet:Ryque'">
            <label class="form_label form-20">お客様NO</label>
            <div class="form_label form-50">{{ customerNo }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">最低発注金額</label>
            <div class="form_label form-50">{{ minOrderAmount }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">備考欄</label>
            <div class="form_label form-50">{{ note }}</div>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <div v-if="isEditing">
          <a v-if="!supplierId" class="btn_admin admin_primary" href="#"
             @click="handleSave(true)"
          >続けて商品を登録</a>
          <a class="btn_admin admin_primary" href="#" @click="handleSave()">{{ supplierId ? '更新' : '発注先を登録' }}</a>
        </div>
        <div v-if="!isEditing">
          <a class="btn_admin admin_primary" href="#" @click="$emit('updateSelectedSupplier', supplierId, true); changeValueEmails = !changeValueEmails">編集</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CorrectNumber } from '../../../../common/util';
import Validator from '../../../../validator';
import { SuppliersService } from '../../../../services/admin/suppliers.service';
import SupplierEmailsInputAdmin from './../../../../components/SupplierEmailsInputAdmin.vue';

export default {
  components: {
    SupplierEmailsInputAdmin
  },
  props: {
    supplierId: {
      type: Number,
      require: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    restaurantAdministrator: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      supplierEmails: [],
      officialName: null,
      name: null,
      tel: null,
      fax: null,
      email: null,
      orderType: 'email',
      minOrderAmount: null,
      note: null,
      errors: {},
      orderTypes: {
        email: 'メール',
        fax: 'FAX',
        line: 'LINE',
      },
      restaurant_administrator_id: this.$router.history.current.params.id,
      mediaSheetClass: 'MediaSheet:Smartrequest',
      orderNo: null,
      customerNo: null,
      changeValueEmails: false,
      emailDetail: null,
    };
  },
  watch: {
    supplierId: async function (val) {
      this.supplierEmails = [];
      this.errors = [];
      if (val) {
        this.$loading(true);
        try {
          const {response} = await SuppliersService.get(val);
          this.setFormData(response.data);
          this.$loading(false);
        } catch (error) {
          console.log(error);
          this.$loading(false);
        }
      } else {
        this.setFormData({});
      }
    },
  },
  methods: {
    getEmailInputs(inputs) {
      this.supplierEmails = inputs.supplierEmailsEdit;
    },
    setFormData(supplier) {
      this.officialName = supplier.supplier_official_name;
      this.name = supplier.name;
      this.tel = supplier.tel;
      this.fax = supplier.fax;
      this.email = supplier.email;
      this.orderType = supplier.order_type || 'email';
      this.minOrderAmount = supplier.min_order_amount;
      this.note = supplier.note;
      this.mediaSheetClass = supplier.media_sheet_class;

      this.orderNo = supplier.order_no;
      this.customerNo = supplier.customer_no;
      if (this.supplierId) {
        this.changeValueEmails = !this.changeValueEmails
        this.supplierEmails = supplier.supplier_emails && supplier.supplier_emails.length === 0 && this.email ? [{email: this.email}] : supplier.supplier_emails;
        if (supplier.supplier_emails && supplier.supplier_emails.length > 0) {
          this.emailDetail = supplier.supplier_emails.map( (supplier_email, i) => `${supplier_email.email}, `).join(' ');
          this.emailDetail = this.emailDetail.slice(0, this.emailDetail.length - 2);
        }
        else if(supplier.email) {
          this.emailDetail = supplier.email;
        }
        else {
          this.emailDetail = null;
        }
      }
    },
    onModalClose() {
      this.$emit('updateSelectedSupplier', null);
      this.setFormData({});
    },
    handleSave: async function (gotoItemsAfterSave = false) {
      this.email = this.supplierEmails.filter(supplierEmail => supplierEmail._destroy === false).length === 0 ? 
        null : this.supplierEmails.filter(supplierEmail => supplierEmail._destroy === false)[0].email;
      this.validate();
      if (Object.keys(this.errors).length) return;

      const params = {
        restaurant_administrator_id: this.restaurant_administrator_id,
        supplier_official_name: this.officialName,
        name: this.name,
        tel: CorrectNumber(this.tel),
        fax: CorrectNumber(this.fax),
        email: this.email,
        order_type: this.orderType,
        min_order_amount: this.minOrderAmount,
        note: this.note,
        media_sheet_class: this.orderType == 'email' || this.orderType == 'line' ? 'MediaSheet:Smartrequest' : this.mediaSheetClass
      };

      params.supplier_emails_attributes = this.supplierEmails

      this.$loading(true);
      try {
        if (this.orderType == 'fax' && this.mediaSheetClass == 'MediaSheet:Ryque') {
          params.order_no = this.orderNo;
          params.customer_no = this.customerNo;
        }

        let supplierId = this.supplierId;
        if (this.supplierId) {
          await SuppliersService.update(this.supplierId, params);
        } else {
          const {response} = await SuppliersService.create(params);
          supplierId = response.data.id;
        }
        this.$loading(false);
        this.$closeModal('supplierForm');
        this.$emit('refreshTable');
        if (gotoItemsAfterSave) {
          this.$router.push({ path: `/admin/restaurant_admin/${this.restaurant_administrator_id}/suppliers/${supplierId}/items` });
        }
      } catch (error) {
        console.log(error);
        this.$loading(false);
        const errorMessage = error.response.result.message[0];
        await this.$swal.fire({
          title: 'エラー',
          width: 600,
          html: `<p>${errorMessage}</p>`,
          confirmButtonText: '閉じる',
          confirmButtonClass: 'btn_admin admin_primary_border',
          showCloseButton: false,
        });
      }
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire('officialName', this.officialName, '発注先企業名') &&
        validator.checkMaxLength('officialName', this.officialName, 255, '発注先企業名');

      validator.checkMaxLength('name', this.supplierName, 255, '発注先企業名（略称）');

      if (this.tel) {
        const tel =  CorrectNumber(this.tel);
        validator.checkRequire('tel', tel, '電話番号') &&
          validator.checkMaxLength('tel', tel, 11, '電話番号') &&
          validator.checkMinLength('tel', tel, 10, '電話番号');
        if (!validator.errors.tel) {
          validator.checkPhone('tel', CorrectNumber(this.tel), '電話番号');
        }
      }

      if (this.fax) {
        const fax =  CorrectNumber(this.fax);
        validator.checkMaxLength('fax', fax, 11, 'FAX番号') &&
          validator.checkMinLength('fax', fax, 10, 'FAX番号');
        if (!validator.errors.fax) {
          validator.checkPhone('fax', fax, 'FAX番号');
        }
      }

      if (this.orderType == 'email') {
        validator.checkRequire('email', this.email, 'メールアドレス');
      }
      this.supplierEmails.filter(supplierEmail => !supplierEmail._destroy).forEach(supplierEmail => {
        if (!validator.errors.email) {
          validator.checkEmail('email', supplierEmail.email, 'メールアドレス');
          validator.checkMaxLength('email', supplierEmail.email, 255, 'メールアドレス');
          if (this.supplierEmails.filter(supplierEmailSearch => !supplierEmailSearch._destroy && supplierEmailSearch.email == supplierEmail.email).length > 1) {
            validator.errors.email = ['メールがすでに登録されています。']
          }
        }
      });
      this.orderType === 'fax' && validator.checkRequire('fax', CorrectNumber(this.fax), 'FAX番号');

      if (this.minOrderAmount) {
        validator.checkDigit('minOrderAmount', this.minOrderAmount, '最低発注金額') &&
          validator.checkMaxNumber('minOrderAmount', this.minOrderAmount, 9, '最低発注金額');
      }

      if (this.orderType == 'fax' && this.mediaSheetClass == 'MediaSheet:Ryque') {
        validator.checkRequire('orderNo', this.orderNo, '注文用紙番号') &&
          validator.checkDigit('orderNo', this.orderNo, '注文用紙番号') &&
          validator.checkLength('orderNo', this.orderNo, 3, '注文用紙番号');

        validator.checkRequire('customerNo', this.customerNo, 'お客様NO') &&
          validator.checkDigit('customerNo', this.customerNo, 'お客様NO') &&
          validator.checkLength('customerNo', this.customerNo, 6, 'お客様NO');
      }

      validator.checkMaxLength('note', this.note, 255, '備考欄');

      this.errors = validator.errors;
    },
  },
};
</script>

<style scoped>
.modal-supplier .modal-content {
  min-width: 800px;
  width: 800px;
}
</style>

