import { ResponseWrapper, ApiError } from './http';

import { BaseService } from './base.service';

export class NotificationService extends BaseService {
  static get resourceEndpoint() {
    return 'notifications';
  }

  static async get(params) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/${params}`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async read(id = []) {
    try {
      const response = await this.request().post(
        `notification/${id}/read`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

}
