<template>
  <div>
    <div id="createEditNotification" class="modal modal-notification">
      <div class="modal-backdrop" />
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header">
          <div class="modal-menu flex">
            <h1 class="title_admin">お知らせ登録</h1>
            <div class="icon close_modal" @click="resetForm" />
          </div>
        </div>
        <!--body-->
        <div class="modal-body">
          <div class="admin_content">
            <Form ref="formNotification" :notification="notification" @refreshTable="$emit('refreshTable')"
                  @updateSelectedNotification="$emit('updateSelectedNotification')"
            />
          </div>
        </div>
        <!--Footer-->
        <div class="modal-footer">
          <a class="btn_admin admin_primary" href="#"
             @click="$refs.formNotification.handleSave(notificationId)"
          >{{ notificationId > 0 ? '更新' : '登録' }}</a>
        </div>

      </div>
    </div>
  </div>
</template>


<script>

import Form from './Form';
import {NotificationsService} from '../../../../services/admin/notifications.service';

export default {
  components: {
    Form
  },
  props: {
    notificationId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      notification: {}
    };
  },
  watch: {
    notificationId: async function (val) {
      if (val > 0) {
        const {response} = await NotificationsService.get(val);
        this.notification = response.data;
      } else {
        this.notification = {};
      }
    },
  },
  methods:{
    resetForm: async function (){
      const confirm = await this.$swal.fire({
        title: '確認',
        width: 500,
        html: `<div class='wrapper_popup'><p>現在入力されている内容は破棄されます。</p>
               <p>よろしいですか？</p></div>`,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonClass: 'btn_admin admin_primary',
        cancelButtonClass: 'btn_admin admin_primary_border',
      });
      if (confirm.isConfirmed) {
        await this.$refs.formNotification.clearForm();
        this.$closeModal('createEditNotification');
      }
    },
  }
};
</script>

<style scoped>
.modal-notification .modal-content {
  min-width: 800px;
  width: 800px;
}
</style>

