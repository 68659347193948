<template>
  <form class="search_form search_form_admin">
    <label :class="isHidden.form ? 'icon_shevrontop': 'icon_shevrondown'" class="form_title after"
           @click="isHidden.form = !isHidden.form"
    >絞込条件</label>
    <div v-if="isHidden.form" class="toggle_content">
      <div class="form_content ">
        <div class="row flex">
          <div class="column">
            <label class="form_label margin-text" for="from">対象日付</label>
            <div class="input_wrapper calender">
              <div class="input-group">
                <flat-pickr
                  id="from"
                  v-model="exportParams.from"
                  :config="config"
                  placeholder="対象日付（from）を入力"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <label class="form_label margin-text" for="to">&nbsp;</label>
            <div class="input_wrapper calender">
              <div class="input-group">
                <flat-pickr
                  id="to"
                  v-model="exportParams.to"
                  :config="config"
                  placeholder="対象日付（to）を入力"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_area_admin search">
        <a class="btn_admin admin_primary_border" href="#" @click="$emit('clearSearch')">検索条件をクリア</a>
        <a class="btn_admin admin_primary" href="#" @click="exportCsv">ダウンロード</a>
      </div>
    </div>
  </form>
</template>

<script>

import {Japanese} from 'flatpickr/dist/l10n/ja.js';

export default {
  props: {
    exportParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isHidden: {
        form: true
      },
      config: {
        enableTime: false,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d'
      },
    };
  },
  methods: {
    async exportCsv() {
      this.$emit('export', this.exportParams);
    }
  }
};
</script>

<style scoped>

.input_wrapper.calender::after {
  right: 32px;
}

.modal .input_wrapper.calender::after {
  right: 16px;
}

</style>


