<template>
  <main class="container bottomUp">
    <div id="body_user" class="main_area smp_content_main">
      <h1 class="title mb_large">商品編集</h1>
      <item ref="item" :item="item" :item-categories="itemCategories"
            :display-dialog="displayDialog" :scroll-top-screen="scrollTopScreen"
      />
    </div>
    <div class="control_area">
      <router-link :to="{}" class="icon_circle" @click.native="backEdit">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control two_items">
        <a class="btn primary btn_point" @click="$refs.item.handleSave()">更新する</a>
        <a class="btn delete ml_small btn_point delete_trigger" @click="$refs.item.handleDelete()">削除する</a>
      </div>
    </div>
    <PopupErrorUser :show-dialog="showDialog" :close-dialog="disableShowDialog" />
  </main>
</template>

<script>
import { ItemsService } from '../../../services/items.service';
import { ItemCategoriesService } from '../../../services/item_categories.service';
import Item from '../components/Item.vue';
import PopupErrorUser from './../../../components/PopupErrorUser.vue';

export default {
  components: {
    Item,
    PopupErrorUser
  },
  data() {
    return {
      item: {},
      itemCategories: [],
      id: this.$router.history.current.params.id,
      showDialog: false
    };
  },
  async mounted() {
    this.$loading(true);
    await this.getItemCategories();
    try {
      const { response } = await ItemsService.get(this.$router.history.current.params.item_id);
      this.item = response.data;
      this.$loading(false);
    } catch (error) {
      this.$loading(false);
    }
  },
  methods: {
    async getItemCategories() {
      this.$loading(true);
      try {
        const { response } = await ItemCategoriesService.index();
        this.itemCategories = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    async backEdit() {
      await this.$store.dispatch('changeTab', 1);
      this.$router.push({ path: `/order_company/${this.id}/detail` });
    },
    disableShowDialog() {
      this.showDialog = false;
    },
    displayDialog() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
      this.showDialog = true;
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
  },
};
</script>
