<template>
  <div class="register-success-container wrapper_owner_have_button">
    <h1 class="color_owner_text owner_text_title mb_medium">パスワード設定完了</h1>
    <span class="note_sp">パスワードを設定しました。</span><br>
    <span class="note_sp">オーナーIDとパスワードを使用してログインを行い、店舗の情報を登録しましょう。</span><br>
    <div class="success-container">
      <label class="text-step font-step_12">
        次のSTEPは
        <span class="font-step_24">店舗登録</span>
        です！
      </label>
      <div class="image-home" />
      <span>店舗登録の際、店舗IDとパスワードを設定します。</span><br>
      <span>店舗IDとパスワードを用いて、店舗様がログイン可能になります。</span><br>
    </div>

    <div class="btn_area_owner control two_items btn_button">
      <router-link :to="{}" class="btn_owner primary_border" @click.native="toLoginScreen()">ログイン画面へ</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toLoginScreen(){
      window.location.href = '/';
    },
  }
};
</script>

<style scoped>
@media screen and (max-width: 480px) {
  .btn_padding_40 {
    padding-bottom: 0px;
  }
}
.main_password_setting
  .btn_button {
    margin-bottom: 0 !important;
    position: absolute !important;
}
.success-container {
  width: 55%;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .success-container {
    width: 70%;
  }
}
@media screen and (max-width: 480px) {
  .success-container {
    width: 100%;
  }
}
</style>