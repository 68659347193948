<template>
  <main class="main_cart">
    <ul>
      <template v-for="(cartItem) in cartItems">
        <li class="item_list" v-if="!cartItem._destroy">
          <div class="item_list_wrapper">
            <div class="item_list_content" :class="settingImage == true ? '' : 'item_list_content-no_img'">
              <div class="item_list_favorite">
                <label class="btn_checkbox ml-auto item_right">
                  <input
                    v-model="cartItem.selected"
                    class="owner_padding_checkbox"
                    type="checkbox"
                    @change="$emit('selectCartItem')"
                  >
                  <span class="checkmark_checkbox" :class="isReorder ? 'disabled_checkbox' : ''" />
                </label>
              </div>
              <div v-show="settingImage == true" v-lazy-container="{ selector: 'img' }" class="item_list_image ml_medium">
                <img :data-src="cartItem.item_img">
              </div>
              <div style="width: -webkit-fill-available" class="item_list-title">
                <div class="item_name item_list-name">{{ cartItem.item_name }}</div>
                <div class="item_price">
                  <div class="item_unit">
                    数量（{{ cartItem.item_unit }}） 
                  </div>
                  <div>
                    単価（税込）{{ showPrice(cartItem.item_price) }} 円
                  </div>
                </div>
                <a class="list_delete" :class="isReorder ? 'disabled_link' : ''" @click="deleteCartItem(cartItem)">リストから削除する</a>
              </div>
              <div>
                <div class="sp_align">
                  <div class="item_list_content_input item_list_option">
                    <div class="input_wrapper controller large ml_small input_wrapper-sm">
                      <button class="minus"
                        :class="parseFloat(cartItem.item_qty) <= 1 || cartItem.item_qty.length <= 0 || !cartItem.item_qty || isReorder ? 'disabled' : ''"
                        :disabled="parseFloat(cartItem.item_qty) <= 1 || cartItem.item_qty.length <= 0 || !cartItem.item_qty || isReorder"
                        @click="changeQty(cartItem, '-')"
                      />
                      <input
                        ref="qty"
                        :value="cartItem.item_qty"
                        placeholder="0"
                        type="number"
                        maxlength="10"
                        :disabled="isReorder"
                        @paste.prevent
                        @wheel="$event.target.blur()"
                        @keypress="changeQuantity($event, cartItem)"
                        @keyup="changeQuantity($event, cartItem)"
                      >
                      <button class="plus" :class="isReorder ? 'disabled' : ''" :disabled="isReorder" @click="changeQty(cartItem, '+')" />
                    </div>
                  </div>
                </div>
                <div v-if="!cartItem.item_qty || cartItem.item_qty == 0" class="alert text_center mb_small is_valid">数量を正しく入力してください。 </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <div v-show="supplierItems.isDisabled" class="bold alert text_center mb_small list_text-w">
          {{ supplierItems.supplier_name }}の最低注文金額 {{ supplierItems.min_order_amount }}円を超えていません。
        </div> -->
      </template>
    </ul>
  </main>
</template>
<script>
import utils from '../../../common/util.js';
export default {
  props: {
    cartItems: {
      type: Array,
      required: true
    },
    settingImage: {
      type: Boolean,
      require: true
    },
    isReorder: {
      type: Boolean
    }
  },
  data() {
    return {
      old_val: '',
      qty_val: '',
      price_val: ''
    };
  },
  methods: {
    ...utils,
    changeQty(cartItem, type) {
      if (type === '-') {
        if (cartItem.item_qty > 1 ) {
          if (cartItem.item_qty == parseInt(cartItem.item_qty)){
            cartItem.item_qty = parseInt(cartItem.item_qty) - 1;
          } else {
            cartItem.item_qty = parseFloat(parseFloat(cartItem.item_qty) - 1.0).toFixed(1);
          }
        }
        else {
          cartItem.item_qty = '';
        }
      } else  {
        if (!cartItem.item_qty) {
          cartItem.item_qty = 1;
        } else if ((cartItem.item_qty) < 999999999) {
          if (cartItem.item_qty == parseInt(cartItem.item_qty)){
            cartItem.item_qty = parseInt(cartItem.item_qty) + 1;
          } else {
            cartItem.item_qty = parseFloat(parseFloat(cartItem.item_qty) + 1.0).toFixed(1);
          }
        }
      }
      this.$emit('checkErrQty');
      this.$emit('calculatePrice');
      this.$emit('changeItemQty');
    },
    changeQuantity(event, cartItem) {
      if (/^[+-]?\d+(\.\d{0,1})?$/.test(event.target.value) || /^\d{0,6}(?:\.\d{0,1})?$/.test(event.target.value)) {
        this.qty_val = event.target.value;
      } else {
        event.target.value = this.qty_val;
        return event.preventDefault();
      }
      if ((event.target.value.indexOf('.') !== -1 && event.key === '.') || (!/\d/.test(event.key) && event.key !== '.') || event.target.value.length >= 9) {
        event.preventDefault();
      }
      cartItem.item_qty = event.target.value;
      
      this.$emit('checkErrQty');
      this.$emit('calculatePrice');
      this.$emit('changeItemQty');
    },
    isFloat(cartItem) {
      if (cartItem.qty && !/^\d+(\.\d+)?$/.test(cartItem.qty)) {
        return true;
      } else {
        return false;
      }
    },
    deleteCartItem(cartItem) {
      cartItem._destroy = true;
      cartItem.selected = false;
      this.cartItems.push();
      this.$emit('calculatePrice');
      this.$emit('selectCartItem');
    }
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  background: #ACACAC !important;
}
.is_valid {
  margin-top: 8px;
  font-size: 16px;
  white-space: nowrap;
}
.sp_align {
  display: grid;
  justify-items: flex-end;
}
.item_list_content_input p {
  white-space: nowrap;
}
.item_name {
  width: unset !important;
}
.order_unit {
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.5em;
}
.item_list-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.item_list_option {
  display: grid !important;
  grid-template-rows: auto auto;
  grid-row-gap: 8px;
}
.item_list_label {
  justify-self: end;
}
.item_list_image img {
  object-fit: scale-down !important;
}
.list_text-w {
  word-break: break-all;
  margin-top: 10px;
}
.list_delete {
    display: block;
    font-size: 16px;
    color: #D52C2C;
    text-align: left;
    cursor: pointer;
    margin-top: 22px;
}
.main_cart {
  width: 100%;
  ul {
    li {
      padding: 8px 24px;
      .item_list_content {
        padding-left: 0;
      }
      .item_list_content {
        display: grid;
        grid-template-columns: auto auto 1fr auto;
        &-no_img {
          grid-template-columns: 1fr max-content;
        }
      }
      .item_name {
        margin: 0 !important;
        line-height: 1.5em;
        height: 1.5em;
      }
    }
  }
  .item_list_image {
    width: 60px;
    height: 60px;
    margin: 0 10px 0 0 !important;
  }
}
@media screen and (max-width: 1025px) {
  .main_cart {
    ul {
      li {
        border: unset;
        border-bottom: 1px solid #BBBBBB;
        .item_list_content {
          padding-left: 0;
        }
      }
      li:first-child {
        border-top: 1px solid #BBBBBB;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .main_item {
    margin: 0px -24px;
  }
  .item_list_content {
    padding-left: 0 !important;
    padding-top: 15px;
  }
  .item_list_label {
    justify-self: start;
  }
  .main_cart {
    ul {
      li {
        margin-left: 0px;
        margin-right: 0px;
        padding: 8px 8px;
        .item_name {
          font-size: 12px;
          margin: 0 !important;
          line-height: 1.5em;
          height: 3em;
        }
        .item_price {
          font-size: 12px;
          display: block;
          .item_unit {
            margin-bottom: 5px;
          }
        }
        .input_wrapper-sm {
          width: 120px !important;
          input {
            font-size: 16px !important;
            width: 120px;
            height: 40px;
            padding: 0 41px !important;
          }
            button {
            width: 35px !important;
            height: 40px !important;
          }
        }
        .item_list-supplier {
          font-size: 16px;
        }
        .item_list-title {
          padding-top: 0;
        }
        .item_list_content {
          padding-top: 0;
        }
      }
      .list_delete {
        margin-top: 8px;
        font-size: 12px;
      }
    }
    .is_valid {
      width: 130px;
      white-space: normal;
      word-break: break-all;
      font-size: 12px;
    }
    .item_list_image {
      width: 40px;
      height: 40px;
    }
  }
  .order_unit {
    line-height: 1;
  }
  .mb_large_cart {
    margin-bottom: 16px;
  }
  .mb_medium_cart {
    margin-bottom: 8px;
  }
}
.btn_checkbox {
  margin-bottom: 20px;
}
.checkmark_checkbox {
  top: 0px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.btn_checkbox input:checked ~ .checkmark_checkbox {
  background-color: #966544;
}
.btn_checkbox .checkmark_checkbox:after {
  left: 7px;
  top: 3px;
  width: 6px;
  border: 1px solid white;
  border-width: 0 3px 3px 0;
}

.disabled_checkbox {
  background: #ACACAC !important;
  border-color: #ACACAC !important;
  pointer-events: none;
}

.disabled_link {
  pointer-events: none;
  color: #ACACAC;
}
.item_price {
  display: flex;
}
</style>
