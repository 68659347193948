<template>
  <div v-show="isActive" class=" order_manage_area chanel_text_padding" :class="{ active: isActive }">
    <h1 class="title mb_medium">発注先詳細</h1>
    <div v-if="supplier.has_scheduled_order" class="status_text error mb_small">発注書の送信予約が設定されているため、編集できません</div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">発注先企業名</div>
      <div class="form_text pre_format">{{ supplier.supplier_official_name ? supplier.supplier_official_name : '設定なし' }}</div>
    </div>
    <div class="form_row vertical mb_medium ">
      <div class="form_label">発注先企業名（略称）</div>
      <div class="form_text pre_format">{{ supplier.name }}</div>
    </div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">電話番号</div>
      <div class="form_text">{{ supplier.tel ? supplier.tel : '設定なし' }}</div>
    </div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">FAX番号</div>
      <div class="form_text">{{ supplier.fax? supplier.fax : '設定なし' }}</div>
    </div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">メールアドレス</div>
      <div class="form_text" style="word-break: break-all;">{{ emails }}</div>
    </div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">発注方法</div>
      <div class="form_text">{{ supplier.order_type === 'email' ? 'メール' : supplier.order_type }}</div>
    </div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">最低発注金額</div>
      <div class="form_text">{{ supplier.min_order_amount ? supplier.min_order_amount + '円' : '設定なし' }}</div>
    </div>
    <div class="form_row vertical mb_medium">
      <div class="form_label">備考欄</div>
      <div class="form_text pre_format">{{ supplier.note ? supplier.note : '記載なし' }}</div>
    </div>
    <div v-if="supplier.order_type == 'fax'" class="form_row vertical mb_medium">
      <div class="form_label">発注書テンプレート</div>
      <div class="form_text pre_format">{{ supplier.media_sheet_class == 'MediaSheet:Ryque' ? "RYQUE（リクエ）フォーマット" : "SmartRequestデフォルト" }}</div>
    </div>
    <div v-if="supplier.media_sheet_class == 'MediaSheet:Ryque'" class="form_row vertical mb_medium">
      <div class="form_label">注文用紙番号</div>
      <div class="form_text pre_format">{{ supplier.order_no }}</div>
    </div>
    <div v-if="supplier.media_sheet_class == 'MediaSheet:Ryque'" class="form_row vertical mb_medium">
      <div class="form_label">お客様NO</div>
      <div class="form_text pre_format">{{ supplier.customer_no }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      emails: '',
    }
  },
  watch: {
    supplier: function() {
      this.getEmails();
    }
  },
  methods: {
    getEmails() {
      if (this.supplier.supplier_emails.length > 0) {
        this.emails = this.supplier.supplier_emails.map( (supplier_email, i) => `${supplier_email.email}, `).join(' ');
        this.emails = this.emails.slice(0, this.emails.length - 2);
      }
      else if(this.supplier.email) {
        this.emails = this.supplier.email;
      }
      else {
        this.emails = '設定なし';
      }
      return this.emails;
    }
  },
};
</script>
<style scoped>
.pre_format {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
  word-break: break-all;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .chanel_text_padding {
    padding: 100px 24px 0 24px;
  }
}
</style>
