<template>
  <div class="owner_main_area">
    <h2 class="mb_medium">店舗アカウント 解約申請の完了</h2>
    <div class="note_sp">店舗アカウントの解約申請が完了しました。</div>
    <div class="note_sp">
      {{ monthAt }}月{{ dayAt }}日の契約期間満了までは引き続きご利用いただけます。
    </div>
    <div class="note_sp">契約期間が満了しましたら店舗アカウントが解約されます。</div>
    <div class="note_sp">ご利用ありがとうございました。</div>
    <div class="btn_area_owner owner_btn_padding">
      <router-link to="/owner" class="btn btn_owner primary_border">
        アカウント管理へ
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      monthAt: '',
      dayAt: '',
    };
  },

  computed: {
    ...mapGetters({
      cancel_at: 'getRestaurantCancelAt',
    })
  },

  mounted() {
    this.formatDate();
  },

  methods: {
    formatDate() {
      let cancelAt;
      cancelAt = moment(this.cancel_at, 'YYYY/MM/DD').format('YYYY/MM/DD');
      this.monthAt = moment(cancelAt, 'YYYY/MM/DD').format('MM');
      this.dayAt = moment(cancelAt, 'YYYY/MM/DD').format('DD');
    }
  },
};
</script>
