<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        在庫一覧
      </h1>
      <paging
        v-if="items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th class="w_80">発注先/商品名</th>
            <th class="w_20">在庫量</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in items"
              :key="index"
          >
            <td>
              <div>
                <div class="text_down max-line">{{ data.supplier_name }}</div>
                <div class="text_down text_bold pt-10 max-line">{{ data.name }}</div>
              </div>
            </td>
            <td>{{ data.item_qty + " " + data.unit}} </td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <div v-else class="text-center mt-28">表示するデータはありません。</div>
    </div>
    <div class="control_area">
      <router-link to="/inventory" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control two_items is-pc-type">
        <a href="javascript:void(0);" @click.prevent="exportExcel" class="btn primary" :class="items === null ? 'disabled' : ''">出力する</a>
      </div>
    </div>
  </main>
</template>

<script>
import utils from '../../common/util.js';
import { ItemQtyHistoriesService } from '../../services/item_qty_histories.service';
import xlsx from "xlsx/dist/xlsx.full.min";
import moment from 'moment';
import Paging from '../../components/Pager';

export default {
  components: { Paging },
  data() {
    return {
      items: [],
      fileName: null,
      sheetName: "MySheets",
      headerExecl: [
        ["発注先", "商品名", "単価", "単価(単位)", "在庫量", "在庫量（単位）"]
      ],
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
    };
  },

  mounted() {
    this.getItems();
  },

  methods: {
    ...utils,

    async exportExcel() {
      this.$loading(true);
      const pagerExcel = {
        page: 1,
        totalPage: 0,
        pageSize: 500,
      }
      var items = [];
      const XLSX = xlsx;
      do {
        try {
          const params = {
            page: pagerExcel.page,
            page_size: pagerExcel.pageSize,
          };
          const { response } = await ItemQtyHistoriesService.index(params);
          pagerExcel.page = response.pager.page;
          pagerExcel.totalPage = response.pager.page_count;
          items = [...items, ...response.data];
          this.fileName = response.restaurant_name + "_在庫_" + moment().format('YYYYMMDD');
        } catch (error) {
          console.log(error);
        }
        pagerExcel.page++;
      } while (pagerExcel.page <= pagerExcel.totalPage);

      const rows = items.map(row => ({
        supplier_name: row.supplier_name,
        items_name: row.name,
        price: row.price,
        price_unit: '円',
        item_qty: row.item_qty,
        unit: row.unit
      }));
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ];

      XLSX.utils.sheet_add_aoa(worksheet, this.headerExecl, { origin: "A1" });
      XLSX.utils.book_append_sheet(workbook, worksheet, this.sheetName);
      XLSX.writeFile(workbook, this.fileName + ".xlsx");
      this.$loading(false);
    },
    async getItems() {
      this.$loading(true);
      try {
        const params = {
          page: this.pager.page,
          page_size: this.pager.pageSize,
        }
        const { response } = await ItemQtyHistoriesService.index(params);
        this.items = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    onPageChange(page) {
      this.scrollTopScreen();
      this.pager.page = page;
      this.getItems();
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.pt-10 {
  padding-top: 10px;
}
.text_down {
  word-break: break-all;
  white-space: normal;
}
.text_bold {
  font-weight: bold;
}
.is-pc-type {
  display: flex;
}
.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1.2;
}
@media screen and (max-width: 1024px) {
  .is-pc-type {
    display: none;
  }
}
</style>
