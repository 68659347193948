import {AuthService} from '../../services/admin/auth.service';
const admins = {
  state: {
    adminModals: {
      owner: null,
      restaurant: null,
    },
  },
  mutations: {
    SET_OWNER_MODAL(state, payload) {
      state.adminModals.owner = payload;
    },
    REMOVE_OWNER_MODAL(state) {
      state.adminModals.owner = null;
    }
  },

  actions: {
    async getAdmin(data) {
      try {
        await AuthService.checkToken(data);
        return;
      } catch (error) {
        return {status: 401};
      }
    },

    removeOwnerModal({commit}) {
      commit('REMOVE_OWNER_MODAL');
    },

    setOwnerModal({commit}, modalData) {
      commit('SET_OWNER_MODAL', modalData);
    }
  },

  getters: {
    getAdminModal: state => {
      return state.adminModals;
    }
  }
};

export default admins;
