<template>
  <section v-if="isLoadingPage" class="template_parts">
    <loading-page />
  </section>
  <section v-else class="template_parts">
    <div class="bottom_menu_bg" :class="openSideBar && openSideBar == true ? 'open' : ''" @click="closeSideBar" />
    <app-bar :hanlde-side-bar="hanldeSideBar" />
    <side-bar :open-side-bar="openSideBar" :close-side-bar="closeSideBar" :open-dialog="openDialog" />
    <router-view />
    <!-- popup logout -->
    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_large">ログアウトします。よろしいですか？</div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary" @click.native="logout">決定する</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeDialog">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog ? 'open' : ''" @click="closeDialog" />
  </section>
</template>

<script>
import Vue from 'vue';
import AppBar from './AppBar.vue';
import SideBar from './SideBar.vue';
import LoadingPage from '../components/LoadingPage';
import { mapGetters } from 'vuex';
import { AuthService } from '../services/auth.service';

export default Vue.extend({
  components: {
    AppBar,
    LoadingPage,
    SideBar
  },
  data() {
    return {
      openSideBar: null,
      dialog: false
    };
  },
  computed: {
    ...mapGetters({
      isLoadingPage: 'getLoadingStatus',
      hasNewMemos: 'hasNewMemos',
      getAllMemos: 'getAllMemos',
      isOwner: 'isOwner'
    })
  },
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    hanldeSideBar() {
      this.openSideBar = true;
    },
    closeSideBar() {
      this.openSideBar = false;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async logout() {
      try {
        this.$store.dispatch('clearOrder');
        this.$store.commit('SET_LOGIN_OWNER_FLAG', false);
        this.$store.commit('CLEAR_RESTAURANT');
        await AuthService.logout();
        return this.$router.push({ path: '/'});
      } catch (error) {
        this.$store.dispatch('clearOrder');
        this.$store.commit('SET_LOGIN_OWNER_FLAG', false);
        this.$store.commit('CLEAR_RESTAURANT');
        return this.$router.push({ path: '/'});
      }
    },
    handleResize() {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.body.classList.add('body_parts');
        var root = document.getElementsByTagName( 'html' )[0];
        root.setAttribute('class', 'html_parts');
      }
    }
  }
});
</script>
<style lang="scss">
  .html_parts {
    height: 100vh;
  }
  .body_parts {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100) !important;
  }
</style>
<style scoped>
  .template_parts {
    height: 100%;
    width: 100%;
    position: relative;
  }
</style>
