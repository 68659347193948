var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"sidebar sidebar-owner",class:[
      _vm.openSidebar ? 'sidebar-owner-open' : '',
      _vm.hideSidebar ? 'sidebar-owner-hide' : '' ]},[_c('ul',[_c('li',{staticClass:"owner_menu_content",on:{"click":_vm.handleSidebar}},[_c('router-link',{class:_vm.$route.path.includes('/owner/restaurant') ||
              _vm.$route.name === 'restaurant_dashboard' ||
              _vm.$route.name == 'owner_detail' ||
              _vm.$route.name == 'owner_delete' ||
              _vm.$route.name == 'owner_edit' ||
              _vm.$route.name == 'complete_delete_owner'
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/owner"}},[_vm._v("\n          アカウント管理\n        ")])],1),_vm._v(" "),_c('li',{on:{"click":_vm.handleSidebar}},[_c('router-link',{class:_vm.$route.path.includes('/owner/payment')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/owner/payment"}},[_vm._v("\n          支払管理\n        ")])],1),_vm._v(" "),(_vm.isPC)?_c('li',{on:{"click":_vm.openDialogCSV}},[_c('router-link',{attrs:{"id":"exportCsv","to":"#"}},[_vm._v("\n          発注データ出力\n        ")])],1):_vm._e(),_vm._v(" "),_c('li',{on:{"click":_vm.logoutMember}},[_c('router-link',{attrs:{"id":"logout","to":"#"}},[_vm._v(" ログアウト ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp_menu"},[_c('div',{staticClass:"logo_owner"}),_vm._v(" "),_c('div',{staticClass:"sp_menu_trigger"})])}]

export { render, staticRenderFns }