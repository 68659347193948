const orders = {
  state: {
    items: [],
    hasOrdersFlag: false,
    staff_id: null,
    countItemSelected: 0
  },
  mutations: {
    SET_ORDERS(state, payload) {
      state.items = [...payload];
    },
    SET_HAS_ORDERS_FLAG(state, payload) {
      state.hasOrdersFlag = payload;
    },
    CHANGE_ORDER(state, payload) {
      const indexOrder = state.items.findIndex(i => i.id === payload.id && i.supplier_id == payload.supplier_id);
      if(indexOrder > -1) {
        const data = [...state.items];
        data[indexOrder] = payload;
        return state.items = data;
      }else{
        if(parseInt(payload.price) >= 0 && parseInt(payload.qty) > 0) {
          state.items.push(payload);
        }
      }
    },
    REMOVE_ORDER(state, payload) {
      const indexOrder = state.items.findIndex(i => i.id === payload.id && i.supplier_id === payload.supplier_id);
      state.items.splice(indexOrder, 1);
    },
    CLEAR_ORDER(state) {
      state.items = [];
    },
    REMOVE_ORDER_WITH_ITEM(state, payload) {
      const data = [...state.items];
      if (payload.item_id) {
        for (let i = data.length -1; i >= 0; i--) {
          if (data[i].id == payload.item_id) {
            data.splice(i ,1);
          }
        }
        return state.items = data;
      } else if (payload.supplier_id) {
        for (let i = data.length -1; i >= 0; i--) {
          if (data[i].supplier_id == payload.supplier_id) {
            data.splice(i ,1);
          }
        }
        return state.items = data;
      } else {
        for (let i = data.length -1; i >= 0; i--) {
          if (data[i].supplier_id == payload.items_with_supplier.supplier_id) {
            const indexItem = payload.items_with_supplier.items.findIndex(j => j.item_id == data[i].id);
            if (indexItem === -1) {
              data.splice(i ,1);
            }
          }
        }
        return state.items = data;
      }
    },
    CHANGE_DETAIL_ORDER(state, payload) {
      const indexOrder = state.items.findIndex(i => i.id == payload.id);
      if(indexOrder > -1) {
        const data = [...state.items];
        data[indexOrder] = { ...data[indexOrder], name: payload.name, price: payload.price };
        return state.items = data;
      }
    },
    SET_STAFF_ID(state, payload) {
      state.staff_id = payload;
    },
    SET_ORDERED_IDS(state, payload) {
      state.ordered_ids = payload;
    },
    CLEAR_ORDERED_IDS(state) {
      state.ordered_ids = '';
    },
    SET_COUNT_ITEM_SELECTED(state, payload) {
      state.countItemSelected = payload;
    },
  },
  actions: {
    async setOrders({ commit }, data) {
      try {
        commit('SET_ORDERS', data);
      } catch (error) {
        commit('SET_ORDERS', []);
      }
    },
    async setHasOrdersFlag({ commit }, data) {
      try {
        commit('SET_HAS_ORDERS_FLAG', data);
      } catch (error) {
        commit('SET_HAS_ORDERS_FLAG', false);
      }
    },
    async changeOrder({ commit }, data) {
      try {
        commit('CHANGE_ORDER', data);
      } catch (error) {
        commit('CHANGE_ORDER', {});
      }
    },
    async removeOrder({ commit }, data) {
      try {
        commit('REMOVE_ORDER', data);
      } catch (error) {
        commit('REMOVE_ORDER', {});
      }
    },
    async clearOrder({ commit }) {
      try {
        commit('CLEAR_ORDER');
      } catch (error) {
        commit('CLEAR_ORDER');
      }
    },
    async removeOrderWithItem({ commit }, data) {
      try {
        commit('REMOVE_ORDER_WITH_ITEM', data);
      } catch (error) {
        commit('REMOVE_ORDER_WITH_ITEM', {});
      }
    },
    async changeDetailOrder({ commit }, data) {
      try {
        commit('CHANGE_DETAIL_ORDER', data);
      } catch (error) {
        commit('CHANGE_DETAIL_ORDER', {});
      }
    },
    async setStaffId({ commit }, data) {
      try {
        commit('SET_STAFF_ID', data);
      } catch (error) {
        commit('SET_STAFF_ID', {});
      }
    },
    async setOrderedIds({ commit }, data) {
      try {
        commit('SET_ORDERED_IDS', data);
      } catch (error) {
        commit('SET_ORDERED_IDS', []);
      }
    },
    async clearOrderedIds({ commit }) {
      try {
        commit('CLEAR_ORDERED_IDS');
      } catch (error) {
        commit('CLEAR_ORDERED_IDS');
      }
    },
    async setCountItemSelected({ commit }, data) {
      try {
        commit('SET_COUNT_ITEM_SELECTED', data);
      } catch (error) {
        commit('SET_COUNT_ITEM_SELECTED', 0);
      }
    },
  },
  getters: {
    getAllOrders: state => state.items,
    getHasOrdersFlag: state => state.hasOrdersFlag,
    getTotals: state => {
      let total = 0;
      state.items.forEach((p) => {
        total = total + Math.floor(p.price * p.qty);
      });

      return total;
    },
    getStaffId: state => state.staff_id,
    getOrderedIds: state => state.ordered_ids,
    getCountItemSelected: state => state.countItemSelected,
  }
};
export default orders;
