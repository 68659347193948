<template>
  <div id="freePeriodExtensionRestModal" class="modal modal-owner">
    <div class="modal-backdrop" />
    <div class="modal-content owner">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <div class="modal-title">確認</div>
          <div class="icon close" @click="error=null" />
        </div>
      </div>
      <div class="modal-body">
        <div class="admin_content">
          <div>無料期間以下の通り延長します</div>
          <div>よろしいですか？</div>
          <div class="time-content">
            <div>{{ updatedAt }}まで無料</div>
            <span v-if="isMac" class="sp-next-mac">&#8594;</span>
            <span v-else class="sp-next">&#8594;</span>
            <div class="admin_color-alert">{{ nextUpdateAt }}まで無料</div>
          </div>

          <div class="form-row">
            <span v-if="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <button class="btn_admin admin_primary" @click="submit">決定</button>
        <button class="btn_admin admin_primary_border" @click="closePopup">キャンセル</button>
      </div>
    </div>
  </div>
</template>
<script>
import {RestaurantAdministratorsService} from '../../../../services/admin/restaurant_administrators.service';
import moment from 'moment';

export default {
  props: {
    freeRestId: {
      type: Number,
      require: true,
    },
    contractUpdateAt: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      error: null,
      updatedAt: null,
      nextUpdateAt: null,
      isMac: false,
    };
  },
  mounted() {
    this.isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  },
  watch: {
    contractUpdateAt: async function (val) {
      this.updatedAt = moment(val).utc().format('YYYY年MM月DD日');
      this.nextUpdateAt =  moment(val).utc().add(1, 'M').endOf('month').format('YYYY年MM月DD日')
    },
  },
  methods: {
    submit: async function () {
      this.$loading(true);
      try {
        await RestaurantAdministratorsService.freePeriodExtension(this.freeRestId);
        this.$emit('refreshTable');
        this.$closeModal('freePeriodExtensionRestModal');
      } catch (error) {
        this.error = '契約中の店舗がございますので、削除できません。';
      }
      this.$loading(false);
    },
    closePopup: function () {
      this.$closeModal('freePeriodExtensionRestModal');
    }
  }
};
</script>
<style scoped lang="scss">
#freePeriodExtensionRestModal{
  .modal-content{
    width: 700px;
    min-width: 700px;
  }
}

.sp-next {
  align-items: center;
  justify-content: center;
  line-height: unset;
  margin: 0 5px;
}

.sp-next-mac {
  align-items: center;
  justify-content: center;
  line-height: unset;
  margin: 0 5px;
}

.time-content {
  display: flex;
}
</style>