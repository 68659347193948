<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        棚卸詳細
      </h1>
      <div class="form_row center chanel_text_padding mb-20">
        <label class="form_label label-title">棚卸日付</label>
        <div> {{ formatDate(inventoryManageHistory.date) }}</div>
      </div>
      <div class="form_row center chanel_text_padding">
        <label class="form_label label-title">棚卸担当者</label>
        <div> {{ inventoryManageHistory.order_staff_name }}</div>
      </div>

      <div class="form_row center chanel_text_padding mb_medium mt_medium">
        <label class="form_label label-title">カテゴリー</label>
        <div class="form_input">
          <div class="select_wrapper arrow_down medium">
            <select id="staff" v-model="categoryId"
                    class="form_option"
                    @change="onSearch()"
            >
              <option selected value="">全てのカテゴリー</option>
              <option v-for="(inventoryCategory, index) in inventoryCategories" :key="index" :value="inventoryCategory.id">
                {{ inventoryCategory.name }}
              </option>
              <option value="0">未選択</option>
            </select>
          </div>
        </div>
      </div>

      <paging
        v-if="items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th class="w_65">発注先/商品名</th>
            <th>単価</th>
            <th class="w_15">在庫量</th>
            <th>小計</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in items"
              :key="index"
          >
            <td>
              <div>
                <div class="text_down max-line">{{ data.supplier_name }}</div>
                <div class="text_down text_bold pt-10 max-line">{{ data.name }}</div>
              </div>
            </td>
            <td>{{ showPrice(data.price) }} 円</td>
            <td>{{ data.item_qty + " " + data.unit}} </td>
            <td>{{ showPrice(data.sum) }} 円</td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <div v-if="items.length > 0" class="table_summary mb_large text_total chanel_text_padding_right mt-16">
        合計金額:
        <div class="ml_large">
          {{ showPrice(totalPrice) }} 円
        </div>
      </div>
      <div v-else class="text-center mt-28">表示するデータはありません。</div>
    </div>
    <div class="control_area">
      <router-link to="/inventory" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control two_items is-pc-type">
        <a href="javascript:void(0);" @click.prevent="exportExcel" class="btn primary" :class="items === null ? 'disabled' : ''">出力する</a>
      </div>
    </div>
  </main>
</template>

<script>
import utils from '../../common/util.js';
import { InventoryManageHistoriesService } from '../../services/inventory_manage_histories.service';
import { InventoryCategoriesService } from '../../services/inventory_categories.service';
import xlsx from "xlsx/dist/xlsx.full.min";
import moment from 'moment';
import Paging from '../../components/Pager';

export default {
  components: {
    Paging
  },
  data() {
    return {
      totalPrice: 0,
      inventoryManageHistory: {},
      inventoryCategories: [],
      items: [],
      categoryId: '',
      fileName: null,
      sheetName: "MySheets",
      headerExecl: [
        ["発注先", "商品名", "単価", "単価(単位)", "在庫量", "在庫量(単位)"]
      ],
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
    };
  },

  async mounted() {
    await this.getInventoryManageHistory();
    await this.getInventoryCategories();
  },

  methods: {
    ...utils,

    async exportExcel() {
      this.$loading(true);
      const pagerExcel = {
        page: 1,
        totalPage: 0,
        pageSize: 500,
      }
      var items = [];
      const XLSX = xlsx;

      do {
        try {
          const params = {
            id: this.$router.history.current.params.id,
            category_id: this.categoryId,
            page: pagerExcel.page,
            page_size: pagerExcel.pageSize,
          };
          const { response } = await InventoryManageHistoriesService.getDetails(params);
          pagerExcel.page = response.pager.page;
          pagerExcel.totalPage = response.pager.page_count;
          response.data.items.map(function(item) {
            item.item_qty = item.update_calc === 'plus' ? (item.qty_history + item.quantity) : (item.qty_history - item.quantity);
            item.item_qty = Number.isInteger(item.item_qty) ? item.item_qty : item.item_qty.toFixed(1);
          });
          items = [...items, ...response.data.items];
          this.fileName = response.data.date;
        } catch (error) {
          console.log(error);
        }
        pagerExcel.page++;
      } while (pagerExcel.page <= pagerExcel.totalPage);

      const rows = items.map(row => ({
        supplier_name: row.supplier_name,
        items_name: row.name,
        price: row.price,
        price_unit: '円',
        item_qty: row.item_qty,
        item_unit: row.unit
      }));
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 }
      ];

      XLSX.utils.sheet_add_aoa(worksheet, this.headerExecl, { origin: "A1" });
      XLSX.utils.book_append_sheet(workbook, worksheet, this.sheetName);
      XLSX.writeFile(workbook, "inventory_" + moment(this.fileName).format('YYYYMMDD') + ".xlsx");

      this.$loading(false);
    },

    onSearch() {
      this.pager.page = 1;
      this.getInventoryManageHistory();
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getInventoryManageHistory();
      this.scrollTopScreen();
    },
    async getInventoryManageHistory() {
      this.$loading(true);
      try {
        const params = {
          id: this.$router.history.current.params.id,
          category_id: this.categoryId,
          page: this.pager.page,
          page_size: this.pager.pageSize,
        };
        const { response } = await InventoryManageHistoriesService.getDetails(params);
        this.inventoryManageHistory = response.data;
        this.items = response.data.items === null ? [] : response.data.items;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.mapItemQty();
        this.getTotalPrice();
        this.fileName = response.data.date;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    mapItemQty() {
      this.items.map(function(item) {
        item.item_qty = item.update_calc === 'plus' ? (item.qty_history + item.quantity) : (item.qty_history - item.quantity);
        item.item_qty = Number.isInteger(item.item_qty) ? item.item_qty : item.item_qty.toFixed(1);
        return item.item_qty
      });
      this.items.map(function(item) {
        return item.sum = item.item_qty * item.price;
      });
    },
    getTotalPrice() {
      for (let i in this.items) {
        this.totalPrice += this.items[i].sum;
      }
    },
    async getInventoryCategories() {
      this.$loading(true);
      try {
        const { response } = await InventoryCategoriesService.index();
        this.inventoryCategories = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tab_area {
  position: unset;
  top: 0px;
  margin-bottom: 0px;
}
.text_center {
  text-align: center;
}
.pt-10 {
  padding-top: 10px;
}

.text_down {
  word-break: break-all;
  white-space: normal;
}
.text_bold {
  font-weight: bold;
}
.is-pc-type {
  display: flex;
}
.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1.2;
}
.mb-20 {
  margin-bottom: 20px;
}
.form_label {
  font-size: 16px;
  width: 160px;
}

.select_wrapper select {
  line-height: 1.3;
  padding: 14px;
}

.w_65 {
  width: 60% !important;
}
@media screen and (max-width: 1024px) {
  .is-pc-type {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .select_wrapper.medium {
    width: 100%;
  }
  .w_15 {
    width: 22% !important;
  }
  .w_65 {
    width: 30% !important;
  }
  .form_label {
    width: 130px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .select_wrapper select {
    padding: 6px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 720px) {
  .w_65 {
    width: 52% !important;
  }
}
</style>
