<template>
  <div id="cancelModal" class="modal modal-owner">
    <div class="modal-backdrop" />
    <div class="modal-content owner">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <div class="modal-title">登録代行キャンセル</div>
          <div class="icon close" @click="error=null" />
        </div>
      </div>
      <div class="modal-body">
        <div class="admin_content">
          <div>キャンセル理由を選択してください</div>
          <div class="form-row">
            <select v-model="reason">
              <option v-for="(cancelReason, index) in cancelReasons" :key="index" :value="cancelReason.value">
                {{ cancelReason.text }}
              </option>
            </select>
          </div>
          <span v-if="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <button class="btn_admin delete" @click="handleCancel">送信</button>
      </div>
    </div>
  </div>
</template>
<script>
import {OcrService} from '../../../../services/admin/ocr.service';

export default {
  props: {
    ocrId: {
      type: Number,
      require: true,
    },
    selectedIndex: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      reason: '',
      error: null,
      cancelReasons: [
        { value: '', text: '選択してください' },
        { value: 'duplicate_supplier',text: '発注先業者名の重複'},
        { value: 'duplicate_image',text: '添付画像の重複'},
        { value: 'insufficient_image', text: '添付画像が不鮮明' },
        { value: 'other', text: 'その他' },
      ]
    };
  },
  methods: {
    handleCancel: async function () {
      if (!this.reason) {
        this.error = 'キャンセル理由を選択してください';
        return;
      } else {
        this.error = null;
      }

      this.$loading(true);
      try {
        await OcrService.cancel(this.ocrId, { reason: this.reason });
        this.$emit('cancelledOcr', this.selectedIndex);
      } catch (error) {
        console.log(error);
      }
      this.$closeModal('cancelModal');
      this.$loading(false);
    },
  }
};
</script>
