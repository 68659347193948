<template>
  <form id="notification-individual-search-form" class="search_form search_form_admin">
    <label :class="isHidden.form ? 'icon_shevrontop': 'icon_shevrondown'" class="form_title after"
           @click="isHidden.form = !isHidden.form"
    >検索条件</label>
    <div v-if="isHidden.form" class="toggle_content">
      <div class="form_content ">
        <div class="row flex">
          <div class="column">
            <label class="form_label margin-text" for="owner_name">法⼈名</label>
            <input id="owner_name" v-model="searchParams.owner_name" placeholder="法⼈名を⼊⼒"
                   type="text"
            >
          </div>
          <div class="column">
            <label class="form_label margin-text" for="restaurant_name">店舗名</label>
            <input id="restaurant_name" v-model="searchParams.restaurant_name" placeholder="店舗名を⼊⼒"
                   type="text"
            >
          </div>
        </div>
        <div class="row flex mb_medium">
          <div class="column">
            <label class="form_label margin-text" for="created_at">お申し込み⽇</label>
            <div class="input_wrapper calender">
              <div class="input-group">
                <flat-pickr
                  id="created_at"
                  v-model="searchParams.created_at"
                  :config="config"
                  placeholder="お申し込み⽇を⼊⼒"
                />
              </div>
            </div>
          </div>
          <div class="column ">
            <label class="form_label margin-text" for="completed_date">作業完了⽇</label>
            <div class="input_wrapper calender">
              <div class="input-group">
                <flat-pickr
                  id="completed_date"
                  v-model="searchParams.completed_date"
                  :config="config"
                  placeholder="作業完了⽇を⼊⼒"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <label class="form_label margin-text">&nbsp;</label>
            <div class="flex alignCenter include_registration_complete">
              <input
                id="check_search"
                ref="checkbox"
                v-model="searchParams.include_registration_complete"
                class="checkbox"
                false-value="0"
                true-value="1"
                type="checkbox"
                @change="!searchParams.include_registration_complete"
              >
              <label class="checkbox_label" for="check_search" />
              <label for="check_search" class="checkbox_text">登録完了も含む</label>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_area_admin search">
        <a class="btn_admin admin_primary_border" @click="$emit('clearSearch')">検索条件をクリア</a>
        <a class="btn_admin admin_primary" @click="search">検索</a>
      </div>
    </div>
  </form>
</template>

<script>

import {Japanese} from 'flatpickr/dist/l10n/ja.js';

export default {
  props: {
    searchParams: {
      type: Object,
      require: true
    },
    restaurants: {
      type: Array,
      require: true,
    },
    paginationParams: {
      type: Object,
      require: true,
      default: () => {
        return {
          page: 1,
          page_size: 10,
          total_page: 0
        };
      }
    },
  },
  data() {
    return {
      isHidden: {
        form: true,
      },
      config: {
        enableTime: false,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d'
      },
    };
  },
  methods: {
    async search() {
      this.paginationParams['page']=1;
      await this.$emit('refreshTable');
    }
  }
};
</script>

<style scoped>

.input_wrapper.calender::after {
  right: 32px;
}
.modal .input_wrapper.calender::after {
  right: 16px;
}


.include_registration_complete {
  margin-top: 12px;
}

</style>


