<template>
  <main class="container bottomUp">
    <div id="body_user" class="main_area smp_content_main">
      <h1 class="title mb_large">
        届け先情報
      </h1>
      <form>
        <div class="form_row vertical mb_medium">
          <label for="restaurantName" class="form_label">
            店舗名<span class="require">必須</span>
          </label>
          <div class="input_wrapper large">
            <input v-model="restaurantName" type="text" placeholder="店舗名を入力">
            <span v-for="error in errors['name']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form_row vertical mb_medium">
          <label for="tel" class="form_label">
            電話番号<span class="require">必須</span>
          </label>
          <div class="input_wrapper medium">
            <input v-model="tel" type="tel" placeholder="電話番号を入力">
            <span v-for="error in errors['tel']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form_row vertical mb_medium">
          <label for="fax" class="form_label">FAX番号</label>
          <div class="input_wrapper medium">
            <input v-model="fax" type="tel" placeholder="FAX番号を入力">
            <span v-for="error in errors['fax']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form_row vertical mb_medium">
          <label for="postNumber" class="form_label">
            郵便番号<span class="require">必須</span>
          </label>
          <div class="input_wrapper medium">
            <input v-model="postCode" type="tel" placeholder="郵便番号を入力">
            <span v-for="error in errors['postcode']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form_row vertical mb_medium">
          <label for="address" class="form_label">
            住所<span class="require">必須</span>
          </label>
          <div class="input_wrapper large">
            <input v-model="address" type="text" placeholder="住所を入力">
            <span v-for="error in errors['address']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form_row vertical mb_medium">
          <label for="mail" class="form_label">
            メールアドレス<span class="require">必須</span>
          </label>
          <div class="input_wrapper large">
            <input v-model="email" type="text" placeholder="メールアドレスを入力">
            <span v-for="error in errors['email']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
      </form>
    </div>
    <div class="control_area">
      <router-link to="/setting" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <a class="btn primary" @click="handleSave">
          更新する
        </a>
      </div>
    </div>
    <PopupErrorUser :show-dialog="showDialog" :close-dialog="disableShowDialog" />
  </main>
</template>

<script>
import { RestaurantsService } from '../../services/restaurants.service';
import { CorrectNumber } from '../../common/util';
import Validator from '../../validator';
import PopupErrorUser from './../../components/PopupErrorUser.vue';

export default {
  components: {
    PopupErrorUser
  },
  data() {
    return {
      restaurant: {},
      restaurantName: '',
      tel: '',
      fax: '',
      postCode: '',
      email: '',
      address: '',
      errors: {},
      showDialog: false
    };
  },
  watch: {
    tel() {
      if(this.tel) {
        this.tel = this.tel.replace(/[^0-9]+/g, '');
      }
    },
    fax() {
      if(this.fax) {
        this.fax = this.fax.replace(/[^0-9]+/g, '');
      }
    },
    postCode() {
      if(this.postCode) {
        this.postCode = this.postCode.replace(/[^0-9]+/g, '');
      }
    }
  },
  async mounted() {
    this.$loading(true);
    try {
      const { response } = await RestaurantsService.index();

      this.restaurant = response.data;
      this.restaurantName = this.restaurant.name,
      this.tel = this.restaurant.tel,
      this.fax = this.restaurant.fax,
      this.postCode = this.restaurant.postcode,
      this.address = this.restaurant.address,
      this.email = this.restaurant.email;
      this.$loading(false);
    } catch (error) {
      this.$loading(false);
    }
  },
  methods: {
    handleSave: async function() {
      this.validate();

      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        this.displayDialog();
        return;
      }

      const params = {
        name: this.restaurantName,
        tel: CorrectNumber(this.tel),
        fax: CorrectNumber(this.fax),
        postcode: CorrectNumber(this.postCode),
        address: this.address,
        email: this.email,
      };
      this.$loading(true);
      try {
        const { response}  = await RestaurantsService.update(params);
        this.$store.commit('SET_RESTAURANT', response.data);
        this.$router.push('/setting');
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    disableShowDialog() {
      this.showDialog = false;
    },
    displayDialog() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
      this.showDialog = true;
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('name', this.restaurantName, '店舗名') &&
        validator.checkMaxLength('name', this.restaurantName, 255, '店舗名');

      const tel =  CorrectNumber(this.tel);
      validator.checkRequire('tel', tel, '電話番号') &&
        validator.checkMaxLength('tel', tel, 11, '電話番号') &&
        validator.checkMinLength('tel', tel, 10, '電話番号');
      if (!validator.errors.tel) {
        validator.checkPhone('tel', CorrectNumber(this.tel), '電話番号');
      }

      if (this.fax) {
        const fax =  CorrectNumber(this.fax);
        validator.checkMaxLength('fax', fax, 11, 'FAX番号') &&
          validator.checkMinLength('fax', fax, 10, 'FAX番号');
        if (!validator.errors.fax) {
          validator.checkPhone('fax', fax, 'FAX番号');
        }
      }

      validator.checkRequire('postcode', this.postCode, '郵便番号') &&
        validator.checkPostCode('postcode', this.postCode, '郵便番号') &&
        validator.checkMaxLength('postcode', CorrectNumber(this.postCode), 7, '郵便番号');
      validator.checkRequire('address', this.address, '住所') &&
        validator.checkMaxLength('address', this.address, 255, '住所');
      validator.checkRequire('email', this.email, 'メールアドレス') &&
        validator.checkEmail('email', this.email, 'メールアドレス');
      this.errors = validator.errors;
    },
  },
};
</script>
<style scoped>
a {
  cursor: pointer;
}
</style>
