<template>
  <div id="editDetailOwnerModal" class="modal modal-owner">
    <div class="modal-backdrop" />
    <div class="modal-content owner">
      <Detail v-show="modalType =='detail'" :owner="owner" @updateModalType="updateModalType" />
      <Edit v-show="modalType =='edit'" :owner="owner" @updateModalType="updateModalType"
            @updateSelectedOwner="$emit('updateSelectedOwner')"
            @refreshTable="$emit('refreshTable')"
      />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {OwnersService} from '../../../../services/admin/owners.service';
import utils from '../../../../common/util';
import Detail from './Detail';
import Edit from './Edit';

export default {
  components: {
    Detail,
    Edit
  },
  props: {
    ownerId: {
      type: Number,
      require: true,
      default: -1
    },
    modalType: {
      type: String,
      require: true,
      default: 'edit'
    }
  },
  data() {
    return {
      owner: {},
    };
  },
  computed: {
    ...mapGetters({
      getPrefectures: 'getAllPrefectures',
      adminModals: 'getAdminModal',
    }),
  },
  watch: {
    ownerId: function (val) {
      if (val > 0) {
        this.getOwnerDetail();
      }
    }
  },
  async mounted() {
    await this.getOwnerDetail();
  },

  methods: {
    ...utils,
    updateModalType(val) {
      this.$emit('updateModalType', val);
    },
    async getOwnerDetail() {
      if (this.ownerId > 0) {
        const {response} = await OwnersService.get(this.ownerId);
        this.owner = response.data;
      } else {
        this.owner = {};
      }
    }
  }
}
;
</script>

<style scoped>
.border_bottom {
  border-bottom: 1px solid #c7c0c0;
}

.pb_t-24 {
  padding: 24px 0px;
}

.small_title {
  opacity: 0.5;
  font-size: 10px;
  font-weight: normal;
}

.large_title {
  font-size: 24px;
  display: inline-block;
}

.row {
  align-items: center;
}
</style>
