<template>
  <div class="admin_content">
    <Form v-if="screenName == 'delete'" @change_screen="onChangeScreenName" />
    <Complete v-if="screenName == 'complete'" @change_screen="onChangeScreenName" />
  </div>
</template>

<script>
import Form from './components/Form.vue';
import Complete from './components/Complete.vue';

export default {
  components: {
    Form,
    Complete
  },

  data() {
    return {
      screenName: 'delete',
    };
  },

  watch: {
    screenName() {
      document.title = this.screenName === 'complete' ? '法人詳細 解約確認モーダル' : '法人詳細 解約確認';
    },
  },

  methods: {
    onChangeScreenName(value) {
      this.screenName = value;
    }
  }
};
</script>

<style scoped>
.primary {
  background: #241811;
}
</style>
