import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class RegistationService extends BaseService {
  static get resourceEndpoint() {
    return 'owners';
  }

  static async confirm(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/register/confirmation`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }

  static async submit(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/register`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }
}