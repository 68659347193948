<template>
  <div class="main_area_pc wrapper_password-reset owner_main_area">
    <div class="wrapper_owner_have_button">
      <div class="password-reset">
        <div class="title">パスワード再設定完了</div>
        <div class="content note_sp">パスワードを再設定しました。</div>
      </div>
      <div class="btn_area_wrapper">
        <div class="button_wrapper">
          <router-link :to="{}" class="btn_area_owner btn_area-owner btn_redirect-login goback" @click.native="login">
            ログイン画面へ
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    login() {
      return this.$router.push('/');
    },
  },
};
</script>
<style lang="scss" scoped>
 
</style>
