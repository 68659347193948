<template>
  <div>
    <div id="shopListModal" class="modal modal-notification">
      <div class="modal-backdrop" />
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header flex">
          <div class="modal-header-inner">
            <span class=" title_admin title_start_date">公開⽇時 ： {{ selectedNotification["start_time"] }}</span>
            <h1 class="title_admin">{{ selectedNotification["title"] }}</h1>
          </div>
          <div class="icon close" />
        </div>
        <!--body-->
        <div class="modal-body">
          <div class="admin_content">
            <div class="pagination admin-panigation">
              <paging
                v-if="shopList.length > 0"
                :page="paginationParams.page"
                :total-page="paginationParams.totalPage"
                compact="true"
                @jumpPage="onPageChange"
              />
            </div>
            <ul class="shop-list">

              <li v-if="shopList.length <= 0" style="border: 0"><span>表示するデータはありません。</span></li>
              <li v-if="shopList.length > 0" class="shop-header"><span>店舗名</span></li>
              <li v-for="shop in shopList" :key="shop['id']">
                <span>{{ shop["name"] }}</span>
              </li>
            </ul>
            <div class="pagination admin-panigation">
              <paging
                v-if="shopList.length > 0"
                :page="paginationParams.page"
                :total-page="paginationParams.totalPage"
                compact="true"
                @jumpPage="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import {NotificationIndividualService} from '../../../../services/admin/notifications-individual.service';
import Paging from '../../../../components/Pager';

export default {
  components: {
    Paging
  },
  props: {
    selectedNotification: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      shopList: [],
      paginationParams: {
        page: 1,
        page_size: 5,
        total_page: 0
      },
    };
  },
  watch: {
    selectedNotification: {
      handler() {
        this.paginationParams.page = 1;
        this.getShopList();
      },
      deep: true
    },
  },
  methods: {
    async getShopList() {
      const {response} = await NotificationIndividualService.get_read_shop_list(this.selectedNotification.id, this.paginationParams);
      this.shopList = response.data;
      this.paginationParams.page = response.pager.page;
      this.paginationParams.totalPage = response.pager.page_count;
    },
    onPageChange(page) {
      this.paginationParams.page = page;
      this.getShopList();
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-notification .modal-content {
  min-width: 700px;
  width: 700px;
}

.admin_content {
  border: 1px solid #BBBBBB;
  border-radius: 5px;
  padding: 15px 0px 15px 15px;
  margin-top: 15px;
}

h1.title_admin {
  margin-top: 8px !important;
}

span.title_admin.title_start_date {
  color: #8a8a8a!important;
  font-size: 14px!important;
  font-weight: 100;
}

.modal-header.flex {
  padding-bottom: 15px;
}

ul.shop-list {
  margin: 16px 0;

  li {
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
    margin-right: 15px;

    span {
      padding: 0 12px;
    }
  }

  li.shop-header {
    background: #ededed;
  }
}
</style>

