<template>
  <main class="before_login before_login-owner">
    <div class="before_login_main">
      <div class="main_logo mb_large main_logo-owner" />
      <div class="login_area">
        <div class="login_form_box login_form_box-owner">
          <div class="login_form login_form-owner">
            <h1 class="login_title_owner">ログイン</h1>
            <div class="input_area">
              <div class="form_row vertical mb_medium">
                <label for="id" class="owner_form_label">オーナーID/店舗ID</label>
                <div class="input_wrapper">
                  <input id="id" v-model="username" type="text">
                  <span v-for="error in errors['username']" :key="error" class="error-message">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="form_row vertical mb_large">
                <label for="password" class="owner_form_label">パスワード</label>
                <div class="input_wrapper">
                  <input id="password" v-model="password" type="password">
                  <span v-for="error in errors['password']" :key="error" class="error-message">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <div class="btn_area_owner">
              <button class="btn_owner primary mb_medium btn_point" @click="login">ログイン</button>
            </div>
            <div class="btn_area_owner">
              <button class="btn_owner white btn_point" @click="register">新規お申し込み</button>
            </div>
            <p class="login_sub line_height_large">オーナーアカウントと店舗アカウント、どちらのアカウントでもログイン可能です。</p>
          </div>
          <div class="login_form login_form-owner wrapper_form_reset">
            <div class="title_sub_text">オーナーアカウントの情報をお忘れの方</div>
            <div class="link_two_form__login">
              <router-link to="/ownerid_reset" class="link_item line_height_large">
                IDの再設定
              </router-link>
              <router-link to="/password_reset" class="link_item line_height_large">
                パスワードの再設定
              </router-link>
            </div>
            <p class="login_sub line_height_large"><span>※</span>店舗アカウントのIDとパスワードをお忘れの場合は、オーナーアカウント管理者にお問い合わせください。</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Validator from '../../validator';
import { AuthService } from '../../services/auth.service';
import { OWNER_ROLE } from '../../common/variables';
import NewRevisionMixin from '../../common/mixins/NewRevisionMixin';
export default {
  mixins: [
    NewRevisionMixin,
  ],
  data () {
    return {
      username: '',
      password: '',
      errors: {},
    };
  },
  methods: {
    async login() {
      this.validate();
      if (Object.keys(this.errors).length) return;
      const params = {
        username: this.username,
        password: this.password
      };
      try {
        const { response }  = await AuthService.login(params);
        this.$store.commit('SET_TOKEN', response.token);
        this.$store.commit('SET_ROLE', response.role);
        this.$store.commit('SET_USER_ID', response.user_id);
        // 改定お知らせ
        if (response.new_revision_policy && Object.keys(response.new_revision_policy).length) {
          const isAgreeRevision = await this.noticeNewRevision(response.new_revision_policy);
          if (isAgreeRevision) {
            this.agreeRevision(null, response.new_revision_policy);
          } else {
            return;
          }
        }

        if (response.new_revision_term && Object.keys(response.new_revision_term).length) {
          const isAgreeRevisionTerm = await this.noticeNewRevisionTerm(response.new_revision_term);
          if (isAgreeRevisionTerm) {
            this.agreeRevision(null, response.new_revision_term);
          } else {
            return;
          }
        }

        this.$store.commit('SET_LOGIN_OWNER_FLAG', true);

        const from = this.$route.query.from;
        if (from) {
          const prefix = from.split('/')[1];
          if ((prefix === 'owner' && response.role !== OWNER_ROLE)) {
            this.$router.push('/dashboard');
          } else if ((prefix !== 'owner' && response.role === OWNER_ROLE)) {
            this.$router.push('/owner');
          } else {
            this.$router.replace(from);
          }
        } else {
          if (response.role === OWNER_ROLE) {
            this.$router.push('/owner');
          } else {
            this.$router.push('/dashboard');
          }
        }
      } catch (error) {
        console.log(error);
        this.errors = { ...this.errors, username: [error.response.result.message] };
      }
    },
    register() {
      this.$router.push('/register_info');
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('username', this.username, 'オーナーIDまたは店舗アカウントID') &&
        validator.checkMaxLength('username', this.username, 255, 'オーナーIDまたは店舗アカウントID');
      validator.checkRequire('password', this.password, 'パスワード') &&
        validator.checkMaxLength('password', this.password, 50, 'パスワード');
      this.errors = validator.errors;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 480px){
  .main_logo {
    padding: 40px
  }
}
.body_owner .login_form_box .login_form .link_two_form__login {
  justify-content: space-evenly;
}
@media screen and (min-width: 480px) {
  .body_owner .login_form_box .wrapper_form_reset .link_two_form__login {
    padding: 0;
  }
}
</style>
