<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        登録代行依頼
      </h1>
      <paging
        v-if="uploads.length !== 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th class="w_30">
              依頼日付/完了日付
            </th>
            <th class="w_40">
              発注先
            </th>
            <th class="w_25">
              ステータス
            </th>
            <th class="w_10">
              画像
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="upload in uploads" :key="upload.id">
            <td class="line_break">{{ upload.created_date }}<br>{{ upload.completed_date }}</td>
            <td>{{ upload.supplier_name }}</td>
            <td class="wrap_text">
              <div :class="['status_label', statusLabel[upload.status]]">
                <div :class="['icon', statusIcon[upload.status]]" />
                {{ statusName[upload.status] }}
              </div>
              <div v-if="upload.status == 'cancelled'" class="status_text fail mt_small">
                {{ cancelReasonText[upload.cancel_reason] }}
              </div>
            </td>
            <td>
              <div v-if="isImageFile(upload.filepath)" class="icon photo show_upload_icon" @click="showUpload(upload.filepath)" />
              <a v-if="!isImageFile(upload.filepath)" class="icon photo show_upload_icon" href="#"
                 @click="download(`${s3BucketUrl}/${upload.filepath}`, upload.filename)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="uploads.length !== 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <div v-show="uploads.length === 0" class="text-center">表示するデータはありません。</div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link to="/upload/new" class="btn primary">登録代行を依頼する</router-link>
      </div>
    </div>
    <!--overlay show upload image-->
    <div v-if="showingUploadFilePath">
      <div class="overlay">
        <div class="overlay_background" @click="showingUploadFilePath = null" />
        <img :src="`${s3BucketUrl}/${showingUploadFilePath}`" alt="img" :class="['image', imageStretchClass]">
      </div>
      <div class="icon close_large" @click="showingUploadFilePath = null" />
    </div>
  </main>
</template>

<script>
import { RestaurantUploadFilesService } from '../../services/restaurant_upload_files.service';
import utils from '../../common/util';
import { saveAs } from 'file-saver';
import Paging from '../../components/Pager';

export default {
  components: {
    Paging
  },
  data() {
    return {
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
      uploads: [],
      statusName: {
        image_uploaded: '依頼中',
        image_parsed: '依頼中',
        corrected_completed: '登録完了',
        cancelled: 'キャンセル',
      },
      statusIcon: {
        image_uploaded: 'upload',
        image_parsed: 'upload',
        corrected_completed: 'check',
        cancelled: 'attention',
      },
      statusLabel: {
        image_uploaded: 'info',
        image_parsed: 'info',
        corrected_completed: 'success',
        cancelled: 'fail',
      },
      cancelReasonText: {
        uncertain_fax: 'FAX番号が不明',
        uncertain_email: 'Emailが不明',
        duplicate_supplier: '発注先業者名の重複',
        duplicate_image: '添付画像の重複',
        insufficient_image: '添付画像が不鮮明',
        other: 'その他',
      },
      s3BucketUrl: process.env.S3_BUCKET_BASE_URL,
      showingUploadFilePath: null,
      imageStretchClass: '',
    };
  },
  mounted() {
    this.getUploads();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...utils,
    getUploads: async function() {
      this.$loading(true);
      try {
        const params = {
          ...this.params,
          ...{
            page: this.pager.page,
            page_size: this.pager.pageSize,
          }
        };
        const { response } = await RestaurantUploadFilesService.index(params);
        this.uploads = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getUploads();
      this.scrollTopScreen();
    },
    showUpload(filepath) {
      this.showingUploadFilePath = filepath;
    },
    download(url, fileName) {
      saveAs(url, fileName);
    },
    isImageFile(filepath) {
      const imageExtentions = ['png', 'jpg', 'jpeg'];
      return imageExtentions.includes(filepath.split('.').pop());
    },
    handleResize() {
      this.imageStretchClass = window.innerWidth < window.innerHeight ? 'stretch_horizontal' : 'stretch_vertical';
    },
  }
};
</script>
<style lang="scss" scoped>
.show_upload_icon {
  width: 28px;
  height: 28px;
}

.overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .overlay_background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000ab;
    z-index: 110;
  }
  .image {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 100px);
    margin: auto;
    z-index: 120;
    &.stretch_horizontal {
      width: 100%;
      height: auto;
    }
    &.stretch_vertical {
      width: auto;
      height: 100%;
    }
  }
}

.icon.close_large {
  right: 20px;
  bottom: 20px;
  position: fixed;
  width: 24px;
  height: 24px;
  z-index: 120;
}

.line_break {
  line-height: 20px;
}

.wrap_text {
  white-space: normal;
}

@media screen and (max-width: 480px){
  .w_25 {
    width: 30% !important;
  }
  .main_area {
    padding: 0px;
  }
  .show_upload_icon {
    width: 22px;
    height: 22px;
  }
  .status_text {
    font-size: 12px;
  }
}
</style>
