<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">検索結果一覧</div>
      <div class="pagination admin-panigation">
        <paging
          v-if="owners.length > 0"
          :total_count="owners.length"
          :page="paginationParams.page"
          :total-page="paginationParams.totalPage"
          compact="true"
          @jumpPage="onPageChange"
        />
      </div>
    </div>
    <table class="sr_ad_table">
      <thead class="">
        <tr>
          <th class="w_15 small border_radius_right">法人名</th>
          <th class="w_10">法人代表者名</th>
          <th class="w_20">住所</th>
          <th class="w_15">電話番号</th>
          <th class="w_20">メールアドレス</th>
          <th class="w_15">状態</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(owner,index_i) in owners">
          <tr :key="index_i">
            <td class="flex" @click="openDetailModal($event, owner.id, index_i)">
              <div v-show="owner.restaurant_administrators.length" :class="!accordionTd[index_i] ? 'rotate-right' : ''"
                   class="icon_arrow_down before"
              />
              <div>{{ owner.name }}</div>
            </td>
            <td @click="openDetailModal($event, owner.id, index_i)">{{ owner.director_name }}</td>
            <td @click="openDetailModal($event, owner.id, index_i)">{{ owner.full_address }}</td>
            <td @click="openDetailModal($event, owner.id, index_i)">{{ owner.phone }}</td>
            <td @click="openDetailModal($event, owner.id, index_i)">{{ owner.email }}</td>
            <td :class="owner.status == 'inactive' ? 'alert': ''">{{ getOwnerStatus(owner.status) }}</td>
            <td>
              <div style="position: relative">
                <button class="icon_leader after ml-auto" @click="onTdMenuOpenOwner($event, owner)" />
              </div>
            </td>
          </tr>
          <tr v-for="(res, index_j) in owner.restaurant_administrators" v-show="accordionTd[index_i]"
              :key="index_i+'-'+index_j" class="shop_name"
          >
            <td class="icon_drill_down before" colspan="3"
                @click="openRestroModal($event, res.id)"
            >{{ res.shop_name }}
            </td>
            <td @click="openRestroModal($event, res.id)">最終発注日：{{ res.latest_order_at }}</td>
            <td @click="openRestroModal($event, res.id)">発注回数：{{ res.order_count }}</td>
            <td :class="res.status === 'inactive' ? 'alert' : '' " @click="openRestroModal($event, res.id)">
              {{ getRestaurantStatus(res.status) }}
            </td>
            <td>
              <div style="position: relative" :class="res.status === 'inactive' ? 'hidden' : '' ">
                <button class="icon_leader after ml-auto" @click="onTdMenuOpenRestro($event,res)" />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-show="owners.length === 0" class="text-center mt_large">表示するデータはありません。</div>
    <div class="pagination admin-panigation">
      <paging
        v-if="owners.length > 0"
        :page="paginationParams.page"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import Paging from '../../../../components/Pager';
import utils from '../../../../common/util';
import {RestaurantAdministratorsService} from '../../../../services/admin/restaurant_administrators.service';

export default {
  components: {
    Paging
  },
  props: {
    ownerSelectedId: {
      type: Number,
      require: true,
      default: -1
    },
    restroSelectedId: {
      type: Number,
      require: true,
      default: -1
    },
    owners: {
      type: Array,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true,
      default: () => {
        return {
          page: 1,
          page_size: 10,
          total_page: 0
        };
      }
    },
  },
  data() {
    return {
      accordionTd: []
    };
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    await this.$emit('refreshTable');
    this.$tableTdMenu.classToIgnore = 'icon_leader';
    this.resetAccordion();
  },
  methods: {
    ...utils,
    onTdMenuOpenOwner(event, owner) {
      this.$emit('updateSelectedOwner', owner.id);
      this.$emit('updateSelectedOwnerContractUpdateAt', owner.contract_update_at);
      if (owner.status == 'inactive') {
        this.$tableTdMenu.menu = [
          {
            'name': '編集する',
            'func': () => {
              this.$emit('updateModalType', 'edit');
              this.$openModal('editDetailOwnerModal');
            }
          }
        ];
      } else {
        this.$tableTdMenu.menu = [
          {
            'name': '編集する',
            'func': () => {
              this.$emit('updateModalType', 'edit');
              this.$openModal('editDetailOwnerModal');
            }
          },
          {
            'name': '解約する',
            'class': 'alert',
            'func': () => {
              this.$openModal('cancelOwnerModal');
            }
          },

        ];

        if (owner.status_free) {
          this.$tableTdMenu.menu.splice(1, 0, {
            'name': '無料期間を延長する',
            'func': () => {
              this.$openModal('freePeriodExtensionModal');
            }
          })
        }
      }
      this.$openTdMenu(event);
    },
    onTdMenuOpenRestro(event, restro) {
      this.$emit('updateSelectedRestro', restro.id);
      this.$emit('updateSelectedOwnerContractUpdateAt', restro.contract_update_at);
      if (restro.status == 'admin_cancel') {
        this.$tableTdMenu.menu = [
          {
            'name': '利用を再開する',
            'func': () => {
              this.popupConfirmUpdate(restro, '利用再開');
            }
          },
        ];
      } else {
        this.$tableTdMenu.menu = [
          {
            'name': '編集する',
            'func': () => {
              this.$emit('updateModalType', 'edit');
              this.$openModal('editDetailResModal');
            }
          },
          {
            'name': '発注先を管理する',
            'func': () => {
              this.$router.push({ path: `/admin/restaurant_admin/${restro.id}/suppliers` });
            }
          },
          {
            'name': '利用を停止する',
            'class': 'alert',
            'func': () => {
              this.popupConfirmUpdate(restro, '利用停止');
            }
          },
          {
            'name': '解約する',
            'class': 'alert',
            'func': () => {
              this.$openModal('cancelRestroModal');
            }
          },
        ];

        if (restro.status_free) {
          this.$tableTdMenu.menu.splice(2, 0, {
            'name': '無料期間を延長する',
            'func': () => {
              this.$openModal('freePeriodExtensionRestModal');
            }
          })
        }
      }
      this.$openTdMenu(event);
    },
    resetAccordion() {
      let aTd = [];
      for (let i = 0; i < this.paginationParams['page_size']; i++) aTd[i] = true;
      this.accordionTd = Object.assign({}, this.accordionTd, aTd);
    },
    onPageChange(page) {
      this.resetAccordion();
      this.$emit('updateCurrentPage', page);
    },
    openDetailModal(event, id, index_i) {
      if (event.target.classList.contains('icon_arrow_down')) {
        this.accordionTd[index_i] = !this.accordionTd[index_i];
      } else if (event.target.classList.contains('icon_leader')) {
        this.$emit('updateSelectedOwner', id);
        this.$openTdMenu(event);
      } else {
        this.$emit('updateModalType', 'detail');
        this.$emit('updateSelectedOwner', id);
        this.$openModal('editDetailOwnerModal');
      }
    },

    openRestroModal(event, id) {
      if (event.target.classList.contains('icon_leader')) {
        this.$openTdMenu(event);
      } else {
        this.$emit('updateModalType', 'detail');
        this.$openModal('editDetailResModal');
      }
      this.$emit('updateSelectedRestro', id);
    },

    async popupConfirmUpdate(restro, status_code) {
      const params = {
        status: restro.status,
      };
      const confirm = await this.$swal.fire({
        title: '確認',
        width: 600,
        html: `<div class='wrapper_popup'><p>店舗名「${restro.shop_name}」を<span class='text_color-reb'>${status_code}</span>します。</p>
               <p>よろしいですか？</p></div>`,
        confirmButtonText: '決定',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonClass: 'btn_admin admin_primary',
        cancelButtonClass: 'btn_admin admin_primary_border',
      });

      if (confirm.isConfirmed) {
        try {
          await RestaurantAdministratorsService.updateStatus(restro.id, params);
          this.$emit('refreshTable');
        } catch (error) {
          var message = '';
          if (status_code == 10) {
            message = '再開';
          } else {
            message = '停止';
          }
          await this.popupWarning(message);
        }
      }
      this.$emit('refreshTable');
    },

    async popupWarning(message) {
      await this.$swal.fire({
        title: 'エラー',
        width: 600,
        html: `<p>店舗がすでに利用${message}されました。</p>`,
        confirmButtonText: '閉じる',
        confirmButtonClass: 'btn_admin admin_primary_border',
        showCloseButton: false,
      });
      this.$emit('refreshTable');
    },

  }
};
</script>
<style scoped>
.alert {
  color: #D52C2C;
}
.hidden {
  display: none;
}
</style>