<template>
  <div>
    <h1 class="title">ご請求CSV出力</h1>
    <form class="search_form search_form_admin">
      <div class="flex alignStart mb_medium">
        <div class="form_row one position_input">
          <label class="form_label margin-text">法人名</label>
          <input id="shopname_search" v-model="params.name" type="text">
        </div>
        <div class="form_row one">
          <label class="form_label margin-text">法人代表者名</label>
          <input id="tel_search" v-model="params.directorName" type="text">
        </div>
      </div>
      <div class="flex alignCenter mb_medium">
        <div class="form_row one position_input">
          <label class="form_label margin-text">住所</label>
          <input id="address_search" v-model="params.fullAddress" type="text">
        </div>
        <div class="form_row one">
          <label class="form_label margin-text">電話番号</label>
          <input id="address_search" v-model="params.phone" type="text">
        </div>
      </div>
      <div class="flex alignCenter mb_medium">
        <div class="form_row one position_input">
          <label class="form_label margin-text">メールアドレス</label>
          <input id="shopname_search" v-model="params.email" type="text">
        </div>
        <div class="form_row one">
          <label class="form_label margin-text">請求月</label>
          <div class="input_wrapper calender">
            <div class="input-group">
              <flat-pickr
                id="start_time"
                v-model="params.time"
                :config="config"
                placeholder="公開日時を入力"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn_area_admin search">
        <a href="#" class="btn primary margin_button" @click="searchOwner">ダウンロード</a>
        <a href="#" class="btn black_border margin_button" @click="clearParams">クリア</a>
      </div>
      <div v-show="error == true" class="form_check">
        <label class="text_color-reb">
          対象の条件で請求する店舗はありませんでした。
        </label>
      </div>
    </form>
  </div>
</template>

<script>
import { OwnerServiceUsagesService } from '../../../services/admin/owner_service_usages.service';
import flatPickr from 'vue-flatpickr-component';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import moment from 'moment';

export default {
  components: {
    flatPickr
  },
  data() {
    return {
      params: {
        name: '',
        fullAddress: '',
        email: '',
        directorName: '',
        phone: '',
        time: ''
      },
      config: {
        locale: Japanese,
        dateFormat: 'Y/m',
        minDate: '2021/10',
        maxDate: '',
        mode: 'multiple',
        plugins: [new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y/m',
          altFormat: 'F Y',
        })]
      },
      error: false
    };
  },
  watch: {
  },
  created() {
    this.getMaxDate();
  },
  methods: {
    clearParams() {
      this.params.name = '';
      this.params.fullAddress = '';
      this.params.email = '';
      this.params.directorName = '';
      this.params.phone = '';
      const format = 'YYYY/MM';
      var date = new Date();
      const dateTime = moment(date).format(format);
      this.config.maxDate = dateTime;
      this.params.time = dateTime;
    },
    onCkeck() {
      this.params.isDeleted = !this.params.isDeleted;
    },
    async searchOwner() {
      const params = {
        name: this.params.name,
        full_address: this.params.fullAddress,
        email: this.params.email,
        director_name: this.params.directorName,
        phone: this.params.phone,
        time:  `${this.params.time}/01`
      };
      try {
        const { response } = await OwnerServiceUsagesService.index(params);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(response));
        // var now = new Date();
        element.setAttribute('download', '法人情報DL.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        this.error = false;
      }catch(error){
        this.error = true;
        console.log(error);
      }
    },
    getMaxDate() {
      const format = 'YYYY/MM';
      var date = new Date();
      const dateTime = moment(date).format(format);
      this.config.maxDate = dateTime;
      this.params.time = dateTime;
    }
  },
};
</script>

<style lang="scss" scoped>
.primary {
  background: #241811;
}
.position_input {
  margin-left: 15px;
}
.input_wrapper {
  &.calender {
    &::after {
      right: 25px;
    }
  }
}
.form_check {
  margin-top: 24px;
}
</style>
