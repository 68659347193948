<template>
  <main id="main_container" class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        発注履歴一覧
      </h1>
      <p class="small_text mb_large chanel_text_padding">
        一覧をタップすると、発注内容の詳細が確認できます。履歴と同じ内容で発注を行いたい場合は、発注詳細から再度発注することが可能です。
      </p>
      <p class="small_text mb_large chanel_text_padding text_search bold">{{ searchDisplayText }}</p>
      <paging
        v-show="orders.length !== 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="is_restaurant"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <div class="is-pc-type">
        <table class="sr_table">
          <thead>
            <tr>
              <th class="w_15">
                発注日付
              </th>
              <th>発注先</th>
              <th class="w_15 sp_none text_right">
                発注金額
              </th>
              <th class="w_5"></th>
              <th class="w_22">
                ステータス
              </th>
              <th class="small th-item-center">
                納品状況
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders" :key="order.id"
              class="to_detail"
              @click="onClickRow(order.id)"
            >
              <td>{{ order.order_date }}</td>
              <td>{{ order.supplier_name }}</td>
              <td class="sp_none text_right">
                {{ showPrice(order.total_amount) }}円
              </td>
              <td></td>
              <td>
                <div :class="['status_label', statusLabel[order.status] || 'success']">
                  <div :class="['icon', statusIcon[order.status] || 'check']" />
                  {{ statusName[order.status] }}
                </div>
              </td>
              <td class="item-center">
                <div v-if="order.is_check_order" class="status_label" :class="order.is_check_qty ? 'success' : 'fail'">
                  <div class="icon" :class="order.is_check_qty ? 'check' : 'attention'" /> 検品済
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="is-mobile">
        <table class="sr_table">
          <thead>
            <tr>
              <th class="w_20">
                発注日付
              </th>
              <th>発注先</th>
              <th class="w_15">
                状態
              </th>
              <th class="small th-item-center">
                納品
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders" :key="order.id"
              class="to_detail"
              @click="onClickRow(order.id)"
            >
              <td>{{ order.order_date }}</td>
              <td>{{ order.supplier_name }}</td>
              <td>
                <div :class="['status-check-label', statusLabel[order.status] || 'success']">
                  <div :class="['icon', statusIcon[order.status] || 'check']" />
                </div>
              </td>
              <td class="item-center">
                <div v-if="order.is_check_order" class="status-check-label" :class="order.is_check_qty ? 'success' : 'fail'">
                  済
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <paging
        v-show="orders.length !== 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="is_restaurant"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <div v-show="orders.length === 0" class="text-center">表示するデータはありません。</div>
    </div>

    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        絞り込み検索
      </h2>
      <div class="mb_medium">
        <label for="from_date" class="mb_small">発注日付</label>
        <div class="flex alignCenter">
          <div class="input_wrapper controller mb_small">
            <a href="javascript:void(0);" class="btn small primary_border mr_medium" @click="applyThisMonth">今月</a>
            <a href="javascript:void(0);" class="btn small primary_border" @click="applyLastMonth">先月</a>
          </div>
        </div>
        <div class="flex alignCenter">
          <DateTimePicker v-model="fromDate" format="YYYY/MM/DD"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input"
                          placeholder="発注日付を入力"
                          value-type="YYYY-MM-DD"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
          <div class="ml_small mr_small">〜</div>
          <DateTimePicker v-model="toDate" format="YYYY/MM/DD"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input"
                          placeholder="発注日付を入力"
                          value-type="YYYY-MM-DD"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
        </div>
        <span v-for="error in errors['fromDate']" :key="error" class="error-message">{{ error }}</span>
        <span v-for="error in errors['toDate']" :key="error" class="error-message">{{ error }}</span>
      </div>
      <div class="mb_xxlarge">
        <label for="company" class="mb_small">発注先</label>
        <div v-click-outside="blurSuggestSupplier" class="input_wrapper input_suggest">
          <ul v-show="suggestSupplierShow" class="input_suggest_list">
            <li
              v-for="(supplier, index) in suggestSuppliers"
              :key="index"
              class="input_suggest_item"
              :class="chosenSupplier && supplier.id == chosenSupplier.id ? 'active' : ''"
              @click="chooseSuggestSupplier(supplier)"
            >
              {{ supplier.name }}
            </li>
          </ul>
          <input v-model="suggestSupplierKw" type="text"
                 placeholder="発注先を入力"
                 @input="onSupplierSuggestKwChange"
                 @click="suggestSupplierShow = true; fetchSupplierSuggestList();"
          >
        </div>
      </div>
      <div class="btn_area two_items">
        <a href="javascript:void(0);" class="btn primary" @click="search">検索する</a>
        <a href="javascript:void(0);" class="btn primary_border cancel ml_medium" @click="closeSearchPopup">キャンセル</a>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control two_items">
        <a href="javascript:void(0);" class="btn primary" @click="openSearchPopup">絞り込み検索</a>
        <a href="javascript:void(0);" class="btn primary_border cancel ml_medium" @click="resetSearch">リセット</a>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog ? 'open' : ''" />
  </main>
</template>
<script>
import { OrdersService } from '../../services/orders.service';
import util from '../../common/util';
import { debounce } from 'lodash';
import { SuppliersService } from '../../services/suppliers.service';
import moment from 'moment';
import Validator from '../../validator';
import Paging from '../../components/Pager';

export default {
  components: { Paging },
  data() {
    return {
      is_restaurant: true,
      orders: [],
      dialog: false,
      errors: {},
      fromDate: null,
      toDate: null,
      suggestSupplierKw: '',
      suggestSupplierShow: false,
      suggestSuppliers: [],
      chosenSupplier: {},
      statusName: {
        fail: 'FAX送信失敗',
        scheduled: 'FAX送信予約',
        cancelled: 'FAX送信キャンセル',
        doing: 'FAX送信中',
        completed: 'FAX送信成功',
        mail_doing: 'メール送信中',
        mail_completed: 'メール送信成功',
        checking: 'ステータス取得中',
        bounced: 'メール送信失敗',
        line_doing: 'LINE共有(未)',
        line_completed: 'LINE共有(済)',
      },
      statusLabel: {
        scheduled: 'info',
        cancelled: 'fail',
        fail: 'fail',
        bounced: 'fail',
        line_doing: 'fail',
      },
      statusIcon: {
        scheduled: 'check_primary',
        cancelled: 'attention',
        fail: 'attention',
        bounced: 'attention',
        line_doing: 'attention',
      },
      checkOrder: false,
      pager: {
        page: 1,
        totalPage: 0,
      },
    };
  },
  computed: {
    searchDisplayText() {
      let subText = '';
      if (this.$route.query.from_date) {
        subText = this.formatDate(this.$route.query.from_date) + 'から';
      }

      if (this.$route.query.to_date) {
        subText = subText + this.formatDate(this.$route.query.to_date) + 'まで';
      }

      if (subText) {
        subText = '発注日付は' + subText;
      }

      if (this.$route.query.supplier_name) {
        if (!subText) {
          subText = '発注先名 '+ this.$route.query.supplier_name;
        } else {
          subText = subText + ' と 発注先名 ' + this.$route.query.supplier_name;
        }
      }
      return subText ? subText + ' で検索中' : '';
    },
  },
  created() {
    // watch the query params of the route to fetch the data again
    this.$watch(
      () => this.$route.query,
      async () => {
        this.fromDate = this.$route.query.from_date;
        this.toDate = this.$route.query.to_date;
        this.suggestSupplierKw = this.$route.query.suggest_supplier_kw;
        if (!this.suggestSuppliers.length) await this.fetchSupplierSuggestList();
        this.chosenSupplier = this.suggestSuppliers.find(e => e.id == this.$route.query.supplier_id);
        await this.getOrders();
      },
      { immediate: true }
    );
  },
  methods: {
    ...util,
    applyThisMonth() {
      this.fromDate = moment().startOf('month').format('YYYY-MM-DD');
      this.toDate = moment().endOf('month').format('YYYY-MM-DD');
    },
    applyLastMonth() {
      this.fromDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      this.toDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    },
    onSupplierSuggestKwChange: debounce(function () {
      this.fetchSupplierSuggestList();
    }, 500),
    blurSuggestSupplier() {
      if (this.chosenSupplier && Object.keys(this.chosenSupplier).length == 0) {
        this.suggestSupplierKw = '';
      }
      this.suggestSupplierShow = false;
    },
    chooseSuggestSupplier(supplier) {
      this.suggestSupplierShow = false;
      this.suggestSupplierKw = supplier.name;
      this.suggestSuppliers = [supplier];
      this.chosenSupplier = supplier;
    },
    async fetchSupplierSuggestList() {
      try {
        if (!this.suggestSupplierKw) this.chosenSupplier = {};
        this.params = {keyword: this.suggestSupplierKw};
        const {response} = await SuppliersService.index(this.params);
        this.suggestSuppliers = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    onPageChange(page) {
      this.scrollTopScreen();
      this.pager.page = page;
      this.getOrders();
    },
    getOrders: async function() {
      this.$loading(true);
      try {
        const params = {
          from_date: this.formatDate(this.fromDate),
          to_date: this.formatDate(this.toDate),
          supplier_id: this.chosenSupplier?.id,
          page: this.pager.page,
        };
        const { response } = await OrdersService.index(params);
        this.orders = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        console.log(error);
        this.$loading(false);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    onClickRow(orderId) {
      this.$router.push({ path: `/order_history/${orderId}` });
    },
    openSearchPopup() {
      this.dialog = true;
    },
    closeSearchPopup() {
      this.dialog = false;
    },
    search() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      this.pager.page = 1;
      const params = {
        from_date: this.fromDate,
        to_date: this.toDate,
        supplier_id: this.chosenSupplier?.id,
        suggest_supplier_kw: this.chosenSupplier?.name,
        _at: Date.now(), // force make vue route change to trigger search again
        page: this.pager.page,
      };
      this.$router.push({ path: '/order_history', query: params });
      this.dialog = false;
    },
    resetSearch() {
      this.fromDate = null;
      this.toDate = null;
      this.suggestSupplierKw = '';
      this.suggestSuppliers = [];
      this.chosenSupplier = {};
      this.search();
    },
    validate() {
      const validator = new Validator();
      if (this.fromDate && this.toDate) {
        validator.checkDateBefore('fromDate', this.fromDate, this.toDate, '発注日付（FROM）', '（TO）');
      }
      this.errors = validator.errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.text_right {
  text-align: right;
}

.text_search {
  margin-top: -4px;
}

.input_suggest_list {
  bottom: 57px;
}

@media screen and (max-width: 480px){
  .w_25 {
    width: 35% !important;
  }
  .main_area {
    padding: 0px;
  }
  .input_suggest_list {
    bottom: 36px;
  }
  .status-check-label {
    padding-top: 8px;
  }
}
</style>
