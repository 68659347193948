<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">検索結果一覧</div>
      <div class="pagination admin-panigation">
        <paging
          v-if="notifications.length > 0"
          :total_count="notifications.length"
          :page="paginationParams.page"
          :total-page="paginationParams.totalPage"
          compact="true"
          @jumpPage="onPageChange"
        />
      </div>
    </div>
    <table class="sr_ad_table no_pointer_td">
      <thead class="">
        <tr>
          <th class="border_radius_right">公開日時</th>
          <th class="w_40">タイトル</th>
          <th class="small">閲覧数</th>
          <th class="">ステータス</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(notification, index) in notifications"
            :key="index"
            class="btn_point"
        >
          <td class="small">{{ formatDateMemoDate(notification.start_time) }}</td>
          <td class="change_table">{{ notification.title }}</td>
          <td class="small">{{ notification.read_count }}</td>
          <td class="change_table">
            {{ notification.status === 'published' ? '公開中' : '未公開' }}
          </td>
          <td>
            <div style="position: relative; cursor: pointer;">
              <button class="icon_leader after ml-auto" @click="onTdMenuOpen($event, notification)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-show="notifications.length === 0" class="text-center">表示するデータはありません。</div>
    <div class="pagination admin-panigation">
      <paging
        v-if="notifications.length > 0"
        :page="paginationParams.page"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
    <ShopListModal :selected-notification="modalParams" />
  </div>
</template>

<script>

import Paging from '../../../../components/Pager';
import moment from 'moment';
import ShopListModal from './ShopListModal';

export default {
  components: {
    ShopListModal,
    Paging
  },
  props: {
    selectedId: {
      type: Number,
      require: true,
      default: -1
    },
    notifications: {
      type: Array,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      modalParams: {
        id: '',
        title: '',
        created_date: '',
      },
    };
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    await this.$emit('refreshTable');
    this.$tableTdMenu.classToIgnore = 'icon_leader';
  },
  methods: {
    onTdMenuOpen(event, notification) {
      this.$emit('updateSelectedNotification', notification.id);
      this.$tableTdMenu.menu = [
        {
          'name': '編集する',
          'func': () => {
            this.$openModal('createEditNotification');
          }
        },
        {
          'name': '閲覧確認',
          'func': () => {
            this.modalParams = {
              id: notification.id,
              title: notification.title,
              start_time: notification.start_time,
            };
            this.$openModal('shopListModal');
          }
        },
      ];
      this.$openTdMenu(event);
    },
    onPageChange(page) {
      this.$emit('updateCurrentPage', page);
    },
    formatDateMemoDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  },
};
</script>