<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">分析</h1>
    </div>
    <form class="search_form search_form_admin">
      <label :class="isHidden.form ? 'icon_shevrontop': 'icon_shevrondown'" class="form_title after"
             @click="isHidden.form = !isHidden.form"
      >絞込条件</label>
      <div v-if="isHidden.form" class="toggle_content">
        <div class="form_content ">
          <div class="row flex">
            <div class="column">
              <label class="form_label margin-text" for="from">分析の種類（全期間）</label>
              <div class="select-wrapper">
                <select id="status" v-model="exportParams.type">
                  <option value="0">発注回数</option>
                  <option value="1">発注方法</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_area_admin search">
          <a class="btn_admin admin_primary" href="#" @click="exportCancelAnswer">ダウンロード</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { AnalysisService } from '../../../services/admin/analysis.service';
import utils from '../../../common/util';

export default {
  data() {
    return {
      exportParams: {
        type: 0
      },
      isHidden: {
        form: true
      },
    };
  },
  methods: {
    async exportCancelAnswer() {
      this.$loading(true);
      try {
        const { response } = this.exportParams.type == 1 ? await AnalysisService.exportCountStatusOrder() :
          await AnalysisService.exportCountOrder();
        
        const nameFile = this.exportParams.type == 1 ? 'order_status_count' : 'order_count';

        utils.csvExport(nameFile, response);
        this.$loading(false);
      } catch(error) {
        console.log(error);
      }
    },
  },
};
</script>
