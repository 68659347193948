<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">検索結果一覧</div>
      <div class="pagination admin-panigation">
        <paging
          v-if="ocrRecords.length > 0"
          :total_count="ocrRecords.length"
          :page="paginationParams.page"
          :total-page="paginationParams.totalPage"
          compact="true"
          @jumpPage="onPageChange"
        />
      </div>
    </div>
    <table class="sr_ad_table no_pointer_td">
      <thead>
        <tr>
          <th class="border_radius_right w_15">法⼈名</th>
          <th class="">店舗名</th>
          <th class="w_15">お申し込み⽇</th>
          <th class="w_15">作業完了⽇</th>
          <th class="w_10">状態</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ocr, index) in ocrRecords" :key="index">
          <td>{{ ocr['owner_name'] }}</td>
          <td>{{ ocr['restaurant_name'] }}</td>
          <td>{{ formatDate(ocr['created_at']) }}</td>
          <td>{{ formatDate(ocr['group_completed_date']) }}</td>
          <td>{{ ocr['group_complete_status'] === 0 ? '未登録' : '登録完了' }}</td>
          <td>
            <div style="position: relative; cursor: default;">
              <router-link
                :to="{ name: 'ocr-detail', params: {
                  page: paginationParams.page,
                  ocrParam: {
                    ownerId: ocr['owner_id'],
                    restaurantId:ocr['restaurant_id'],
                    createdAt:ocr['created_at'] },
                }}"
                class="special-link"
              >&nbsp;
              </router-link>
              <button class="icon_leader after ml-auto"
                      @click="onTdMenuOpen($event,ocr['owner_id'],ocr['restaurant_id'], ocr['created_at'])"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination admin-panigation">
      <paging
        v-if="ocrRecords.length > 0"
        :page="paginationParams.page"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
  </div>
</template>

<script>

import Paging from '../../../../components/Pager';
import moment from 'moment';

export default {
  components: {
    Paging
  },
  props: {
    selectedRecord: {
      type: Object,
      require: true
    },
    ocrRecords: {
      type: Array,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true,
      default: () => {
        return {
          page: 1,
          page_size: 10,
          total_page: 0
        };
      }
    },
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    await this.$emit('refreshTable');
    this.$tableTdMenu.classToIgnore = 'icon_leader';
  },
  methods: {
    onTdMenuOpen(event, owner_id, restaurant_id, date) {
      this.$emit('updateSelectedOcrRecord', owner_id, restaurant_id, date);

      this.$tableTdMenu.menu = [
        {
          'name': '編集する',
          'func': () => {
            event.preventDefault();
            event.target.closest('td').querySelector('.special-link').click();
          }
        }
      ];
      this.$openTdMenu(event);
    },
    onPageChange(page) {
      this.$emit('updateCurrentPage', page);
    },
    formatDate(date) {
      if (!date || date.length <= 0) return null;
      return moment(date).format('YYYY/MM/DD');
    },
  },
};
</script>

<style scoped>

.special-link {
  width: 0 !important;
  height: 0 !important;
  position: absolute;
  top: -9999px;
}

</style>