<template>
  <div id="popup-message-screen">
    <div :class="['bottom_menu', type]">
      <h2 class="bottom_menu_title">{{ title }}</h2>
      <div class="mb_large pre-formatted" :class="styles">{{ message }}</div>
      <div class="btn_area two_items">
        <a href="#" class="btn primary_border" @click="choose(true)">閉じる</a>
      </div>
    </div>
    <div class="bottom_menu_bg" @click="cancel" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    styles: String,
    persistent: Boolean,
  },
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener('keyup', this.onEnterPressed);
  },
  methods: {
    choose(value) {
      this.value = value;
      this.$destroy();
    },
    cancel() {
      this.$destroy();
    },
  },
};
</script>
<style lang="scss" scoped>
#popup-message-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
}
.bottom_menu_bg {
  pointer-events: all;
}
.bottom_menu {
  &.error {
    .bottom_menu_title {
      color: #D52C2C;
    }
  }
}
.content-max-height {
  max-height: 150px;
  overflow: auto;
}

@media screen and (max-width: 480px) {
  .btn_area {
    &.two_items {
      width: 100%;
    }
  }
}
</style>
