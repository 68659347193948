<template>
  <div>
    <h1 class="title_admin">申し込み一覧</h1>

    <SearchForm :search-params="searchParams" :pagination-params="paginationParams"
                @clearSearch="clearSearch"
                @refreshTable="getOcrRecords"
    />

    <Table :ocr-records="ocrRecords" :pagination-params="paginationParams" :selected-record="selectedRecord"
           @refreshTable="getOcrRecords" @updateCurrentPage="updateCurrentPage"
           @updateSelectedOcrRecord="updateSelectedOcrRecord"
    />
  </div>
</template>

<script>
import Table from './components/Table';
import SearchForm from './components/SearchForm';
import {OcrService} from '../../../services/admin/ocr.service';


export default {
  components: {
    Table,
    SearchForm
  },
  data() {
    return {
      selectedRecord: {
        ownerId: -1,
        restaurantId: -1,
        createdAt: null,
      },
      ocrRecords: [],
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      urlPage: -1,
      searchParams: {
        include_registration_complete: false
      },
    };
  },
  mounted() {
    if (this.$route.params.page && this.$route.params.page > 0) {
      this.urlPage = this.$route.params.page;
    }
  },
  methods: {
    updateSelectedOcrRecord(owner_id, restaurant_id, createdAt) {
      this.selectedRecord = {
        ownerId: owner_id,
        restaurantId: restaurant_id,
        createdAt: createdAt
      };
    },
    onCheck() {
      this.searchParams.isDeleted = !this.searchParams.isDeleted;
    },
    async getOcrRecords() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams, ...this.searchParams};
        const {response} = await OcrService.index(params);
        this.ocrRecords = response.data;
        if (this.urlPage > 0) {
          this.paginationParams.page = this.urlPage;
        } else {
          this.paginationParams.page = response.pager.page;
        }
        this.paginationParams.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    updateCurrentPage(page) {
      this.urlPage = -1;
      this.paginationParams.page = page;
    },
    async clearSearch() {
      this.searchParams = {};
      await this.getOcrRecords();
    },
  },
};
</script>

<style lang="scss" scoped>
.sr_ad_table td {
  word-break: break-all;
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
}

.form_row {
  display: table;
}

.page {
  border: 1px solid #000000;
  color: #000000;

  &.current {
    background: #000000;
  }
}

.form_label {
  padding-bottom: 8px;
}

.primary_border {
  border: 2px solid #241811;
  color: #241811;
}

.page_next {
  margin: 0 8px;
}

.primary {
  background: #241811;
}

.button_admin {
  background: #fd5118;
  color: white;
  padding: 10px 30px;
  width: unset;
}

.position_input {
  margin-left: 15px;
}

.change_button_table {
  text-overflow: unset;
}

.admin_mouse {
  cursor: default;
}
</style>