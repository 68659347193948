<template>
  <div class="admin_content">
    <div>
      <h1 class="mb_medium">法人情報管理 解約</h1>
      <form action="">
        <div>法人情報を削除し、アカウントを削除します。</div>
        <div class="admin_color-alert">解約後、法人の情報・請求書情報は削除されます。復旧はできませんのでご注意ください。</div>
        <div>また、紐づいている店舗情報も削除されます。</div>
        <div>問題ない場合は、「解約する」ボタンを推して続けてください。</div>
      </form>
    </div>
    <div class="btn_area_admin btns edit">
      <button class="btn delete btn_point" @click="handleCancel">解約する</button>
      <button class="btn btn_black_border btn_point" @click="redirectBack">戻る</button>
    </div>
  </div>
</template>

<script>
import { OwnersService } from '../../../../services/admin/owners.service';

export default {
  data(){
    return{
      ownerId: this.$router.history.current.params.id,
      owner: {},
    };
  },

  created(){
    this.getOwner();
  },

  methods: {
    handleCancel: async function() {
      this.popupConfirmCancel();
    },

    async popupConfirmCancel() {
      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>オーナーアカウントを解約します。</p>
               <p>よろしいですか？</p>`,
        confirmButtonText: '決定',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
      });

      if (confirm.isConfirmed) {
        try {
          await OwnersService.cancel(this.ownerId);
          this.$emit('change_screen', 'complete');
        } catch (error) {
          return this.$router.push({ path: '/admin'});
        }
      }
    },

    async getOwner() {
      try {
        const { response } = await OwnersService.get(this.ownerId);
        this.owner = response.data;
        if(this.owner.status == 'inactive') {
          this.$router.push({ path: '/admin'});
        }
      } catch (error) {
        console.log(error);
      }
    },

    redirectBack() {
      return this.$router.push({ path: `/admin/owners/${this.ownerId}/detail` });
    }
  }
};
</script>

<style scoped>
.primary {
  background: #241811;
}
</style>