<template>
  <div class="owner_main_area">
    <h2 class="mb_medium">店舗の新規登録</h2>
    <form>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗名<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.shop_name" type="text"
                 placeholder="店舗名を入力"
          >
          <span v-for="error in errors['shop_name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗名（カナ）<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.shop_name_kana" type="text"
                 placeholder="店舗名（カナ）を入力"
          >
          <span v-for="error in errors['shop_name_kana']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">氏名<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.name" type="text"
                 placeholder="氏名を入力"
          >
          <span v-for="error in errors['name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">氏名（カナ）<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.name_kana" type="text"
                 placeholder="氏名（カナ）を入力"
          >
        </div>
        <span v-for="error in errors['name_kana']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">郵便番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="createForm.postcode" type="text"
                 placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['postcode']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">都道府県<span class="require">必須</span></label>
        <div class="owner_select_wrapper arrow_down medium">
          <select v-model="createForm.prefecture_id">
            <option v-for="option in prefectures" :key="option.value" :value="option.code">{{ option.name }}</option>
          </select>
        </div>
        <span v-for="error in errors['prefecture_id']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">市区町村<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.city" type="text"
                 placeholder="例：港区北青山"
          >
          <span v-for="error in errors['city']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">丁目・番地・号<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.address" type="text"
                 placeholder="例：2-14-4"
          >
          <span v-for="error in errors['address']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="mail" class="form_label">ビル・マンション</label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.building_name" type="text"
                 placeholder="ビル・マンション名を入力"
          >
          <span v-for="error in errors['building_name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗代表者メールアドレス<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.email" type="text"
                 placeholder="店舗代表者メールアドレスを入力"
          >
          <span v-for="error in errors['email']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">電話番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="createForm.phone" type="text"
                 placeholder="電話番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['phone']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_12">
        <label class="form_label">店舗ID<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <div class="flex alignCenter">
            <input v-model="createForm.login_id" type="text"
                   placeholder="店舗IDを入力"
            >
          </div>
          <p class="owner_text_note form_text_note_margin">半角英数字6文字以上で入力してください</p>
          <span v-for="error in errors['login_id']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_12">
        <label class="form_label">店舗パスワード<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input id="password_old" v-model="createForm.password" type="password"
                 class="owner_input_pwd"
                 placeholder="店舗パスワードを入力"
          >
          <div class="owner_password_old" :class="showOldPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordOld" />
          <p class="owner_text_note form_text_note_margin">半角英数字8文字以上で入力してください</p>
          <span v-for="error in errors['password']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗パスワード（確認用）<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input id="password_confirm" v-model="passwordConfirm" type="password"
                 class="owner_input_pwd"
                 placeholder="店舗パスワード（確認用）を入力"
          >
          <div class="owner_password_old" :class="showConfirmPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordConfirm" />
          <span v-for="error in errors['password_confirm']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
    </form>
    <div class="owner_text_note_submit">
      <p v-if="restaurantAdministrators.length > 0" class="owner_text_note text_note_mb">※店舗を追加するとご利用料金が追加になります。</p>
    </div>
    <div class="btn_area_owner two_items owner_text_note_submit">
      <button class="btn btn_owner primary owner_font_16 btn_pointer"
              @click="handleSave"
      >
        確認する
      </button>
      <button class="btn btn_owner primary_border owner_font_16 btn_pointer ml_medium" @click="redirectBack">キャンセル</button>
    </div>
  </div>
</template>

<script>
import Validator from '../../../../validator';
import { CorrectNumber } from '../../../../common/util';
import { mapGetters } from 'vuex';
import { RestaurantAdministratorsService } from '../../../../services/Owner/restaurant_administrators.service';
import { AddressInfo, PopupDisplayError } from '../../../../common/util';

export default {
  props: {
    restaurantAdministrators: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      errors: [],
      showOldPwd: false,
      showConfirmPwd: false,
      passwordConfirm: ''
    };
  },
  computed: {
    ...mapGetters({
      createForm: 'getCreateForm',
      prefectures: 'getAllPrefectures',
    })
  },

  watch: {
    'createForm.postcode'(val) {
      if (!val || val.length !== 7) return;

      AddressInfo(val, this.prefectures).then(addressInfo => {
        this.createForm.prefecture_id = addressInfo.code;
        this.createForm.city = addressInfo.city + addressInfo.address;
      });
    },
  },

  methods: {
    async handleSave() {
      this.validate();

      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = Object.assign({}, this.createForm);
      params['login_id' ] = this.createForm.login_id;
      try {
        await RestaurantAdministratorsService.confirm_register(params);
        this.$emit('change_screen', 'confirm');
      } catch (error) {
        this.errors = error.response;
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },

    showPasswordOld() {
      this.showOldPwd = !this.showOldPwd;
      const passwordField = document.querySelector('#password_old');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },
    showPasswordConfirm() {
      this.showConfirmPwd = !this.showConfirmPwd;
      const passwordField = document.querySelector('#password_confirm');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },

    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },

    validate() {
      const validator = new Validator();
      validator.checkRequire('shop_name', this.createForm.shop_name, '店舗名') &&
        validator.checkMaxLength('shop_name', this.createForm.shop_name, 255, '店舗名');

      validator.checkRequire('shop_name_kana', this.createForm.shop_name_kana, '店舗名（カナ）') &&
        validator.checkKatakana('shop_name_kana', this.createForm.shop_name_kana, '店舗名（カナ）') &&
        validator.checkMaxLength('shop_name_kana', this.createForm.shop_name_kana, 255, '店舗名（カナ）');

      validator.checkRequire('name', this.createForm.name, '氏名') &&
        validator.checkMaxLength('name', this.createForm.name, 255, '氏名');

      validator.checkRequire('name_kana', this.createForm.name_kana, '氏名（カナ）') &&
        validator.checkKatakana('name_kana', this.createForm.name_kana, '氏名（カナ）') &&
        validator.checkMaxLength('name_kana', this.createForm.name_kana, 255, '氏名（カナ）');

      validator.checkRequire('postcode', this.createForm.postcode, '郵便番号')&&
        validator.checkMaxLength('postcode', this.createForm.postcode, 7, '郵便番号')&&
        validator.checkPostCode('postcode', this.createForm.postcode, '郵便番号');

      validator.checkRequire('prefecture_id', this.createForm.prefecture_id, '都道府県');

      validator.checkRequire('city', this.createForm.city, '市区町村') &&
        validator.checkMaxLength('city', this.createForm.city, 255, '市区町村');

      validator.checkRequire('address', this.createForm.address, '丁目・番地・号') &&
        validator.checkMaxLength('address', this.createForm.address, 255, '丁目・番地・号');

      if(this.createForm.building_name){
        validator.checkMaxLength('building_name', this.createForm.building_name, 255, 'ビル・マンション');
      }

      validator.checkRequire('email', this.createForm.email, '店舗代表者メールアドレス') &&
        validator.checkEmail('email', this.createForm.email, '店舗代表者メールアドレス');

      const phone =  this.createForm.phone;
      validator.checkRequire('phone', phone, '電話番号') &&
        validator.checkMaxLength('phone', CorrectNumber(phone), 11, '電話番号') &&
        validator.checkMinLength('phone', CorrectNumber(phone), 10, '電話番号') &&
        validator.checkTel('phone', phone, '電話番号');
      if (!validator.errors.phone) {
        validator.checkPhone('phone', CorrectNumber(this.createForm.phone), '電話番号');
      }

      validator.checkRequire('login_id', this.createForm.login_id, '店舗ID') &&
        validator.checkAlphanumeric('login_id', this.createForm.login_id, '店舗ID')&&
        validator.checkMinLength('login_id', this.createForm.login_id, 6, '店舗ID') &&
        validator.checkMaxLength('login_id', this.createForm.login_id, 250, '店舗ID');

      validator.checkRequire('password', this.createForm.password, '店舗パスワード') &&
        validator.checkAsciiWithoutSpace('password', this.createForm.password, '店舗パスワード')&&
        validator.checkMinLength('password', this.createForm.password, 8, '店舗パスワード') &&
        validator.checkMaxLength('password', this.createForm.password, 50, '店舗パスワード');

      validator.checkRequire('password_confirm', this.passwordConfirm, '店舗パスワード（確認用）') &&
        validator.checkSameValue('password_confirm', this.passwordConfirm, this.createForm.password, '店舗パスワード（確認用）');

      this.errors = validator.errors;
    },
    redirectBack() {
      this.$store.commit('CLEAR_STATE_RESTAURANTS_OWNER');
      return this.$router.push('/owner');
    }
  }
};
</script>

<style scoped>
.mb_12 {
  margin-bottom: 12px;
}
.text_note_mb {
  margin-bottom: 6px;
}
.two_items {
  width: 90%;
}
</style>