<template>
  <div id="confirm-screen">
    <div class="bottom_menu">
      <h2 class="bottom_menu_title">{{ title }}</h2>
      <div class="mb_large pre-formatted">{{ message }}</div>
      <div class="btn_area two_items">
        <a href="#" class="btn delete" @click="choose(true)">削除する</a>
        <a href="#" class="btn primary_border cancel ml_medium" @click="cancel">キャンセル</a>
      </div>
    </div>
    <div class="bottom_menu_bg" @click="cancel" />
  </div>
</template>

<script>
export default {
  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes',
    },
    buttonFalseText: {
      type: String,
      default: 'No',
    },
    buttonTrueColor: {
      type: String,
      default: 'primary',
    },
    buttonFalseColor: {
      type: String,
      default: 'grey',
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    persistent: Boolean,
  },
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener('keyup', this.onEnterPressed);
  },
  methods: {
    choose(value) {
      this.value = value;
      this.$destroy();
    },
    cancel() {
      this.$destroy();
    },
  },
};
</script>
<style scoped>
#confirm-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
}
.bottom_menu_bg {
  pointer-events: all;
}
</style>
