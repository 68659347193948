<template>
  <div>
    <h1 class="title mb_medium admin_line_down">{{ ownerName }}店舗一覧</h1>
    <table class="sr_ad_table">
      <thead>
        <tr>
          <th class="w_15">店舗名</th>
          <th class="w_10">契約日</th>
          <th class="w_15">契約更新日</th>
          <th class="w_20">解約日</th>
          <th class="w_15">最終発注日</th>
          <th class="w_10">発注回数</th>
          <th class="w_15">ステータス</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(restaurantAdministrator, index) in restaurantAdministrators"
            :key="index"
            class="btn_point"
            :class="restaurantAdministrator.status == 'inactive' ? 'row_inactive' : ''"
            @click="restaurantDetail(restaurantAdministrator.id)"
        >
          <td class="no_hidden">{{ restaurantAdministrator.shop_name }}</td>
          <td class="no_hidden">
            {{ restaurantAdministrator.contract_start_at ? formatDateWithoutLeadingZeros(restaurantAdministrator.contract_start_at) : '' }}
          </td>
          <td class="no_hidden">{{
            restaurantAdministrator.contract_update_at == null || (restaurantAdministrator.contract_end_at == !null &&
              restaurantAdministrator.contract_start_at == null &&
              restaurantAdministrator.contract_update_at == !null)
              ? '' : formatDateWithoutLeadingZeros(restaurantAdministrator.contract_update_at) }}
          </td>
          <td class="no_hidden text_cancel">
            {{
              restaurantAdministrator.contract_end_at != null && restaurantAdministrator.status != 'inactive' ?
                `${formatEndDate(restaurantAdministrator.contract_end_at)}まで利用可能` :
                restaurantAdministrator.status == 'inactive' ?
                  formatDateWithoutLeadingZeros(restaurantAdministrator.contract_end_at) : ''
            }}
          </td>
          <td>{{ restaurantAdministrator.order_at }}</td>
          <td>{{ restaurantAdministrator.order_count }}</td>
          <td
            v-if="restaurantAdministrator.status == 'admin_cancel' || restaurantAdministrator.status == 'inactive'"
            class="no_hidden text_cancel"
          >
            {{ statusName[restaurantAdministrator.status] }}
          </td>
          <td v-else class="no_hidden">{{ statusName[restaurantAdministrator.status] }}</td>
        </tr>
      </tbody>
    </table>
    <div v-show="restaurantAdministrators.length === 0" class="text-center mt_large">表示するデータはありません。</div>
    <div class="btn_area_admin btns edit">
      <button class="btn btn_black_border btn_point" @click="redirectBack">戻る</button>
    </div>
  </div>
</template>

<script>
import { RestaurantAdministratorsService } from '../../../services/admin/restaurant_administrators.service';
import utils from '../../../common/util.js';
import moment from 'moment';
export default {
  data() {
    return {
      restaurantAdministrators: [],
      statusName: {
        active: '利用中',
        admin_cancel: '利用停止中',
        inactive: '解約'
      },
      ownerName: null,
    };
  },
  async mounted() {
    this.getRestaurantAdministrators();
  },
  methods: {
    ...utils,
    formatEndDate(contract_end_at) {
      return moment(contract_end_at, 'YYYY/MM/DD').format('YYYY/M/D');
    },
    async getRestaurantAdministrators() {
      const owner_id = this.$router.history.current.params.id;
      try {
        const { response } = await RestaurantAdministratorsService.index({ owner_id: owner_id });
        this.restaurantAdministrators = response.data;
        this.ownerName = response.owner.name;
      }catch(error){
        console.log(error);
      }
    },
    restaurantDetail(id) {
      return this.$router.push({ path: `/admin/restaurant/detail/${id}` });
    },
    async redirectBack() {
      this.$router.go(-1);
    }
  },
};
</script>
