<template>
  <div class="edit">
    <!--Header-->
    <div class="modal-header adjust_title_space">
      <div class="modal-menu flex">
        <div class="form-row">
          <a class="tab" :class="isBasic ? 'active': ''" @click="isBasic = true">基本情報</a>
          <a class="tab" :class="!isBasic ? 'active': ''" @click="isBasic = false">住所・連絡先</a>
        </div>
        <div class="icon close" @click="isBasic=true" />
      </div>
    </div>

    <!--body-->
    <div class="modal-body">
      <div class="admin_content">
        <form action="">
          <div class="basic-info" :class="isBasic ? '' : 'hidden'">
            <div class="form-row">
              <label for="shopName" class="form_label form-20">店舗名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.shop_name" type="text" placeholder="店舗名を入力">
                <span v-for="error in errors['shopName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="shopNameKana" class="form_label form-20">店舗名（カナ）<span class="require">必須</span></label>
              <div class="flex-column  form-50">
                <input v-model="editRest.shop_name_kana" type="text" placeholder="店舗名（カナ）を入力">
                <span v-for="error in errors['shopNameKana']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="name" class="form_label form-20">氏名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.name" type="text" placeholder="氏名を入力">
                <span v-for="error in errors['name']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="nameKana" class="form_label form-20">氏名（カナ）<span
                class="require"
              >必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.name_kana" type="text" placeholder="氏名（カナ）を入力">
                <span v-for="error in errors['nameKana']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="address-info" :class="!isBasic ? '' : 'hidden'">
            <div class="form-row">
              <label class="form_label form-20" for="title">郵便番号<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="title" v-model="editRest.postcode" placeholder="郵便番号を⼊⼒（ハイフン無し）"
                       type="text"
                >
                <span v-for="error in errors['postcode']" :key="error" class="error-message">{{ error }}</span>
              </div>
            </div>
            <div class="form-row">
              <label for="prefectureId" class="form_label form-20">都道府県<span class="require">必須</span></label>
              <div class="flex-column form-50 select-wrapper">
                <select v-model="editRest.prefecture_id" class="admin_select_background">
                  <option v-for="(prefecture, index) in prefectures" :key="index" :value="prefecture.code">
                    {{ prefecture.name }}
                  </option>
                </select>
              </div>
              <span v-for="error in errors['prefectureId']" :key="error" class="error-message">
                {{ error }}
              </span>
            </div>
            <div class="form-row">
              <label for="city" class="form_label form-20">市区町村<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.city" type="text" placeholder="市区町村を入力">
                <span v-for="error in errors['city']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="address" class="form_label form-20">番地<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.address" type="text" placeholder="番地を入力">
                <span v-for="error in errors['address']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="building_name" class="form_label form-20">建物名</label>
              <div class="flex-column form-50">
                <input v-model="editRest.building_name" type="text" placeholder="建物名を入力">
                <span v-for="error in errors['buildingName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="phone" class="form_label form-20">電話番号<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.phone" type="text" placeholder="電話番号を⼊⼒（ハイフン無し）">
                <span v-for="error in errors['phone']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="email" class="form_label form-20">メールアドレス<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editRest.email" type="text" placeholder="メールアドレスを入力">
                <span v-for="error in errors['email']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--Footer-->
    <div class="modal-footer">
      <button class="btn_admin admin_primary" @click="confirmUpdateRest">登録</button>
    </div>
  </div>
</template>
<script>
import Validator from '../../../../validator';
import utils, {AddressInfo} from '../../../../common/util';
import {mapGetters} from 'vuex';
import {RestaurantAdministratorsService} from '../../../../services/admin/restaurant_administrators.service';

export default {
  props: {
    editRest: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isBasic: true,
      postcode: null,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
  },
  watch: {
    postcode(val) {
      if (val !== this.editRest.postcode) {
        AddressInfo(val, this.prefectures).then((address) => {
          this.editRest.prefecture_id = address.code;
          this.editRest.city = address.city;
          this.editRest.address = address.address;
        });
      }
    }
  },
  methods: {
    ...utils,
    confirmUpdateRest() {
      this.validate();
      if (Object.keys(this.errors).length) return;
      this.updateRest();
    },
    async updateRest() {
      this.$loading(true);
      try {
        const params = {
          login_id: this.editRest.login_id,
          shop_name: this.editRest.shop_name,
          shop_name_kana: this.editRest.shop_name_kana,
          name: this.editRest.name,
          name_kana: this.editRest.name_kana,
          email: this.editRest.email,
          prefecture_id: this.editRest.prefecture_id,
          postcode: this.editRest.postcode,
          city: this.editRest.city,
          address: this.editRest.address,
          building_name: this.editRest.building_name,
          phone: this.editRest.phone,
        };
        await RestaurantAdministratorsService.update(this.editRest['id'], params);
        this.$closeModal('editDetailResModal');
        this.$emit('refreshTable');
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
      this.$loading(false);
    },
    validate() {
      this.editRest.shop_name_kana = this.editRest.shop_name_kana.trim();
      this.editRest.name_kana = this.editRest.name_kana.trim();
      
      const validator = new Validator();

      validator.checkRequire('shopName', this.editRest.shop_name, '店舗名') &&
      validator.checkMaxLength('shopName', this.editRest.shop_name, 255, '店舗名');

      validator.checkRequire('shopNameKana', this.editRest.shop_name_kana, '店舗名（カナ）') &&
      validator.checkMaxLength('shopNameKana', this.editRest.shop_name_kana, 255, '店舗名（カナ）') &&
      validator.checkKatakana('shopNameKana', this.editRest.shop_name_kana, '店舗名（カナ）');

      validator.checkRequire('name', this.editRest.name, '氏名') &&
      validator.checkMaxLength('name', this.editRest.name, 255, '氏名');

      validator.checkRequire('nameKana', this.editRest.name_kana, '氏名（カナ）') &&
      validator.checkMaxLength('nameKana', this.editRest.name_kana, 255, '氏名（カナ）') &&
      validator.checkKatakana('nameKana', this.editRest.name_kana, '氏名（カナ）');

      validator.checkRequire('postcode', this.editRest.postcode, '郵便番号') &&
      validator.checkMaxLength('postcode', this.editRest.postcode, 7, '郵便番号') &&
      validator.checkPostCode('postcode', this.editRest.postcode, '郵便番号');

      validator.checkRequire('prefectureId', this.editRest.prefecture_id, '都道府県');

      validator.checkRequire('city', this.editRest.city, '市区町村') &&
      validator.checkMaxLength('city', this.editRest.city, 255, '市区町村');

      validator.checkRequire('address', this.editRest.address, '番地') &&
      validator.checkMaxLength('address', this.editRest.address, 255, '番地');

      validator.checkMaxLength('buildingName', this.editRest.building_name, 255, 'ビル・マンション');

      validator.checkRequire('phone', this.editRest.phone, '電話番号');
      if (this.editRest.phone) {
        const phone = this.editRest.phone;
        validator.checkTel('phone', phone, '電話番号') &&
        validator.checkMaxLength('phone', phone, 11, '電話番号') &&
        validator.checkMinLength('phone', phone, 10, '電話番号');
        if (!validator.errors.phone) {
          validator.checkPhone('phone', this.editRest.phone, '電話番号');
        }
      }

      validator.checkRequire('email', this.editRest.email, '連絡先メールアドレス') &&
      validator.checkEmail('email', this.editRest.email, '連絡先メールアドレス');

      this.errors = validator.errors;
    },
  }
}
;
</script>

<style lang="scss" scoped>
.select-wrapper select {
  width: 100%;
}

.tab {
  min-width: 150px;
  padding: 8px 16px;
  text-align: center;
}

.adjust_title_space {
  padding: 24px 24px 0 24px;
}

.background-color {
  background-color: #241811;
}

.input_edit {
  margin-top: 10px;
  max-width: 50%;
}

.display_select_text {
  line-height: 1.2;
}

.modal-menu {
  font-size: 14px;
}
.modal-menu {
  border-bottom: 1px solid #E7EDF7;
}

.modal-menu .active {
  border-bottom: 3px solid #6D8EFD;
}

.modal-footer {
  border-top: 1px solid #E7EDF7;
}
</style>
