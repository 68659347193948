<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <div class="wrapper-body_dashboard">
        <h2 class="mb_medium">オーナーアカウント 解約申請</h2>
        <div>
          <div class="note_sp">オーナーアカウントの解約申請を行います。</div>
          <div class="note_sp owner_color_reb">解約後、オーナーアカウントの法人情報、請求書受取先情報は削除されます。復旧はできませんのでご注意ください。</div>
          <div class="note_sp owner_color_reb">送信済みの発注書について何か問題が発生した場合は、直接お取り引き業者様とご連絡の上、ご対応いただきますようお願いします。</div>
          <div class="note_sp text_color-reb">
            本解約申請後、ご契約期間が終了する{{ yearAt }}年{{ monthAt }}月末まで継続してご利用いただけます。
          </div>
          <div v-if="owner.contract_start_at" class="note_sp text_color-reb">
            {{ yearAt }}年{{ monthAt }}月までのご利用料金は発生いたしますので、予めご了承ください。
          </div>
          <div class="note_sp mb_medium">よろしければ、以下のアンケートにお答えいただき、「解約申請する」ボタンを押してください。</div>
        </div>
        <label class="owner_form_label mb_12">解約アンケート</label>
        <form v-for="(question, index) in questions" :key="index" class="ml_small">
          <label class=" owner_form_label mb_12 down_line">
            <span v-html="cuttingStrings(question)[0]" />
            <span v-show="question.is_require" class="require">必須</span> <br>
            <span v-if="cuttingStrings(question)[1] != undefined" v-html="cuttingStrings(question)[1]" />
          </label>

          <div v-if="question.type == 'checkbox'">
            <div v-for="(answer, i) in question.answers" :key="i">
              <div class="" :class="['text', 'textarea', 'datetime'].includes(answer.remark) ? 'mb_large' : ''">
                <div class="btn_checkbox">
                  <input :id="'checkbox_' + answer.id" v-model="answer.checked" type="checkbox"
                         class="owner_padding_checkbox"
                         @change="onChangeChangeBoxAnswer(answer)"
                  >
                  <label class="checkmark_checkbox btn_pointer" :for="'checkbox_' + answer.id" />
                  <label class="text_label down_line btn_pointer" :for="'checkbox_' + answer.id" v-html="answer.answer" />
                </div>

                <div v-if="answer.remark == 'text'" class="width_input_75 input_padding">
                  <input v-model="answer.answer_content" type="text" maxlength="3000"
                         @input="changeValueInputCheckBox(answer)"
                  >
                </div>

                <div v-if="answer.remark == 'textarea'" class="width_input_75 input_padding">
                  <textarea v-model="answer.answer_content"
                            class="owner_textarea"
                            cols="20"
                            rows="4"
                            maxlength="3000"
                            @input="changeValueInputCheckBox(answer)"
                  />
                </div>

                <div v-if="answer.remark == 'datetime'"
                     class="input_wrapper calender width_input_75 input_padding"
                >
                  <flat-pickr
                    v-model="answer.answer_content"
                    :config="config"
                    @input="changeValueInputCheckBox(answer)"
                  />
                </div>
              </div>
            </div>
            <span v-for="error in errors[question.id]" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>

          <div v-if="question.type == 'radio'" class="input_owner down_line">
            <div>
              <label v-for="(answer, z) in question.answers" :key="z" class="radio_btn_owner">
                <input v-model="question.radio_value" type="radio" :value="answer.id"
                       class="owner_padding_checkbox owner_border_input_radius"
                >
                <span class="checkmark_owner" />
                <div class="owner_radio_text"> {{ answer.answer }}</div>
              </label>
              <span v-for="error in errors[question.id]" :key="error" class="error-message">
                {{ error }}
              </span>
            </div>
          </div>

          <div v-if="question.type == 'text'" class="input_owner">
            <input v-model="question.answer_content" type="text" maxlength="3000">
            <span v-for="error in errors[question.id]" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>

          <div v-if="question.type == 'datetime'" class="input_owner">
            <div class="input_wrapper calender">
              <flat-pickr
                v-model="question.answer_content"
                :config="config"
              />
            </div>
            <span v-for="error in errors[question.id]" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </form>

        <div v-if="showButton" class="btn_area_owner two_items owner_btn_padding">
          <button class="btn btn_owner delete owner_font_16 btn_pointer"
                  @click="handleDelete"
          >
            解約申請する
          </button>
          <button class="btn btn_owner primary_border owner_font_16 btn_pointer ml_medium"
                  @click="redirectBack"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { OwnersService } from '../../../services/Owner/owners.service';
import { OwnerQuestionsService } from '../../../services/Owner/owner_questions.service';
import { OwnerAnswersService } from '../../../services/Owner/owner_answers.service';
import flatPickr from 'vue-flatpickr-component';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
  components: {
    flatPickr
  },
  data() {
    return {
      questions: [],
      errors: [],
      flg_error: false,
      config: {
        enableTime: true,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d H:i',
      },
      owner: {},
      showButton: false,
      yearAt: '',
      monthAt: '',
    };
  },
  async created(){
    await this.getOwners();
    if(this.owner.contract_end_at != null || !this.owner.is_canceled || !this.owner.is_cancel_at || this.owner.status != 'active') {
      window.location.href = '/owner';
    }
    await this.formatDate();
    await this.getOwnerQuestions();
  },
  methods: {
    changeValueInputCheckBox(answer) {
      answer.checked = answer.answer_content !== '';
    },
    onChangeChangeBoxAnswer(answer) {
      if(!answer.checked) {
        answer.answer_content = '';
      }
    },
    async getOwnerQuestions() {
      this.$loading(true);
      try {
        const { response } = await OwnerQuestionsService.index();
        this.questions = response.data;
        this.showButton = true;
        this.$loading(false);
      } catch(error) {
        this.$loading(false);
      }
    },

    async handleDelete() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      try {
        const { response } = await OwnersService.index();
        if(response.data.is_canceled){
          this.popupDelete();
        }else{
          this.popupCheckDelete();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async popupDelete() {
      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>オーナーアカウントの解約を申請します。</p>
               <p>よろしいですか？</p>`,
        showCancelButton: true,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });

      if (confirm.isConfirmed) {
        try {
          const { response } = await OwnerAnswersService.create(this.fetchParamsQuestionAnswer());
          this.$store.commit('SET_CANCEL_AT', response.data.cancel_at);
          return this.$router.push('/owner/complete_delete_owner');
        } catch(error) {
          this.popupCheckDeleteWithMessage(error.response.result.message);
        }
      }
    },

    fetchParamsQuestionAnswer() {
      const questions_answers = [];
      this.questions.forEach(element => {
        if (element.type == 'text' || element.type == 'datetime') {
          questions_answers.push({
            owner_cancel_question_id: element.id,
            owner_cancel_question_answer_id: null,
            answer_content: element.answer_content,
            question_no: element.question_no
          });
        } else if (element.type == 'radio') {
          questions_answers.push({
            owner_cancel_question_id: element.id,
            owner_cancel_question_answer_id: element.radio_value,
            answer_content: null,
            question_no: element.question_no
          });
        } else if (element.type == 'checkbox') {
          element.answers.forEach(answer => {
            if (answer.checked == true) {
              questions_answers.push({
                owner_cancel_question_id: element.id,
                owner_cancel_question_answer_id: answer.id,
                answer_content: answer.answer_content,
                question_no: element.question_no
              });
            }
          });
        }
      });
      return questions_answers;
    },

    async popupCheckDelete() {
      this.$swal.fire({
        title: '削除ができません',
        html: `<p>契約中の店舗がございます。</p>
               <p>お手数ですが、店舗解約後に実行してください。</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });
    },

    validate() {
      this.flg_error = false;
      const errors = {};
      this.questions.map(element => {
        if (element.type == 'text') {
          if(!element.answer_content && element.is_require == true) {
            errors[`${element.id}`] = ['この質問に答えてください。'];
          }
        } else if(element.type == 'radio') {
          if (!element.radio_value && element.is_require == true) {
            errors[`${element.id}`] = ['この質問に答えてください。'];
          }
        } else if(element.type == 'checkbox') {
          const checked = element.answers.find(p => p.checked === true);
          if (checked == undefined && element.is_require == true) {
            errors[`${element.id}`] = ['この質問に答えてください。'];
          }
        } else if(element.type == 'datetime') {
          if(!element.answer_content && element.is_require == true) {
            errors[`${element.id}`] = ['この質問に答えてください。'];
          }
        }
      });
      this.errors = errors;
    },

    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    redirectBack() {
      return this.$router.push('/owner/detail');
    },
    formatDate() {
      let cancelAt;
      cancelAt = moment(this.owner.update_contract, 'YYYY/MM/DD').subtract(1, 'days').format('YYYY/MM/DD');
      this.yearAt = moment(cancelAt, 'YYYY/MM/DD').format('YYYY');
      this.monthAt = moment(cancelAt, 'YYYY/MM/DD').format('MM');
    },
    cuttingStrings(questions){
      let input = questions.question;
      let fields = input.split('</br>');
      return fields;
    },
    popupCheckDeleteWithMessage(errorMessage) {
      this.$swal.fire({
        title: 'アラート',
        html: `<p>${errorMessage}</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },
  }
};

</script>

<style scoped>
.text_label {
  font-weight: normal;
  display: inline;
}
.mb_12 {
  margin-bottom: 12px;
}
.radio_btn_owner {
  width: unset;
  margin-right: 24px;
  margin-bottom: 16px;
  height: 25px;
}
.btn_checkbox {
  cursor: unset;
}
.owner_form_label {
  display: block;
}
.owner_form_label .require {
  vertical-align: bottom;
}
.two_items {
  width: 90%;
}
</style>