<template>
  <div class="sidebar">
    <div class="sp_menu">
      <div class="logo_owner" />
      <div class="sp_menu_trigger" />
    </div>
    <div
      class="sidebar sidebar-owner"
      :class="[
        openSidebar ? 'sidebar-owner-open' : '',
        hideSidebar ? 'sidebar-owner-hide' : '',
      ]"
    >
      <ul>
        <li class="owner_menu_content" @click="handleSidebar">
          <router-link
            to="/owner"
            :class="
              $route.path.includes('/owner/restaurant') ||
                $route.name === 'restaurant_dashboard' ||
                $route.name == 'owner_detail' ||
                $route.name == 'owner_delete' ||
                $route.name == 'owner_edit' ||
                $route.name == 'complete_delete_owner'
                ? 'router-link-exact-active'
                : ''
            "
          >
            アカウント管理
          </router-link>
        </li>
        <li @click="handleSidebar">
          <router-link
            to="/owner/payment"
            :class="
              $route.path.includes('/owner/payment')
                ? 'router-link-exact-active'
                : ''
            "
          >
            支払管理
          </router-link>
        </li>
        <li v-if="isPC" @click="openDialogCSV">
          <router-link id="exportCsv" to="#" class="">
            発注データ出力
          </router-link>
        </li>
        <li @click="logoutMember">
          <router-link id="logout" to="#" class=""> ログアウト </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { AuthService } from '../../services/auth.service';

export default {
  props: {
    openSidebar: {
      type: Boolean,
    },
    hideSidebar: {
      type: Boolean,
    },
    handleSidebar: {
      type: Function,
    },
  },
  data() {
    return {
      showPopup: true,
      typeExport: null,
      restaurantId: null,
      isPC: true
    };
  },
  mounted() {
    this.isPC = screen.width > 1180 ? true : false;
  },
  methods: {
    closeSidebar() {
      this.handleSidebar();
    },

    openDialogCSV() {
      this.$emit('open-popup', true);
      document.getElementById('exportCsv').classList.add('router-link-exact-active');
      document.getElementById('logout').classList.remove('router-link-exact-active');
    },

    async logoutMember() {
      document.getElementById('logout').classList.add('router-link-exact-active');
      const logoutPopup = await this.$swal.fire({
        title: '確認',
        html: 'ログアウトします。よろしいですか？',
        showCancelButton: true,
        confirmButtonText: 'ログアウトする',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'confirm-popup-owner owner_width_button',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner owner_width_button',
          htmlContainer: 'html-popup-owner',
        },
      });

      if (logoutPopup.isConfirmed) {
        try {
          await AuthService.logout();
          this.$store.commit('SET_LOGIN_OWNER_FLAG', false);
          window.location.href = '/';
        } catch (error) {
          window.location.href = '/';
        }
      } else {
        document.getElementById('logout').classList.remove('router-link-exact-active');
      }
    },
  },
};
</script>
