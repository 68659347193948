<template>
  <main class="container bottomUp">
    <div class="main_area_admin_content">
      <div class="ml_main">
        <h1 class="title mb_large">
          ページが見つかりませんでした</h1>
        <p>お探しのページは見つかりませんでした。URLをお確かめください。</p>
      </div>
    </div>
  </main>
</template>

<script>
export default {

};
</script>
<style scoped>
.ml_main {
  margin-left: 20%
}
</style>
