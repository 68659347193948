<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        設定
      </h1>
      <p class="small_text mb_large chanel_text_padding">この画面では、登録情報の設定や変更、発注担当者様の追加など、SMART REQUESTに関する設定を行います。</p>
      <ul class="setting_list">
        <li>
          <router-link to="/delivery_info">
            届け先情報
          </router-link>
        </li>
        <li>
          <router-link to="/order_staff">
            担当者管理
          </router-link>
        </li>
        <li>
          <router-link to="/setting_image">
            その他の設定
          </router-link>
        </li>
      </ul>
      <!-- <h1 v-show="$store.state.auth.role === 10" class="title mb_large title_user_owner">
        店舗切り替え
      </h1>
      <ul v-for="(restaurant, index) in restaurants" :key="index" class="setting_list">
        <li>
          <router-link :to="{}" exact-active-class="" @click.native="changeRestaurant(restaurant.user_id)">
            <div class="flex">
              <p>{{ restaurant.name ? restaurant.name : '未設定' }}</p>
              <p v-show="restaurantAdministratorId === restaurant.user_id" class="alert">「表示中」</p>
            </div>
          </router-link>
        </li>
      </ul> -->
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
    </div>
  </main>
</template>

<script>
import { RestaurantsService } from '../../services/restaurants.service';

export default {
  data() {
    return {
      restaurants:[],
      restaurantAdministratorId: ''
    };
  },
  async mounted() {
    if (this.$store.state.auth.role === 10) {
      await this.getRestaurants();
      this.restaurantAdministratorId = this.$store.state.auth.user_id;
    }
  },
  methods: {
    toIDManager() {
      window.open(`${process.env.MEMBER_BASE_URL}`, '_self');
    },
    async getRestaurants() {
      this.$loading(true);
      try {
        const { response } = await RestaurantsService.search();
        this.restaurants = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    // async changeRestaurant(userId) {
    //   this.$loading(true);
    //   try {
    //     this.$store.commit('SET_USER_ID', userId);
    //     const { response } = await RestaurantsService.index();
    //     this.$store.commit('SET_RESTAURANT', response.data);
    //     await this.$store.dispatch('clearOrder');
    //     this.$loading(false);
    //     this.restaurantAdministratorId = userId;
    //     if (!response.data.id) {
    //       this.$router.push('/delivery_info');
    //     }
    //   } catch (error) {
    //     this.$loading(false);
    //   }
    // }
  },
};
</script>
<style scoped>
.title_owner {
  margin-top: 40px;
}
</style>
