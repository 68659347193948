<template>
  <main class="container bottomUp">
    <div id="body_user" class="main_area smp_content_main">
      <h1 class="title mb_large">
        担当者編集
      </h1>
      <form action="" @submit.prevent="">
        <div class="form_row vertical mb_medium">
          <label for="staffname" class="form_label">
            担当者名<span class="require">必須</span>
          </label>
          <input
            v-model="name"
            type="text"
            placeholder="担当者名を入力"
          >
          <span v-for="error in errors['name']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </form>
    </div>
    <div class="control_area">
      <router-link to="/order_staff" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area two_items">
        <a class="btn primary" @click="handleSave">
          更新する
        </a>
        <a class="btn delete ml_small" @click="handleDelete">削除する</a>
      </div>
    </div>
    <PopupErrorUser :show-dialog="showDialog" :close-dialog="disableShowDialog" />
  </main>
</template>

<script>
import { OrderStaffsService } from '../../services/order_staffs.service';
import Validator from '../../validator';
import PopupErrorUser from './../../components/PopupErrorUser.vue';

export default {
  components: {
    PopupErrorUser
  },
  data() {
    return {
      name: '',
      errors: {},
      showDialog: false
    };
  },
  async mounted() {
    this.$loading(true);
    try {
      const { response } = await OrderStaffsService.get(this.$router.history.current.params.id);
      this.name = response.data.name;
      this.$loading(false);
    } catch (error) {
      this.$loading(false);
    }
  },
  methods: {
    handleSave: async function() {
      this.validate();

      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        this.displayDialog();
        return;
      }

      const params = {
        name: this.name,
      };
      this.$loading(true);
      try {
        await OrderStaffsService.update(this.$router.history.current.params.id, params);
        this.$router.push('/order_staff');
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    handleDelete: async function() {
      const value = await this.$confirm('担当者削除確認', '担当者を削除します。\n本当によろしいですか？');
      if (!value) return;
      this.$loading(true);
      try {
        await OrderStaffsService.delete(this.$router.history.current.params.id);
        this.$router.push('/order_staff');
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('name', this.name, '担当者名') &&
        validator.checkMaxLength('name', this.name, 255, '担当者名');
      this.errors = validator.errors;
    },
    disableShowDialog() {
      this.showDialog = false;
    },
    displayDialog() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
      this.showDialog = true;
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
a {
  cursor: pointer !important;
}
</style>
