import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class PasswordResetService extends BaseService {
  static get resourceEndpoint() {
    return 'owners';
  }

  static async password_reset(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/password_reset`,
        data 
      );
      return new ResponseWrapper(response);
    } catch (error) {     
      throw new ApiError(error);
    }
  }

  static async password_auth(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/password_reset/auth`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async password_submit(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/password_reset/submit`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
