import { ResponseWrapper, ApiError } from './http';

import { BaseService } from './base.service';

export class RestaurantsService extends BaseService {
  static get resourceEndpoint() {
    return 'restaurants';
  }

  static async get() {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async update(data = {}) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async search() {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/search`
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }

  static async agreeRevision(data = {}) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/agree_revision`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
