<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <h2 class="">法人情報管理</h2>
      <form>
        <div class="input_owner">
          <label class="owner_form_label">オーナーID</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.login_id }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">パスワード</label>
          <div class="owner_color_black owner_margin_inline_10"> {{ password }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人名</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.name }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人名（カナ）</label>
          <div class="owner_color_black owner_margin_inline_10 down_line">
            <p class="break-space">{{ owner.name_kana }}</p>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人代表者名</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.director_name }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人代表者名（カナ）</label>
          <div class="owner_color_black owner_margin_inline_10 down_line">
            <p class="break-space">{{ owner.director_name_kana }}</p>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">担当者名</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.staff_name }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">担当者名（カナ）</label>
          <div class="owner_color_black owner_margin_inline_10 down_line">
            <p class="break-space">{{ owner.staff_name_kana }}</p>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">部署名</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.department_name }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">郵便番号</label>
          <div class="owner_color_black owner_margin_inline_10"> {{ owner.postcode }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">都道府県</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ getPrefectureName(getPrefectures, owner.prefecture_id) }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">市区町村</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner. city }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">丁目・番地・号</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.address }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">ビル・マンション</label>
          <div class="owner_color_black owner_margin_inline_10 down_line"> {{ owner.building_name }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">連絡先メールアドレス</label>
          <div class="owner_color_black owner_margin_inline_10"> {{ owner.email }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">電話番号</label>
          <div class="owner_color_black owner_margin_inline_10"> {{ owner.phone }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">契約日</label>
          <div class="owner_color_black owner_margin_inline_10"> {{ formatDate(owner.created_at) }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">解約予定日</label>
          <div class="owner_color_black owner_margin_inline_10"> {{ owner.canceled_at ? formatDate(owner.canceled_at) : '未設定' }}</div>
        </div>
      </form>
      <div class="btn_area_owner two_items owner_btn_padding">
        <button
          class="btn btn_owner primary owner_font_16 btn_pointer"
          @click="handleEdit"
        >
          編集する
        </button>
        <button v-if="owner.status == 'active' && owner.contract_end_at == null"
                class="btn btn_owner delete owner_font_16 btn_pointer ml_medium"
                @click="handleDelete"
        >
          解約申請する
        </button>
        <button v-else
                class="btn btn_owner delete owner_font_16 btn_pointer ml_medium unsubscribe-cancel"
                @click="unsubscribeCancel"
        >
          解約申請の取消をする
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { OwnersService } from '../../../services/Owner/owners.service';
import utils from '../../../common/util.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      password: '* * * * * * * *',
      owner: {},
    };
  },

  computed: {
    ...mapGetters({
      getPrefectures: 'getAllPrefectures',
    }),
  },

  async mounted() {
    await this.getOwners();
  },

  methods: {
    ...utils,

    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    handleEdit: async function () {
      return this.$router.push('/owner/edit');
    },
    async handleDelete() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
        if (this.owner.is_canceled && this.owner.is_cancel_at) {
          this.$router.push('/owner/delete');
        } else if (!this.owner.is_cancel_at && !this.owner.is_canceled) {
          this.popupCheckDelete();
        } else if (!this.owner.is_cancel_at) {
          this.popupCheckDateDelete();
        } else if (!this.owner.is_canceled) {
          this.popupConfirmDelete();
        }
      } catch (error) {
        console.log(error);
      }
    },

    popupConfirmDelete() {
      this.$swal.fire({
        title: '削除できません',
        html: `<p>契約中の店舗がございます。</p>
               <p>お手数ですが、店舗解約後に実行してください。</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },
    popupCheckDelete() {
      this.$swal.fire({
        title: '削除できません',
        html: `<p>解約可能の期間が過ぎています。また、契約中の店舗がございます。</p>
               <p>お手数ですが、翌月の1日に店舗解約後に解約を実行してください。</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },
    popupCheckDateDelete() {
      this.$swal.fire({
        title: '削除できません',
        html: `<p>解約可能の期間が過ぎています。</p>
               <p>お手数ですが、翌月の1日に解約を実行してください。</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },

    popupCheckDeleteMessage(errorMessage) {
      this.$swal.fire({
        title: 'アラート',
        html: `<p>${errorMessage}</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },

    async unsubscribeCancel() {
      try {
        let textContentPopup;
        if(this.owner.is_unsubscribe_cancel) {
          textContentPopup = `<p>解約申請の取消を行います。</p>
                              <p>よろしいですか。</p>`;
        } else {
          textContentPopup = `<p>解約申請の取消を行います。</p>
                              <p>解約申請の取消を行った場合、再度解約申請を行うことが可能になるのは翌月になります。よろしいですか？</p>`;
        }

        const confirm = await this.$swal.fire({
          title: '確認',
          html: textContentPopup,
          confirmButtonText: '決定する',
          cancelButtonText: 'キャンセル',
          showCancelButton: true,
          customClass: {
            confirmButton: 'confirm-popup-owner',
            title: 'title-popup-owner',
            cancelButton: 'cancel-popup-owner',
            htmlContainer: 'html-popup-owner'
          }
        });

        if(confirm.isConfirmed) {
          await OwnersService.unsubscribeCancel();
          window.location.reload();
        }
      } catch (error) {
        this.popupCheckDeleteMessage(error.response.result.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.two_items {
  width: 90%;
}
.unsubscribe-cancel {
  color: #ac0000;
  background: #fff;
  border: 2px solid #ac0000;
}
</style>
