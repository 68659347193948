<template>
  <main class="container">
    <div class="main_area main_area-dashboard">
      <div ref="infoBox" id="container-content">
        <p class="title_md">
          {{ getOwnerName }}
        </p>
        <div class="card_text mb_large title_center">
          {{ restaurant.name }}
        </div>
        <!--
        <div class="banner_area title_center">
          <a href="https://bespo.zendesk.com/hc/ja/articles/7897595752473-SMART-REQUEST-%E3%83%88%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%AB%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3#h_01G7NGDRKREFN74ZW34R9S2QKF" v-on:click="sendGa" target="_blank"><img src="../../../assets/images/banner/smartrequest_02.png" alt="img"></a>
        </div>
        -->
      </div>
      <div id="height-pc">
        <div class="flex sep_order mb_medium">
          <router-link to="/order/select_company" class="card">
            <div class="icon for_card company mb_icon" />
            <div class="card_text">発注先から</div>
            <div class="card_text">発注書を作成する</div>
          </router-link>
          <router-link to="/order/item_select" class="card">
            <div class="icon for_card item mb_icon" />
            <div class="card_text">商品から</div>
            <div class="card_text">発注書を作成する</div>
          </router-link>
          <router-link to="/order_history" class="card">
            <div class="icon for_card history_order mb_icon" />
            <div class="card_text down_the_line card_text-pc">
              発注履歴を確認する
            </div>
            <div class="card_text card_text-mb">
              発注履歴を確認する
            </div>
          </router-link>
        </div>
        <div class="flex sep_order mb_medium">
          <router-link to="/message" class="card">
            <div class="icon for_card message mb_icon" />
            <div class="card_text">発注先へ</div>
            <div class="card_text">メールで連絡する</div>
          </router-link>
          <router-link to="/memo" class="card">
            <div class="card_main">
              <div class="icon for_card memo mb_icon" />
              <div v-if="hasNewMemos" class="card_count">
                <div class="count count_qty">
                  <span v-if="getAllMemos.length <= 99" getAllMemos.length>{{ getAllMemos.length }}</span>
                  <span v-else class="count_minus">99</span>
                </div>
              </div>
            </div>
            <div class="card_text down_the_line card_text-pc">
              メモを確認する
            </div>
            <div class="card_text card_text-mb">
              メモを確認する
            </div>
          </router-link>
          <router-link to="/upload" class="card">
            <div class="icon for_card upload mb_icon" />
            <div class="card_text down_the_line card_text-pc">
              登録代行を依頼する
            </div>
            <div class="card_text card_text-mb">
              登録代行を依頼する
            </div>
          </router-link>
        </div>
        <div class="flex sep_order">
          <router-link to="/inventory" class="card">
            <div class="icon for_card inventory_manage mb_icon" />
            <div class="card_text down_the_line card_text-pc">
              在庫を管理する
            </div>
            <div class="card_text card_text-mb">
              在庫を管理する
            </div>
          </router-link>
          <router-link to="/order_report" class="card">
            <div class="icon for_card order_report mb_icon" />
            <div class="card_text down_the_line card_text-pc">
              利用レポートを見る
            </div>
            <div class="card_text card_text-mb">
              利用レポートを見る
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { RestaurantsService } from '../../services/restaurants.service.js';
export default {
  data() {
    return {
      restaurant: {}
    };
  },
  async mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        const height = document.getElementById('height-pc');
        height.style.height = `calc(100% - ${this.$refs.infoBox.offsetHeight + 30}px)`;
      }
    }

    this.$nextTick(() => {
      setTimeout(() => {
        let heightContent = this.$refs.infoBox.offsetHeight
        let height = document.getElementById('height-pc');
        heightContent = heightContent < 200 ? 200 : heightContent;
        height.style.height = `calc(100% - ${heightContent + 20}px)`;
      }, 30)
    })

    await this.getRestaurant();
  },
  computed: {
    ...mapGetters({
      getAllMemos: 'getAllMemos',
      hasNewMemos: 'hasNewMemos',
      getOwnerName: 'getOwnerName'
    }),
  },
  methods: {
    sendGa() {
      gtag('event', 'bannerclick', {'event_category': 'banner', 'event_label': 'SmartRequestオーナーキャンペーン', 'value': '0'});
    },
    async getRestaurant() {
      this.$loading(true);
      try {
        const { response } = await RestaurantsService.get();
        this.restaurant = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
  }
};
</script>
<style scoped>
@media screen and (max-width: 767px){
  #height-pc {
    height: unset !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .down_the_line {
    text-align: center !important;
    word-break: keep-all !important;
  }
  .card_text-mb {
    display: none !important;
  }
  .card_text-pc {
    display: block !important;
  }
}
.container {
  padding: 0px;
}
.down_the_line {
  word-break: unset;
}
.card_text-pc {
  display: none;
}
.card_text-mb {
  display: block;
  white-space: nowrap;
}
</style>
