import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class RestaurantAdministratorsService extends BaseService {
  static get resourceEndpoint() {
    return 'owners/restaurant_administrators';
  }

  static async confirm_register(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/confirm_register`,
        data,
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async register(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/register`,
        data,
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async unsubscribeCancel(data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/unsubscribe_cancel`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
