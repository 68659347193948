import {BaseService} from './base.service';
import {ApiError, ResponseWrapper} from './http';

export class OcrService extends BaseService {
  static get resourceEndpoint() {
    return 'ocr';
  }

  static async detail(params) {
    try {
      const response = await this.request().get(
        'ocr/detail',
        {params}
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async saveToDraft(params) {
    try {
      const response = await this.request().post(
        'ocr/save_draft_text',
        params
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async cancel(id, params) {
    try {
      const response = await this.request().put(
        `ocr/cancel/${id || ''}`,
        params
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async csvExportParseText(params) {
    try {
      const response = await this.request().get(
        'ocr/export_parse_text',
        {params}
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async csvItemImport(id, supplier_id, data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/restaurant/${id}/suppliers/${supplier_id}/items/import`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async csvExportTemplate(params = {}) {
    try {
      const response = await this.request().get(
        'ocr/export_template_to_csv',
        {params}
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
