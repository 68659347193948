<template>
  <div class="owner_main_area">
    <h1 class="title_owner mb_medium">店舗の新規登録</h1>
    <p class="note_sp mb_large">
      店舗の新規登録と店舗アカウントの登録が完了しました。
    </p>
    <div class="btn_area_owner three_items owner_btn_padding">
      <router-link
        :to="{}"
        class="btn btn_owner primary"
        @click.native="back"
      >
        続けて店舗を登録する
      </router-link>
      <router-link
        to="/owner"
        class="btn btn_owner primary_border ml_medium"
      >
        店舗管理へ
      </router-link>
      <router-link :to="{}" class="btn btn_owner dark_orange ml_medium" @click.native="toTop">発注書作成画面へ</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    restaurant: {
      type: Object,
      default: () => {}
    },
  },

  methods: {
    toTop() {
      this.$store.commit('SET_USER_ID', this.restaurant.restaurant_administrator_id);
      this.$store.commit('SET_RESTAURANT', this.restaurant);
      this.$router.push('/dashboard');
    },
    back() {
      this.$store.commit('CLEAR_STATE_RESTAURANTS_OWNER');
      this.$emit('change_screen', 'create');
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>