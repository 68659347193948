<template>
  <div class="edit">
    <!--Header-->
    <div class="modal-header adjust_title_space">
      <div class="modal-menu flex">
        <div class="form-row">
          <a class="tab" :class="isBasic ? 'active': ''" @click="isBasic = true">基本情報</a>
          <a class="tab" :class="!isBasic ? 'active': ''" @click="isBasic = false">住所・連絡先</a>
        </div>
        <div class="icon close" @click="isBasic=true" />
      </div>
    </div>

    <!--body-->
    <div class="modal-body">
      <div class="admin_content">
        <form action="">
          <div class="basic-info" :class="isBasic ? '' : 'hidden'">
            <div class="form-row">
              <label for="name" class="form_label form-20">法人名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.name" type="text" placeholder="法人名を入力">
                <span v-for="error in errors['name']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="nameKana" class="form_label form-20">法人名（カナ）<span class="require">必須</span></label>
              <div class="flex-column  form-50">
                <input v-model="editOwner.name_kana" type="text" placeholder="法人名（カナ）を入力">
                <span v-for="error in errors['nameKana']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="directorName" class="form_label form-20">法人代表者名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.director_name" type="text" placeholder="法人代表者名を入力">
                <span v-for="error in errors['directorName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="directorNameKana" class="form_label form-20">法人代表者名（カナ）<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.director_name_kana" type="text" placeholder="法人代表者名（カナ）を入力">
                <span v-for="error in errors['directorNameKana']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="staffName" class="form_label form-20">担当者名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.staff_name" type="text" placeholder="担当者名を入力">
                <span v-for="error in errors['staffName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="staffNameKana" class="form_label form-20">担当者名（カナ）<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.staff_name_kana" type="text" placeholder="担当者名（カナ）を入力">
                <span v-for="error in errors['staffNameKana']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="" class="form_label form-20">代理店名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <div class="select_wrapper arrow_down">
                  <select v-model="editOwner.agency.agency_id" class="admin_select_background display_select_text">
                    <option value="0" />
                    <option v-for="(agencie, index) in agencies" :key="index" :value="agencie.id">
                      {{ agencie.name }}
                    </option>
                  </select>
                </div>
                <span v-for="error in errors['agency.agency_id']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="" class="form_label form-20">紹介コード<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.agency.code" type="text" placeholder="紹介コードを入力">
                <span v-for="error in errors['agency.code']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

          </div>
          <div class="address-info" :class="!isBasic ? '' : 'hidden'">
            <div class="form-row">
              <label class="form_label form-20" for="title">郵便番号<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="title" v-model="editOwner.postcode" placeholder="郵便番号を⼊⼒（ハイフン無し）"
                       type="text"
                >
                <span v-for="error in errors['postcode']" :key="error" class="error-message">{{ error }}</span>
              </div>
            </div>
            <div class="form-row">
              <label for="prefectureId" class="form_label form-20">都道府県<span class="require">必須</span></label>
              <div class="flex-column form-50 select-wrapper">
                <select v-model="editOwner.prefecture_id" class="admin_select_background">
                  <option v-for="(prefecture, index) in prefectures" :key="index" :value="prefecture.code">
                    {{ prefecture.name }}
                  </option>
                </select>
              </div>
              <span v-for="error in errors['prefectureId']" :key="error" class="error-message">
                {{ error }}
              </span>
            </div>
            <div class="form-row">
              <label for="city" class="form_label form-20">市区町村<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.city" type="text" placeholder="市区町村を入力">
                <span v-for="error in errors['city']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="address" class="form_label form-20">番地<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.address" type="text" placeholder="番地を入力">
                <span v-for="error in errors['address']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="building_name" class="form_label form-20">建物名</label>
              <div class="flex-column form-50">
                <input v-model="editOwner.building_name" type="text" placeholder="建物名を入力">
                <span v-for="error in errors['buildingName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="phone" class="form_label form-20">電話番号<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.phone" type="text" placeholder="電話番号を⼊⼒（ハイフン無し）">
                <span v-for="error in errors['phone']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="email" class="form_label form-20">メールアドレス<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input v-model="editOwner.email" type="text" placeholder="メールアドレスを入力">
                <span v-for="error in errors['email']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--Footer-->
    <div class="modal-footer">
      <button class="btn_admin admin_primary" @click="confirmUpdateOwner">登録</button>
    </div>
  </div>
</template>
<script>
import Validator from '../../../../validator';
import utils from '../../../../common/util';
import {mapGetters} from 'vuex';
import {OwnersService} from '../../../../services/admin/owners.service';
import {AgenciesService} from '../../../../services/admin/agencies.service';

export default {
  props: {
    owner: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      editOwner: { agency: {}, ...this.owner },
      isBasic: true,
      errors: {},
      agencies: [],
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
  },
  watch: {
    owner: function (newVal) {
      this.editOwner = { agency: {}, ...newVal };
    },
  },
  async mounted() {
    await this.getListAgencies();
  },
  methods: {
    ...utils,
    async getListAgencies() {
      try {
        const {response} = await AgenciesService.index();
        this.agencies = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    confirmUpdateOwner() {
      this.validate();
      if (Object.keys(this.errors).length) return;
      this.popupconfirmUpdateOwner();
    },
    async popupconfirmUpdateOwner() {
      this.$loading(true);
      let agencyParams = {};
      try {
        if (this.editOwner.agency.agency_id === 0 || this.editOwner.agency.agency_id === null) {
          agencyParams = {
            code: this.editOwner.agency.code,
            agency_id: this.editOwner.agency.agency_id,
            _destroy: true
          };
        } else {
          agencyParams = {
            code: this.editOwner.agency.code,
            agency_id: this.editOwner.agency.agency_id,
            _destroy: false
          };
        }
        const params = {
          login_id: this.editOwner.login_id,
          name: this.editOwner.name,
          name_kana: this.editOwner.name_kana,
          email: this.editOwner.email,
          director_name: this.editOwner.director_name,
          director_name_kana: this.editOwner.director_name_kana,
          staff_name: this.editOwner.staff_name,
          staff_name_kana: this.editOwner.staff_name_kana,
          prefecture_id: this.editOwner.prefecture_id,
          postcode: this.editOwner.postcode,
          city: this.editOwner.city,
          address: this.editOwner.address,
          building_name: this.editOwner.building_name,
          phone: this.editOwner.phone,
          agency: agencyParams
        };
        await OwnersService.update(this.editOwner['id'], params);
        this.$closeModal('editDetailOwnerModal');
        this.$emit('refreshTable');
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
      this.$emit('updateSelectedOwner', -1);
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire('name', this.editOwner.name, '法人名') &&
      validator.checkMaxLength('name', this.editOwner.name, 255, '法人名');

      validator.checkRequire('nameKana', this.editOwner.name_kana, '法人名（カナ）') &&
      validator.checkMaxLength('nameKana', this.editOwner.name_kana, 255, '法人名（カナ）') &&
      validator.checkKatakana('nameKana', this.editOwner.name_kana, '法人名（カナ）');

      validator.checkRequire('directorName', this.editOwner.director_name, '法人代表者名') &&
      validator.checkMaxLength('directorName', this.editOwner.director_name, 255, '法人代表者名');

      validator.checkRequire('directorNameKana', this.editOwner.director_name_kana, '法人代表者名（カナ）') &&
      validator.checkMaxLength('directorNameKana', this.editOwner.director_name_kana, 255, '法人代表者名（カナ）') &&
      validator.checkKatakana('directorNameKana', this.editOwner.director_name_kana, '法人代表者名（カナ）');

      validator.checkRequire('staffName', this.editOwner.staff_name, '担当者名') &&
      validator.checkMaxLength('staffName', this.editOwner.staff_name, 255, '担当者名');

      validator.checkRequire('staffNameKana', this.editOwner.staff_name_kana, '担当者名（カナ）') &&
      validator.checkKatakana('staffNameKana', this.editOwner.staff_name_kana, '担当者名（カナ）') &&
      validator.checkMaxLength('staffNameKana', this.editOwner.staff_name_kana, 255, '担当者名（カナ）');

      validator.checkRequire('postcode', this.editOwner.postcode, '郵便番号') &&
      validator.checkMaxLength('postcode', this.editOwner.postcode, 7, '郵便番号') &&
      validator.checkPostCode('postcode', this.editOwner.postcode, '郵便番号');

      validator.checkRequire('prefectureId', this.editOwner.prefecture_id, '都道府県');

      validator.checkRequire('city', this.editOwner.city, '市区町村') &&
      validator.checkMaxLength('city', this.editOwner.city, 255, '市区町村');

      validator.checkRequire('address', this.editOwner.address, '番地') &&
      validator.checkMaxLength('address', this.editOwner.address, 255, '番地');

      validator.checkMaxLength('buildingName', this.editOwner.building_name, 255, 'ビル・マンション');

      validator.checkRequire('phone', this.editOwner.phone, '電話番号');
      if (this.editOwner.phone) {
        const phone = this.editOwner.phone;
        validator.checkTel('phone', phone, '電話番号') &&
        validator.checkMaxLength('phone', phone, 11, '電話番号') &&
        validator.checkMinLength('phone', phone, 10, '電話番号');
        if (!validator.errors.phone) {
          validator.checkPhone('phone', this.editOwner.phone, '電話番号');
        }
      }
      validator.checkRequire('agency.code', this.editOwner.agency.code, '紹介者コード') &&
      validator.checkMaxLength('agency.code', this.editOwner.agency.code, 255, '紹介者コード');
      if(this.editOwner.agency.agency_id == 0 || this.editOwner.agency.agency_id == null){
        this.editOwner.agency.agency_id = '';
        validator.checkRequire('agency.agency_id', this.editOwner.agency.agency_id,  '代理店名');
      }

      validator.checkRequire('email', this.editOwner.email, '連絡先メールアドレス') &&
      validator.checkEmail('email', this.editOwner.email, '連絡先メールアドレス');

      this.errors = validator.errors;
    },
    redirectBack() {
      return this.$router.push({path: `/admin/owners/${this.ownerId}/detail`});
    },
    closeModal() {
      this.$emit('hideModal');
    }
  }
}
;
</script>

<style lang="scss" scoped>
.select-wrapper select {
  width: 100%;
}
.body_admin .container_admin .main_area_admin .main_content .modal .form-row .select_wrapper select {
  padding-right: 40px;
}

.tab {
  min-width: 150px;
  padding: 8px 16px;
  text-align: center;
}

.adjust_title_space {
  padding: 24px 24px 0 24px;
}

.background-color {
  background-color: #241811;
}

.input_edit {
  margin-top: 10px;
  max-width: 50%;
}

.display_select_text {
  line-height: 1.2;
}

.modal-menu {
  font-size: 14px;
}

.modal-menu {
  border-bottom: 1px solid #E7EDF7;
}

.modal-menu .active {
  border-bottom: 3px solid #6D8EFD;
}

.modal-footer {
  border-top: 1px solid #E7EDF7;
}
</style>
