<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        発注履歴詳細 検品
      </h1>
      <div class="mb_medium chanel_text_padding">納品数を確認の上、チェックボタンをタップしてください。</div>
      <div class="form_row center chanel_text_padding mb_medium">
        <label class="form_label">納品日付</label>
        <div class="form_input">
          <DateTimePicker v-model="checkDate" format="YYYY/MM/DD"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender medium" input-class="calendar__input"
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          value-type="YYYY-MM-DD"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
        </div>
        <span v-for="error in errors['check_date']" :key="error" class="error-message">{{ error }}</span>
      </div>
      <div class="mb_medium text_right flex">
        <div class="count_item">表示中: {{order_items.length}}/{{this.totalItem}}件</div>
        <label class="mr_large ml-auto">全ての項目をチェックする</label>
        <label class="btn_checkbox padding-btn-checkbox">
          <input
            v-model="selectAll"
            class="owner_padding_checkbox"
            type="checkbox"
          >
          <span class="checkmark_checkbox" />
        </label>
      </div>
      <table class="sr_table">
        <thead>
          <tr>
            <th class="w_50 device-pc">商品名</th>
            <th class="small device-pc">単価</th>
            <th class="w_30 device-mobile">商品名  単価</th>
            <th>発注数</th>
            <th class="small">納品数</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in order_items"
              :key="index"
          >
            <td class="device-pc" :class="errors[`unit_price_${index}`] || errors[`item_received_qty_${index}`] ? 'padding-0-16' : ''">
              {{ data.name }}
            </td>
            <td class="device-pc" :class="errors[`unit_price_${index}`] || errors[`item_received_qty_${index}`] ? 'padding-0-16' : ''">
              <input
                v-model="data.unit_price"
                class="input_text"
                :class="errors[`unit_price_${index}`] ? 'margin-top-34px' : ''"
                type="number"
                @keypress="convertPrice($event, data)"
                @keyup="convertPrice($event, data)"
                @input="changePrice()"
              >
              <span v-for="error in errors[`unit_price_${index}`]" :key="error" class="error-message down_line error-message-pc">{{ error }}</span>
            </td>
            <td class="device-mobile">
              <div class="mb_small change-show-text " :class="errors[`unit_price_${index}`] ? 'margin-top-34px' : ''">{{ data.name }}</div>
              <input
                v-model="data.unit_price"
                class="input_text"
                type="number"
                @keypress="convertPrice($event, data)"
                @keyup="convertPrice($event, data)"
                @input="changePrice()"
              >
              <span v-for="error in errors[`unit_price_${index}`]" :key="error" class="error-message down_line">{{ error }}</span>
            </td>
            <td class="text-right" :class="errors[`unit_price_${index}`] || errors[`item_received_qty_${index}`] ? 'padding-0-16' : ''">
              <div class="mb_small change-show-text device-mobile"></div>
              {{ data.item_qty }}
              </td>
            <td class="" :class="errors[`unit_price_${index}`] || errors[`item_received_qty_${index}`] ? 'padding-0-16' : ''">
              <div class="mb_small change-show-text device-mobile"></div>
              <input
                :value="data.item_received_qty"
                class="input_text"
                :class="errors[`item_received_qty_${index}`] ? 'margin-top-34px' : ''"
                type="text"
                @paste.prevent
                @wheel="$event.target.blur()"
                @keypress="changeQuantity($event, data)"
                @keyup="changeQuantity($event, data)"
              >
              <span v-for="error in errors[`item_received_qty_${index}`]" :key="error" class="error-message down_line error-message-pc">{{ error }}</span>
            </td>
            <td class="text-right padding-16" :class="errors[`unit_price_${index}`] || errors[`item_received_qty_${index}`] ? 'padding-0-16' : ''">
              <div class="mb_small change-show-text device-mobile"></div>
              <label class="btn_checkbox ml-auto item_right">
                <input
                  v-model="selected"
                  @click="changeData(data.item_id)"
                  class="owner_padding_checkbox"
                  type="checkbox"
                  :value="data.item_id"
                  number
                >
                <span class="checkmark_checkbox" />
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <scroll-loader :loader-method="onPageChange" :loader-disable="disable" />
      <div class="table_summary mb_large text_total chanel_text_padding_right">
        合計金額
        <div class="ml_large">
          {{ showPrice(order.total_amount) }}円<span class="summary_small">（税抜：{{ showPrice(order.tax_amount) }}円）</span>
        </div>
      </div>
    </div>
    <div class="control_area">
      <router-link to="" class="icon_circle" @click.native="$router.go(-1)">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <button class="btn btn_point" :class="selectAll ? 'primary' : 'disabled'" @click="handleSave">更新する</button>
      </div>
    </div>
    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_medium">
        <label class="mb_small mb_small-left mb_small-md">
          <span>商品の最新単価を商品管理の単価へ反映しますか。</span>
        </label>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary" @click.native="handleConfirm">反映する</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="handleCancel">反映しない</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog ? 'open' : ''" />
  </main>
</template>

<script>
import { OrdersService } from '../../services/orders.service';
import { CheckOrdersService } from '../../services/check_orders.service';
import util from '../../common/util';
import moment from 'moment';
import Validator from '../../validator';
import Vue from 'vue';
import ScrollLoader from 'vue-scroll-loader';
Vue.use(ScrollLoader);

export default {
  data() {
    return {
      reCheckOrder: false,
      allChecked: false,
      totalItem: null,
      disable: true,
      checkDate: moment(new Date()).format('YYYY-MM-DD'),
      selected: [],
      checkAll: false,
      order: {},
      order_items: [],
      dialog: false,
      isChangePrice: false,
      errors: [],
      qty_val: '',
      orderId: this.$router.history.current.params.id,
      pager: {
        page: 1,
        pageSize: 30,
      },
    };
  },

  computed: {
    selectAll: {
      get() {
        if (this.order_items && this.order_items.length > 0 &&
          ((this.checkAll && this.selected.length === this.order_items.length) || this.selected.length === this.totalItem)) {
          this.allChecked = true;
        }
        else {
          this.allChecked = false;
        }
        return this.allChecked;
      },
      set(value) {
        const checked = [];
        if (value) {
          this.order_items.forEach((data) => {
            if (data.item_received_qty == null || data.item_received_qty == '') {
              data.item_received_qty = data.item_qty;
            }
            checked.push(data.item_id);
          });
          this.checkAll = true;
          this.allChecked = true;
        }
        else {
          this.checkAll = false;
          this.allChecked = false;
        }
        this.selected = checked;
      }
    },
  },

  async mounted() {
    await this.getOrder();
  },

  methods: {
    ...util,
    async getOrder() {
      this.$loading(true);
      try {
        const params = {
          id: this.orderId,
          page: this.pager.page,
          page_size: this.pager.pageSize,
        };
        const { response } = await OrdersService.getCheckOrders(params);
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.totalItem = response.pager.item_count;
        this.order = response.data;
        this.disable = this.pager.page === this.pager.totalPage ? true : false;
        if (this.selectAll) {
          this.order.order_items.forEach(element => {
            if (element.item_received_qty === null || element.item_received_qty === '') {
              element.item_received_qty = element.item_qty;
            }
            this.selected.push(element.item_id);
          });
        }
        else {
          if (this.pager.page === 1) {
            this.order.order_items.forEach(element => {
              if(element.item_received_qty != null) {
                this.reCheckOrder = true;
                this.checkAll = true;
                this.allChecked = true;
                this.selected.push(element.item_id)
              }
            });
          }
        }
        this.order_items = this.pager.page === 1 ? this.order.order_items : [...this.order_items, ...this.order.order_items];
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    onPageChange() {
      this.pager.page++;
      this.getOrder();
    },

    changeData(id) {
      this.order_items.forEach(data => {
        if (!this.selected.includes(data.item_id) && data.item_id == id &&
          (data.item_received_qty == null || data.item_received_qty == ''))
        {
          data.item_received_qty = data.item_qty;
        }
      })
    },

    changePrice() {
      this.isChangePrice = true;
    },

    handleSave() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      if (this.isChangePrice) {
        this.dialog = true;
      } else {
        this.createCheckOrder();
      }
    },

    handleConfirm() {
      this.dialog = false;
      this.createCheckOrder();
    },

    handleCancel() {
      this.dialog = false;
      this.isChangePrice = false;
      this.createCheckOrder();
    },

    async createCheckOrder() {
      this.$loading(true);
      try {
        const check_orders = this.order_items.map((item) => {
          return {
            order_item_id: item.id,
            item_id: item.item_id,
            order_id: this.$router.history.current.params.id,
            item_price: item.unit_price,
            item_received_qty: item.item_received_qty,
            check_date: this.checkDate,
          };
        });

        const params = {
          check_orders: check_orders,
          order_id: this.orderId,
          is_change_price: this.isChangePrice
        }
  
        await CheckOrdersService.create(params);
        this.$router.push({ path: `/order_history/${this.orderId}` });
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    convertPrice(event, order_item) {
      if (!/\d/.test(event.key)) {
        event.preventDefault();
      }
      order_item.item_price = event.target.value;
    },
    changeQuantity(event, order_item) {
      if (/^[+-]?\d+(\.\d{0,1})?$/.test(event.target.value) || /^\d{0,6}(?:\.\d{0,1})?$/.test(event.target.value)) {
        this.qty_val = event.target.value;
      } else {
        event.target.value = this.qty_val;
        return event.preventDefault();
      }
      if ((event.target.value.indexOf('.') !== -1 && event.key === '.') || (!/\d/.test(event.key) && event.key !== '.') || event.target.value.length >= 9) {
        event.preventDefault();
      }
      order_item.item_received_qty = event.target.value
    },

    validate() {
      const validator = new Validator();
      validator.checkRequire('check_date', this.checkDate, '納品日付');

      this.order_items.forEach((e, i) => {
        validator.checkRequire(`unit_price_${i}`, e.unit_price, '単価') &&
          validator.checkDigit(`unit_price_${i}`, e.unit_price, '単価') &&
          validator.checkMaxNumber(`unit_price_${i}`, e.unit_price, 9, '単価');

        validator.checkRequire(`item_received_qty_${i}`, e.item_received_qty, '納品数') &&
        validator.checkMaxNumber(`item_received_qty_${i}`, e.item_received_qty, 9, '納品数');
      });

      this.errors = validator.errors;
    },
  },
};
</script>

<style scoped>
.item_right {
  float: right;
}
.input_text {
  text-align: right;
  height: 46px;
  font-size: 14px;
}
.btn_checkbox {
  margin-bottom: 20px;
}
.checkmark_checkbox {
  top: 0px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.btn_checkbox input:checked ~ .checkmark_checkbox {
  background-color: #966544;
}
.btn_checkbox .checkmark_checkbox:after {
  left: 7px;
  top: 3px;
  width: 6px;
  border: 1px solid white;
  border-width: 0 3px 3px 0;
}
.error-message {
  display: flex;
  margin-top: 4px;
}
.padding-btn-checkbox {
  padding-right: 18px;
}
.change-show-text {
  height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.margin-top-34px {
  margin-top: 34px;
}
.error-message-pc {
  height: 30px;
}
.padding-16 {
  padding-right: 16px;
}
.device-pc {
  display: revert;
}
.device-mobile {
  display: none;
}
.count_item {
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .small {
    width: 30% !important;
  }
  .medium {
    width: 100%;
  }
  .input_text {
    height: 36px;
  }
  .padding-btn-checkbox {
    padding-right: 2px;
  }
  .device-pc {
    display: none;
  }
  .device-mobile {
    display: revert;
  }
  .padding-16 {
   padding-right: 0;
  }
}
</style>
