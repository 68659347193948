<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">メッセージを送信</h1>
      <p class="small_text mb_large chanel_text_padding">発注先にメッセージを送信できます。発注先を選択してください。</p>
      <div class="input_wrapper search mb_medium chanel_text_padding">
        <input v-model="params.keyword" type="text" placeholder="発注先 業者名で検索"
               @input="onChange"
        >
      </div>
      <div v-if="suppliers.length <= 0" class="text-center">表示するデータはありません。</div>
      <div v-else>
        <paging
          :page="pager.page"
          :total-page="pager.totalPage"
          :is_restaurant="is_restaurant"
          styles="mb_medium"
          @jumpPage="onPageChange"
        />
        <ul>
          <li v-for="supplier in suppliers" :key="supplier.id" class="item_list">
            <div class="item_list_wrapper">
              <div class="flex alignCenter">
                <div class="input_supplier">
                  <input :id="supplier.id" v-model="selectedSupplier" type="radio"
                         name="company_check"
                         class="radio"
                         :value="supplier"
                  >
                  <label :for="supplier.id" class="radio_label" />
                </div>
                <label :for="supplier.id" class="ml_large supplier_name">{{ supplier.name }}</label>
              </div>
            </div>
          </li>
        </ul>
        <paging
          :page="pager.page"
          :total-page="pager.totalPage"
          :is_restaurant="is_restaurant"
          styles="mt_medium"
          @jumpPage="onPageChange"
        />
      </div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link
          :to="{}"
          :class="{ disabled: Object.keys(selectedSupplier).length == 0 }"
          class="btn primary"
          @click.native="toMail"
        >
          決定する
        </router-link>
      </div>
    </div>
    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_xxlarge pre-formatted">
        メールアドレスが登録されていないため、メッセージを送信できません。<br>発注先管理でメールアドレスを登録してください。
      </div>
      <div class="btn_area control">
        <router-link :to="{}" class="btn primary" @click.native="closeDialog">はい</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog ? 'open' : ''" />
  </main>
</template>
<script>
import { SuppliersService } from '../../services/suppliers.service';
import _ from 'lodash';
import Paging from '../../components/Pager';

export default {
  components: {
    Paging
  },
  data() {
    return {
      suppliers: [],
      params: {
        sort: 'most_ordered',
        keyword: ''
      },
      selectedSupplier: {},
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
      dialog:false,
      is_restaurant: true,
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    async getSuppliers() {
      this.$loading(true);
      try {
        const params = {
          ...this.params,
          ...{
            page: this.pager.page,
            page_size: this.pager.pageSize,
          }
        };
        const { response } = await SuppliersService.index(params);
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.suppliers = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    onChange: _.debounce(function() {
      this.getSuppliers();
    }, 500),

    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },

    onPageChange(page) {
      this.pager.page = page;
      this.getSuppliers();
      this.scrollTopScreen();
    },

    toMail() {
      if (this.selectedSupplier.supplier_emails.length !== 0) {
        let emails = 'mailto:';
        this.selectedSupplier.supplier_emails.forEach(supplier_email => {
          emails += supplier_email.email + ',';
        });
        emails.slice(0, emails.length - 1);
        window.location.href = emails
      }
      else if (this.selectedSupplier.email) {
        window.location.href = `mailto:${this.selectedSupplier.email}`;
      } else {
        this.dialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.input_supplier {
  width: max-content;
  max-height: 16px;
}

.supplier_name {
  font-size: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-weight: unset;
}

label {
  cursor: pointer !important;
}

@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .input_wrapper {
    &.search {
      &::after {
        right: 30px;
      }
    }
  }
}
</style>
