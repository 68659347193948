import { RestaurantAdministratorsService } from '../../services/Owner/restaurant_administrators.service';
import { RestaurantsService } from '../../services/restaurants.service';

const reviseType = {
  privacy_policy: 'プライバシーポリシー',
  term_of_service: '利用規約',
};

export default {
  methods: {
    async noticeNewRevision(newRevision) {
      const confirm = await this.$swal.fire({
        title: `[重要] ${reviseType[newRevision.revise_type]}改定のお知らせ`,
        html: `<p>${reviseType[newRevision.revise_type]}が改定されました。</p>
              <p>改定日：${newRevision.revise_at}</p>
              <div class="mt_small pre-formatted">${newRevision.notice_text}</div>`,
        width: 600,
        confirmButtonText: '同意する',
        cancelButtonText: '同意しない',
        showCloseButton: false,
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner small',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
      return confirm.isConfirmed;
    },
    async agreeRevision(id, newRevision) {
      try {
        const params = {
          agreed_revision_id: newRevision.id,
        };
        if (id) {
          // ownerでログインしている場合
          await RestaurantAdministratorsService.agreeRevision(id, params);
        } else {
          // restaurantでログインしている場合
          await RestaurantsService.agreeRevision(params);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async noticeNewRevisionTerm(newRevision) {
      const confirm = await this.$swal.fire({
        title: `[重要] ${reviseType[newRevision.revise_type]}改定のお知らせ`,
        html: `<p>${reviseType[newRevision.revise_type]}が改定されました。</p>
              <p>改定日：${newRevision.revise_at}</p>
              <div class="mt_small pre-formatted">${newRevision.notice_text}</div>`,
        width: 600,
        confirmButtonText: '同意する',
        cancelButtonText: '同意しない',
        showCloseButton: false,
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner small',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
      return confirm.isConfirmed;
    }
  }
};