<template>
  <div id="confirm-screen">
    <div class="bottom_menu">
      <h2 class="bottom_menu_title">
        {{ title }}
      </h2>
      <div class="mb_large">
        <textarea
          id=""
          v-model="value"
          name=""
          :placeholder="placeholder"
        />
        <span v-for="error in errors['value']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
      <div class="btn_area two_items">
        <a href="#" class="btn primary" @click="choose(true)">登録する</a>
        <a href="#" class="btn primary_border cancel ml_medium" @click="cancel">キャンセル</a>
      </div>
    </div>
    <div class="bottom_menu_bg" @click="cancel" />
  </div>
</template>

<script>
import Validator from '../validator';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    placeholder: {
      type: String,
    },
    persistent: Boolean,
  },
  data() {
    return {
      value: '',
      errors: []
    };
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener('keyup', this.onEnterPressed);
  },
  methods: {
    choose() {
      this.validate();

      if (Object.keys(this.errors).length) return;

      this.$destroy();
    },
    cancel() {
      this.value = '';
      this.$destroy();
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('value', this.value, this.title);
      if (this.maxLength) {
        validator.checkMaxLength('value', this.value, this.maxLength, this.title);
      }
      this.errors = validator.errors;
    }
  },
};
</script>
<style scoped>
#confirm-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
}
.bottom_menu_bg {
  pointer-events: all;
}
</style>
