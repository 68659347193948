<template>
  <div id="confirm-screen">
    <div class="bottom_menu">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_xxlarge pre-formatted">
        発注リストの内容は更新されていません。画面を移動しますか？
      </div>
      <div class="btn_area two_items">
        <a href="#" class="btn primary btn_two_line" @click="choose(true)">保存せずに移動する </a>
        <a href="#" class="btn primary_border cancel ml_medium" @click="cancel">キャンセル</a>
      </div>
    </div>
    <div class="bottom_menu_bg" @click="cancel" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener('keyup', this.onEnterPressed);
  },
  methods: {
    choose(value) {
      this.value = value;
      this.$destroy();
    },
    cancel() {
      this.$destroy();
    },
  },
};
</script>
<style scoped>
#confirm-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
}
.bottom_menu_bg {
  pointer-events: all;
}
@media screen and (max-width: 480px){
  .two_items {
    width: 100%;
  }
}
</style>
