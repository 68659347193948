<template>
  <div class="detail-modal">
    <!--Header-->
    <div class="modal-header adjust_title_space">
      <div class="flex mb_small">
        <label class="small_title">登録⽇：{{ formatDate(owner.created_at) }}</label>
        <label class="small_title cancel ml_large">解約日：{{ formatDate(owner.canceled_at) }}</label>
        <div class="icon close" />
      </div>
      <div>
        <div class="owner_label">オーナーアカウント</div>
        <div class="flex alignEnd">
          <div class="large_title">{{ owner.name }}</div>
          <div class="small_text ml_medium" :class="owner.status == 'inactive' ? 'alert' : ''">{{ getOwnerStatus(owner.status) }}</div>
        </div>
        <label class="small_text">{{ owner.name_kana }}</label>
      </div>
    </div>
    <!--body-->
    <div class="modal-body">
      <div class="adjust_modal_space border_bottom">
        <div class="form-row margin-0">
          <label class="form_label form-20">法人名</label>
          <div class="form_label flex-column form-50">
            {{ owner.name }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">法⼈代表者名:</label>
          <div class="form_label flex-column form-50">
            {{ owner.director_name }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">法⼈代表者名（カナ）:</label>
          <div class="form_label flex-column form-50">
            {{ owner.director_name_kana }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">担当者名: </label>
          <div class="form_label flex-column form-50">
            {{ owner.staff_name }}
          </div>
        </div>
      </div>
      <div class="adjust_modal_space">
        <div class="form-row margin-0">
          <label class="form_label form-20">担当者名（カナ）:</label>
          <div class="form_label flex-column form-50">
            {{ owner.staff_name_kana }}
          </div>
        </div>
        <div class="form-row ">
          <label class="form_label form-20">住所: </label>
          <div class="form_label flex-column form-50">
            {{ owner.postcode }} {{ getPrefectureName(getPrefectures, owner.prefecture_id) }}{{ owner.city }}{{ owner.address }}{{ owner.building_name }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">電話番号: </label>
          <div class="form_label flex-column form-50">
            {{ owner.phone }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">メールアドレス: </label>
          <div class="form_label flex-column form-50">
            {{ owner.email }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">代理店名</label>
          <div class="form_label flex-column form-50">{{ owner.agency ? owner.agency.agency_name : null }}</div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">紹介コード</label>
          <div class="form_label flex-column form-50">{{ owner.agency ? owner.agency.code : null }}</div>
        </div>
      </div>
    </div>
    <!--Footer-->
    <div class="modal-footer">
      <button class="btn_admin admin_primary"
              @click="$emit('updateModalType', 'edit')"
      >編集</button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import utils from '../../../../common/util';

export default {
  props: {
    owner: {
      type: Object,
      require: true,
    }
  },
  computed: {
    ...mapGetters({
      getPrefectures: 'getAllPrefectures'
    }),
  },
  methods: {
    ...utils,
  }
}
;
</script>

<style scoped>
.border_bottom {
  border-bottom: 1px solid #E7EDF7;
}

.adjust_modal_space {
  padding: 24px 0px;
}

.adjust_title_space {
  padding: 24px 24px 0 24px;
}

.small_title {
  opacity: 0.5;
  font-size: 10px;
  font-weight: normal;
}
.small_title.cancel {
  color: #D52C2C;
}

.large_title {
  font-size: 24px;
  display: inline-block;
  max-width: calc(100% - 120px);
}

.row {
  align-items: center;
}
</style>