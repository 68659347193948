<template>
  <div class="main_area_pc wrapper_password-reset owner_main_area">
    <div class="wrapper_owner_have_button">
      <div class="password-reset">
        <div class="title">パスワードをお忘れの方</div>
        <div class="content note_sp">メールでお送りした認証コードをご入力ください。</div>
        <div class="form_row vertical mb_medium form_reset">
          <label for="" class="owner_form_label">
            認証コード
          </label>
          <div class="input_wrapper">
            <input v-model="token" type="text" placeholder="">
            <span v-for="error in errors['token']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn_area-owner btn_area_wrapper">
        <div class="button_wrapper">
          <router-link :to="{}" class="btn_area-owner primary goback" @click.native="sendAuth">
            認証する
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../../validator';
import { PasswordResetService } from '../../../services/Owner/password_reset.service';
import { PopupDisplayError } from '../../../common/util';

export default {
  data () {
    return {
      token: '',
      errors: {}
    };
  },
  methods: {
    async sendAuth() {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = {
        token: this.token,
      };
      try {
        await PasswordResetService.password_auth(params);
        return this.$router.push({ path: `/password_reset_input/${this.token}` });
      } catch (error) {
        this.validate();
        this.errors['token'] = ['認証コードが正しく無いか、有効期限が切れました。'];
        console.log(error);
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('token', this.token, '認証コード') &&
      validator.checkMaxLength('token', this.token, 255, '認証コード');
      this.errors = validator.errors;
    },
  },
};
</script>

