<template>
  <form action="">
    <div class="form_row vertical mb_medium">
      <label class="form_label">商品画像</label>
      <div class="input_wrapper vertical">
        <div v-show="image" class="file_upload_image" @click="$refs.file.click()">
          <img :src="image">
        </div>
        <div v-show="!image" class="file_upload_image no_image" @click="$refs.file.click()">
          <div class="file_upload_image_main">
            <div class="icon add_image" />
            <div class="file_upload_image_text">画像を追加</div>
          </div>
        </div>
        <div class="file_upload_image_sub">ご自身で撮影した画像をご利用ください。</div>
        <div class="file_upload_image_sub">アップロード可能なサイズは３MBまでです。</div>
        <div>
          <input id="file_uploader" ref="file" type="file"
                 class="file_uploader"
                 accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff,image/bmp"
                 hidden @change="previewFiles"
          >
        </div>
        <span v-for="error in errors['image']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form_row vertical mb_medium">
      <label for="foodname" class="form_label">
        商品名<span class="require">必須</span>
      </label>
      <div class="input_wrapper">
        <input id="foodname" v-model="name" type="text"
               placeholder="例：たまご（1箱100個入り）"
        >
        <span v-for="error in errors['name']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form_row vertical mb_medium">
      <label for="unit" class="form_label">
        単位<span class="require">必須</span>
      </label>
      <div class="input_wrapper medium">
        <input id="unit" v-model="unit" type="text"
               placeholder="例：本、箱、個、パック、kg"
        >
        <span v-for="error in errors['unit']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form_row vertical mb_medium">
      <label for="price" class="form_label">
        価格<span class="require">必須</span>
      </label>
      <div class="input_wrapper medium flex alignCenter">
        <input id="price" v-model="price" type="tel"
               placeholder="価格" class="unit_input"
        >
        <div class="ml_small">円</div>
      </div>
      <span v-for="error in errors['price']" :key="error" class="error-message">
        {{ error }}
      </span>
    </div>
    <div class="small_text note_unit_item">
      ※価格は税込の金額で入力してください
    </div>
    <div class="form_row vertical mb_medium">
      <label for="product_no" class="form_label">
        品番<span v-if="mediaSheetClass == 'MediaSheet:Ryque'" class="require">必須</span></label>
      <div class="input_wrapper">
        <input id="product_no" v-model="productNo" type="text"
               placeholder="品番"
        >
        <span v-for="error in errors['product_no']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form_row vertical mb_medium">
      <label for="category" class="form_label">
        カテゴリー<span class="require">必須</span>
      </label>
      <div class="select_wrapper arrow_down">
        <select v-model="itemCategory">
          <option v-for="option in itemCategories" :key="option.value" :value="option.id">{{ option.name }}{{ `（${option.tax_percent}%）` }}</option>
        </select>
      </div>
      <span v-for="error in errors['itemCategory']" :key="error" class="error-message">
        {{ error }}
      </span>
    </div>
    <div class="form_row vertical mb_medium">
      <label for="mail" class="form_label">
        備考欄
      </label>
      <div class="form_input">
        <div class="input_wrapper">
          <textarea id="" v-model="note"
                    cols="30" rows="5" placeholder="何か記載する内容があればご記載ください。"
          />
          <span v-for="error in errors['note']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { SuppliersService } from '../../../services/suppliers.service';
import { ItemsService } from '../../../services/items.service';
import Validator from '../../../validator';

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    itemCategories: {
      type: Array,
      default: () => []
    },
    activeCreate: {
      type: Boolean,
      required: false
    },
    openDialog: {
      type: Function
    },
    disableShowDialog: {
      type: Function
    },
    displayDialog: {
      type: Function
    },
    scrollTopScreen: {
      type: Function
    }
  },
  data() {
    return {
      name: '',
      unit: '',
      price: '',
      productNo: '',
      itemCategory: '',
      imageName: '',
      image: '',
      errors: [],
      imageError: false,
      note: null,
      taxType: {
        standard: '10%',
        reduced: '8%'
      },
      dialog:false,
      mediaSheetClass: '',
    };
  },
  watch: {
    item: function (newVal) {
      this.name = newVal.name,
      this.unit = newVal.unit,
      this.price = newVal.price,
      this.productNo = newVal.product_no,
      this.itemCategory = newVal.item_category_id,
      this.image = newVal.img;
      this.note = newVal.note;
    },
    price() {
      this.price = this.price.replace(/[^0-9]+/g, '');
    },
  },
  async mounted() {
    await this.getSupplier();
  },
  methods: {
    async getSupplier() {
      this.$loading(true);
      try {
        const { response } = await SuppliersService.get(this.$router.history.current.params.id);
        this.mediaSheetClass = response.data.media_sheet_class;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    async handleSave() {
      if (this.$router.history.current.params.item_id) {
        this.validate();

        if (Object.keys(this.errors).length) {
          this.scrollTopScreen();
          this.displayDialog();
          return;
        }
      }

      const params = {
        name: this.name,
        unit: this.unit,
        default_price: this.price,
        product_no: this.productNo,
        item_category_id: this.itemCategory,
        note: this.note
      };
      const supplier_item = {
        supplier_id: this.$router.history.current.params.id,
        price: this.price
      };

      if (this.image && this.imageName) {
        params['image'] = {
          name: this.imageName,
          content: this.image
        };
      }
      this.$loading(true);
      try {
        if (this.$router.history.current.params.item_id) {
          params.supplier_item = supplier_item;
          await ItemsService.update(this.$router.history.current.params.item_id, params);
          await this.$store.dispatch('changeDetailOrder', { id: this.$router.history.current.params.item_id, name: this.name, price: this.price });
        } else {
          params.supplier_item = supplier_item;
          await ItemsService.create(params);
        }
        await this.$store.dispatch('changeTab', 1);
        this.$router.push({ path: `/order_company/${this.$router.history.current.params.id}/detail` });
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    handleDelete: async function() {
      const value = await this.$confirm('商品マスタ削除確認', '商品マスタを削除します。\n本当によろしいですか？');
      if (!value) return;
      this.$loading(true);
      try {
        await ItemsService.delete(this.$router.history.current.params.item_id);
        await this.$store.dispatch('removeOrderWithItem', { item_id: this.$router.history.current.params.item_id });
        await this.$store.dispatch('changeTab', 1);
        this.$router.push(`/order_company/${this.$router.history.current.params.id}/detail`);
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    validate() {
      const validator = new Validator();
      validator.checkRequire('name', this.name, '商品名') &&
        validator.checkMaxLength('name', this.name, 255, '商品名');
      validator.checkRequire('unit', this.unit, '単位') &&
        validator.checkMaxLength('unit', this.unit, 4, '単位');
      validator.checkRequire('price', this.price, '価格') &&
        validator.checkDigit('price', this.price, '価格') &&
        validator.checkMaxNumber('price', this.price, 9, '価格');
      validator.checkMaxLength('product_no', this.productNo, 255, '品番');
      validator.checkMaxLength('note', this.note, 255, '備考欄');
      validator.checkRequire('itemCategory', this.itemCategory, 'カテゴリー');

      if (this.mediaSheetClass == 'MediaSheet:Ryque') {
        validator.checkRequire('product_no', this.productNo, '品番') &&
          validator.checkDigit('product_no', this.productNo, '品番') &&
          validator.checkLength('product_no', this.productNo, 5, '品番');
      }

      if (this.imageError) {
        validator.errors['image'] = [];
        validator.errors['image'].push('添付ファイルのサイズが許容制限エラーを超えています。');
      }

      this.errors = validator.errors;
    },
    previewFiles(event) {
      if (event.target.files.length < 1) {
        return;
      }
      this.imageError = false;
      const file = event.target.files[0];
      if (file.size > 3 * 1000 * 1000) { // 3MB is max size
        this.imageError = true;
      }
      this.createBase64Image(file);
    },
    createBase64Image(file) {
      this.imageName = file.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
};
</script>

<style scoped>
.note_unit_item {
  margin-bottom: 20px;
}

.condition_label {
  font-weight: unset !important;
}

.unit_input {
  width: 50% !important;
}

.file_upload_image img {
  object-fit: scale-down !important;
}
@media screen and (max-width: 480px){
  #product_no{
    padding: 8px 40px 8px 8px;
    font-size: 16px;
  }
}
</style>
