<template>
  <div class="wrapper_owner_have_button">
    <h1 class="color_owner_text owner_text_title mb_medium">パスワード設定</h1>
    <span class="note_sp">オーナーアカウントのパスワードを設定します。</span>
    <form action="">
      <div class="input_owner form_row vertical">
        <label for="password" class="owner_form_label color_owner_text">パスワード<span class="require">必須</span></label>
        <input v-model="passwordSetting.password" type="password" placeholder="">
        <p class="owner_text_note form_text_note_margin">半角英数字8文字以上で入力してください</p>
        <span v-for="error in errors['password']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="input_owner form_row vertical">
        <label for="password_confirmation" class="owner_form_label color_owner_text">パスワード確認用<span class="require">必須</span></label>
        <input v-model="passwordSetting.password_confirmation" placeholder="" type="password">
        <span v-for="error in errors['password_confirmation']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
    </form>

    <div class="btn_area_owner control two_items owner_btn_padding btn_button">
      <router-link :to="{}" class="btn_owner primary" @click.native="passwordSettingSubmit()">パスワードを設定する</router-link>
    </div>
  </div>
</template>

<script>
import Validator from '../../../../validator';
import { PasswordSettingService } from '../../../../services/Owner/password_setting.service';
import { PopupDisplayError } from '../../../../common/util';

export default {
  data(){
    return{
      errors: {},
      password: '',
      password_confirmation: '',
      token: null,
      passwordSetting: {}
    };
  },
  mounted() {
    this.token = this.$router.history.current.params.token;
    this.checkTokenValid();
  },
  methods: {
    async checkTokenValid() {
      const params = { token: this.token };
      try {
        const { response } = await PasswordSettingService.password_setup(params);
        if (response.result && response.result.message == 'not_found') {
          this.$router.push('/not_found_data');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async passwordSettingSubmit(){
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = {
        token: this.token,
        password: this.passwordSetting.password,
        password_confirmation: this.passwordSetting.password_confirmation
      };
      try {
        const { response } = await PasswordSettingService.password_setup_submit(params);
        if (response.result && response.result.message == 'not_found') {
          this.$router.push('/not_found_data');
          return;
        }

        window.location.href = '/register_success';

      } catch (error) {
        console.log(error);
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire('password', this.passwordSetting.password, 'パスワード') &&
        validator.checkAsciiWithoutSpace('password', this.passwordSetting.password, 'パスワード') &&
          validator.checkMinLength('password', this.passwordSetting.password, 8, 'パスワード') &&
            validator.checkMaxLength('password', this.passwordSetting.password, 50, 'パスワード');

      validator.checkRequire('password_confirmation', this.passwordSetting.password_confirmation, 'パスワード確認用') &&
        validator.checkAsciiWithoutSpace('password_confirmation', this.passwordSetting.password_confirmation, 'パスワード確認用') &&
          validator.checkSameValue('password_confirmation', this.passwordSetting.password, this.passwordSetting.password_confirmation, 'パスワード確認用') &&
            validator.checkMinLength('password_confirmation', this.passwordSetting.password_confirmation, 8, 'パスワード確認用') &&
              validator.checkMaxLength('password_confirmation', this.passwordSetting.password_confirmation, 50, 'パスワード確認用');

      this.errors = validator.errors;
    }
  }
};
</script>