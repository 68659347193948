<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">{{ restaurant_administrator.shop_name }}</h1>
      <a class="btn_admin admin_primary_border mt-28 mb_medium" href="#" @click="csv_export">CSVエクスポート</a>
      <a class="btn_admin admin_primary_border mt-28 mb_medium ml_medium" href="#"
         @click="$openModal('supplierImport')"
      >CSVアップロード</a>
      <a class="btn_admin admin_primary mt-28 mb_medium ml_medium" href="#"
         @click="updateSelectedSupplier(null, true); $openModal('supplierForm')"
      >発注先新規登録</a>
    </div>
    <Table :suppliers="suppliers" :pagination-params="paginationParams" :selected-id="selectedId"
           @refreshTable="getSuppliers" @updateCurrentPage="updateCurrentPage"
           @updateSelectedSupplier="updateSelectedSupplier"
    />
    <div class="btn_area_admin bottom_control btn_back">
      <router-link to="/admin" class="btn_admin admin_primary_border">
        戻る
      </router-link>
    </div>
    <FormModal :supplier-id="selectedId" :is-editing="isEditing" :restaurant-administrator="restaurant_administrator"
               @refreshTable="getSuppliers" @updateSelectedSupplier="updateSelectedSupplier"
    />
    <ImportModal :restaurant-administrator="restaurant_administrator" @refreshTable="getSuppliers" />
  </div>
</template>

<script>
import {SuppliersService} from '../../../services/admin/suppliers.service';
import FormModal from './components/FormModal';
import Table from './components/Table';
import ImportModal from './components/ImportModal';
import utils from '../../../common/util.js';

export default {
  components: {
    FormModal,
    Table,
    ImportModal,
  },
  data() {
    return {
      suppliers: [],
      restaurant_administrator: {},
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      searchParams: {
        restaurant_administrator_id: this.$router.history.current.params.id,
      },
      selectedId: null,
      isEditing: false,
    };
  },
  async mounted() {
    this.getSuppliers();
  },
  methods: {
    async getSuppliers() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams, ...this.searchParams};

        const {response} = await SuppliersService.index(params);
        this.suppliers = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.restaurant_administrator = response.restaurant_administrator;
        this.$loading(false);
        if (this.restaurant_administrator.status == 'inactive') {
          this.$router.push({path: '/admin/not_found'});
        }
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedSupplier(id, isEditing = false) {
      this.selectedId = id;
      this.isEditing = isEditing;
    },

    async csv_export() {
      let params = {restaurant_administrator_id: this.$router.history.current.params.id};
      try {
        const {response} = await SuppliersService.csvExport(params);
        utils.csvExport(`${this.restaurant_administrator.shop_name}_発注先⼀覧`, response);
        this.error = false;
      } catch (error) {
        this.error = true;
        console.log(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
