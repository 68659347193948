<template>
  <main class="container bottomUp">
    <div id="body_user" class="main_area">
      <h1 class="title mb_large">商品登録</h1>

      <item ref="item" :item="item" :item-categories="itemCategories"
            :active-create="activeCreate" :open-dialog="openDialog"
      />
    </div>
    <div class="control_area">
      <router-link :to="{}" class="icon_circle" @click.native="backCreate">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <button class="btn primary" @click="openDialog">登録する</button>
      </div>
    </div>
    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_medium">
        <p for="from_date" class="mb_small mb_small-left mb_small-md">商品を登録します。よろしいですか？</p>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary btn_point" @click.native="createItem">登録する</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium btn_point" @click.native="closeDialog">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog ? 'open' : ''" />
    <PopupErrorUser :show-dialog="showDialog" :close-dialog="disableShowDialog" />
  </main>
</template>

<script>
import { ItemCategoriesService } from '../../../services/item_categories.service';
import Item from '../components/Item.vue';
import PopupErrorUser from './../../../components/PopupErrorUser.vue';

export default {
  components: {
    Item,
    PopupErrorUser
  },
  props: {
  },
  data() {
    return {
      item: {},
      itemCategories: [],
      dialog: false,
      activeCreate: false,
      id: this.$router.history.current.params.id,
      showDialog: false
    };
  },
  async mounted() {
    await this.getItemCategories();
  },
  methods: {
    async getItemCategories() {
      try {
        const { response } = await ItemCategoriesService.index();
        this.itemCategories = response.data;
      } catch (error) {
        this.$loading(false);
      }
    },
    async openDialog() {
      await this.$refs.item.validate();
      if (Object.keys(this.$refs.item.errors).length > 0) {
        this.scrollTopScreen();
        this.displayDialog();
        return;
      } else {
        this.dialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    async createItem() {
      this.dialog = false;
      this.$loading(true);
      try {
        await this.$refs.item.handleSave();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    async backCreate() {
      await this.$store.dispatch('changeTab', 1);
      this.$router.go(-1);
    },
    disableShowDialog() {
      this.showDialog = false;
    },
    displayDialog() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
      this.showDialog = true;
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
  }
};
</script>
