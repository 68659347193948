<template>
  <div class="owner_main_area">
    <div class="wrapper_owner_have_two_button">
      <h1 class="title_owner">新規店舗登録</h1>
      <div class="input_owner">
        <label class="owner_form_label">店舗名</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.shop_name }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名（カナ）</label>
        <div class="owner_margin_inline_10 owner_line_down">
          <p class="break-space">{{ createForm.shop_name_kana.trim() }}</p>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">氏名</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.name }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">氏名（カナ）</label>
        <div class="owner_margin_inline_10 owner_line_down">
          <p class="break-space">{{ createForm.name_kana.trim() }}</p>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">郵便番号</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.postcode }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">都道府県</label>
        <div class="owner_margin_inline_10 owner_line_down">{{ getPrefectureName(prefectures, createForm.prefecture_id) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">市区町村</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.city }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">丁目・番地・号</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.address }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">ビル・マンション</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.building_name }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗代表者メールアドレス</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.email }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">電話番号</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.phone }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗ID</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.login_id }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label"> 店舗パスワード</label>
        <div class="owner_margin_inline_10 owner_line_down pasword_text">
          {{ password }}
        </div>
      </div>
      <div class="btn-wrapper_obsolute">
        <div class="owner_text_note_submit">
          <p v-if="restaurantAdministrators.length > 0" class="owner_text_note">※店舗を追加するとご利用料金が追加になります。</p>
        </div>
        <div class="btn_area_owner two_items owner_text_note_submit">
          <router-link
            :to="{}"
            class="btn btn_owner primary"
            :class="submitFormFlg ? 'disabled' : ''"
            @click.native="handleSave"
          >追加登録する</router-link>
          <router-link
            :to="{}"
            class="btn btn_owner primary_border ml_medium"
            @click.native="back"
            :class="submitFormFlg ? 'disabled' : ''"
          >キャンセル</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RestaurantAdministratorsService } from '../../../../services/Owner/restaurant_administrators.service';
import { mapGetters } from 'vuex';
import utils from '../../../../common/util';
export default {
  props: {
    restaurantAdministrators: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      password: '********',
      submitFormFlg: false,
    };
  },

  computed: {
    ...mapGetters({
      createForm: 'getCreateForm',
      prefectures: 'getAllPrefectures',
    })
  },

  methods: {
    ...utils,
    async handleSave() {
      const params = Object.assign({}, this.createForm);
      params['login_id'] = this.createForm.login_id;
      try {
        this.submitFormFlg = true;
        const { response } = await RestaurantAdministratorsService.register(
          params
        );
        this.$emit('change_screen', 'complete');
        this.$emit('created', response.restaurant);
      } catch (errors) {
        console.log(errors);
      }
    },
    back() {
      this.$emit('change_screen', 'create');
    },
  },
};
</script>

<style scoped>
.pasword_text {
  letter-spacing: 5px;
  font-size: 20px;
}
a {
  cursor: pointer;
}
.two_items {
  width: 90%;
}
.wrapper_owner_have_two_button {
  padding-bottom: 120px;
}
@media screen and (max-width: 480px) {
  .wrapper_owner_have_two_button {
    padding-bottom: 160px;
  }
}
</style>