<template>
  <main class="container bottomUp">
    <div class="main_area smp_content_main">
      <h1 class="title mb_large">
        メモ詳細
      </h1>
      <div>
        <div class="memo_date">
          {{ memo.created_at ? formatDateMemoDate(memo.created_at) : '' }}
        </div>
        <div class="memo_text pre-formatted">
          {{ memo.message }}
        </div>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/memo" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <a href="#" class="btn delete" @click="handleDelete">削除する</a>
      </div>
    </div>
  </main>
</template>

<script>
import { MemosService } from '../../services/memos.service';
import utils from '../../common/util';
import moment from 'moment';

export default {
  data() {
    return {
      memo: {}
    };
  },
  async mounted() {
    this.$loading(true);
    try {
      const { response } = await MemosService.get(this.$router.history.current.params.id);
      this.memo = response.data;
      this.$loading(false);
    } catch (error) {
      this.$loading(false);
    }
  },
  methods: {
    ...utils,
    async handleDelete() {
      const value = await this.$confirm('削除確認', '削除を実施します。\n本当によろしいですか？');
      if (!value) return;
      this.$loading(true);
      try {
        await MemosService.delete(this.memo.id);
        this.$router.push('/memo');
      } catch (error) {
        this.$loading(false);
      }
    },
    formatDateMemoDate (date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    }
  }
};
</script>
<style scoped>
  .pre-formatted {
    word-break: break-all;
  }
</style>