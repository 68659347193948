<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <div class="tab_area">
        <a
          v-for="tab in tabs"
          :key="tab"
          class="tab order_manage"
          :class="{ active: tab === currentTab }"
          @click="currentTab = tab"
        >
          {{ tab }}
        </a>
      </div>
      <supplier ref="supplier" :is-active="currentTab === tabs[0]" :supplier="supplier"
                :close-dialog="closeDialog" :open-dialog="openDialog"
                :disable-show-dialog="disableShowDialog" :display-dialog="displayDialog" :scroll-top-screen="scrollTopScreen"
      />
      <items ref="items" :is-active="currentTab === tabs[1]" :supplier="supplier"
             :items="items"
      />
    </div>
    <div v-if="currentTab === tabs[0]" class="control_area">
      <router-link :to="{}" class="icon_circle" @click.native="redirectBack">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control two_items">
        <router-link :to="{}" class="btn primary" @click.native="checkValid">更新する</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="$router.go(-1)">キャンセル</router-link>
      </div>
    </div>
    <div v-if="currentTab === tabs[1]" class="control_area">
      <router-link to="/order_company" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link :to="`/order_company/${id}/create_item`" class="btn primary_border">新規商品登録</router-link>
      </div>
    </div>
    <div class="bottom_menu" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_large">発注先の情報を更新します。よろしいですか？</div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary" @click.native="$refs.supplier.handleSave()">更新する</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeDialog">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog ? 'open' : ''" />
    <PopupErrorUser :show-dialog="showDialog" :close-dialog="disableShowDialog" />
  </main>
</template>

<script>
import { SuppliersService } from '../../services/suppliers.service';
import { SupplierItemsService } from '../../services/supplier_items.service';
import Items from './Item/Index.vue';
import Supplier from './components/Supplier.vue';
import PopupErrorUser from './../../components/PopupErrorUser.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Supplier,
    Items,
    PopupErrorUser
  },
  data() {
    return {
      currentTab: this.$router.history.current.params.isItemTab ? '商品管理' : '発注先管理',
      tabs: ['発注先管理', '商品管理'],
      id: this.$router.history.current.params.id,
      supplier: {},
      items: [],
      dialog: false,
      showDialog: false
    };
  },
  computed: {
    ...mapGetters({
      tabActive: 'getTab'
    })
  },
  watch: {
    async currentTab(val) {
      if( val == '発注先管理' ) {
        await this.$store.dispatch('changeTabDefault');
      }
    },
  },
  created() {
    this.getSupplier();
    this.getSupplierItems();
    if(this.tabActive !== null ) {
      this.currentTab = '商品管理';
    }
  },
  async destroyed() {
    await this.$store.dispatch('changeTabDefault');
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    disableShowDialog() {
      this.showDialog = false;
    },
    displayDialog() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
      this.showDialog = true;
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    getSupplier: async function() {
      this.$loading(true);
      try {
        const { response } = await SuppliersService.get(this.id);
        this.supplier = response.data;
        if (this.supplier.order_template == null) {
          this.supplier.order_template = 'SmartRequest'; 
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    getSupplierItems: async function() {
      this.$loading(true);
      try {
        const params = {
          supplier_id: this.id
        };
        const { response } = await SupplierItemsService.index(params);
        this.items = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    checkValid: async function() {
      this.$refs.supplier.checkValid();
    },
    addItems () {
      this.$refs.items.addItems();
    },
    redirectBack() {
      return this.$router.push({ path: `/order_company/${ this.supplier.id }/detail` });
    }
  },
};
</script>
<style scoped>
a {
  cursor: pointer !important;
}

@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
  }
}
</style>
