import { BaseService } from './base.service';
import { ResponseWrapper, ApiError } from './http';

export class TaxsService extends BaseService {
  static get resourceEndpoint() {
    return 'taxs';
  }

  static async find_tax_reduced_and_standard() {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/find_tax_reduced_and_standard`
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }
}
