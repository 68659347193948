<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">発注先選択</h1>
      <div class="list_search">
        <div class="input_wrapper search mb_medium chanel_text_padding">
          <input v-model="params.keyword" type="text" placeholder="発注先 業者名で検索"
                 @input="onChange"
          >
        </div>
        <div class="flex justEnd mb_large chanel-padding chanel_text_padding search_option">
          <div class="select_wrapper arrow_down flex_fourty">
            <select id="filter" v-model="params.sort" name="filter"
                    @change="onChangeOption"
            >
              <option value="most_ordered">注文回数順</option>
              <option value="recently_ordered">最近注文した順</option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="suppliers.length <= 0" class="text-center">表示するデータはありません。</div>
      <div v-else>
        <ul>
          <li v-for="supplier in suppliers" :key="supplier.id" class="item_list item_list-company"
              @click="handleSupplierItem(supplier.id)"
          >
            <p class="supplier_name">{{ supplier.name }}</p>
          </li>
        </ul>
      </div>
      <scroll-loader :loader-method="getImageList" :loader-disable="disable" />
    </div>
    <!-- popup search -->
    <div class="bottom_menu search_mb" :class="openSearch ? 'open' : ''">
      <h2 class="bottom_menu_title">
        絞り込み検索
      </h2>
      <div class="mb_medium">
        <label for="company" class="mb_small">発注先 業者名検索</label>
        <div class="input_wrapper">
          <input v-model="params.keyword" type="text" placeholder="発注先 業者名で検索">
        </div>
      </div>

      <div class="mb_xxlarge">
        <label for="company" class="mb_small">並べ替え</label>
        <div class="select_wrapper arrow_down flex_fourty">
          <select id="filter" v-model="params.sort" name="filter">
            <option value="most_ordered">注文回数順</option>
            <option value="recently_ordered">最近注文した順</option>
          </select>
        </div>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary" @click.native="search">検索する</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeSearchPopup">キャンセル</router-link>
      </div>
    </div>
    <!-- --- -->
    <div class="bottom_menu_bg search_mb" :class="openSearch ? 'open' : ''" />

    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div to="" class="icon_circle icon_search_mb icon_circle_search" @click="openFormSearch">
        <div class="icon search for_controlArea icon_search_order" />
      </div>
    </div>
  </main>
</template>
<script>
import { SuppliersService } from '../../services/suppliers.service';
import _ from 'lodash';
import Vue from 'vue';
import ScrollLoader from 'vue-scroll-loader';
Vue.use(ScrollLoader);

export default {
  data() {
    return {
      suppliers: [],
      params: {
        sort: 'most_ordered',
        keyword: ''
      },
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
      loadMore: true,
      openSearch: false,
      disable: true
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    async getSuppliers() {
      this.$loading(true);
      try {
        const params = {
          ...this.params,
          ...{
            page: this.pager.page,
            page_size: this.pager.pageSize,
          }
        };
        const { response } = await SuppliersService.index(params);
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        if(this.pager.page == 1) {
          if(this.pager.page == this.pager.totalPage) {
            this.disable = true;
          } else {
            this.disable = false;
          }
          this.suppliers = response.data;
        } else {
          this.suppliers = [...this.suppliers, ...response.data];
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    handleSupplierItem(i) {
      this.$router.push({ path: `/order/item_select/${i}` });
    },
    onChange: _.debounce(function() {
      this.pager.page = 1;
      this.getSuppliers();
    }, 500),
    onChangeOption() {
      this.pager.page = 1;
      this.getSuppliers();
    },
    openFormSearch() {
      this.openSearch = true;
    },
    closeSearchPopup() {
      this.openSearch = false;
    },
    getImageList () {
      this.pager.page = this.pager.page + 1;
      this.getSuppliers();
      if(this.pager.page == this.pager.totalPage) {
        return this.disable = true;
      }
    },
    search() {
      this.pager.page = 1;
      this.getSuppliers();
      this.openSearch = false;
    }
  }
};
</script>

<style scoped>
.supplier_name {
  font-size: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

p {
  cursor: pointer !important;
}
.search_mb {
  display: none;
}
.loader {
  padding: 0;
  opacity: 0;
}
@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
  }
  .search_mb {
    display: block;
  }
}
</style>
<style lang="scss" scoped>
.item_list-company {
  padding: 16px;
  .supplier_name {
    font-size: 16px;
  }
}
.list_search {
  display: grid;
  grid-template-columns: 70% 30%;
  .search_option {
    display: unset;
    padding-left: 0;
    select {
      padding: 17px;
    }
  }
  .select_wrapper {
    padding-left: 25px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .input_wrapper {
    &.search {
      &::after {
        right: 40px;
        top: 47%;
      }
    }
  }
  .list_search {
    display: grid;
    grid-template-columns: 70% 30%;
    .search_option {
      display: unset;
      padding-left: 0;
      select {
        padding: 17px;
      }
    }
    .select_wrapper {
      padding-left: 0;
    }
  }
}
@media screen and (max-width: 480px){
  .list_search {
    display: none;
  }
}
</style>
