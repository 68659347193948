<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">検索結果一覧</div>
      <paging
        v-if="agencies.length > 0"
        :total_count="agencies.length"
        :page="paginationParams.page"
        :styles="'admin-panigation'"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
    <table class="sr_ad_table no_pointer_td">
      <thead class="">
        <tr>
          <th class="w_20 border_radius_right">代理店名</th>
          <th class="w_30">代理店コード</th>
          <th class="w_35">URL</th>
          <th class="w_10">ステータス</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(agency, index) in agencies"
            :key="index"
        >
          <td>{{ agency.name }}</td>
          <td>{{ agency.code }}</td>
          <td>{{ baseUrlSmart }}/register_info/{{ agency.code }}</td>
          <td>{{ statusActices[agency.active_flag] }}</td>
          <td>
            <div style="position: relative; cursor: pointer;">
              <button class="icon_leader after ml-auto" @click="onTdMenuOpen($event, agency)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-show="agencies.length === 0" class="text-center">表示するデータはありません。</div>
    <div class="pagination pagination_admin">
      <paging
        v-if="agencies.length > 0"
        :page="paginationParams.page"
        :styles="'admin-panigation'"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import Paging from '../../../../components/Pager';
import utils from '../../../../common/util.js';

export default {
  components: {
    Paging,
  },
  props: {
    selectedId: {
      type: Number,
      require: true,
      default: -1
    },
    agencies: {
      type: Array,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      statusActices: {
        'active': '有効',
        'inactive': '無効',
      },
      location: window.location,
      baseUrlSmart: window.location.origin
    };
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    this.$tableTdMenu.classToIgnore = 'icon_leader';
  },
  methods: {
    ...utils,
    onTdMenuOpen(event, agency) {
      this.$tableTdMenu.menu = [
        {
          'name': '編集する',
          'func': () => {
            this.$emit('updateSelectedAgency', agency.id, true);
            this.$openModal('agencyForm');
          }
        },
      ];
      this.$openTdMenu(event);
    },
    onPageChange(page) {
      this.$emit('updateCurrentPage', page);
    }
  },
};
</script>
<style lang="scss" scoped>
</style>