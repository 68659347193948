<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">改定履歴⼀覧</div>
      <paging
        v-if="revisionHistories.length > 0"
        :total_count="revisionHistories.length"
        :page="paginationParams.page"
        :styles="'admin-panigation'"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
    <table class="sr_ad_table no_pointer_td">
      <thead class="">
        <tr>
          <th class="w_20 border_radius_right">公開⽇時</th>
          <th class="w_30">種別</th>
          <th class="w_25">改定日</th>
          <th class="w_20">バージョン</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(revisionHistory, index) in revisionHistories"
            :key="index"
        >
          <td>{{ revisionHistory.publish_at }}</td>
          <td>{{ reviseTypes[revisionHistory.revise_type] }}</td>
          <td>{{ revisionHistory.revise_at }}</td>
          <td>{{ revisionHistory.version }}</td>
          <td>
            <div style="position: relative; cursor: pointer;">
              <button class="icon_leader after ml-auto" @click="onTdMenuOpen($event, revisionHistory)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-show="revisionHistories.length === 0" class="text-center">表示するデータはありません。</div>
    <div class="pagination pagination_admin">
      <paging
        v-if="revisionHistories.length > 0"
        :page="paginationParams.page"
        :styles="'admin-panigation'"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { RevisionHistoriesService } from '../../../../services/admin/revision_histories.service';
import Paging from '../../../../components/Pager';
import utils from '../../../../common/util.js';

export default {
  components: {
    Paging,
  },
  props: {
    selectedId: {
      type: Number,
      require: true,
      default: -1
    },
    revisionHistories: {
      type: Array,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      reviseTypes: {
        'privacy_policy': 'プライバシーポリシー',
        'term_of_service': '利用規約',
      },
    };
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    this.$tableTdMenu.classToIgnore = 'icon_leader';
  },
  methods: {
    ...utils,
    onTdMenuOpen(event, revisionHistory) {
      this.$tableTdMenu.menu = [
        {
          'name': '編集する',
          'func': () => {
            this.$emit('updateSelectedRevisionHistory', revisionHistory.id, true);
            this.$openModal('revisionHistoryForm');
          }
        },
        {
          'name': '削除する',
          'class': 'alert',
          'func': () => {
            this.popupConfirmDelete(revisionHistory);
          }
        }
      ];
      this.$openTdMenu(event);
    },
    onPageChange(page) {
      this.$emit('updateCurrentPage', page);
    },
    async popupConfirmDelete(revisionHistory) {
      const confirm = await this.$swal.fire({
        title: '削除確認',
        html: `<p>改定履歴を<span class='text_color-reb'>削除</span>します。</p>
               <p>よろしいですか？</p>`,
        confirmButtonText: '決定',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonClass: 'btn_admin admin_primary',
        cancelButtonClass: 'btn_admin admin_primary_border',
      });

      if (confirm.isConfirmed) {
        try {
          await RevisionHistoriesService.delete(revisionHistory.id);
          this.$emit('updateSelectedRevisionHistory', null);
        } catch (error) {
          console.log(error);
        }
      }
      this.$emit('refreshTable');
    },
  },
};
</script>
<style lang="scss" scoped>
</style>