<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <h1 class="title_owner">店舗編集</h1>
      <div class="input_owner">
        <label class="owner_form_label">店舗名<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="shopName" type="text"
                 placeholder="店舗名を入力"
          >
          <span v-for="error in errors['shop_name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名（カナ）<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="shopNameKana" type="text"
                 placeholder="店舗名（カナ）を入力"
          >
          <span v-for="error in errors['shop_name_kana']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">氏名<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="name" type="text"
                 placeholder="氏名を入力"
          >
          <span v-for="error in errors['name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">氏名（カナ）<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="nameKana" type="text"
                 placeholder="氏名（カナ）を入力"
          >
        </div>
        <span v-for="error in errors['name_kana']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">郵便番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="postcode" type="text"
                 placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['postcode']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">都道府県<span class="require">必須</span></label>
        <div class="owner_select_wrapper arrow_down medium">
          <select v-model="prefectureId">
            <option v-for="option in prefectures" :key="option.value" :value="option.code">{{ option.name }}</option>
          </select>
        </div>
        <span v-for="error in errors['prefecture_id']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">市区町村<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="city" type="text"
                 placeholder="例：港区北青山"
          >
          <span v-for="error in errors['city']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">丁目・番地・号<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="address" type="text"
                 placeholder="例：2-14-4"
          >
          <span v-for="error in errors['address']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label for="mail" class="form_label">ビル・マンション</label>
        <div class="owner_input_wrapper">
          <input v-model="buildingName" type="text"
                 placeholder="ビル・マンション名を入力"
          >
          <span v-for="error in errors['building_name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">メールアドレス<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="email" type="text"
                 placeholder="メールアドレスを入力"
          >
          <span v-for="error in errors['email']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">電話番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="phone" type="text"
                 placeholder="電話番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['phone']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗ID<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <div class="flex alignCenter">
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.login_id }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isEditPasswword">
        <div class="input_owner">
          <label class="owner_form_label">店舗パスワード<span class="require">必須</span></label>
          <router-link :to="{}" class="ml_small owner_color_blue" @click.native="showInputPassword">変更する</router-link>
        </div>
      </div>
      <div v-else>
        <div class="input_owner">
          <label class="owner_form_label">店舗パスワード<span class="require">必須</span></label>
          <div class="owner_input_wrapper medium">
            <input id="password_old" v-model="password" type="password"
                   class="owner_input_pwd"
                   placeholder="店舗パスワードを入力"
            >
            <div class="owner_password_old" :class="showOldPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordOld" />
            <p class="note_sp form_text_note_margin">半角英数字8文字以上で入力してください</p>
            <span v-for="error in errors['password']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">店舗パスワード（確認用）<span class="require">必須</span></label>
          <div class="owner_input_wrapper medium">
            <input id="password_confirm" v-model="passwordConfirm" type="password"
                   class="owner_input_pwd"
                   placeholder="店舗パスワード（確認用）を入力"
            >
            <div class="owner_password_old" :class="showConfirmPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordConfirm" />
            <span v-for="error in errors['password_confirm']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">登録日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          {{ formatDate(restaurantAdministrator.created_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">契約日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          {{ showDate(restaurantAdministrator.contract_start_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">契約更新日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          {{ showDate(restaurantAdministrator.contract_update_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">解約申請日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          {{ showDate(restaurantAdministrator.contract_cancel_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">解約日</label>
        <div class="owner_margin_inline_10 owner_line_down" :class="changeTextColor() ? 'text_color-reb' : 'owner_color_black'">
          {{ showDate(restaurantAdministrator.contract_end_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">ステータス</label>
        <div class="owner_margin_inline_10 owner_line_down" :class="changeTextColor() ? 'text_color-reb' : 'owner_color_black'">
          {{ status[restaurantAdministrator.status] }}
        </div>
      </div>
      <div class="btn_area_owner two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn btn_owner primary"
          :class="submitFormFlg ? 'disabled' : ''"
          @click.native="handleConfirm"
        >
          更新する
        </router-link>
        <router-link
          :to="`/owner/restaurant/${restaurantAdministrator.id}/detail`"
          class="btn btn_owner primary_border ml_medium"
        >
          キャンセル
        </router-link>
      </div>
    </div>
  </main>
</template>
<script>
import Validator from '../../../validator';
import { RestaurantAdministratorsService } from '../../../services/Owner/restaurant_administrators.service';
import { mapGetters } from 'vuex';
import utils from '../../../common/util';
import { AddressInfo, PopupDisplayError } from '../../../common/util';
import { CorrectNumber } from '../../../common/util';

export default {
  data() {
    return {
      restaurantAdministrator: {},
      shopName: '',
      shopNameKana: '',
      name: '',
      nameKana: '',
      postcode: '',
      prefectureId: '',
      city: '',
      address: '',
      buildingName: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      showOldPwd: false,
      showConfirmPwd: false,
      status: {
        active: '利用中',
        admin_cancel: '停止中',
        owner_cancel: '利用中',
        inactive: '解約済',
      },
      isEditPasswword: false,
      errors: [],
      submitFormFlg: false,
    };
  },

  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    })
  },

  watch: {
    'postcode'(val) {
      if (!val || val.length !== 7) return;

      if (val === this.restaurantAdministrator.postcode) {
        this.prefectureId = this.restaurantAdministrator.prefecture_id;
        this.city = this.restaurantAdministrator.city;
        this.address = this.restaurantAdministrator.address;
      }else {
        AddressInfo(val, this.prefectures).then(addressInfo => {
          this.prefectureId = addressInfo.code;
          this.city = addressInfo.city + addressInfo.address;
        });
      }
    },
  },

  created() {
    this.getRestaurantAdministrator();
  },

  methods: {
    ...utils,
    changeTextColor() {
      return this.restaurantAdministrator.status === 'admin_cancel';
    },
    async getRestaurantAdministrator() {
      this.$loading(true);
      try {
        const { response } = await RestaurantAdministratorsService.get(this.$router.history.current.params.id);
        this.restaurantAdministrator = response.data;
        this.shopName = response.data.shop_name;
        this.shopNameKana = response.data.shop_name_kana;
        this.name = response.data.name;
        this.nameKana = response.data.name_kana;
        this.postcode = response.data.postcode;
        this.prefectureId = response.data.prefecture_id;
        this.city = response.data.city;
        this.address = response.data.address;
        this.buildingName = response.data.building_name;
        this.email = response.data.email;
        this.phone = response.data.phone;
        this.$loading(false);
      } catch (error){
        this.$loading(false);
      }
    },
    showInputPassword() {
      this.isEditPasswword = true;
    },
    showPasswordOld() {
      this.showOldPwd = !this.showOldPwd;
      const passwordField = document.querySelector('#password_old');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },
    showPasswordConfirm() {
      this.showConfirmPwd = !this.showConfirmPwd;
      const passwordField = document.querySelector('#password_confirm');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },
    showDate(date) {
      return date !== null ? this.formatDateWithoutLeadingZeros(date) : '';
    },
    async handleConfirm() {
      this.validate();

      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>店舗情報を更新します。</p>
              <p>よろしいですか？</p>`,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        showCancelButton: true,
        customClass: {
          title: 'title-popup-owner',
          htmlContainer: 'html-popup-owner',
          confirmButton: 'confirm-popup-owner',
          cancelButton: 'cancel-popup-owner',
        }
      });

      if (confirm.isConfirmed) {
        this.handleSave();
      }
    },
    async handleSave() {
      let params = {
        shop_name: this.shopName,
        shop_name_kana: this.shopNameKana,
        name: this.name,
        name_kana: this.nameKana,
        postcode: this.postcode,
        prefecture_id: this.prefectureId,
        city: this.city,
        address: this.address,
        building_name: this.buildingName,
        email: this.email,
        phone: this.phone,
        login_id: this.restaurantAdministrator.login_id,
      };

      if (this.password) {
        params = {...params, password: this.password };
      }

      this.$loading(true);
      try {
        this.submitFormFlg = true;
        await RestaurantAdministratorsService.update(this.restaurantAdministrator.id, params);
        this.$router.push(`/owner/restaurant/${this.restaurantAdministrator.id}/detail`);
        this.$loading(false);
      } catch (error) {
        this.submitFormFlg = false;
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        this.$loading(false);
      }
    },

    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },

    validate() {
      const validator = new Validator();
      validator.checkRequire('shop_name', this.shopName, '店舗名') &&
        validator.checkMaxLength('shop_name', this.shopName, 255, '店舗名');

      validator.checkRequire('shop_name_kana', this.shopNameKana, '店舗名（カナ）') &&
        validator.checkKatakana('shop_name_kana', this.shopNameKana, '店舗名（カナ）') &&
        validator.checkMaxLength('shop_name_kana', this.shopNameKana, 255, '店舗名（カナ）');

      validator.checkRequire('name', this.name, '氏名') &&
        validator.checkMaxLength('name', this.name, 255, '氏名');

      validator.checkRequire('name_kana', this.nameKana, '氏名（カナ）') &&
        validator.checkKatakana('name_kana', this.nameKana, '氏名（カナ）') &&
        validator.checkMaxLength('name_kana', this.nameKana, 255, '氏名（カナ）');

      validator.checkRequire('postcode', this.postcode, '郵便番号')&&
        validator.checkMaxLength('postcode', this.postcode, 7, '郵便番号')&&
        validator.checkPostCode('postcode', this.postcode, '郵便番号');
      
      validator.checkRequire('prefecture_id', this.prefectureId, '都道府県');

      validator.checkRequire('city', this.city, '市区町村') &&
        validator.checkMaxLength('city', this.city, 255, '市区町村');

      validator.checkRequire('address', this.address, '丁目・番地・号') &&
        validator.checkMaxLength('address', this.address, 255, '丁目・番地・号');

      if(this.buildingName){
        validator.checkMaxLength('building_name', this.buildingName, 255, 'ビル・マンション');
      }

      validator.checkRequire('email', this.email, 'メールアドレス') &&
        validator.checkEmail('email', this.email, 'メールアドレス');

      const phone =  this.phone;
      validator.checkRequire('phone', phone, '電話番号') &&
        validator.checkMaxLength('phone', CorrectNumber(phone), 11, '電話番号') &&
        validator.checkMinLength('phone', CorrectNumber(phone), 10, '電話番号') &&
        validator.checkTel('phone', phone, '電話番号');
      if (!validator.errors.phone) {
        validator.checkPhone('phone', CorrectNumber(this.phone), '電話番号');
      }

      if (this.isEditPasswword) {
        if (this.password === null && this.passwordConfirm === null || this.password === '' && this.passwordConfirm === '') {
          this.errors = validator.errors;
        } else {
          validator.checkRequire('password', this.password, '店舗パスワード') &&
            validator.checkAsciiWithoutSpace('password', this.password, '店舗パスワード') &&
            validator.checkMinLength('password', this.password, 8, '店舗パスワード') &&
            validator.checkMaxLength('password', this.password, 50, '店舗パスワード');

          validator.checkRequire('password_confirm', this.passwordConfirm, '店舗パスワード（確認用）') &&
            validator.checkSameValue('password_confirm', this.passwordConfirm, this.password, '店舗パスワード（確認用）');

          this.errors = validator.errors;
        }
      } else {
        this.errors = validator.errors;
      }
    },
  }
};
</script>