<template>
  <div id="revisionHistoryForm" class="modal modal-revision-history">
    <div class="modal-backdrop" />
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <h1 class="title_admin">改定履歴登録</h1>
          <div class="icon close" />
        </div>
      </div>
      <!--body-->
      <div class="modal-body">
        <div class="admin_content">
          <form action="">
            <div class="form-row">
              <label for="publish_at" class="form_label form-20">公開⽇時<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <div class="input_wrapper calender">
                  <div class="input-group">
                    <flat-pickr
                      id="publish_at"
                      v-model="publishAt"
                      :config="configPublishAt"
                      placeholder="公開⽇時を入力"
                    />
                  </div>
                </div>
                <span v-for="error in errors['publishAt']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="revise_type" class="form_label form-20">種別<span class="require">必須</span></label>
              <div class="flex-row form-50">
                <label v-for="(type, index) in reviseTypes" :key="index" class="radio_btn">
                  {{ type.label }}
                  <input :id="`revise_type_${index}`" v-model="reviseType"
                         type="radio"
                         :disabled="revisionHistoryId"
                         :value="type.value"
                  >
                  <span v-if="revisionHistoryId" class="checkmark disabled" />
                  <span v-else class="checkmark" />
                </label>
                <span v-for="error in errors['reviseType']" :key="error" class="error-message ">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="revise_at" class="form_label form-20">改定日<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <div class="input_wrapper calender">
                  <div class="input-group">
                    <flat-pickr
                      id="revise_at"
                      v-model="reviseAt"
                      :config="configReviseAt"
                      placeholder="公開⽇時を入力"
                    />
                  </div>
                </div>
                <span v-for="error in errors['reviseAt']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="version" class="form_label form-20">バージョン</label>
              <div class="flex-column form-50">
                <div class="flex alignCenter">
                  <input id="version" v-model="version" type="text"
                         placeholder="バージョンを入力"
                  >
                </div>
                <span v-for="error in errors['version']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="notice_text" class="form_label form-20">通知本文<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <textarea id="notice_text" v-model="noticeText"
                          rows="6" placeholder="通知本文を入力"
                />
                <span v-for="error in errors['noticeText']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <div>
          <a class="btn_admin admin_primary" href="#" @click="handleSave()">{{ revisionHistoryId ? '更新' : '登録' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../../../validator';
import { RevisionHistoriesService } from '../../../../services/admin/revision_histories.service';
import {Japanese} from 'flatpickr/dist/l10n/ja.js';

export default {
  props: {
    revisionHistoryId: {
      type: Number,
      require: true,
    },
    restaurantAdministrator: {
      type: Object,
      require: true,
    },
    supplier: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      publishAt: null,
      reviseType: null,
      reviseAt: null,
      version: null,
      noticeText: null,
      errors: [],
      reviseTypes: [
        {
          value: 'privacy_policy',
          label: 'プライバシーポリシー',
        },
        {
          value: 'term_of_service',
          label: '利用規約',
        },
      ],
      configPublishAt: {
        enableTime: true,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d H:i',
      },
      configReviseAt: {
        enableTime: false,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d',
      },
    };
  },
  watch: {
    revisionHistoryId: async function (val) {
      if (val) {
        this.$loading(true);
        try {
          const {response} = await RevisionHistoriesService.get(val);
          this.setFormData(response.data);
          this.$loading(false);
        } catch (error) {
          console.log(error);
          this.$loading(false);
        }
      } else {
        this.setFormData({});
      }
    },
  },
  methods: {
    setFormData(revision_history) {
      this.errors = [];
      this.publishAt = revision_history.publish_at;
      this.reviseType = revision_history.revise_type;
      this.reviseAt = revision_history.revise_at;
      this.version = revision_history.version;
      this.noticeText = revision_history.notice_text;
    },
    onModalClose() {
      this.$emit('updateSelectedRevisionHistory', null);
      this.setFormData({});
    },
    handleSave: async function () {
      this.errors = [];
      this.validate();
      if (Object.keys(this.errors).length) return;

      const params = {
        publish_at: this.publishAt,
        revise_type: this.reviseType,
        revise_at: this.reviseAt,
        version: this.version,
        notice_text: this.noticeText,
      };
      this.$loading(true);
      try {
        if (this.revisionHistoryId) {
          await RevisionHistoriesService.update(this.revisionHistoryId, params);
        } else {
          await RevisionHistoriesService.create(params);
        }
        this.$loading(false);
        this.$closeModal('revisionHistoryForm');
        this.$emit('refreshTable');
      } catch (error) {
        if(error.response.result.message.base) {
          this.errors = { publishAt: error.response.result.message.base }
        } else {
          this.errors = { publishAt: error.response.result.message };
        }
        this.$loading(false);
      }
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire('publishAt', this.publishAt, '公開⽇時') &&
        validator.checkMaxLength('publishAt', this.publishAt, 255, '公開⽇時');

      validator.checkRequire('reviseType', this.reviseType, '種別');

      validator.checkRequire('reviseAt', this.reviseAt, '改定日') &&
        validator.checkMaxLength('reviseAt', this.reviseAt, 255, '改定日');

      validator.checkMaxLength('version', this.version, 255, 'バージョン');

      validator.checkRequire('noticeText', this.noticeText, '通知本文') &&
        validator.checkMaxLength('noticeText', this.noticeText, 10000, '通知本文');

      this.errors = validator.errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-revision-history .modal-content {
  min-width: 800px;
  width: 800px;
}
.radio_btn {
  width: 50%;
}

.checkmark {
  &.disabled {
    color: #ffffff;
    cursor: not-allowed;
    pointer-events: none;
    border: 2px solid #ACACAC;
    &:after {
          background: #ACACAC;
    }
  }
}
</style>

