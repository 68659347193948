const ModalHelper = {
  install(Vue) {

    const closeModal = (modal) => {
      if (!modal) return console.log('No such modal!!!');
      modal.style.display = 'none';
      if (modal.__vue__ && modal.__vue__.onModalClose) {
        modal.__vue__.onModalClose();
      }
    };

    const openModal = (modal) => {
      if (!modal) return console.log('No such modal!!!');
      modal.style.display = 'block';
      if (modal.__vue__ && modal.__vue__.onModalOpen) {
        modal.__vue__.onModalOpen();
      }
    };
    Vue.prototype.$openModal = function (modalId) {
      openModal(document.getElementById(modalId));
    };

    Vue.prototype.$closeModal = function (modalId) {
      closeModal(document.getElementById(modalId));
    };

    Vue.mixin({
      // Add components lifecycle hooks or properties.
      created() {
        document._global_modal_onClick ||= function(e) {
          if (e.target.classList.contains('close') || (!!e.target.closest('.modal') && e.target.closest('.modal').length > 0)) {
            closeModal(e.target.closest('.modal'));
          }
        };
        document.body.addEventListener('click', document._global_modal_onClick);
      }
    });
  }
};

export default ModalHelper;

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(ModalHelper);
}