<template>
  <div class="admin_content">
    <div>
      <h1 class="mb_medium">法人情報管理 解約</h1>
      <div>解約を行いました。</div>
    </div>
    <div class="btn_area_admin edit">
      <button class="btn btn_black_border btn_point" @click="redirectBack">法人情報管理へ</button>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    redirectBack() {
      return this.$router.push({ path: '/admin'});
    }
  }
};
</script>