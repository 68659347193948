var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('body',{staticClass:"body_admin"},[_c('main',{staticClass:"container_admin wrap",attrs:{"id":"app"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"sp_menu_bg"}),_vm._v(" "),_c('div',{staticClass:"admin_header"},[_c('div',{staticClass:"header_1 flex"},[_c('div',{staticClass:"logo"}),_vm._v(" "),_c('div',{staticClass:"right"},[_c('div',{staticClass:"icon_shevron_down after",on:{"click":function($event){_vm.showLogOut=!_vm.showLogOut}}},[_vm._v(_vm._s(_vm.login_id))]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogOut),expression:"showLogOut"}],staticClass:"logout_admin btn_point",on:{"click":_vm.logout}},[_vm._v("\n            ログアウト\n          ")])])]),_vm._v(" "),_c('ul',{staticClass:"header_2 flex"},[_c('li',[_c('router-link',{class:!_vm.$route.path.includes('/admin/notifications') &&
              !_vm.$route.path.includes('/admin/ocr') &&
              !_vm.$route.path.includes('/admin/supplier') &&
              !_vm.$route.path.includes('/admin/cancel-answers') &&
              !_vm.$route.path.includes('/admin/revision-histories') &&
              !_vm.$route.path.includes('/admin/agencies') &&
              !_vm.$route.path.includes('/admin/export_csv') ? 'router-link-exact-active' : '',attrs:{"to":"/admin"}},[_vm._v("\n            法人情報管理\n          ")])],1),_vm._v(" "),_c('li',[_c('router-link',{class:_vm.$route.path.includes('/admin/ocr') ||
              _vm.$route.path.includes('/admin/ocr') ? 'router-link-exact-active' : '',attrs:{"to":"/admin/ocr"}},[_vm._v("\n            申し込み一覧\n          ")])],1),_vm._v(" "),_c('li',[_c('router-link',{class:_vm.$route.path.includes('/admin/agencies') ? 'router-link-exact-active' : '',attrs:{"to":"/admin/agencies"}},[_vm._v("\n            代理店一覧\n          ")])],1),_vm._v(" "),_c('li',{staticClass:"dropdown"},[_c('span',[_vm._v("お知らせ")]),_vm._v(" "),_c('div',{staticClass:"dropdown-content"},[_c('router-link',{class:_vm.$route.path === '/admin/notifications' ? 'router-link-exact-active' : '',attrs:{"to":"/admin/notifications"}},[_vm._v("\n              お知らせ\n            ")]),_vm._v(" "),_c('router-link',{class:_vm.$route.path ==='/admin/notifications-individual' ? 'router-link-exact-active' : '',attrs:{"to":"/admin/notifications-individual"}},[_vm._v("\n              お知らせ 個別\n            ")])],1)]),_vm._v(" "),_c('li',[_c('router-link',{class:_vm.$route.path.includes('/admin/revision-histories') ||
              _vm.$route.path.includes('/admin/revision-histories') ? 'router-link-exact-active' : '',attrs:{"to":"/admin/revision-histories"}},[_vm._v("\n            改定履歴\n          ")])],1),_vm._v(" "),_c('li',[_c('router-link',{class:_vm.$route.path.includes('/admin/cancel-answers/export') ||
              _vm.$route.path.includes('/admin/cancel-answers/export') ? 'router-link-exact-active' : '',attrs:{"to":"/admin/cancel-answers/export"}},[_vm._v("\n            解約アンケート\n          ")])],1),_vm._v(" "),_c('li',[_c('router-link',{class:_vm.$route.path.includes('/admin/analysis') ||
              _vm.$route.path.includes('/admin/analysis') ? 'router-link-exact-active' : '',attrs:{"to":"/admin/analysis"}},[_vm._v("\n            分析\n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"main_area_admin"},[_c('div',{staticClass:"main_content"},[_c('router-view')],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp_menu"},[_c('div',{staticClass:"logo logo_admin"}),_vm._v(" "),_c('div',{staticClass:"sp_menu_trigger"})])}]

export { render, staticRenderFns }