<template>
  <div>
    <div class="bottom_menu" :class="showDialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        エラー
      </h2>
      <div class="mb_large">
        <div>未入力の項目または入力内容に誤りがあります。</div>
        <div>ご確認いただき、再度登録をお願いいたします。</div>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary_border" @click.native="disableShowDialog">閉じる</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="showDialog ? 'open' : ''" />
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function
    },
  },
  methods: {
    disableShowDialog() {
      this.closeDialog();
    }
  }

};
</script>

<style lang="scss" scoped>
  .bottom_menu_title {
    color: #D52C2C;
  }
</style>