<template>
  <div id="confirm-screen">
    <div class="bottom_menu">
      <h2 class="bottom_menu_title">{{ title }}</h2>
      <div class="mb_large pre-formatted">{{ message }}</div>
      <div class="btn_area two_items">
        <a href="#" class="btn primary router-link-exact-active active" style="margin-right: 20px; cursor: pointer;" @click="choose(true)">{{ buttonTrueText }}</a>
        <a href="#" class="btn primary_border router-link-exact-active active" @click="cancel">{{ buttonFalseText }}</a>
      </div>
    </div>
    <div class="bottom_menu_bg" @click="cancel" />
  </div>
</template>
  
<script>
export default {
  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes',
    },
    buttonFalseText: {
      type: String,
      default: 'No',
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: false,
    };
  },
  methods: {
    choose(value) {
      this.value = value;
      this.$destroy();
    },
    cancel() {
      this.$destroy();
    },
  },
};
</script>
<style scoped>
#confirm-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
}
.bottom_menu_bg {
  pointer-events: all;
}
</style>
