<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <h1 class="title mb_large padding_text">
        メモ一覧
      </h1>
      <ul class="memo_list">
        <li v-for="memo in memos" :key="memo.id">
          <div class="flex alignCenter wrap to_detail">
            <div class="memo_text_area" @click="selectMemo(memo)">
              <div v-if="isNewMemo(memo)" class="memo_label new">
                NEW
              </div>
              <div class="memo_date memo_date-list">
                {{ formatDateMemoDate(memo.created_at) }}
              </div>
              <div class="memo_text">{{ memo.message }}</div>
            </div>
            <div class="memo_control">
              <router-link to="/dashboard" class="memo_control_btn primary">
                <div class="icon order_create" />
                <div class="memo_control_btn_text">
                  発注書作成
                </div>
              </router-link>
              <a href="#" class="memo_control_btn delete" @click="handleDelete(memo)">
                <div class="icon trash" />
                <div class="memo_control_btn_text">削除</div>
              </a>
            </div>
          </div>
        </li>
      </ul>

      <div v-show="isNoMemo" class="text-center">表示するデータはありません。</div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <a href="#" class="btn primary_border memo_create" @click="handleNew">新規登録</a>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { MemosService } from '../../services/memos.service';
import utils from '../../common/util';

export default {
  computed: {
    ...mapGetters({
      memos: 'getAllMemos',
      isNoMemo: 'isNoMemo'
    })
  },
  mounted() {
    this.getMemos();
  },
  methods: {
    ...utils,
    isNewMemo(memo) {
      return moment(memo.created_at) >= moment().subtract(1, 'day');
    },
    async handleNew() {
      const value = await this.$inputDialog('メモ', { maxLength: 255, placeholder: 'メモを入力' });
      if (!value) return;
      const params = {
        message: value
      };
      this.$loading(true);
      try {
        await MemosService.create(params);
        this.getMemos();
      } catch (error) {
        this.$loading(false);
      }
    },
    async handleDelete(memo) {
      const value = await this.$confirm('削除確認', 'メモを削除します。\n本当によろしいですか？');
      if (!value) return;
      this.$loading(true);
      try {
        await MemosService.delete(memo.id);
        this.getMemos();
      } catch (error) {
        this.$loading(false);
      }
    },
    async getMemos() {
      this.$loading(true);
      await this.$store.dispatch('getMemos');
      this.$loading(false);
    },
    selectMemo(memo) {
      this.$router.push({ path: `/memo/${memo.id}` });
    },
    formatDateMemoDate (date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  }
};
</script>
<style lang="scss" scoped>
.memo_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 10px;
}
.memo_date-list {
  font-size: 16px;
}
@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
  }
}
</style>
