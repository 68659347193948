<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">担当者管理</h1>
      <p class="small_text mb_large chanel_text_padding">発注、在庫管理（棚卸）の担当者を登録します。</p>
      <paging
        v-if="order_staffs.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th>担当者名</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order_staff in order_staffs"
            :key="order_staff.id"
            class="to_detail"
            @click="selectRow(order_staff)"
          >
            <td>{{ order_staff.name }}</td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="order_staffs.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <div v-else class="text-center">表示するデータはありません。</div>
    </div>
    <div class="control_area">
      <router-link to="/setting" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link to="/order_staff/new" class="btn primary_border">
          新規登録
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { OrderStaffsService } from '../../services/order_staffs.service';
import Paging from '../../components/Pager';

export default {
  components: {
    Paging
  },
  data() {
    return {
      order_staffs: [],
      noData: false,
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
    };
  },
  mounted() {
    this.getOrderStaffs();  
  },
  methods: {
    async getOrderStaffs() {
      this.$loading(true);
      const params = {
        page: this.pager.page,
        page_size: this.pager.pageSize,
      };
      try {
        const { response } = await OrderStaffsService.index(params);
        this.order_staffs = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        if (!this.order_staffs || this.order_staffs.length < 1) {
          this.noData = true;
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    selectRow(staff) {
      this.$router.push({ path: `/order_staff/${staff.id}/edit` });
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getOrderStaffs();
      this.scrollTopScreen();
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
  },
};
</script>
