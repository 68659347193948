<template>
  <div class="pagination" :class="styles">
    <div class="page_count" v-if="!is_restaurant">
      {{ page }}/{{ totalPage }}ページ
    </div>
    <div
      class="page page_prev"
      :class="currentPage <= 1 ? 'disabled' : ''"
      @click="jumpPage(currentPage - 1)"
    >
      <i v-if="!compact" class="fas fa-angle-left" />{{ compact ? '' : ' 前へ' }}
    </div>

    <template v-for="(page_no, index) in totalPage">
      <div
        v-if="page_no >= minVisiblePage && page_no <= maxVisiblePage"
        :key="index"
        class="page"
        :class="currentPage === page_no ? 'current' : ''"
        @click="jumpPage(page_no)"
      >
        {{ page_no }}
      </div>
    </template>

    <div
      class="page page_next"
      :class="currentPage >= totalPage ? 'disabled' : ''"
      @click="jumpPage(currentPage + 1)"
    >
      {{ compact ? '' : '次へ ' }}<i v-if="!compact" class="fas fa-angle-right" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['totalPage', 'page', 'styles', 'compact', 'is_restaurant'],
  data() {
    return {
      currentPage: this.page,
    };
  },
  computed: {
    minVisiblePage() {
      return this.currentPage - 2;
    },
    maxVisiblePage() {
      const addition = this.minVisiblePage <= 0 ? this.minVisiblePage - 1 : 0;
      return this.currentPage + 2 - addition;
    },
  },
  watch: {
    page(newVal) {
      this.currentPage = newVal;
    },
  },
  methods: {
    jumpPage(page) {
      if (this.currentPage !== page) {
        this.currentPage = page;
        this.$emit('jumpPage', page);
      }
    },
  },
};
</script>

<style scoped>
.page {
  cursor: pointer !important;
}
</style>
