<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <h2 class="mb_medium">オーナーアカウント 解約申請の完了</h2>
      <div class="note_sp">オ一ナ一ア力ウントの解約申請が完了いたしました。</div>
      <div class="note_sp">
        {{ monthAt }}月{{ dayAt }}日の契約期間満了までは引き続きご利用いただけます。
      </div>
      <div class="note_sp">契約期聞が満了いたしま したらオ一ナ一アカウントが解約されます。</div>
      <div class="note_sp">ご利用ありがとうございました。</div>
      <div class="btn_area_owner owner_btn_padding">
        <button class="btn btn_owner primary_border owner_font_16 btn_pointer" 
                @click="redirectBack"
        >
          アカウント管理へ
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      monthAt: '',
      dayAt: '',
    };
  },
  
  computed: {
    ...mapGetters({
      cancel_at: 'getCancelAt',
    })
  },

  mounted() {
    this.formatDate();
  },

  methods: {
    redirectBack() {
      return this.$router.push('/owner/detail');
    },
    formatDate() {
      let cancelAt;
      cancelAt = moment(this.cancel_at, 'YYYY/MM/DD').format('YYYY/MM/DD');
      this.monthAt = moment(cancelAt, 'YYYY/MM/DD').format('MM');
      this.dayAt = moment(cancelAt, 'YYYY/MM/DD').format('DD');
    }
  },
};
</script>