const getDefaultState = () => {
  return {
    createForm: {
      login_id: null,
      name: null,
      name_kana: null,
      email: null,
      shop_name: null,
      shop_name_kana: null,
      prefecture_id: null,
      postcode: null,
      city: null,
      address: null,
      building_name: null,
      phone: null,
      password: null,
    },
  };
};

const restaurants_owner = {
  state: {
    createForm: {
      login_id: null,
      name: null,
      name_kana: null,
      email: null,
      shop_name: null,
      shop_name_kana: null,
      prefecture_id: null,
      postcode: null,
      city: null,
      address: null,
      building_name: null,
      phone: null,
      password: null,
    },
  },
  mutations: {
    CLEAR_STATE_RESTAURANTS_OWNER(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    getCreateForm: state => state.createForm,
  }
};

export default restaurants_owner;
