<template>
  <div>
    <div>
      <div>
        <span class="note_sp">お申し込みいただき、ありがとうございます。</span><br>
        <span class="note_sp">はじめに、オーナーアカウントを作成します。</span><br>
        <span class="note_sp">入力フォームの内容をご入力いただき、「確認する」ボタンを押して次へ進んでください。</span><br>
      </div>
      <div v-if="hasAgencyCode">
        <h1 class="owner_text_title">代理店情報</h1>
        <div class="input_owner">
          <label class="owner_form_label">代理店名</label>
          <div class="owner_line_down owner_color_black">
            {{ agencie.name }}
          </div>
        </div>
      </div>
      <div class="input_owner">
        <div class="width_input">
          <label for="agencie_code" class="owner_form_label">紹介者コード<span class="require">必須</span></label>
          <input
            v-model="registerForm.agency.code"
            type="text"
            placeholder="紹介者コードを入力"
          >
        </div>
        <span>紹介者コードがない場合は、「0」をご入力ください。</span>
        <span
          v-for="error in errors['agencie_code']"
          :key="error"
          class="error-message owner"
        >
          {{ error }}
        </span>
      </div>

      <h1 class="owner_text_title owner_margin_20">法人情報</h1>
      <form action="">
        <div class="input_owner">
          <label for="login_id" class="owner_form_label">オーナーID<span class="require">必須</span></label>
          <input
            v-model="registerForm.login_id"
            type="text"
            placeholder="オーナーIDを入力"
          >
          <p class="owner_text_note form_text_note_margin">
            半角英数6文字以上で入力してください。記号の利用はできません。オーナーIDはログイン時に使用します。
          </p>
          <span
            v-for="error in errors['login_id']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="name" class="owner_form_label">法人名<span class="require">必須</span></label>
          <input
            v-model="registerForm.name"
            type="text"
            placeholder="法人名を入力"
          >
          <p class="owner_text_note form_text_note_margin">個人事業主の方は屋号か個人事業主名をご入力ください。</p>
          <span
            v-for="error in errors['name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="name_kana" class="owner_form_label">法人名（カナ）<span class="require">必須</span></label>
          <input
            v-model="registerForm.name_kana"
            type="text"
            placeholder="法人名（カナ）を入力"
          >
          <span
            v-for="error in errors['name_kana']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="director_name" class="owner_form_label">法人代表者名<span class="require">必須</span></label>
          <input
            v-model="registerForm.director_name"
            type="text"
            placeholder="法人代表者名を入力"
          >
          <span
            v-for="error in errors['director_name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="director_name_kana" class="owner_form_label">法人代表者名（カナ）<span class="require">必須</span></label>
          <input
            v-model="registerForm.director_name_kana"
            type="text"
            placeholder="法人代表者名（カナ）を入力"
          >
          <span
            v-for="error in errors['director_name_kana']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="staff_name" class="owner_form_label">担当者名<span class="require">必須</span></label>
          <input
            v-model="registerForm.staff_name"
            type="text"
            placeholder="担当者名を入力"
          >
          <span
            v-for="error in errors['staff_name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="staff_name_kana" class="owner_form_label">担当者名（カナ）<span class="require">必須</span></label>
          <input
            v-model="registerForm.staff_name_kana"
            type="text"
            placeholder="担当者名（カナ）を入力"
          >
          <span
            v-for="error in errors['staff_name_kana']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="department_name" class="owner_form_label">部署名</label>
          <input
            v-model="registerForm.department_name"
            type="text"
            placeholder="部署名を入力"
          >
          <span
            v-for="error in errors['department_name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner width_input">
          <label for="postcode" class="owner_form_label">郵便番号<span class="require">必須</span></label>
          <input
            v-model="registerForm.postcode"
            type="text"
            placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
          >
          <span
            v-for="error in errors['postcode']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label class="owner_form_label">都道府県<span class="require">必須</span></label>
          <div class="select_wrapper medium arrow_down select_boder">
            <select
              v-model="registerForm.prefecture_id"
              class="select_box_padding"
            >
              <option
                v-for="(prefecture, index) in prefectures"
                :key="index"
                :value="prefecture.code"
              >
                {{ prefecture.name }}
              </option>
            </select>
          </div>
          <span
            v-for="error in errors['prefecture_id']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label for="city" class="owner_form_label">市区町村<span class="require">必須</span></label>
          <input
            v-model="registerForm.city"
            type="text"
            placeholder="例：港区北青山"
          >
          <span
            v-for="error in errors['city']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label for="address" class="owner_form_label">丁目・番地・号<span class="require">必須</span></label>
          <input
            v-model="registerForm.address"
            type="text"
            placeholder="例：2-14-4"
          >
          <span
            v-for="error in errors['address']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="building_name" class="owner_form_label">ビル・マンション</label>
          <input
            v-model="registerForm.building_name"
            type="text"
            placeholder="ビル・マンションを入力"
          >
          <span
            v-for="error in errors['building_name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="email" class="owner_form_label">連絡先メールアドレス<span class="require">必須</span></label>
          <input
            v-model="registerForm.email"
            type="text"
            placeholder="連絡先メールアドレスを入力"
          >
          <p class="owner_text_note form_text_note_margin">
            運営会社からご連絡させていただく場合がございますので、お間違いのないようにご入力ください。
          </p>
          <p class="owner_text_note form_text_note_margin">大文字と小文字は区別して登録します。ご注意ください。</p>
          <p class="owner_text_note form_text_note_margin">
            ドメイン指定受信を設定している場合、メールが届かない場合がございます。「@bspk.co.jp」からのメールを受信できるように設定をお願いします。
          </p>
          <span
            v-for="error in errors['email']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="confirm_email" class="owner_form_label">連絡先メールアドレス（確認）<span class="require">必須</span></label>
          <input
            v-model="confirm_email"
            type="text"
            placeholder="連絡先メールアドレス（確認）を入力"
            autocomplete="off"
            @copy.prevent @paste.prevent
          >
          <span
            v-for="error in errors['confirm_email']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="phone" class="owner_form_label">電話番号<span class="require">必須</span></label>
          <div class="width_input">
            <input
              v-model="registerForm.phone"
              type="text"
              placeholder="電話番号（ハイフン不要、半角のみ）を入力"
            >
          </div>
          <p class="owner_text_note form_text_note_margin">
            法人としてご連絡の取れる電話番号をご入力ください。
          </p>
          <span
            v-for="error in errors['phone']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <h1 class="owner_margin_20 owner_text_title">請求書受け取り先情報</h1>
        <span class="note_sp">ご利用料金の請求書を送付させていただきます。</span><br>
        <span class="note_sp">受取先の情報をご入力ください。</span><br>
        <div class="input_owner">
          <label for="invoice_send_type" class="owner_form_label">
            お支払い方法
            <span class="require">必須</span>
          </label>
          <div class="radio_position owner_margin_buttom radio_position_change">
            <label class="radio_btn_owner"  style="width: fit-content; margin-right: 10px;" v-for="payment_type in payment_types" :key="payment_type.id">
              <input
                v-model="registerForm.payment_type_id"
                type="radio"
                :value="payment_type.id"
                class="owner_padding_checkbox owner_border_input_radius"
              >
              <span class="checkmark_owner" />
              <label class="owner_padding_label owner_form_label">{{ payment_type.name }}</label>
            </label>
          </div>
          <span
            v-for="error in errors['payment_type_id']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div v-if="registerForm.payment_type_id == '1'">
          <p class="owner_text_note form_text_note_margin">クレジットカード払いをご選択の場合、クレジットカード情報の入力は「支払い管理」からお願いいたします。</p>
          <p class="owner_text_note form_text_note_margin">クレジットカード決済は、PCI DSS（カード業界の情報セキュリティ基準）に準拠しているGMOイプシロン株式会社が当社に代行して行っております。
          またご登録いただくクレジットカード情報は、GMOイプシロン株式会社が提供する「fincode byGMO」のカード情報保持機能にてお預かりします。</p>
        </div>
        <div v-else-if="registerForm.payment_type_id != null">
          <div v-if="registerForm.payment_type_id == '3'">
            <p class="owner_text_note form_text_note_margin">口座振替適用までは、口座振替依頼書受け付け後から約2か月程お時間が掛かります。</p>
            <p class="owner_text_note form_text_note_margin">口座振替適用までの間は、請求書払いにてお支払いをお願いいたします。</p>
          </div>
          <div class="input_owner">
            <label for="invoice_send_type" class="owner_form_label">
              請求書受け取り方法
              <span class="require">必須</span>
            </label>
            <div class="radio_position owner_margin_buttom">
              <label class="radio_btn_owner">
                <input
                  v-model="registerForm.payment.invoice_send_type"
                  type="radio"
                  :checked="true"
                  value="0"
                  class="owner_padding_checkbox owner_border_input_radius"
                >
                <span class="checkmark_owner" />
                <label class="owner_padding_label owner_form_label">メール</label>
              </label>
              <label class="radio_btn_owner">
                <input
                  v-model="registerForm.payment.invoice_send_type"
                  type="radio"
                  value="1"
                  class="owner_padding_checkbox owner_border_input_radius"
                >
                <span class="checkmark_owner" />
                <label class="owner_padding_label owner_form_label">郵送</label>
              </label>
            </div>
            <span
              v-for="error in errors['invoice_send_type']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner">
            <label for="orderType" class="owner_form_label">請求書受け取り先情報<span class="require">必須</span></label>
            <div class="radio_position radio_position_change owner_margin_buttom">
              <label class="radio_btn_owner width_radio">
                <input
                  v-model="registerForm.payment.use_owner_info"
                  type="radio"
                  :checked="true"
                  value="0"
                  class="owner_padding_checkbox owner_border_input_radius"
                >
                <span class="checkmark_owner" />
                <label class="owner_padding_label owner_form_label">法人情報を使用する</label>
              </label>
              <label class="radio_btn_owner width_radio">
                <input
                  v-model="registerForm.payment.use_owner_info"
                  type="radio"
                  value="1"
                  class="owner_padding_checkbox owner_border_input_radius"
                  @click="cleanFromInputPayment"
                >
                <span class="checkmark_owner" />
                <label class="owner_padding_label owner_form_label">別の情報を設定する</label>
              </label>
            </div>
            <span
              v-for="error in errors['use_owner_info']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div v-if="registerForm.payment.use_owner_info === '1'">
            <div class="input_owner">
              <label for="name_change" class="owner_form_label">請求書受け取り先名（会社名）<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.name"
                type="text"
                placeholder="請求書受け取り先名（会社名）を入力"
              >
              <span
                v-for="error in errors['name_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>

            <div class="input_owner">
              <label for="staff_name_change" class="owner_form_label">担当者名<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.staff_name"
                type="text"
                placeholder="担当者名を入力"
              >
              <span
                v-for="error in errors['staff_name_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
            <div class="input_owner">
              <label for="staff_name_kana_change" class="owner_form_label">担当者名（カナ）<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.staff_name_kana"
                type="text"
                placeholder="担当者名（カナ）を入力"
              >
              <span
                v-for="error in errors['staff_name_kana_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
            <div class="input_owner">
              <label for="department_name_change" class="owner_form_label">部署名</label>
              <input
                v-model="registerForm.payment.department_name"
                type="text"
                placeholder="部署名を入力"
              >
              <span
                v-for="error in errors['department_name_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>

            <div class="input_owner width_input">
              <label for="postcode_change" class="owner_form_label">郵便番号<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.postcode"
                type="text"
                placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
              >
              <span
                v-for="error in errors['postcode_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>

            <div class="input_owner">
              <label class="owner_form_label">都道府県<span class="require">必須</span></label>
              <div class="select_wrapper medium arrow_down select_boder">
                <select
                  v-model="registerForm.payment.prefecture_id"
                  class="select_box_padding"
                >
                  <option
                    v-for="(prefecture, index) in prefectures"
                    :key="index"
                    :value="prefecture.code"
                  >
                    {{ prefecture.name }}
                  </option>
                </select>
              </div>
              <span
                v-for="error in errors['prefecture_id_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>

            <div class="input_owner">
              <label for="city_change" class="owner_form_label">市区町村<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.city"
                type="text"
                placeholder="例：港区北青山"
              >
              <span
                v-for="error in errors['city_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
            <div class="input_owner">
              <label for="address_change" class="owner_form_label">丁目・番地・号<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.address"
                type="text"
                placeholder="例：2-14-4"
              >
              <span
                v-for="error in errors['address_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
            <div class="input_owner">
              <label for="building_name_change" class="owner_form_label">ビル・マンション</label>
              <input
                v-model="registerForm.payment.building_name"
                type="text"
                placeholder="ビル・マンションを入力"
              >
              <span
                v-for="error in errors['building_name_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
            <div class="input_owner">
              <label for="email_change" class="owner_form_label">連絡先メールアドレス<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.email"
                type="text"
                placeholder="連絡先メールアドレスを入力"
              >
              <span
                v-for="error in errors['email_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>

            <div class="input_owner width_input">
              <label for="phone_change" class="owner_form_label">電話番号<span class="require">必須</span></label>
              <input
                v-model="registerForm.payment.phone"
                type="text"
                placeholder="電話番号（ハイフン不要、半角のみ）を入力"
              >
              <span
                v-for="error in errors['phone_change']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </form>

      <div class="btn_area_owner control two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn_owner primary"
          @click.native="confirmRegister"
        >確認する</router-link>
        <router-link
          :to="{}"
          class="btn_owner primary_border ml_medium"
          @click.native="goBack()"
        >戻る</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Validator from '../../../../validator';
import { mapGetters } from 'vuex';
import { RegistationService } from '../../../../services/Owner/registation.service';
import { AgenciesService } from '../../../../services/Owner/agencies.service';
import { PaymentTypesService } from '../../../../services/Owner/payment_types.service';
import { AddressInfo, PopupDisplayError} from '../../../../common/util';
import { BESPO_AGENCY_CODE } from '../../../../common/variables';

export default {
  data() {
    return {
      errors: {},
      agencie_code: null,
      agencie: [],
      confirm_email: null,
      agencie_id: null,
      urlCurrent: null,
      payment_types: [],
    };
  },
  computed: {
    ...mapGetters({
      registerForm: 'getRegisterForm',
      prefectures: 'getAllPrefectures',
    }),
    hasAgencyCode() {
      return this.agencie_code !== null && this.agencie_code !== '' && this.agencie_code !== BESPO_AGENCY_CODE;
    },
  },

  watch: {
    'registerForm.postcode'(val) {
      if (!val || val.length !== 7) return;
      AddressInfo(val, this.prefectures).then(addressInfo => {
        this.registerForm.prefecture_id = addressInfo.code;
        this.registerForm.city = addressInfo.city + addressInfo.address;
      });
    },

    'registerForm.payment.postcode'(val) {
      if (!val || val.length !== 7) return;
      AddressInfo(val, this.prefectures).then((address) => {
        this.registerForm.payment.prefecture_id = address.code;
        this.registerForm.payment.city = address.city + address.address;
      });
    },
  },
  mounted() {
    this.urlCurrent = this.$route.path;
    if (this.urlCurrent.indexOf('register') !== -1) {
      if (this.urlCurrent === '/register') {
        this.agencie_code = BESPO_AGENCY_CODE;
      } else {
        this.agencie_code = this.$route.params.id;
      }
      this.getListPaymentType();
      this.getInfoAgencies();
    }
  },
  methods: {
    cleanFromInputPayment() {
      this.registerForm.payment.name = null;
      this.registerForm.payment.postcode = null;
      this.registerForm.payment.prefecture_id = null;
      this.registerForm.payment.city = null;
      this.registerForm.payment.address = null;
      this.registerForm.payment.building_name = null;
      this.registerForm.payment.email = null;
      this.registerForm.payment.phone = null;
    },
    async getInfoAgencies() {
      try {
        const params = {
          code: this.agencie_code,
        };
        const { response } = await AgenciesService.index(params);
        this.agencie = response.data;
        this.agencie_id = response.data.id;
      } catch (error) {
        console.log(error);
        if (error.response.result.message == 'not_found') {
          this.$router.push('/not_found_data');
        }
      }
    },
    async getListPaymentType() {
      try {
        const { response } = await PaymentTypesService.index();
        this.payment_types = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async confirmRegister() {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      if (this.agencie_code === null || this.agencie_code === '') {
        delete this.registerForm['agency'];
      } else {
        this.registerForm.agency.agency_id = this.agencie_id;
      }

      if (this.registerForm.payment_type_id == '1') {
        this.registerForm.payment.use_owner_info = '0';
        this.registerForm.payment.invoice_send_type = '0';
        this.registerForm.payment.payment_credit_id = '1';
      }

      if (this.registerForm.payment.use_owner_info === '0') {
        this.registerForm.payment.name = this.registerForm.name;
        this.registerForm.payment.postcode = this.registerForm.postcode;
        this.registerForm.payment.prefecture_id =
          this.registerForm.prefecture_id;
        this.registerForm.payment.city = this.registerForm.city;
        this.registerForm.payment.address = this.registerForm.address;
        this.registerForm.payment.building_name =
          this.registerForm.building_name;
        this.registerForm.payment.email = this.registerForm.email;
        this.registerForm.payment.phone = this.registerForm.phone;
        this.registerForm.payment.department_name = this.registerForm.department_name;
        this.registerForm.payment.staff_name = this.registerForm.staff_name;
        this.registerForm.payment.staff_name_kana = this.registerForm.staff_name_kana;
      }
      try {
        await RegistationService.confirm(this.registerForm);
        this.$store.dispatch('setIsConfirm', true);
      } catch (error) {
        console.log(error);
        this.errors = error.response;
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },

    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire(
        'login_id',
        this.registerForm.login_id,
        'オーナーID'
      ) &&
        validator.checkAlphanumeric(
          'login_id',
          this.registerForm.login_id,
          'オーナーID'
        ) &&
        validator.checkMinLength(
          'login_id',
          this.registerForm.login_id,
          6,
          'オーナーID'
        ) &&
        validator.checkMaxLength(
          'login_id',
          this.registerForm.login_id,
          255,
          'オーナーID'
        );

      validator.checkRequire('name', this.registerForm.name, '法人名') &&
        validator.checkMaxLength('name', this.registerForm.name, 255, '法人名');

      validator.checkRequire(
        'name_kana',
        this.registerForm.name_kana,
        '法人名（カナ）'
      ) &&
        validator.checkMaxLength(
          'name_kana',
          this.registerForm.name_kana,
          255,
          '法人名（カナ）'
        ) &&
        validator.checkKatakana(
          'name_kana',
          this.registerForm.name_kana,
          '法人名（カナ）'
        );

      validator.checkRequire(
        'director_name',
        this.registerForm.director_name,
        '法人代表者名'
      ) &&
        validator.checkMaxLength(
          'director_name',
          this.registerForm.director_name,
          255,
          '法人代表者名'
        );

      validator.checkRequire(
        'director_name_kana',
        this.registerForm.director_name_kana,
        '法人代表者名（カナ）'
      ) &&
        validator.checkMaxLength(
          'director_name_kana',
          this.registerForm.director_name_kana,
          255,
          '法人代表者名（カナ）'
        ) &&
        validator.checkKatakana(
          'director_name_kana',
          this.registerForm.director_name_kana,
          '法人代表者名（カナ）'
        );

      validator.checkRequire(
        'staff_name',
        this.registerForm.staff_name,
        '担当者名'
      ) &&
        validator.checkMaxLength(
          'staff_name',
          this.registerForm.staff_name,
          255,
          '担当者名'
        );

      validator.checkRequire(
        'staff_name_kana',
        this.registerForm.staff_name_kana,
        '担当者名（カナ）'
      ) &&
        validator.checkKatakana(
          'staff_name_kana',
          this.registerForm.staff_name_kana,
          '担当者名（カナ）'
        ) &&
        validator.checkMaxLength(
          'staff_name_kana',
          this.registerForm.staff_name_kana,
          255,
          '担当者名（カナ）'
        );

      validator.checkMaxLength('department_name',this.registerForm.department_name,255,'部署名');

      validator.checkRequire(
        'postcode',
        this.registerForm.postcode,
        '郵便番号'
      ) &&
        validator.checkMaxLength(
          'postcode',
          this.registerForm.postcode,
          7,
          '郵便番号'
        ) &&
        validator.checkPostCode(
          'postcode',
          this.registerForm.postcode,
          '郵便番号'
        );

      validator.checkRequire(
        'prefecture_id',
        this.registerForm.prefecture_id,
        '都道府県'
      );

      validator.checkRequire('city', this.registerForm.city, '市区町村') &&
        validator.checkMaxLength(
          'city',
          this.registerForm.city,
          255,
          '市区町村'
        );

      validator.checkRequire('address', this.registerForm.address, '丁目・番地・号') &&
        validator.checkMaxLength(
          'address',
          this.registerForm.address,
          255,
          '丁目・番地・号'
        );

      validator.checkRequire('phone', this.registerForm.phone, '電話番号');
      if (this.registerForm.phone) {
        const phone = this.registerForm.phone;
        validator.checkTel('phone', phone, '電話番号') &&
          validator.checkMaxLength('phone', phone, 11, '電話番号') &&
          validator.checkMinLength('phone', phone, 10, '電話番号');
        if (!validator.errors.phone) {
          validator.checkPhone('phone', this.registerForm.phone, '電話番号');
        }
      }

      validator.checkMaxLength(
        'building_name',
        this.registerForm.building_name,
        255,
        'ビール・マンション'
      );

      validator.checkRequire(
        'email',
        this.registerForm.email,
        '連絡先メールアドレス'
      ) &&
        validator.checkEmail(
          'email',
          this.registerForm.email,
          '連絡先メールアドレス'
        );

      validator.checkRequire(
        'confirm_email',
        this.confirm_email,
        '連絡先メールアドレス（確認）'
      ) &&
        validator.checkEmail(
          'confirm_email',
          this.confirm_email,
          '連絡先メールアドレス（確認）'
        ) &&
        validator.checkSameValue(
          'confirm_email',
          this.registerForm.email,
          this.confirm_email,
          '連絡先メールアドレス（確認）'
        );

        validator.checkRequireSelect(
          'payment_type_id',
          this.registerForm.payment_type_id,
          'お支払い方法'
        );

      if (this.registerForm.payment_type_id != 1) {
        validator.checkRequireSelect(
          'invoice_send_type',
          this.registerForm.payment.invoice_send_type,
          '請求書受け取り方法'
        );
        validator.checkRequireSelect(
          'use_owner_info',
          this.registerForm.payment.use_owner_info,
          '請求書受け取り先情報'
        );

        if (this.registerForm.payment.use_owner_info === '1') {
          validator.checkRequire(
            'name_change',
            this.registerForm.payment.name,
            '請求書受け取り先名（会社名）'
          ) &&
            validator.checkMaxLength(
              'name_change',
              this.registerForm.payment.name,
              255,
              '請求書受け取り先名（会社名）'
            );

          validator.checkRequire(
            'postcode_change',
            this.registerForm.payment.postcode,
            '郵便番号'
          ) &&
            validator.checkMaxLength(
              'postcode_change',
              this.registerForm.payment.postcode,
              7,
              '郵便番号'
            ) &&
            validator.checkPostCode(
              'postcode_change',
              this.registerForm.payment.postcode,
              '郵便番号'
            );

          validator.checkRequire(
            'prefecture_id_change',
            this.registerForm.payment.prefecture_id,
            '都道府県'
          );

          validator.checkRequire(
            'city_change',
            this.registerForm.payment.city,
            '市区町村'
          ) &&
            validator.checkMaxLength(
              'city_change',
              this.registerForm.payment.city,
              255,
              '市区町村'
            );

          validator.checkRequire(
            'address_change',
            this.registerForm.payment.address,
            '丁目・番地・号'
          ) &&
            validator.checkMaxLength(
              'address_change',
              this.registerForm.payment.address,
              255,
              '丁目・番地・号'
            );

          validator.checkMaxLength(
            'building_name_change',
            this.registerForm.payment.building_name,
            255,
            'ビール・マンション'
          );

          validator.checkRequire('staff_name_change',this.registerForm.payment.staff_name,'担当者名') &&
            validator.checkMaxLength('staff_name_change',this.registerForm.payment.staff_name,255,'担当者名');

          validator.checkRequire('staff_name_kana_change', this.registerForm.payment.staff_name_kana, '担当者名（カナ）' ) &&
            validator.checkKatakana('staff_name_kana_change', this.registerForm.payment.staff_name_kana,'担当者名（カナ）') &&
            validator.checkMaxLength('staff_name_kana_change', this.registerForm.payment.staff_name_kana,255,'担当者名（カナ）');

          validator.checkMaxLength('department_name_change',this.registerForm.payment.department_name,255,'部署名');

          validator.checkRequire(
            'email_change',
            this.registerForm.payment.email,
            '連絡先メールアドレス'
          ) &&
            validator.checkEmail(
              'email_change',
              this.registerForm.payment.email,
              '連絡メールアドレス'
            );

          validator.checkRequire(
            'phone_change',
            this.registerForm.payment.phone,
            '電話番号'
          );
          if (this.registerForm.payment.phone) {
            const phone_change = this.registerForm.payment.phone;
            validator.checkTel('phone_change', phone_change, '電話番号') &&
              validator.checkMinLength(
                'phone_change',
                phone_change,
                10,
                '電話番号'
              ) &&
              validator.checkMaxLength(
                'phone_change',
                phone_change,
                11,
                '電話番号'
              );
            if (!validator.errors.phone_change) {
              validator.checkPhone(
                'phone_change',
                this.registerForm.payment.phone,
                '電話番号'
              );
            }
          }
        }
      }

      if (this.agencie_code === null || this.agencie_code === '') {
        this.errors = validator.errors;
      } else {
        validator.checkRequire(
          'agencie_code',
          this.registerForm.agency.code,
          '紹介者コードを入力'
        ) &&
          validator.checkMaxLength(
            'agencie_code',
            this.registerForm.agency.code,
            '紹介者コードを入力'
          );
        this.errors = validator.errors;
      }
    },
    goBack() {
      this.$store.commit('CLEAR_STATE_REGISTER');
      return this.$router.push('/');
    },
  },
};
</script>
<style lang="scss" scoped>
.btn_area_owner.control {
  width: 95%;
}
.radio_btn_owner {
  padding-left: 0;
}
.owner_padding_label {
  padding-left: 35px;
}
.owner_margin_buttom {
  margin-bottom: -6px;
}
@media screen and (max-width: 480px) {
  .radio_position_change {
    display: block;
    .radio_btn_owner {
      margin-top: 3px;
    }
  }
  .owner_margin_buttom {
    margin-bottom: -4px;
  }
}
</style>
