<template>
  <div>
    <h1 class="title_admin">{{ ocrDetail.restaurant_name }}</h1>
    <form action="" class="search_form search_form_admin">
      <carousel :navigation-enabled="true" :pagination-enabled="false" :per-page-custom="[[480, 4], [768, 5]]"
                class="mb_large"
      >
        <slide v-for="(ocr, index) in ocrList" :key="index" :class="selectedIndex === index ? 'active' : ''"
               class="slide-wrap"
        >
          <div class="ocr-image-wrapper">

            <img v-if="isFileImage(ocr.filepath) =='image'" :src="`${s3BucketUrl}/${ocr.filepath}`"
                 alt="img"
                 class="ocr-thumbnail"
                 @click="updateSelectedId(ocr.id,index)"
            >
            <div v-else class="uppercase" @click="updateSelectedId(ocr.id,index)">
              {{ isFileImage(ocr.filepath) }}
            </div>
            <div v-if="isOcrProcessed(ocr)"
                 :class="['slide-img-overlay', ocr['status']]"
                 @click="updateSelectedId(ocr.id,index)"
            />
          </div>
        </slide>
      </carousel>
      <div v-if="ocrDetail.supplier_id" class="form-row mb_large display-flex">
        <div>
          <label>発注先名</label>
          <div class="padding_text">{{ ocrDetail.supplier_name }}</div>
        </div>
        <div class="padding-left-20px">
          <label>発注方法</label>
          <div v-if="ocrDetail.supplier_media_sheet_class=='MediaSheet:Ryque'" class="padding_text">
            {{ ocrDetail.supplier_order_type }}(リクエ）フォーマット)
          </div>
          <div v-if="ocrDetail.supplier_order_type!='fax'" class="padding_text">
            {{ ocrDetail.supplier_order_type }}
          </div>
          <div v-if="ocrDetail.supplier_order_type=='fax' && ocrDetail.supplier_media_sheet_class=='MediaSheet:Smartrequest'" class="padding_text">
            {{ ocrDetail.supplier_order_type }}(SmartRequestデフォルト)
          </div>
        </div>
      </div>
      <div class="form-row mb_large break-work-all">
        <label>登録代行へのメッセージ</label>
        <div class="padding_text">{{ ocrDetail.note ? ocrDetail.note : "記載なし" }}</div>
      </div>
      <div class="form-row pre-content">
        <div class="uploaded_image form_50">
          <label class="form_label">アップロードされた画像</label>
          <img v-show="!!ocrDetail.filepath"
               v-if="isFileImage(ocrDetail.filepath) =='image'"
               :src="`${s3BucketUrl}/${ocrDetail.filepath}`"
               alt="img"
               @click="showOverlay=!showOverlay"
          >
          <a v-else :href="`${s3BucketUrl}/${ocrDetail.filepath}`" class="link">
            <span class="uppercase link">
              {{ isFileImage(ocrDetail.filepath) }}</span>をダウンロード
          </a>
        </div>
        <!--overlay zoom image-->
        <div v-if="showOverlay">
          <div id="overlay" />
          <div id="overlayContent">
            <img v-show="!!ocrDetail.filepath" :src="`${s3BucketUrl}/${ocrDetail.filepath}`"
                 alt="img"
            >
            <div class="icon close_large" @click="showOverlay=!showOverlay" />
          </div>
        </div>
        <div class="form_50 parsed-text-section">
          <div class="export-csv-container">
            <label class="form_label">
              取り込み結果
            </label>
            <button
              :class="!ocrDetail.parsed_text ? 'disabled' : ''"
              class="btn_admin admin_primary mr_medium"
              type="button" @click="exportParseTextToCsv"
            >CSVダウンロード
            </button>
          </div>
          <div class="parsed-text-outer-wrapper">
            <div class="parsed-text-wrapper">
              <div v-show="ocrDetail.parsed_text" class="parsed-text">
                <pre>{{ ocrDetail.plain_parsed_text }}</pre>
              </div>
            </div>
            <span class="clipboard-icon" @click="copyStringToClipboard(ocrDetail.plain_parsed_text)" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <label class="form_label form-20">アップロード内容</label>
        <div class="form_input flex-column form-50">
          <textarea id="import_text" v-model="ocrDetail.draft_parsed_text"
                    :class="isOcrProcessed(ocrList[selectedIndex]) ? 'disabled' : ''"
                    cols="15"
                    placeholder="アップロードする内容を入力"
                    rows="7"
          />
        </div>
      </div>
      <div style="margin-top:20px; background-color: #cccccc; font-size: 12px; padding: 10px;">
        [format]<br>
        必須{商品名}|必須{単位}|必須{価格}|{品番}|必須{カテゴリID}|{備考欄}|{商品補足情報1}|{商品補足情報2}<br>
        <br>
        カテゴリID<br>
        1:酒類（10%）<br>
        2:肉・卵・畜産物（8%）<br>
        3:魚・水産物（8%）<br>
        4:野菜・果物・農産物（8%）<br>
        5:加工食品（8%）<br>
        6:調味料・油・粉（8%）<br>
        7:その他（備品など）（10%）<br>
        8:設定なし（10%）<br>
        9:飲料品（8%）<br>
        10:料理酒、本みりん（10%）<br>
        <br>
        [sample]<br>
        テスト商品|個|100|TEST001|1|備考欄|補足情報1|補足情報2<br>
      </div>
      <span v-for="(error,index) in errors" :key="index" class="error-message">
        {{ error }}
      </span>
      <div v-if="ocrDetail.supplier_id" class="btn_area_admin btns">
        <button
          :class="isOcrProcessed(ocrList[selectedIndex]) ? 'disabled' : ''"
          class="btn_admin admin_primary"
          type="button" @click="saveToDraft"
        >保存
        </button>
        <button
          :class="isOcrProcessed(ocrList[selectedIndex]) ? 'disabled' : ''"
          class="btn_admin admin_primary mr_medium"
          type="button" @click="importParsedText"
        >インポートする
        </button>
        <button
          :class="isOcrProcessed(ocrList[selectedIndex]) ? 'disabled' : ''"
          class="btn_admin admin_primary mr_medium"
          type="button" @click="$openModal('itemImport')"
        >CSVインポート
        </button>
        <button
          :class="isOcrProcessed(ocrList[selectedIndex]) ? 'disabled' : ''"
          class="btn_admin delete"
          type="button" @click="$openModal('cancelModal');"
        >登録代行キャンセル
        </button>
      </div>
    </form>
    <div class="btn_area_admin btns">
      <router-link
        :to="{ name: 'ocr', params: {
          page: this.$route.params.page}}"
        class="btn_admin admin_primary_border"
      >戻る
      </router-link>
    </div>
    <cancel :ocr-id="selectedID" :selected-index="selectedIndex" @cancelledOcr="cancelledOcr" />
    <ImportModal :ocr-detail="ocrDetail" :selected-index="selectedIndex" @activeOcr="activeOcr" />
  </div>
</template>
<script>
import {Carousel, Slide} from 'vue-carousel';
import {OcrService} from '../../../services/admin/ocr.service';
import moment from 'moment';
import {SuppliersService} from '../../../services/admin/suppliers.service';
import Cancel from './components/Cancel.vue';
import utils from '../../../common/util';
import ImportModal from './components/ImportModal';

export default {
  name: 'OcrDetail',
  components: {
    Carousel,
    Slide,
    Cancel,
    ImportModal
  },
  props: {
    ocrParam: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      errors: null,
      selectedIndex: 0,
      selectedID: -1,
      showOverlay: false,
      ocrList: {},
      ocrDetail: {
        draft_parsed_text: null
      },
      draftParsedText: null,
      s3BucketUrl: null,
    };
  },
  async mounted() {
    this.s3BucketUrl = process.env.S3_BUCKET_BASE_URL;
    if (!this.ocrParam) {
      this.$router.push('/admin/ocr');
      return false;
    }
    await this.getOcrList();
  },
  methods: {
    isFileImage(file) {
      if (file) {
        var image_arr = ['png', 'jpg', 'jpeg'];
        var file_type = file.split('.').pop();
        if (image_arr.includes(file_type)) {
          return 'image';
        } else {
          return file_type;
        }
      } else {
        return 'image';
      }
    },

    async getOcrList() {
      const params = {
        owner_id: this.ocrParam.ownerId,
        restaurant_id: this.ocrParam.restaurantId,
        date: moment(this.ocrParam.createdAt, 'YYYY/MM/DD').format('YYYY/MM/DD')
      };
      try {
        const {response} = await OcrService.detail(params);
        this.ocrList = response.data;
        await this.getOcr(this.ocrList[0].id);
      } catch (error) {
        console.log(error);
      }
    },
    isOcrProcessed(ocr) {
      return ocr && (ocr['status'] === 'corrected_completed' || ocr['status'] === 'cancelled');
    },
    copyStringToClipboard(str) {
      let el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style = {position: 'absolute', left: '-9999px'};
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    resetData() {
      this.errors = null;
      this.$loading(false);
    },
    updateSelectedId(id, index) {
      this.selectedIndex = index;
      this.getOcr(id);
      this.errors = [];
    },
    changeMessageError() {
      var errors = [];
      this.errors.forEach(element => {
        element = element.replace('5文字以内で入力してください。','半角数字5桁で入力してください。');
        element = element.replace('5文字以上で入力してください。','半角数字5桁で入力してください。');
        errors.push(element);
      });
      this.errors = errors;
    },
    async getOcr(id) {
      try {
        const {response} = await OcrService.get(id);
        response.data['plain_parsed_text'] = '';
        if (!!response.data['parsed_text'] && response.data['parsed_text'].length > 0) {
          response.data['plain_parsed_text'] = JSON.parse(response.data['parsed_text']);
          response.data['plain_parsed_text'] = response.data['plain_parsed_text'].map(e => e.join(' | ')).join('\n');
        }
        this.ocrDetail = response.data;
        this.selectedID = this.ocrDetail.id;
      } catch (error) {
        console.log(error);
      }
    },
    async importParsedText() {
      if (this.ocrDetail.draft_parsed_text) {
        try {
          const params = {
            restaurant_upload_files_id: this.ocrDetail.id,
            corrected_text: this.ocrDetail.draft_parsed_text,
            supplier_id: this.ocrDetail.supplier_id,
          };
          const {response} = await SuppliersService.ocrItemImport(this.ocrDetail.restaurant_id, params);
          if (!!response && response.data.result.status === 'OK') {
            this.resetData();
            this.ocrList[this.selectedIndex]['status'] = 'corrected_completed';
          } else {
            if((typeof response.data.result.message) === 'string'){
              this.errors= [response.data.result.message];
            }else{
              this.errors = response.data.result.message;
            }
            this.changeMessageError();
          }
        } catch (error) {
          console.log(error);
        }

      } else {
        this.errors = ['アップロード内容を入力してください。'];
      }
      this.$loading(false);
    },
    async saveToDraft() {
      if (this.ocrDetail.draft_parsed_text) {
        this.$loading(true);
        const params = {
          id: this.ocrDetail.id,
          draft_parsed_text: this.ocrDetail.draft_parsed_text
        };
        const {response} = await OcrService.saveToDraft(params);
        if (response.data.result.status === 'OK') {
          this.$loading(false);
        } else {
          this.errors = response.data.result.message;
          this.$loading(false);
        }
        this.resetData();
      } else {
        this.errors = ['アップロード内容を入力してください。'];
      }
    },
    cancelledOcr(index) {
      this.ocrList[index]['status'] = 'cancelled';
    },
    async exportParseTextToCsv() {
      try {
        const params = {
          id: this.ocrDetail.id,
        };
        const {response} = await OcrService.csvExportParseText(params);
        utils.csvExport(`orc_${this.ocrDetail.restaurant_name}_${this.ocrDetail.supplier_name}`, response);
      } catch (error) {
        console.log(error);
      }

      this.$loading(false);
    },
    activeOcr(index) {
      this.ocrList[index]['status'] = 'corrected_completed';
    }
  }
}
;
</script>
<style lang="scss" scoped>
.padding_text {
  padding: 8px 16px;
}

.pre-content {
  display: flex;
  height: 500px;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: 15px;

  .uploaded_image {
    height: 96%;
    box-sizing: border-box;
    margin-right: 20px;

    img {
      max-height: 100%;
      object-fit: contain;
    }

  }

  .parsed-text-section {
    height: 100%;
    overflow: hidden;
    flex: 1;

    .parsed-text-outer-wrapper {
      position: relative;
      overflow: hidden;
      max-height: 96%;
      height: 96%;
      padding: 10px;
      border: 1px solid #dedede;
      display: flex;

      .parsed-text-wrapper {
        flex: 1;
        display: flex;
        overflow: auto;
      }

      .parsed-text-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.4);
        border-radius: 10px;
        background-color: #F5F5F5;
      }

      .parsed-text-wrapper::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
      }

      .parsed-text-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #9e9e9e;
      }


      pre {
        font-size: 12px;
        line-height: 1.7;
      }

      pre:first-child {
        margin-top: 0;
      }

      span.clipboard-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 30px;
        top: 15px;
        //icon added from _admin_icon.scss
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      span.clipboard-icon:hover {
        -webkit-transform: scale(1.3); /* Saf3.1+, Chrome */
        -moz-transform: scale(1.3); /* FF3.5+ */
        -ms-transform: scale(1.3); /* IE9 */
        -o-transform: scale(1.3); /* Opera 10.5+ */
        transform: scale(1.3);
      }

    }

  }

}

.slide-wrap {
  display: flex;

  .ocr-image-wrapper {
    position: relative;
    margin: 16px;

    .ocr-thumbnail {
      width: 100px;
      height: 100px;
    }
  }
}

.VueCarousel-slide {
  position: relative;
  display: flex;
  justify-content: center;
}

.VueCarousel {
  border-bottom: 1px solid #c7c0c0;
}

.VueCarousel .active::after {
  display: block;
  content: "";
  background: #6D8EFD;
  width: 60%;
  height: 2px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}


textarea.disabled {
  background: #f5f5f5;
  cursor: not-allowed;
  pointer-events: none;
}

.slide-img-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.42);
  width: 100%;
  height: 100%;
  top: 0;
}

.slide-img-overlay::before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d0d0d0;
  font-size: 40px;
}

.slide-img-overlay.corrected_completed::before {
  content: '\2713';
}

.slide-img-overlay.cancelled::before {
  content: '\2715';
}

.btn_area_admin {
  &.btns {
    max-width: unset;
  }
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #000;
  opacity: 0.7;
  filter: alpha(opacity=70) !important;
  z-index: 100;

}

#overlayContent {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  text-align: center;
  overflow: hidden;
  z-index: 100;
  transform: translate(-50%, -50%);

  img {
    height: 90vh;
    object-fit: contain;
  }
}

.close_large {
  position: absolute;
  top: 0;
  right: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.link {
  margin-top: 20px;
  display: inline-block;
}

.break-work-all {
  word-break: break-all;
}
.export-csv-container {
  display: flex;
  margin-bottom: 10px;
}
</style>