import { ResponseWrapper, ApiError } from './http';
import { BaseService } from './base.service';
export class RestaurantAdministratorsService extends BaseService {
  static get resourceEndpoint() {
    return 'restaurant_administrators';
  }

  static async updateStatus(id, data = {}) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/admin_cancel/${id}`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async cancel(id) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/cancel/${id || ''}`
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }

  static async freePeriodExtension(id) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/${id || ''}/extend_free_period`
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }

  static async csvSupplierImport(id, data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/${id}/suppliers/csv-import`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }
}
