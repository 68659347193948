<template>
  <div class="detail-modal">
    <!--Header-->
    <div class="modal-header adjust_title_space">
      <div class="row flex mb_0">
        <label class="small_title">登録⽇：{{ formatDate(restaurantAdministrator.created_at) }}</label>
        <label class="small_title ml_large">契約日：{{ formatDate(restaurantAdministrator.contract_start_at) }} </label>
        <div class="icon close" />
      </div>
      <div class="flex mb_small">
        <label class="small_title">契約更新日： {{ formatDate(restaurantAdministrator.contract_update_at) }} </label>
        <label class="small_title cancel ml_large">解約申請日：{{ formatDate(restaurantAdministrator.contract_cancel_at) }}</label>
        <label class="small_title cancel ml_large">解約日：{{ formatDate(restaurantAdministrator.contract_end_at) }}</label>
      </div>
      <div>
        <div class="restaurant_label">店舗アカウント</div>
        <div class="flex alignEnd">
          <div class="large_title">{{ restaurantAdministrator.shop_name }}</div>
          <div class="small_text ml_medium" :class="restaurantAdministrator.status == 'inactive' || restaurantAdministrator.status == 'admin_cancel' ? 'alert' : ''">
            {{ getRestaurantStatus(restaurantAdministrator.status) }}
          </div>
        </div>
        <label class="small_text">{{ restaurantAdministrator.shop_name_kana }}</label>
      </div>
    </div>
    <!--body-->
    <div class="modal-body">
      <div class="adjust_modal_space border_bottom">
        <div class="form-row margin-0">
          <label class="form_label form-20">店舗ID：</label>
          <div class="form_label flex-column form-50">
            {{ restaurantAdministrator.id }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">氏名：</label>
          <div class="form_label flex-column form-50">
            {{ restaurantAdministrator.name }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">氏名（カナ）： </label>
          <div class="form_label flex-column form-50">
            {{ restaurantAdministrator.name_kana }}
          </div>
        </div>
      </div>
      <div class="adjust_modal_space">
        <div class="form-row ">
          <label class="form_label form-20">住所： </label>
          <div class="form_label flex-column form-50">
            {{ restaurantAdministrator.postcode }} {{ restaurantAdministrator.full_address }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">電話番号： </label>
          <div class="form_label flex-column form-50">
            {{ restaurantAdministrator.phone }}
          </div>
        </div>
        <div class="form-row">
          <label class="form_label form-20">メールアドレス： </label>
          <div class="form_label flex-column form-50">
            {{ restaurantAdministrator.email }}
          </div>
        </div>
      </div>
      <div class="list_service-status">
        <table class="sr_ad_table">
          <thead class="">
            <tr>
              <th class="small border_radius_right">ステータス更新日</th>
              <th class="w_70">利用ステータス</th>
            </tr>
          </thead>
          <tbody v-if="checkEmptyStatus()">
            <tr v-for="service_status in restaurantAdministrator.data_status" :key="service_status.id">
              <td class="small">{{ formatDate(service_status.created_at) }}</td>
              <td class="change_table"
                  :class="service_status.status == 'inactive' || service_status.status == 'admin_cancel' ? 'alert' : ''"
              >
                {{ getRestaurantStatus(service_status.status) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-if="!checkEmptyStatus()">表示するデータはありません。</p>
    </div>
    <!--Footer-->
    <div class="modal-footer">
      <button class="btn_admin admin_primary"
              @click="$emit('updateModalType', 'edit')"
      >編集</button>
    </div>
  </div>
</template>
<script>

import utils from '../../../../common/util';
import {RestaurantAdministratorsService} from '../../../../services/admin/restaurant_administrators.service';
import moment from 'moment';
export default {
  props: {
    restaurantAdministrator: {
      type: Object,
      require: true,
    }
  },
  methods: {
    ...utils,
    subDate(date) {
      return moment(date, 'YYYY/MM/DD').subtract(1, 'days');
    },
    changeTextColor() {
      return ['inactive'].includes(this.restaurantAdministrator.status);
    },
    checkButtonActive() {
      return ['active'].includes(this.restaurantAdministrator.status);
    },
    checkEmptyStatus() {
      if (this.restaurantAdministrator.data_status != undefined ) {
        return this.restaurantAdministrator.data_status.length > 0 ? true : false;
      } else {
        return false;
      }
    },
    changeTextStatusColor(status) {
      return ['admin_cancel'].includes(status);
    },
    async popupConfirmUpdate(nameshop, status, status_code) {
      const params = {
        status: status_code,
      };
      const confirm = await this.$swal.fire({
        title: '確認',
        width: 600,
        html: `<div class='wrapper_popup'><p>店舗名「${nameshop}」を<span class='text_color-reb'>${status}</span>します。</p>
               <p>よろしいですか？</p></div>`,
        confirmButtonText: '決定',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonClass: 'btn_width_popup',
        cancelButtonClass: 'btn_width_popup',
      });

      if (confirm.isConfirmed) {
        try {
          await RestaurantAdministratorsService.updateStatus(this.$router.history.current.params.id, params);
          location.reload();
        } catch (error) {
          var message = '';
          if (status_code == 10) {
            message = '再開';
          } else {
            message = '停止';
          }
          this.popupWarning(message);
          console.log(error);
        }
      }
    }
  }
};
</script>
<style scoped>
.modal-body{
  max-height: 435px;
  overflow: scroll;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.border_bottom {
  border-bottom: 1px solid #E7EDF7;
}

.adjust_modal_space {
  padding: 24px 0px;
}

.adjust_title_space {
  padding: 24px 24px 0 24px;
}

.small_title {
  opacity: 0.5;
  font-size: 10px;
  font-weight: normal;
}
.small_title.cancel {
  color: #D52C2C;
}

.large_title {
  font-size: 24px;
  display: inline-block;
  max-width: calc(100% - 120px);
}

.row {
  align-items: center;
}
</style>