<template>
  <div class="owner_container ownermain_body">
    <div class="owner_payment wrapper_owner_have_button">
      <h1 class="title_owner mb_medium">支払管理</h1>
      <p class="text-desc mb_medium">
        クレジットカード情報の入力がキャンセルされました。
      </p>
      <p class="error-message fz-16">
        クレジットカード情報の入力がキャンセルされました。
      </p>
      <p class="note_sp mb_large">
        お手数ですが、「支払情報管理」より、再度クレジットカード情報の入力必をお願いいたします。
      </p>
      <div class="btn_area_owner three_items owner_btn_padding btn_area_wrapper">
        <button
          @click="backToPayment"
          class="btn btn_owner primary_border ml_medium cursor-pointer"
        >
          支払管理画面へ
      </button>
      </div>
    </div>
  </div>
</template>

<script>
import { OwnersService } from '../../../services/Owner/owners.service';

export default {
  async mounted() {
    await this.getOwners();
  },

  methods: {
    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    backToPayment() {
      return this.$router.push({ path: `/owner/payment`});
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
.owner_payment {
  margin: 40px;
}

.text-desc {
  color: rgb(219 23 21);
  font-weight: bold;
  font-size: 18px;
}

.fz-16 {
  font-size: 16px;
}

#body_wrapper_owner .wrapper_owner_have_button {
  position: relative;
  height: calc(100% - 80px);
  padding-bottom: unset;
}

.btn_area_wrapper {
  position: absolute;
  bottom: 0px;
  left: 24px;
  right: 24px;
  width: auto;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .owner_payment .btn_area_owner.three_items {
    margin: 0;
  }

  .btn_area_owner.three_items .btn {
    width: 90%;
  }

  .owner_payment {
    margin: 20px;
  }
}
</style>