import { MemosService } from '../../services/memos.service';
import moment from 'moment';

const memos = {
  state: {
    memos: []
  },
  mutations: {
    SET_MEMOS(state, payload) {
      state.memos = payload;
    }
  },
  actions: {
    async getMemos({ commit }) {
      try {
        const { response } = await MemosService.index();
        commit('SET_MEMOS', response.data);
      } catch (error) {
        commit('SET_MEMOS', []);
      }
    }
  },
  getters: {
    hasNewMemos: state => {
      return state.memos.filter(memo => moment(memo.created_at) >= moment().subtract(1, 'day')).length > 0;
    },
    getAllMemos: state => state.memos,
    isNoMemo: state => !state.memos || state.memos.length < 1
  }
};
export default memos;
