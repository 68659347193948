import TableTdMenuComponent from './../components/TableTdMenu';

let vm = {};

const optionsDefaults = {
  classToIgnore: 'td-menu-icon'
};

const TableTdMenu = {

  install(Vue, options = {}) {
    const property = '$tableTdMenu';
    const TableTdMenu = Vue.extend(TableTdMenuComponent);
    options = {...optionsDefaults, ...options};
    vm = new TableTdMenu({
      data: options
    }).$mount();
    // document.body.appendChild(vm.$el);

    vm.open = false;

    Vue.mixin({
      // Add components lifecycle hooks or properties.
      created() {
        document._global_table_menu_onClick ||= function(e) {
          if (document.getElementsByClassName('td-menu').length > 0) {
            if (!e.target.classList.contains(options.classToIgnore)) options.open = false;
          }
        };
        document.body.addEventListener('click', document._global_table_menu_onClick);
      }
    });

    Vue.prototype.$openTdMenu = function (elemEvent) {
      if (!!elemEvent && !!elemEvent.currentTarget) {
        elemEvent.target.closest('td').style.overflow = 'visible';
        elemEvent.target.parentElement.appendChild(vm.$el);
        options.open = true;
      }
    };

    Vue.prototype[property] = vm;
    Vue.prototype[property].menu = options.menu;
    Vue.prototype[property].classToIgnore = options.classToIgnore;

  }
};

export default TableTdMenu;

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(TableTdMenu);
}