<template>
  <main class="container bottomUp">
    <div class="main_area smp_content_main">
      <h1 v-if="view_status != 'LINE'" class="title mb_large">
        発注書を送信しました
      </h1>
      <h1 v-else class="title mb_large">
        LINEで発注書を送信する
      </h1>
      <div v-if="view_status != 'LINE'">
        【ご注意ください】<br>
        ・FAX送信の場合、送信完了までに時間がかかる場合や、混線等の影響で送信エラーになる場合がございます。<br>
        ・送信結果については必ず「発注履歴を確認する」からご確認をお願い致します。<br>
        ・ご登録メールアドレスに、発注書の控えを送信しておりますので併せてご確認をお願い致します。
      </div>
      <div v-else>
        LINEで発注書を送信します。<br>
        「LINEで送る」ボタンを押下するとLINEアプリが起動します。<br>
        「LINEアプリ」から送信したい友達を選択して送信してください。
      </div>
      <p v-if="share_status == 'line_doing'" class="no_share bold">
        未完了の発注があります。
      </p>
      <div v-if="view_status != 'FAX_MAIL'" class="line_list">
        <div v-for="order in orders" :key="order.id" class="line_list_area">
          <div>
            <dl>
              <dt>発注業社</dt>
              <dd>{{ order.supplier_name }}</dd>
            </dl>
          </div>
          <a v-if="line_share_status[order.id] == 'OK'" :href="order.line_url" class="btn line_share"
             target="_blank" @click="updateLineCompleted(order)"
          >LINEで送る</a>
          <a v-else class="btn line_share" @click="openDialogErr">LINEで送る</a>
        </div>
      </div>
      <div v-if="view_status != 'FAX_MAIL'" class="line_talk">
        ※トークへの送信が完了しているかはLINEのトーク履歴をご確認ください。
      </div>
    </div>
    <div class="control_area justCenter">
      <div class="btn_area control">
        <router-link to="/order_history" class="btn primary_border">
          発注履歴一覧へ
        </router-link>
      </div>
    </div>
    <!-- popup err -->
    <div class="bottom_menu" :class="dialogErr ? 'open' : ''">
      <h2 class="bottom_menu_title text_color-reb">
        エラー
      </h2>
      <div class="mb_medium">
        <p class="mb_small-left">発注書をアップロードしているか、共有期限が過ぎています。</p>
        <p class="mb_small mb_small-left mb_small-md">時間を置いてお試しいただくか、再度発注を行ってください。</p>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary_border" @click.native="closeDialogErr">閉じる</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialog || dialogErr ? 'open' : ''" />

  </main>
</template>

<script>

import { mapGetters } from 'vuex';
import { OrdersService } from '../../services/orders.service';
import utils from '../../common/util.js';
import { CartsService } from '../../services/carts.service';

export default {
  data() {
    return {
      orders: [],
      order_ids: '',
      share_status: '',
      line_share_status: [],
      view_status: 'FAX_MAIL',
      dialog: false,
      dialogErr: false
    };
  },
  computed: {
    ...mapGetters({
      orderedIds: 'getOrderedIds',
    }),
  },
  async created() {
    this.getOrders();
    await this.cartItem();
  },
  methods: {
    ...utils,
    getOrders: async function() {
      this.$loading(true);
      try {
        const params = {
          order_ids: this.orderedIds.join(','),
        };
        const { response } = await OrdersService.index(params);

        for (const orders of response.data) {
          orders.line_url = 'http://line.me/R/msg/text/?' + encodeURI('発注のご依頼 (' + orders.supplier_name + ' 様)発注書を添付いたします。\n' + orders.share_url + '\n');
          if(orders.status == 'line_doing') this.share_status = 'line_doing';
          if(orders.status == 'line_completed' || orders.status == 'line_doing') this.orders.push(orders);
          this.line_share_status[orders.id] = 'OK';
          //await this.linkLineShare(orders.id);
        }

        if(this.orders.length == 0){
          this.view_status = 'FAX_MAIL';
        }else if (this.orders.length == response.data.length){
          this.view_status = 'LINE';
        } else {
          this.view_status = 'LINE_FAX_MAIL';
        }
        this.$loading(false);
      } catch (error) {
        alert(error);
        this.$loading(false);
      }
    },

    async linkLineShare(order_id) {
      try {
        const { response } = await OrdersService.lineFileExits(order_id);
        this.line_share_status[order_id] = response.result.status;
      } catch (error) {
        this.line_share_status[order_id] = 'NG';
        console.log(error);
      }
    },

    async updateLineCompleted(order) {
      if (order.status  == 'line_doing') {
        try {
          await OrdersService.updateStatus(order.id);
          location.reload();
        } catch (error) {
          var message = '';
          this.popupWarning(message);
          console.log(error);
        }
      }
    },

    openDialogErr() {
      this.dialogErr = true;
    },

    closeDialogErr() {
      this.dialogErr = false;
      location.reload();
    },

    closeDialog() {
      this.dialog = false;
    },
    async cartItem() {
      const { response } = await CartsService.index();
      let count = 0;

      for (const cart_item of response.data) {
        count += cart_item.cart_items.length;
      }

      this.$store.dispatch('setCountItemSelected', count);
    },
  }
};
</script>
<style lang="scss" scoped>
.no_share {
  margin-top: 10px;
  color: #D52C2C;
}
.line_talk {
  text-align: center;
}
</style>