<template>
  <div id="body_wrapper_owner" class="body_owner" :class="addClass()">
    <Navbar
      v-if="login"
      :handle-sidebar="handleSidebar"
      :open-sidebar="openSidebar"
    />
    <Sidebar
      v-if="login"
      :open-sidebar="openSidebar"
      :hide-sidebar="hideSidebar"
      :handle-sidebar="handleSidebar"
      @open-popup="isPopup"
    />
    <div
      class="owner_sidebar_bg"
      :class="openSidebar ? 'open' : ''"
      @click="handleSidebar"
    />
    <router-view />
    <PopupOwnerExportCsv v-if="showDialog" @close-popup="isPopup" />
    <Footer :login="login" />
  </div>
</template>

<script>
import Vue from 'vue';
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';
import Navbar from './Navbar.vue';
import { mapGetters } from 'vuex';
import PopupOwnerExportCsv from './../../components/PopupOwnerExportCsv.vue';

export default Vue.extend({
  components: {
    Footer,
    Sidebar,
    Navbar,
    PopupOwnerExportCsv
  },
  data() {
    return {
      openSidebar: false,
      hideSidebar: false,
      divice: null,
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      login: 'getLoginOwnerFlag',
      prefectures: 'getAllPrefectures',
    }),
  },
  watch: {
    '$route' () {
      this.scrollToTop();
    }
  },
  async mounted() {
    const body = document.getElementsByTagName('BODY')[0];
    body.classList.add('class_html_owner');
    if (this.prefectures.length === 0) {
      await this.$store.dispatch('getPrefectures');
    }
    this.checkdevice();
    let that = this;
    window.onload = await function() {
      that.isresize();
      window.addEventListener('resize', that.isresize);
    };
  },
  created() {
    window.addEventListener('resize', this.addClass);
  },
  destroyed() {
    window.removeEventListener('resize', this.addClass);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    handleSidebar() {
      this.openSidebar = !this.openSidebar;
      if (this.openSidebar == false) {
        this.hideSidebar = true;
      } else {
        this.hideSidebar = false;
      }
    },
    checkdevice() {
      if (this.iOS_version() == undefined) {
        this.divice = 0;
      } else {
        let ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('android') > -1) {
          this.divice = 0;
        } else if (ua.indexOf('safari') != -1) {
          if (ua.indexOf('chrome') > -1 || /CriOS/i.test(navigator.userAgent)) {
            this.divice = 0;
          } else {
            this.divice = 1;
          }
        }
      }
    },
    iOS_version() { 
      if(navigator.userAgent.match(/ipad|iphone|ipod/i)){ //if the current device is an iDevice
        var ios_info ={};
        ios_info.User_Agent=navigator.userAgent;
        ios_info.As_Reported=(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0];
        ios_info.Major_Release=(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0];
        ios_info.Full_Release=(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].replace(/_/g,'.');
        ios_info.Major_Release_Numeric=+(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0].replace('OS ','');
        ios_info.Full_Release_Numeric=+(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].replace('_','.').replace('_','').replace('OS ','');   //converts versions like 4.3.3 to numeric value 4.33 for ease of numeric comparisons
        return(ios_info);
      }
    },
    addClass() {
      var classList = '';
      if (this.divice > 0) {
        if (this.iOS_version().Major_Release_Numeric < 15) {
          classList = 'body_owner_safari_before';
        } else {
          classList = 'body_owner_safari';
        }
      } else {
        classList = 'body_owner_chroom';
      }
      if (this.login) {
        classList = classList + ' body_owner-logged';
      }
      return classList;
    },
    checkmobile() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('android') > -1) {
        return true;
      } else {
        return false;
      }
    },
    isresize() {
      const wdth = window.screen.width;
      if (wdth < 480 && this.checkmobile()) {
        document.getElementById('body_wrapper_owner').style.minHeight = window.innerHeight + 'px';
      } else {
        if (wdth < 480 && (this.iOS_version().Major_Release_Numeric < 15)) {
          document.getElementById('body_wrapper_owner').style.minHeight = window.innerHeight + 'px';
        }
      }
    },

    isPopup(value) {
      this.showDialog = value;
      
    },
  },
});
</script>
<style lang="scss">
.body_owner_safari {
  @media screen and (max-width: 480px) {
    min-height: calc(100vh + 1px);
  }
}
.body_owner_safari_before {
  height: 100vh;
  @media screen and (max-width: 480px) {
    height: 100%;
    min-height: -webkit-fill-available;
  }
}
.body_owner_chroom {
  @media screen and (max-width: 480px) {
    height: 100%;
  }
}
</style>
