<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">お知らせ管理</h1>
      <a class="btn_admin admin_primary mt-28 mb_medium" href="#"
         @click="selectedId=-1;$openModal('createEditNotification')"
      >お知らせ作成</a>
    </div>
    <SearchForm :pagination-params="paginationParams" :search-params="searchParams"
                @clearSearch="clearSearch" @refreshTable="getNotifications"
    />
    <Table :notifications="notifications" :pagination-params="paginationParams" :selected-id="selectedId"
           @refreshTable="getNotifications" @updateCurrentPage="updateCurrentPage"
           @updateSelectedNotification="updateSelectedNotification"
    />
    <FormModals :notification-id="selectedId" @refreshTable="getNotifications"
                @updateSelectedNotification="updateSelectedNotification"
    />
  </div>
</template>

<script>
import {NotificationsService} from '../../../services/admin/notifications.service';
import FormModals from './components/FormModals';
import Table from './components/Table';
import SearchForm from './components/SearchForm';

export default {
  components: {
    SearchForm,
    FormModals,
    Table
  },
  data() {
    return {
      selectedId: -1,
      notifications: [],
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      searchParams: {
        status: ''
      },
    };
  },
  methods: {
    async getNotifications() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams, ...this.searchParams};

        const {response} = await NotificationsService.index(params);
        this.notifications = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    async clearSearch() {
      this.searchParams = {
        status: ''
      };
      await this.getNotifications();
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedNotification(id) {
      this.selectedId = id;
    },
  },
};
</script>
