<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">検索結果一覧</div>
      <div class="pagination admin-panigation">
        <paging
          v-if="notifications.length > 0"
          :total_count="notifications.length"
          :page="paginationParams.page"
          :total-page="paginationParams.totalPage"
          compact="true"
          @jumpPage="onPageChange"
        />
      </div>
    </div>
    <table class="sr_ad_table no_pointer_td">
      <thead class="">
        <tr>
          <th class="border_radius_right w_30">法⼈名</th>
          <th class="">公開日時</th>
          <th class="w_40">タイトル</th>
          <th class="">ステータス</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(no, index_i) in notifications">
          <tr :key="index_i">
            <td>
              <div class="flex">
                <div :class="!accordionTd[index_i] ? 'rotate-right' : ''" class="icon_arrow_down before"
                     @click="accordionTd[index_i]=!accordionTd[index_i]"
                />
                <span>{{ no.owner_name }}</span>
              </div>
            </td>
          </tr>
          <tr v-for="(rn, index_j) in JSON.parse(no['restaurant_notification'])" v-show="accordionTd[index_i]"
              :key="index_i+'-'+index_j" class="shop_name"
          >
            <td>{{ rn.shop_name }}</td>
            <td class="small">{{ formatDateMemoDate(rn.start_time) }}</td>
            <td class="change_table">{{ rn.title }}</td>
            <td class="change_table">{{ rn.status === 1 ? '公開中' : '未公開' }}</td>
            <td>
              <div style="position: relative; cursor: pointer;">
                <button class="icon_leader after ml-auto" @click="onTdMenuOpen($event,rn.id)" />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-show="notifications.length === 0" class="text-center">表示するデータはありません。</div>
    <div class="pagination admin-panigation">
      <paging
        v-if="notifications.length > 0"
        :page="paginationParams.page"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
  </div>
</template>

<script>

import Paging from '../../../../components/Pager';
import moment from 'moment';

export default {
  components: {
    Paging
  },
  props: {
    selectedId: {
      type: Number,
      require: true,
      default: -1
    },
    notifications: {
      type: Array,
      require: true
    },
    accordionTd: {
      type: Object,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true
    },
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    await this.$emit('refreshTable');
    this.$tableTdMenu.classToIgnore = 'icon_leader';
    this.$tableTdMenu.menu = [
      {
        'name': '編集する',
        'func': () => {
          this.$openModal('createEditNotificationIndividual');
        }
      }
    ];
    this.$emit('resetAccordion');
  },
  methods: {
    onTdMenuOpen(event, id) {
      this.$emit('updateSelectedNotification', id);
      this.$openTdMenu(event);
    },

    onPageChange(page) {
      this.$emit('resetAccordion');
      this.$emit('updateCurrentPage', page);
    },
    formatDateMemoDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  },
};
</script>