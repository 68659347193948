<template>
  <form id="notification-individual-form" action="">
    <div class="form-row">
      <label class="form_label form-20">宛先<span class="require">必須</span></label>
      <div class="form_label flex-column form-50">
        <vSelect
          v-model="params.restaurant_id"
          :options="restaurantAdministrators"
          :reduce="(option) => option.restaurant_id"
          class="v-select"
          label="shop_name"
          placeholder="宛先を選択してください"
        >
          <span slot="no-options">該当する店舗がありません</span>
        </vSelect>
        <span v-for="error in errors['selectedRestaurant']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label class="form_label form-20" for="start_time">公開⽇時<span class="require">必須</span></label>
      <div class="form_label flex-column form-50">
        <div class="input_wrapper calender">
          <div class="input-group">
            <flat-pickr
              id="start_time"
              v-model="params.start_time"
              :config="config"
              placeholder="公開日時を入力"
            />
          </div>
        </div>
        <span v-for="error in errors['startTime']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label class="form_label form-20" for="end_time">⾮公開⽇時</label>
      <div class="input_wrapper calender flex-column form-50">
        <div class="input-group">
          <flat-pickr
            id="end_time"
            v-model="params.end_time"
            :config="config_end"
            placeholder="非公開日時を入力"
          />
        </div>
      </div>
      <span v-for="error in errors['endTime']" :key="error" class="error-message">
        {{ error }}
      </span>
    </div>
    <div class="form-row">
      <label class="form_label form-20" for="title">タイトル<span class="require">必須</span></label>
      <div class="flex-column form-50">
        <input id="title" v-model="params.title" placeholder="タイトルを入力"
               type="text"
        >
        <span v-for="error in errors['title']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label class="form_label form-20" for="content">本⽂<span class="require">必須</span></label>
      <div class="form_input flex-column form-50">
        <textarea id="content" v-model="params.content"
                  cal cols="20" placeholder="本文を入力"
                  rows="7"
        />
        <span v-for="error in errors['content']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label class="form_label form-20" for="status">ステータス<span class="require">必須</span></label>
      <label class="radio_btn">公開
        <input id="status" v-model="params.status"
               :checked="true"
               type="radio"
               value="1"
        >
        <span class="checkmark" />
      </label>
      <label class="radio_btn">非公開
        <input v-model="params.status"
               type="radio"
               value="0"
        >
        <span class="checkmark" />
      </label>
      <span v-for="error in errors['status']" :key="error" class="error-message">
        {{ error }}
      </span>
    </div>
  </form>
</template>

<script>
import Validator from '../../../../validator';
import flatPickr from 'vue-flatpickr-component';
import {Japanese} from 'flatpickr/dist/l10n/ja.js';
import 'flatpickr/dist/flatpickr.css';
import {NotificationsService} from '../../../../services/admin/notifications.service';
import moment from 'moment';
import vSelect from 'vue-select';


export default {
  components: {
    flatPickr,
    vSelect
  },
  props: {
    notification: {
      type: Object,
      default: () => {
      }
    },
    restaurantAdministrators: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      params: {
        start_time: '',
        end_time: '',
        title: '',
        content: '',
        status: '1',
        restaurant_id: ''
      },
      errors: {},
      config: {
        enableTime: true,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d H:i',
        minDate: 'today',
      },
      config_end: {
        enableTime: true,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d H:i',
        minDate: 'today'
      }
    };
  },
  watch: {
    notification: function (newVal) {
      if (!newVal.content) {
        this.params = {status: 1};
        this.config.minDate = moment(Date.now()).format('YYYY-MM-DD');
        this.config_end.minDate = moment(Date.now()).format('YYYY-MM-DD');
        return;
      }
      this.params = {
        start_time: newVal.start_time,
        end_time: newVal.end_time,
        title: newVal.title,
        content: newVal.content,
        status: newVal.status === 'published' ? 1 : 0,
        restaurant_id: newVal.restaurant_id
      };
      this.config.minDate = moment(newVal.start_time).format('YYYY-MM-DD');
      this.config_end.minDate = moment(newVal.end_time).format('YYYY-MM-DD');
    },
    params: {
      handler() {
        this.config_end.minDate = moment(this.params.start_time).format('YYYY-MM-DD');
      },
      deep: true
    },
  },
  methods: {
    clearForm: async function(){
      this.params ={};
      this.$emit('updateSelectedNotification', -1);
    },
    handleSave: async function (notificationId) {
      this.validate(notificationId);

      if (Object.keys(this.errors).length) return;

      this.$loading(true);
      try {
        if (notificationId > 0) {
          await NotificationsService.update(notificationId, this.params);
        } else {
          await NotificationsService.create(this.params);
        }
        this.$emit('refreshTable');
        this.$closeModal('createEditNotificationIndividual');
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
      this.notification = {id: -1};
      this.$emit('updateSelectedNotification', -1);
    },
    validate(notificationId) {
      const validator = new Validator();
      const date_new = moment(new Date).format('YYYY-MM-DD');

      validator.checkRequire('startTime', this.params.start_time, '公開日時');
      if (!notificationId || notificationId < 0) validator.checkStartTimeBefore('startTime', date_new, this.params.start_time, '');

      if (this.params.end_time) {
        validator.checkDateTimeBefore('startTime', this.params.start_time, this.params.end_time, '');
      }

      validator.checkRequire('title', this.params.title, 'タイトル') &&
      validator.checkMaxLength('title', this.params.title, 255, 'タイトル');

      validator.checkRequire('content', this.params.content, '本文') &&
      validator.checkMaxLength('content', this.params.content, 10000, '本文');

      if (this.params.status) {
        validator.checkRequire('status', this.params.status, 'ステータス');
      }

      validator.checkRequire('selectedRestaurant', this.params.restaurant_id, '宛先');

      this.errors = validator.errors;
    }
  }
};

</script>

<style lang="scss" scoped>
#notification-individual-form {
  .v-select {
    width: 100%;
  }
}
</style>