import {BaseService} from './base.service';
import {ApiError, ResponseWrapper} from './http';

export class AnalysisService extends BaseService {
  static get resourceEndpoint() {
    return 'analysis';
  }

  static async exportCountOrder(data = {}) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/export_count_order`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async exportCountStatusOrder(data = {}) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/export_count_status_order`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
