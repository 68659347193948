const active_confirm = {
  state: {
    active_confirm: true,
  },
  mutations: {
    CHANGE_ACTIVE_CONFIRM(state, payload) {
      state.active_confirm = payload;
    },
  },
  actions: {
    changeActiveConfirm({ commit }, data) {
      try {
        commit('CHANGE_ACTIVE_CONFIRM', data);
      } catch (error) {
        commit('CHANGE_ACTIVE_CONFIRM', {});
      }
    },
  },
  getters: {
    getActiveConfirm: state => state.active_confirm,
  }
};

export default active_confirm;
  