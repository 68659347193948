<template>
  <div>
    <h1 class="title_admin">法人情報管理</h1>
    <SearchForm :search-params="searchParams"
                @clearSearch="clearSearch"
                @refreshTable="getOwner"
                @updateCurrentPage="updateCurrentPage"
    />
    <Table :owners="owners"
           :pagination-params="paginationParams"
           :owner-selected-id="selectedOwnerID"
           :restro-selected-id="selectedRestroID"
           @refreshTable="getOwner"
           @updateCurrentPage="updateCurrentPage"
           @updateSelectedOwner="updateSelectedOwner"
           @updateSelectedRestro="updateSelectedRestro"
           @updateModalType="updateModalType"
           @updateSelectedOwnerContractUpdateAt="updateSelectedOwnerContractUpdateAt"
    />
    <!--modal-->
    <OwnerModals :owner-id="selectedOwnerID"
                 :modal-type="modalType"
                 @refreshTable="getOwner"
                 @updateModalType="updateModalType"
                 @updateSelectedOwner="updateSelectedOwner"
    />
    <OwnerCancel :cancel-owner-id="selectedOwnerID"
                 @refreshTable="getOwner"
    />
    <RestModals :rest-id="selectedRestroID"
                :modal-type="modalType"
                @refreshTable="getOwner"
                @updateModalType="updateModalType"
    />
    <RestCancel :cancel-restro-id="selectedRestroID"
                @refreshTable="getOwner"
    />
    <OwnerFreePeriodExtension :free-owner-id="selectedOwnerID"
                              :contract-update-at="contractUpdateAt"
                              @refreshTable="getOwner"
    />
    <RestFreePeriodExtension :free-rest-id="selectedRestroID"
                              :contract-update-at="contractUpdateAt"
                              @refreshTable="getOwner"
    />
  </div>
</template>
<script>
import {OwnersService} from '../../../services/admin/owners.service';
import utils from '../../../common/util.js';
import SearchForm from './components/SearchForm';
import Table from './components/Table';
import OwnerModals from './components/Modals';
import OwnerCancel from './components/Cancel';
import RestModals from '../Restaurant/components/Modals';
import RestCancel from '../Restaurant/components/Cancel';
import OwnerFreePeriodExtension from './components/FreePeriodExtension';
import RestFreePeriodExtension from '../Restaurant/components/FreePeriodExtension';

export default {
  components: {
    OwnerModals,
    SearchForm,
    Table,
    OwnerCancel,
    RestModals,
    RestCancel,
    OwnerFreePeriodExtension,
    RestFreePeriodExtension
  },
  data() {
    return {
      modalType: null,
      selectedOwnerID: -1,
      selectedRestroID: -1,
      searchParams: {
        name: '',
        full_address: '',
        email: '',
        director_name: '',
        phone: '',
        is_deleted: false,
        status: '',
        shop_name: '',
      },
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0,
      },
      owners: [],
      errors: {},
      isHidden: {
        form: true,
        shop_name: true,
      },
      contractUpdateAt: null
    };
  },
  watch: {
    async page() {
      this.getOwner();
    }
  },
  async mounted() {
    this.getOwner();
  },
  methods: {
    ...utils,
    async getOwner() {
      try {
        this.$loading(true);
        let params = {...this.paginationParams, ...this.searchParams};
        const {response} = await OwnersService.index(params);
        this.owners = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedOwner(id) {
      this.selectedOwnerID = id;
    },
    updateSelectedRestro(id) {
      this.selectedRestroID = id;
    },
    updateModalType(type) {
      this.modalType = type;
    },
    searchOwner() {
      this.getOwner();
    },
    updateSelectedOwnerContractUpdateAt(contractUpdateAt) {
      this.contractUpdateAt = contractUpdateAt;
    },
    async clearSearch() {
      this.searchParams = { status: '' };
      await this.getOwner();
    }
  },
};
</script>