import { ResponseWrapper, ApiError } from './http';

import { BaseService } from './base.service';

export class SupplierItemsService extends BaseService {
  static get resourceEndpoint() {
    return 'items';
  }

  static async get() {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async csvExport(data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/csv_export`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}