<template>
  <div v-show="isActive" class="order_manage_area chanel_text_padding" :class="{ active: isActive }">
    <div class="flex mb_medium">
      <h2 class="title">
        商品管理
      </h2>
      <p class="title_supplier">発注先名：{{ supplier.name || supplier.supplier_official_name }}</p>
    </div>
    <div v-if="supplier.has_scheduled_order" class="status_text error">発注書の送信予約が設定されているため、編集できません</div>
    <div class="input_wrapper search mb_medium input_wrapper-sm">
      <input v-model="keyWord" type="text" class="form_search"
             placeholder="商品名で検索" @input="onChange"
      >
    </div>
    <paging
      v-if="items.length > 0"
      :page="pager.page"
      :total-page="pager.totalPage"
      :is_restaurant="true"
      styles="mt_medium"
      @jumpPage="onPageChange"
      style="margin-bottom: 20px;"
    />
    <ul class="no_mr">
      <li v-for="(item, index) in items" :key="index" :class="['item_list', supplier.has_scheduled_order ? '' : 'btn_point']"
          @click="editItem(item.id)"
      >
        <div class="item_list_wrapper">
          <div v-show="settingImage == true" v-lazy-container="{ selector: 'img' }" class="item_list_image">
            <img :data-src="item.img">
          </div>
          <div class="item_list_content" :class="settingImage == true ? '' : 'item_list_content-no_img'">
            <div class="item_list-title with_items_name">
              <div class="item_name item_list-name">{{ item.name }}</div>
            </div>
            <div class="sp_align sp_align_pc">
              <div class="item_list_content_input item_list_option">
                <p>単価（税込）</p>
                <div class="ml_small">{{ item.price }} 円</div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <paging
      v-if="items.length > 0"
      :page="pager.page"
      :total-page="pager.totalPage"
      :is_restaurant="true"
      styles="mt_medium"
      @jumpPage="onPageChange"
      style="margin-bottom: 20px;"
    />
    <div v-else class="text-center">表示するデータはありません。</div>
  </div>
</template>

<script>
import { ItemsService } from '../../../services/items.service';
import utils from '../../../common/util';
import _ from 'lodash';
import Paging from '../../../components/Pager';

export default {
  components: {
    Paging
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: Object,
      default: () => {}
    },
    action: {
      type: String,
      default: null
    },
    settingImage: {
      type: Boolean,
      require: true
    }
  },

  data() {
    return {
      items: [],
      keyWord: '',
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
    };
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    ...utils,
    async getItems() {
      this.$loading(true);
      try {
        const params = {
          supplier_id: this.$router.history.current.params.id,
          keyword: this.keyWord,
          page: this.pager.page,
          page_size: this.pager.pageSize,
        };
        const { response } = await ItemsService.index(params);
        this.items = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getItems();
      this.scrollTopScreen();
    },
    async sortItems() {
      await this.getItems();
    },
    onChange: _.debounce(function() {
      this.pager.page = 1;
      this.getItems();
    }, 500),
    editItem(item_id) {
      if (this.supplier.has_scheduled_order) return;

      this.$router.push({ path: `/order_company/${this.$router.history.current.params.id}/edit_item/${item_id}` });
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer !important;
}

.title {
  white-space: nowrap;
}

.title_supplier {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 24px;
  height: fit-content;
  align-self: flex-end;
}

.text_right {
  text-align: right;
}
.with_items_name {
  width: 72%;
}
.btn_point {
  cursor: pointer;
}
.item_list-name {
  width: 100% !important;
  word-break: break-all;
}
.sp_align_pc {
  width: 35% !important;
}
.item_list_option {
  display: grid !important;
  grid-template-columns: 1fr max-content !important;
  align-items: flex-end !important;
  justify-items: flex-end !important;
}

.ml_small {
  margin-left: 30px;
}
ul .item_list .item_list_image {
  width: 60px;
  height: 60px;
}
.item_list_image img {
  object-fit: scale-down!important;
}

@media screen and (max-width: 480px){
  .item_list_content {
    display: unset !important;
  }
  .with_items_name {
    width: 100% !important;
  }
  .item_list-name {
    padding-right: 0 !important;
  }
  ul .item_list .item_list_image {
    margin: 0 auto 0 !important;
    width: 40px;
    height: 40px;
  }
  .item_name {
    font-size: 12px;
    line-height: 1 !important;
    margin-bottom: 16px !important;
  }
  .sp_align_pc {
    width: unset !important;
  }
  .item_list_option {
    display: flex !important;
    font-size: 12px !important;
  }
  .title_supplier {
    font-size: 12px;
  }
  ul .item_list {
    padding: 16px;
  }
  ul .item_list {
    padding: 16px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .chanel_text_padding {
    padding: 100px 24px 0 24px;
  }
}
</style>