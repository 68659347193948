<template>
  <div v-show="open" ref="dropdown"
       class="td-menu dropdown"
  >
    <div class="dropdown-content">
      <a v-for="(m,i) in menu" :key="i" href="javascript:void(0);"
         :class="m['class']" @click="m['func']"
      >
        {{ m["name"] }}
      </a>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    menu: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      open: false,
      offset: {top: 0, left: 0}
    };
  },
};

</script>


<style scoped>

.td-menu.dropdown {
  position: absolute;
  background-color: #FFFFFF;
  width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 100%;
  margin-right: -22px;
  top:50%;
}

.td-menu.dropdown .dropdown-content {
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.td-menu.dropdown .dropdown-content a {
  color: #222222;
  line-height: 1;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.td-menu.dropdown .dropdown-content a:hover {
  background-color: #F5F9FF;
}

.td-menu.dropdown .dropdown-content a.alert {
  color: #D52C2C;
}

</style>
