<template>
  <div v-if="resetpassword" class="main_area_pc wrapper_loginid-reset owner_main_area">
    <div class="wrapper_owner_have_button">
      <div class="loginid-reset">
        <div class="title">オーナーIDをお忘れの方</div>
        <div class="content note_sp">登録の際に使用したメールアドレスを入力してください。
          入力いただいたメールアドレス宛に、オーナーIDをお送りさせていただきます。</div>
        <div class="form_row vertical mb_medium form_reset">
          <label for="" class="owner_form_label">
            メールアドレス<span class="require">必須</span>
          </label>
          <div class="input_wrapper">
            <input v-model="email" type="text" placeholder="">
            <span v-for="error in errors['email']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn_area_wrapper">
        <div class="button_wrapper">
          <router-link :to="{}" class="btn_area_owner btn_area-owner primary" @click.native="sendmail">
            送信する
          </router-link>
          <router-link :to="{}" class="btn_area_owner btn_area-owner btn_redirect-login" @click.native="login">
            ログイン画面へ
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="main_area_pc wrapper_loginid-reset owner_main_area">
    <div class="wrapper_owner_have_button">
      <div class="loginid-reset">
        <div class="title">オーナーIDをお忘れの方</div>
        <div class="content note_sp">オーナーIDをご入力いただいたメールアドレス宛に送付いたしました。<br>
          メールの内容をご確認ください。</div>
      </div>
      <div class="btn_area_wrapper">
        <div class="button_wrapper">
          <router-link :to="{}" class="btn_area_owner btn_area-owner btn_redirect-login btn_goback" @click.native="login">
            ログイン画面へ
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../validator';
import { OwnersService } from '../../services/owners.service';
import { PopupDisplayError} from '../../common/util';

export default {
  data () {
    return {
      resetpassword: true,
      email: '',
      errors: {}
    };
  },
  watch: {
    'resetpassword': {
      handler() {
        if (this.resetpassword) {
          document.title = 'オーナーID忘れ';
        } else {
          document.title = 'オーナーID忘れ 完了';
        }
      }
    },
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    async sendmail() {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = {
        email: this.email,
      };
      try {
        await OwnersService.resetid(params);
        this.resetpassword = false;
      } catch (error) {
        this.errors = { ...this.errors, email: [error.response.result.message] };
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('email', this.email, 'メールアドレス') &&
        validator.checkEmail('email', this.email, 'メールアドレス');
      this.errors = validator.errors;
    },
    login() {
      window.location.href = '/';
    },
  },
};
</script>
<style lang="scss" scoped>
 .body_owner {
   min-height: 100vh;
 }
</style>
