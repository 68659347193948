<template>
  <body class="body_admin">
    <main id="app" class="container_admin wrap">
      <div class="sp_menu">
        <div class="logo logo_admin" />
        <div class="sp_menu_trigger" />
      </div>
      <div class="sp_menu_bg" />
      <div class="admin_header">
        <div class="header_1 flex">
          <div class="logo" />
          <div class="right">
            <div class="icon_shevron_down after" @click="showLogOut=!showLogOut">{{ login_id }}</div>
            <div v-show="showLogOut" class="logout_admin btn_point" @click="logout">
              ログアウト
            </div>
          </div>
        </div>
        <ul class="header_2 flex">
          <li>
            <router-link
              :class="!$route.path.includes('/admin/notifications') &&
                !$route.path.includes('/admin/ocr') &&
                !$route.path.includes('/admin/supplier') &&
                !$route.path.includes('/admin/cancel-answers') &&
                !$route.path.includes('/admin/revision-histories') &&
                !$route.path.includes('/admin/agencies') &&
                !$route.path.includes('/admin/export_csv') ? 'router-link-exact-active' : ''"
              to="/admin"
            >
              法人情報管理
            </router-link>
          </li>
          <li>
            <router-link
              :class="$route.path.includes('/admin/ocr') ||
                $route.path.includes('/admin/ocr') ? 'router-link-exact-active' : ''"
              to="/admin/ocr"
            >
              申し込み一覧
            </router-link>
          </li>
          <li>
            <router-link
              :class="$route.path.includes('/admin/agencies') ? 'router-link-exact-active' : ''"
              to="/admin/agencies"
            >
              代理店一覧
            </router-link>
          </li>
          <li class="dropdown">
            <span>お知らせ</span>
            <div class="dropdown-content">
              <router-link
                :class="$route.path === '/admin/notifications' ? 'router-link-exact-active' : ''"
                to="/admin/notifications"
              >
                お知らせ
              </router-link>
              <router-link
                :class="$route.path ==='/admin/notifications-individual' ? 'router-link-exact-active' : ''"
                to="/admin/notifications-individual"
              >
                お知らせ 個別
              </router-link>
            </div>
          </li>
          <li>
            <router-link
              :class="$route.path.includes('/admin/revision-histories') ||
                $route.path.includes('/admin/revision-histories') ? 'router-link-exact-active' : ''"
              to="/admin/revision-histories"
            >
              改定履歴
            </router-link>
          </li>
          <li>
            <router-link
              :class="$route.path.includes('/admin/cancel-answers/export') ||
                $route.path.includes('/admin/cancel-answers/export') ? 'router-link-exact-active' : ''"
              to="/admin/cancel-answers/export"
            >
              解約アンケート
            </router-link>
          </li>
          <li>
            <router-link
              :class="$route.path.includes('/admin/analysis') ||
                $route.path.includes('/admin/analysis') ? 'router-link-exact-active' : ''"
              to="/admin/analysis"
            >
              分析
            </router-link>
          </li>
        </ul>
      </div>
      <div class="main_area_admin">
        <div class="main_content">
          <router-view />
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {AuthService} from '../services/admin/auth.service';

export default Vue.extend({
  components: {},
  data() {
    return {
      isShow: false,
      showLogOut: false,
      login_id: ''
    };
  },

  computed: {
    ...mapGetters({
      isLoadingPage: 'getLoadingStatus',
      prefectures: 'getAllPrefectures',
    })
  },

  async mounted() {
    if (this.prefectures.length === 0) {
      await this.$store.dispatch('getPrefectures');
    }
    this.login_id = localStorage.getItem('login_admin');
    if (!this.login_id) {
      this.login_id = '';
    }
  },

  methods: {
    isShowCart() {
      this.isShow = !this.isShow;
    }
    ,
    async logout() {
      await AuthService.logout();
      this.showLogOut = !this.showLogOut;
      return this.$router.push('/admin/login');
    }
  }
})
;
</script>
<style scoped>
body {
  position: relative;
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  border-radius: 5px;
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 150px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  font-size: 12px;
  color: #222222;
  padding: 12px 8px 12px 8px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #F5F9FF;
  border-radius: 5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.icon_shevron_down.after:after {
  margin-left: 8px !important;
}

</style>
