const select_tab = {
  state: {
    tab: null,
  },
  mutations: {
    SET_TAB(state, payload) {
      state.tab = payload;
    },
  },
  actions: {
    async changeTab({ commit }, data) {
      try {
        commit('SET_TAB', data);
      } catch (error) {
        commit('SET_TAB', {});
      }
    },
    async changeTabDefault({ commit }) {
      try {
        commit('SET_TAB', null);
      } catch (error) {
        commit('SET_TAB', {});
      }
    }
  },
  getters: {
    getTab: state => state.tab,
  }
};

export default select_tab;
