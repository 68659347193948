<template>
  <div>
    <div>
      <p class="note_sp">
        ご入力いただいた内容をご確認いただき、問題なければ、利用規約とプライバシーポリシーを確認後、「契約する」ボタンを押してください。
      </p>
      <h2 class="mt_medium">法人情報確認</h2>
      <div>
        <div class="input_owner">
          <label class="owner_form_label">オーナーID</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.login_id }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人名</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.name }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人名（カナ）</label>
          <div class="owner_margin_inline_10 owner_line_down">
            <p class="break-space">{{ registerForm.name_kana.trim() }}</p>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人代表者名</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.director_name }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">法人代表者名（カナ）</label>
          <div class="owner_margin_inline_10 owner_line_down">
            <p class="break-space">{{ registerForm.director_name_kana.trim() }}</p>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">担当者名</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.staff_name }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">担当者名（カナ）</label>
          <div class="owner_margin_inline_10 owner_line_down">
            <p class="break-space"></p>{{ registerForm.staff_name_kana.trim() }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">部署名</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.department_name }}
          </div>
        </div>
        <div class="input_owner width_input">
          <label class="owner_form_label">郵便番号</label>
          <div class="owner_margin_inline_10">{{ registerForm.postcode }}</div>
        </div>
        <div class="input_owner width_input">
          <label class="owner_form_label">都道府県</label>
          <div class="owner_margin_inline_10">{{ prefecture }}</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">市区町村</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.city }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">丁目・番地・号</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.address }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">ビル・マンション</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.building_name }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">連絡メールアドレス</label>
          <div class="owner_margin_inline_10 owner_line_down">
            {{ registerForm.email }}
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">電話番号</label>
          <div class="owner_margin_inline_10">{{ registerForm.phone }}</div>
        </div>
        <div class="input_owner">
          <h2 class="owner_margin_20">請求書受け取り先情報</h2>
          <p class="note_sp">ご入力いただいた内容をご確認いただき、問題なければ、利用規約を確認後、「契約する」ボタンを押してください。</p>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">お支払い方法</label>
          <div class="owner_margin_inline_10">
            {{ payment_type_name }}
          </div>
        </div>
        <div class="input_owner" v-if="registerForm.payment_type_id != '1'">
          <label class="owner_form_label">請求書受け取り方法</label>
          <div v-if="isCheckInvoiceSendType()" class="owner_margin_inline_10">
            メール
          </div>
          <div v-else class="owner_margin_inline_10">郵送</div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">請求書受け取り先情報</label>
          <div v-if="isCheckUserOwnerInfo()" class="owner_margin_inline_10">
            法人情報を使用する
          </div>
          <div v-else class="owner_margin_inline_10">別の情報を設定する</div>
        </div>
        <div v-if="!isCheckUserOwnerInfo()">
          <div class="input_owner">
            <label class="owner_form_label">請求書受け取り先名（会社名）</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">担当者名</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.staff_name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">担当者名（カナ）</label>
            <div class="owner_margin_inline_10 owner_line_down">
              <p class="break-space">{{ registerForm.payment.staff_name_kana.trim() }}</p>
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">部署名</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.department_name }}
            </div>
          </div>
          <div class="input_owner width_input">
            <label class="owner_form_label">郵便番号</label>
            <div class="owner_margin_inline_10">
              {{ registerForm.payment.postcode }}
            </div>
          </div>
          <div class="input_owner width_input">
            <label class="owner_form_label">都道府県</label>
            <div class="owner_margin_inline_10">{{ prefecture_change }}</div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">市区町村</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.city }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">丁目・番地・号</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.address }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">ビル・マンション</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.building_name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">メールアドレス</label>
            <div class="owner_margin_inline_10 owner_line_down">
              {{ registerForm.payment.email }}
            </div>
          </div>
          <div class="input_owner width_input">
            <label class="owner_form_label">電話番号</label>
            <div class="owner_margin_inline_10">
              {{ registerForm.payment.phone }}
            </div>
          </div>
        </div>
        <div class="owner_line_height_2">
          <label class="btn_checkbox position_checkbox">
            <input
              v-model="checked"
              type="checkbox"
              class="owner_padding_checkbox"
            >
            <span class="checkmark_checkbox" />
            <label class="owner_padding_label">
              <a v-if="checkDomain()" href="https://stg.bespo.tech/smartrequest/contract.html"
                 class="owner_color_blue text_decoration" target="_blank"
              >利用規約
              </a>
              <a v-else href="https://bespo.tech/smartrequest/contract.html"
                 class=" owner_color_blue text_decoration" target="_blank"
              >利用規約
              </a>
              と
              <a v-if="checkDomain()" href="https://stg.bespo.tech/privacy_policy.html"
                 class="owner_color_blue text_decoration" target="_blank"
              >プライバシーポリシー
              </a>
              <a v-else href="https://bespo.tech/privacy_policy.html"
                 class="owner_color_blue text_decoration" target="_blank"
              >プライバシーポリシー
              </a>
              に同意します。
            </label>
          </label>
          <label class="text_center">
            利用規約とプライバシーポリシーに同意後、契約開始となり、請求の対象となりますので必ずご確認ください。
          </label>
        </div>
      </div>
      <div class="btn_area_owner control two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn_owner primary"
          :class="checked === false || submitFormFlg ? 'disabled' : ''"
          @click.native="submitRegister"
        >
          契約する
        </router-link>
        <router-link
          :to="{}"
          class="btn_owner primary_border ml_medium"
          :class="submitFormFlg ? 'disabled' : ''"
          @click.native="goBack"
        >入力画面へ戻る</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { RegistationService } from '../../../../services/Owner/registation.service';
import utils from '../../../../common/util';
import { PaymentTypesService } from '../../../../services/Owner/payment_types.service';

export default {
  data() {
    return {
      checked: false,
      prefecture: null,
      prefecture_change: null,
      location: window.location,
      submitFormFlg: false,
      payment_types: [],
      payment_type_name: null
    };
  },
  computed: {
    ...mapGetters({
      registerForm: 'getRegisterForm',
      prefectures: 'getAllPrefectures',
    }),
  },
  async mounted() {
    await this.getListPaymentType();

    this.prefecture = this.getPrefectureName(this.prefectures, this.registerForm.prefecture_id);
    this.prefecture_change = this.getPrefectureName(this.prefectures, this.registerForm.payment.prefecture_id);
  },
  methods: {
    ...utils,
    async getListPaymentType() {
      try {
        const payment_type_id = this.registerForm.payment_type_id;
        const { response } = await PaymentTypesService.index();
        this.payment_types = response.data;

        const payment_type = this.payment_types.filter(function (el) {
          return el.id === payment_type_id
        });

        this.payment_type_name = payment_type[0].name;
      } catch (error) {
        console.log(error);
      }
    },
    isCheckInvoiceSendType() {
      return this.registerForm.payment.invoice_send_type === '0';
    },
    isCheckUserOwnerInfo() {
      if (this.registerForm.payment.use_owner_info === '0') {
        this.registerForm.payment.address = this.registerForm.address;
      }
      return this.registerForm.payment.use_owner_info === '0';
    },
    async submitRegister() {
      try {
        this.submitFormFlg = true;
        await RegistationService.submit(this.registerForm);
        this.$router.push('/complete_register');
      } catch (error) {
        this.submitFormFlg = false;
        console.log(error);
      }
    },
    goBack() {
      return this.$store.dispatch('setIsConfirm', false);
    },
    checkDomain() {
      return this.location.host.includes('stg.smartrequest') || this.location.protocol.includes('http:')  ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn_area_owner.control {
  width: 95%;
}
.btn_checkbox {
  padding-left: 0;
}
</style>
