<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <h1 class="title_owner">店舗詳細</h1>
      <div class="input_owner">
        <label class="owner_form_label">店舗名</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.shop_name }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名（カナ）</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          <p class="break-space">{{ restaurantAdministrator.shop_name_kana }}</p>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">氏名</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.name }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">氏名（カナ）</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          <p class="break-space">{{ restaurantAdministrator.name_kana }}</p>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">郵便番号</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.postcode }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">都道府県</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ getPrefectureName(prefectures, restaurantAdministrator.prefecture_id) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">市区町村</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.city }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">丁目・番地・号</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.address }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">ビル・マンション</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.building_name }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">メールアドレス</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.email }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">電話番号</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.phone }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗ID</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ restaurantAdministrator.login_id }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗パスワード</label>
        <div class="text_wrap pasword_text owner_color_black ml_small">{{ password }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">契約日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ formatDate(restaurantAdministrator.created_at) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">契約更新日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ showDate(restaurantAdministrator.contract_update_at) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">解約申請日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ showDate(restaurantAdministrator.contract_cancel_at) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">解約日</label>
        <div class="owner_margin_inline_10 owner_line_down" :class="changeTextColor() ? 'text_color-reb' : 'owner_color_black'">
          {{ showDate(restaurantAdministrator.contract_end_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">ステータス</label>
        <div class="owner_margin_inline_10 owner_line_down" :class="changeTextColor() ? 'text_color-reb' : 'owner_color_black'">
          {{ status[restaurantAdministrator.status] }}
        </div>
      </div>
      <div class="btn_area_owner owner_btn_padding" :class="setButtonArea">
        <router-link
          v-show="restaurantAdministrator.status !== 'inactive'"
          :to="{ path: `/owner/restaurant/${restaurantAdministrator.id}/edit` }"
          class="btn btn_owner primary"
        >
          編集する
        </router-link>
        <router-link
          to="/owner"
          class="btn btn_owner primary_border ml_medium"
        >
          戻る
        </router-link>
        <router-link
          v-show="['active', 'owner_cancel', 'admin_cancel'].includes(restaurantAdministrator.status) && restaurantAdministrator.contract_end_at == null"
          :to="{}"
          class="btn btn_owner delete ml_medium"
          @click.native="toDetele"
        >
          解約申請する
        </router-link>
        <router-link
          v-show="['active', 'owner_cancel', 'admin_cancel'].includes(restaurantAdministrator.status) && restaurantAdministrator.contract_end_at != null"
          :to="{}"
          class="btn btn_owner delete ml_medium unsubscribe-cancel"
          @click.native="unsubscribeCancel"
        >
          解約申請の取消をする
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { RestaurantAdministratorsService } from '../../../services/Owner/restaurant_administrators.service';
import { mapGetters } from 'vuex';
import utils from '../../../common/util';

export default {
  data() {
    return {
      restaurantAdministrator: {},
      password: '********',
      status: {
        active: '利用中',
        admin_cancel: '利用停止中',
        owner_cancel: '利用中',
        inactive: '解約済',
      },
      dialog:false,
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
    setButtonArea() {
      if (this.restaurantAdministrator.status === 'inactive') {
        return '';
      } else if (this.restaurantAdministrator.status === 'owner_cancel') {
        return 'two_items';
      } else {
        return 'three_items';
      }
    },
  },
  created() {
    this.getRestaurantAdministrator();
  },
  methods: {
    ...utils,
    changeTextColor() {
      return ['admin_cancel', 'inactive'].includes(this.restaurantAdministrator.status);
    },
    async getRestaurantAdministrator() {
      this.$loading(true);
      try {
        const { response } = await RestaurantAdministratorsService.get(this.$router.history.current.params.id);
        this.restaurantAdministrator = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    showDate(date) {
      return date !== null ? this.formatDateWithoutLeadingZeros(date) : '';
    },
    async toDetele() {
      if (this.restaurantAdministrator.is_cancel) {
        return this.$router.push({ path: `/owner/restaurant/${ this.restaurantAdministrator.id }/delete` });
      } else {
        await this.$swal.fire({
          title: '削除ができません。',
          html: `<p>解約可能の期間が過ぎています。</p>
                <p>お手数ですが、翌月の1日に解約を実行してください。</p>`,
          cancelButtonText: '閉じる',
          showCloseButton: false,
          showConfirmButton: false,
          showCancelButton: true,
          customClass: {
            cancelButton: 'cancel-popup-owner',
            title: 'title-popup-owner',
            htmlContainer: 'html-popup-owner'
          }
        });
      }
    },

    popupCheckDelete(errorMessage) {
      this.$swal.fire({
        title: 'アラート',
        html: `<p>${errorMessage}</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },

    async unsubscribeCancel() {
      let textContentPopup;
      if(this.restaurantAdministrator.is_unsubscribe_cancel) {
        textContentPopup = `<p>解約申請の取消を行います。</p>
                              <p>よろしいですか。</p>`;
      } else {
        textContentPopup = `<p>解約申請の取消を行います。</p>
                              <p>解約申請の取消を行った場合、再度解約申請を行うことが可能になるのは翌月になります。よろしいですか？</p>`;
      }

      const confirm = await this.$swal.fire({
        title: '確認',
        html: textContentPopup,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });

      try {
        if(confirm.isConfirmed) {
          await RestaurantAdministratorsService.unsubscribeCancel({id: this.$router.history.current.params.id});
          window.location.reload();
        }
      } catch (error) {
        this.popupCheckDelete(error.response.result.message);
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .pasword_text {
    letter-spacing: 5px;
    font-size: 20px;
  }

  .unsubscribe-cancel {
    color: #ac0000;
    background: #fff;
    border: 2px solid #ac0000;
  }
</style>
