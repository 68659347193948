<template>
  <div class="admin_table">
    <div class="admin_table_top">
      <div class="table-title">発注先⼀覧</div>
      <paging
        v-if="suppliers.length > 0"
        :total_count="suppliers.length"
        :page="paginationParams.page"
        :styles="'admin-panigation'"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
    <table class="sr_ad_table">
      <thead class="">
        <tr>
          <th class="w_15 border_radius_right">登録日付</th>
          <th class="w_45">発注先名</th>
          <th class="small">登録商品数</th>
          <th class="w_5 border_radius_left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(supplier, index) in suppliers"
            :key="index"
            class="btn_point"
        >
          <td class="small" @click="openDetail($event, supplier)">{{ formatDateMemoDate(supplier.created_at) }}</td>
          <td class="change_table" @click="openDetail($event, supplier)">{{ supplier.name ? supplier.name : supplier.supplier_official_name }}</td>
          <td class="small" @click="openDetail($event, supplier)">{{ supplier.count }}</td>
          <td>
            <div style="position: relative; cursor: pointer;">
              <button class="icon_leader after ml-auto" @click="onTdMenuOpen($event, supplier)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-show="suppliers.length === 0" class="text-center">表示するデータはありません。</div>
    <div class="pagination pagination_admin">
      <paging
        v-if="suppliers.length > 0"
        :page="paginationParams.page"
        :styles="'admin-panigation'"
        :total-page="paginationParams.totalPage"
        compact="true"
        @jumpPage="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { SuppliersService } from '../../../../services/admin/suppliers.service';
import Paging from '../../../../components/Pager';
import moment from 'moment';

export default {
  components: {
    Paging,
  },
  props: {
    selectedId: {
      type: Number,
      require: true,
      default: -1
    },
    suppliers: {
      type: Array,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      restaurant_administrator_id: this.$router.history.current.params.id,
    };
  },
  watch: {
    paginationParams: {
      async handler() {
        await this.$emit('refreshTable');
      },
      deep: true
    },
  },
  async mounted() {
    this.$tableTdMenu.classToIgnore = 'icon_leader';
  },
  methods: {
    openDetail(event, supplier) {
      this.$emit('updateSelectedSupplier', supplier.id);
      this.$openModal('supplierForm');
    },
    onTdMenuOpen(event, supplier) {
      this.$tableTdMenu.menu = [
        {
          'name': '編集する',
          'func': () => {
            this.$emit('updateSelectedSupplier', supplier.id, true);
            this.$openModal('supplierForm');
          }
        },
        {
          'name': '商品管理',
          'func': () => {
            this.$router.push({ path: `/admin/restaurant_admin/${this.restaurant_administrator_id}/suppliers/${supplier.id}/items` });
          }
        },
        {
          'name': '削除する',
          'class': 'alert',
          'func': () => {
            this.popupConfirmDelete(supplier);
          }
        }
      ];
      this.$openTdMenu(event);
    },
    onPageChange(page) {
      this.$emit('updateCurrentPage', page);
    },
    formatDateMemoDate(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    async popupConfirmDelete(supplier) {
      const confirm = await this.$swal.fire({
        title: '削除確認',
        html: `<p>発注先を<span class='text_color-reb'>削除</span>します。</p>
               <p>よろしいですか？</p>`,
        confirmButtonText: '決定',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonClass: 'btn_admin admin_primary',
        cancelButtonClass: 'btn_admin admin_primary_border',
      });

      if (confirm.isConfirmed) {
        try {
          await SuppliersService.delete(supplier.id);
          this.$emit('updateSelectedSupplier', null);
        } catch (error) {
          console.log(error);
          const errorMessage = error.response.result.message[0];
          await this.$swal.fire({
            title: 'エラー',
            width: 600,
            html: `<p>${errorMessage}</p>`,
            confirmButtonText: '閉じる',
            confirmButtonClass: 'btn_admin admin_primary_border',
            showCloseButton: false,
          });
        }
      }
      this.$emit('refreshTable');
    },
  },
};
</script>