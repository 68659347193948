import { BaseService } from './base.service';
import { ResponseWrapper, ApiError } from './http';

export class OrderReportsService extends BaseService {
  static get resourceEndpoint() {
    return 'order_reports';
  }

  static async searchSupplierOrder(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/search_supplier_order`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async searchItemCategoryOrder(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/search_item_category_order`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async searchItemOrderDetail(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/search_item_order_detail`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async getAllSuppliers(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/suppliers`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
