<template>
  <div>
    <div class="bottom_menu" :class="showDialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_large">
        <div>過去に同じファイル名の画像をアップロードしています。</div>
        <div>よろしいですか？</div>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary" @click.native="$emit('uploadDuplicateImage')">送信する</router-link>
        <router-link :to="{}" class="btn primary_border ml_medium" @click.native="disableShowDialog">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="showDialog ? 'open' : ''" />
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function
    },
  },
  methods: {
    disableShowDialog() {
      this.closeDialog();
    }
  }

};
</script>

<style lang="scss" scoped>
.bottom_menu_title {
  color: #D52C2C;
}
</style>