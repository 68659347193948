import { BaseService } from './base.service';
import { ResponseWrapper, ApiError } from './http';

export class OrdersService extends BaseService {
  static get resourceEndpoint() {
    return 'orders';
  }
  
  static async getDetails(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/details`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async getCheckOrders(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/check_orders`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async confirm(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/confirmation`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }

  static async updateStatus(id) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/line_complete/${id}`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async lineFileExits(id) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/line_file_exits/${id}`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async cancel(id) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/${id}/cancel`
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async csvExport(params) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/csv_export`,
        {params}
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
