<template>
  <main class="owner_container ownermain_body">
    <div class="owner_main_area">
      <div class="wrapper_owner_have_one_button">
        <h1 class="owner_text_title">支払管理</h1>
        <p class="note_sp mt_medium">
          情報の変更後、反映にお時間がかかる場合がございます。ご注意ください。
        </p>
        <div class="">
          <div class="input_owner">
            <label class="owner_form_label">お支払い種別</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ owner.payment_type }}
              <span v-if="payment.flg_card_create && owner.payment_type_id == 1" class="text_red">カード情報未登録</span>
            </div>
          </div>
          <div class="input_owner" v-if="owner.payment_type_id == 1 && !payment.flg_card_create">
            <label for="orderType" class="owner_form_label">クレジットカード番号</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black" v-for="card in payment.list_cards" :key="card.id">
              <p>{{ card.card_no }}</p>
            </div>
          </div>
          <div class="input_owner" v-if="owner.payment_type_id == 1 && !payment.flg_card_create">
            <label for="orderType" class="owner_form_label">クレジットカードブランド</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black"  v-for="card in payment.list_cards" :key="card.id">
              {{ card.brand }}
            </div>
          </div>
          <div class="input_owner" v-if="owner.payment_type_id == 1 && !payment.flg_card_create">
            <label for="orderType" class="owner_form_label">クレジットカード　有効期限</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black"  v-for="card in payment.list_cards" :key="card.id">
              {{ convertStringToDate(card.expire) }}
            </div>
          </div>
          <div class="input_owner" v-if="owner.payment_type_id == typeCredit && (fincodeOwnerSubscriptionsPresent.length > 0 ||
                                                                                 fincodeOwnerSubscriptionsFuture.length > 0)">
            <label for="orderType" class="owner_form_label">ご契約中のプラン</label>
            <div v-for="fincodeOwnerSubscription in fincodeOwnerSubscriptionsPresent" :key="fincodeOwnerSubscription.id">
              <div class="owner_margin_inline_10 owner_line_down owner_color_black">
                {{ fincodeOwnerSubscription.plan_name }}
              </div>
              <div class="owner_margin_inline_10 owner_line_down owner_color_black">
                ご請求開始月： {{ formatDateJapan(fincodeOwnerSubscription.start_date) }}
              </div>
              <div class="owner_margin_inline_10 owner_line_down owner_color_black">
                ご請求額： {{ fincodeOwnerSubscription.amount }}円
              </div>
            </div>  
          </div>
          <div class="input_owner" v-if="owner.payment_type_id == typeCredit && (fincodeOwnerSubscriptionsPresent.length > 0 ||
                                                                                 fincodeOwnerSubscriptionsFuture.length > 0)">
            <label for="orderType" class="owner_form_label">変更申請中のプラン</label>
            <div v-for="fincodeOwnerSubscription in fincodeOwnerSubscriptionsFuture" :key="fincodeOwnerSubscription.id">
              <div class="owner_margin_inline_10 owner_line_down owner_color_black">
                {{ fincodeOwnerSubscription.plan_name }}
              </div>
              <div class="owner_margin_inline_10 owner_line_down owner_color_black">
                ご請求開始月： {{ formatDateJapan(fincodeOwnerSubscription.start_date) }}
              </div>
              <div class="owner_margin_inline_10 owner_line_down owner_color_black">
                ご請求額： {{ fincodeOwnerSubscription.amount }}円
              </div>
            </div>
          </div>
          <div class="input_owner" v-if="owner.payment_type_id == typeCredit && (fincodeOwnerSubscriptionsPresent.length == 0 &&
                                                                                 fincodeOwnerSubscriptionsFuture.length == 0) && owner.payment_type_id == 1 && !payment.flg_card_create">
            <label for="orderType" class="owner_form_label">ご契約のプラン</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              契約プラン情報を取得しています・・・
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">請求書受け取り方法</label>
            <div
              v-if="invoice_send_type === 0"
              class="owner_margin_inline_10 owner_color_black"
            >
              メール
            </div>
            <div v-else class="owner_margin_inline_10 owner_color_black">
              郵送
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">請求書受け取り先情報</label>
            <div
              v-if="use_owner_info === false"
              class="owner_margin_inline_10 owner_color_black"
            >
              法人情報を使用する
            </div>
            <div v-else class="owner_margin_inline_10 owner_color_black">
              別の情報を設定する
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">請求書受け取り先名（会社名）</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">担当者名</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.staff_name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">担当者名（カナ）</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              <p class="break-space">{{ payment.staff_name_kana }}</p>
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">部署名</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.department_name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">郵便番号</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.postcode }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">都道府県</label>
            <div class="owner_margin_inline_10 owner_color_black">
              {{ getPrefectureName(prefectures, payment.prefecture_id) }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">市区町村</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.city }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">丁目・番地・号</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.address }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">ビル・マンション</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.building_name }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">連絡先メールアドレス</label>
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">
              {{ payment.email }}
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">電話番号</label>
            <div class="owner_margin_inline_10 owner_color_black">
              {{ payment.phone }}
            </div>
          </div>
        </div>
        <div class="btn_area_owner owner_btn_padding owner_button">
          <router-link
            :to="{}"
            class="btn primary btn_owner btn_border_radius"
            @click.native="redirectEditPaymnet"
          >編集する</router-link>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { PaymentsService } from '../../../services/Owner/payments.service';
import { mapGetters } from 'vuex';
import utils from '../../../common/util';
import { TYPE_CREDIT } from '../../../common/variables';
import moment from 'moment';

export default {
  data() {
    return {
      payment: {},
      invoice_send_type: null,
      use_owner_info: null,
      owner: {},
      typeCredit: TYPE_CREDIT,
      fincodeOwnerSubscriptionsFuture: [],
      fincodeOwnerSubscriptionsPresent: []
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
  },
  async mounted() {
    await this.getInfoPayment();
  },
  methods: {
    ...utils,
    async getInfoPayment() {
      try {
        const { response } = await PaymentsService.index();
        this.owner = response.owner;
        this.payment = response.data;
        this.payment_type_id = response.owner.payment_type_id;
        this.invoice_send_type = response.data.invoice_send_type;
        this.use_owner_info = response.data.use_owner_info;
        if (this.payment_type_id == this.typeCredit) {
          this.fincodeOwnerSubscriptionsFuture = response.fincode_owner_subscriptions_future
          this.fincodeOwnerSubscriptionsPresent = response.fincode_owner_subscriptions_present
        }
      } catch (error) {
        console.log(error);
      }
    },
    redirectEditPaymnet() {
      return this.$router.push('/owner/payment/edit');
    },
    convertStringToDate(str) {
      return moment(str, "YYMM").format("YYYY/MM");
    }
  },
};
</script>
<style lang="scss" scoped>
.btn_area_owner.control {
  width: 95%;
}
a {
  color: blue;
}
.text_red {
  color: red;
  font-weight: bold;
}
.break-space {
  white-space: break-spaces !important;
}
</style>
