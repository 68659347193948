<template>
  <main class="owner_main_area main_area_pc main_password_setting">
    <Continue step="3" />
    <Setting />
  </main>
</template>

<script>
import Setting from './components/Setting.vue';
import Continue from '../../../components/CompleteStep.vue';

export default {
  components: {
    Setting,
    Continue
  },
};
</script>
<style lang="scss" scoped>

</style>