<template>
  <main class="container wrapper-registerinfo">
    <div class="wrapper_complete">
      <div class="main_logo mb_large main_logo-owner" />
      <div class="main_area">
        <div>
          <h1 class="title mb_large">エラー</h1>
          <p>アクセス方法は正しくありません。</p>
          <p>「ホームに戻る」ボタンを押して、ホームに戻るか、管理者へお問い合わせください。</p>
        </div>
        <div class="btn_area_owner control">
          <router-link :to="{}" class="btn_owner primary_border" @click.native="btn_continue">ホームに戻る</router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    btn_continue() {
      window.location.href = '/';
    }
  }
};
</script>
<style scoped>
.container {
  margin: 10px auto !important;
  max-width: 960px;
}
.wrapper_complete {
  padding-top: 30px;
}
.btn_area_owner.control {
  margin-top: 90px;
  max-width: 250px;
}
</style>
