<template>
  <div class="box">
    <div class="tag-container">
      <input class="input_mutiple" placeholder="メールアドレス" @keyup="tagkeyUpChange" @blur="clickOutInput"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TagInput',
  props: {
    supplierEmails: {
      type: Array,
    },
    changeValueEmails: {
      type: Boolean,
    },
    supplierId: {
      type: Number,
    }
  },
  data () {
    return {
      supplierEmailsEdit: [],
      removeItem: null,
      tagContainer: document.querySelector('.tag-container'),
      input: document.querySelector('.tag-container input'),
      enter: false,
      email: null,
    }
  },
  watch: {
    changeValueEmails: {
      handler() {
        this.$nextTick(() => {
          this.tagContainer = document.querySelector('.tag-container')
          this.input = document.querySelector('.tag-container input')
          this.input.focus()
          if (this.supplierId) {
            this.supplierEmailsEdit = this.supplierEmails.map((supplierEmail) => {
              return {
                id: supplierEmail.id,
                email: supplierEmail.email,
                _destroy: false,
              }
            });
            this.addTags();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    supplierEmailsEdit: function (val) {
      this.$emit('getInputs', {supplierEmailsEdit: val})
      return val;
    },
  },
  methods: {
    createTag(label, _destroy, dataIndex) {
      const div = document.createElement('div')
      _destroy ? div.setAttribute('class', 'tag_multiple_email_admin hidden') : div.setAttribute('class', 'tag_multiple_email_admin')
      const span = document.createElement('span')
      span.innerHTML = label
      const closeIcon = document.createElement('span')
      closeIcon.innerHTML = 'x'
      closeIcon.setAttribute('class', 'span_x_multiple_email')
      closeIcon.setAttribute('arial-hidden', 'true')
      closeIcon.setAttribute('data-item', label)
      closeIcon.setAttribute('data-index', dataIndex)
      closeIcon.addEventListener('click', this.removeEmail)
      div.appendChild(span)
      div.appendChild(closeIcon)
      return div
    },

    clearTags() {
      document.querySelectorAll('.tag_multiple_email_admin').forEach(tag => {
        tag.parentElement.removeChild(tag)
      })
    },

    addTags() {
      this.clearTags()
      let dataIndex = 0;
      this.supplierEmailsEdit.slice().reverse().forEach(tag => {
        this.tagContainer.prepend(this.createTag(tag.email, tag._destroy,dataIndex++));
      })
    },
    tagkeyUpChange(e) {
      if (e.target.value === '') {
        return
      }
      else if (e.target.value) {
        this.email = e.target.value;
      }
      if ((e.key === 'Enter' || this.enter) && this.email) {
        if (this.lengthOfSupplierEmails() >=  10)
          return;
        let emailInput = this.email
        this.email = null
        let supplierEmail = {
          email: emailInput,
          _destroy: false,
        }
        this.supplierEmailsEdit.push(supplierEmail);
        this.addTags()
        this.input.value = ''
      }
    },
    lengthOfSupplierEmails() {
      let lenght = 0;
      this.supplierEmailsEdit.forEach(supplierEmail=> {
        if (!supplierEmail._destroy) {
          lenght++;
        }
      })
      return lenght;
    },
    clickOutInput(e) {
      this.enter = true;
      this.tagkeyUpChange(e);
      this.enter = false;
    },
    removeEmail(e) {
      if (e.target.tagName === 'SPAN') {
        const dataIndex = e.target.getAttribute('data-index')
        let dataIndexSearch = 0;
        this.supplierEmailsEdit.slice().reverse().forEach(tag => {
          if (dataIndex == dataIndexSearch++) {
            tag._destroy = true;
          }
        })
        this.addTags()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tag-container {
  overflow-y: auto;
  max-height: 150px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  padding: 6px;
  min-height: 55px;
}
.tag-container input {
  padding: 5px;
  font-size: 20px;
  border: 0 !important;
  outline: none;
  font-family: 'Rubik';
  color: #333;
  flex: 1;
  margin: 0;
}
.box {
  width: 100%;
}

</style>
<style lang="scss">
.tag_multiple_email_admin {
  padding: 5px 10px;
  margin-right: 5px;
  cursor: default;
  margin-bottom: 6px;
  background-color: #BBBBBB;
  word-break: break-all;
}
.span_x_multiple_email {
  font-size: 16px;
  color: #000000;
  margin-left: 5px;
  cursor: pointer;
}
.input_mutiple {
  min-width: 10%;
}
</style>
