<template>
  <div id="exportCsvModal" class="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="input_owner">
                <div class="flex select_wrapper arrow_down select_boder">
                  <label class="owner_form_label">出力種別</label>
                  <select id="filter" v-model="typeSelectCsv" name="filter">
                    <option value="発注集計">発注集計</option>
                    <option value="発注履歴">発注履歴</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div class="input_owner">
                <div class="flex select_wrapper arrow_down select_boder">
                  <label class="owner_form_label">店舗名</label>
                  <select id="filter" v-model="restaurantId" name="filter">
                    <option v-for="(option, i) in restaurants" :key="i" :value="option.user_id">{{ option.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div class="input_owner">
                <div class="flex select_boder">
                  <label class="owner_form_label">出力期間</label>
                  <div class="form-content">
                    <div class="flex">
                      <div class="w-100">
                        <DateTimePicker 
                          v-model="fromDate" format="YYYY/MM/DD"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input"
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          value-type="YYYY-MM-DD"
                        >
                          <span slot="icon-calendar" />
                        </DateTimePicker>
                        <span v-for="error in errors['fromDate']" :key="error" class="error-message">{{ error }}</span>
                      </div>
                      <div class="ml_small mr_small margin_y"
                           :class="errors['fromDate'] || errors['toDate'] ? 'mt-2' : ''">
                        〜
                      </div>
                      <div class="w-100">
                        <DateTimePicker 
                          v-model="toDate" format="YYYY/MM/DD"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input"
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          value-type="YYYY-MM-DD"
                        >
                          <span slot="icon-calendar" />
                        </DateTimePicker>
                        <span v-for="error in errors['toDate']" :key="error" class="error-message">{{ error }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div class="input_owner">
                <div class="flex select_boder">
                  <label class="owner_form_label">出力内容</label>
                  <div class="select_wrapper">
                    <div class="select_wrapper arrow_down">
                      <select v-model="typeExport" class="select_box_padding" :class="typeSelectCsv == '発注履歴' ? 'disabled' : ''">
                        <option
                          v-for="(item, index) in items"
                          :key="index"
                          :value="item.name"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <span v-for="error in errors['type']" :key="error" class="error-message">{{ error }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div class="input_owner">
                <div class="btn_area_owner two_items">
                  <button
                    class="btn_owner primary btn_pointer"
                    @click="exportCSV"
                  >
                    CSV出力
                  </button>
                  <button
                    class="btn_owner primary_border ml_medium btn_pointer"
                    @click="closeDialogCSV"
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { RestaurantsService } from '../services/restaurants.service';
import { OrdersService } from '../services/orders.service.js';
import Validator from '../validator';
import moment from 'moment';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';


export default Vue.extend({
  data() {
    return {
      restaurants: [],
      items: [
        {name: '店舗'},
        {name: '発注先'},
        {name: '商品'}
      ],
      typeExport: null,
      restaurantId: 0,
      fromDate: null,
      toDate: null,
      errors: [],
      restaurantName: null,
      typeSelectCsv: "発注集計"
    };
  },

  watch: {
    typeSelectCsv: {
      handler(value) {
        if (value == "発注履歴") {
          this.typeExport = null;
        }
      }
    }
  },

  async mounted() {
    await this.getRestaurantAdministrators();
  },

  methods: {
    async getRestaurantAdministrators() {
      this.$loading(true);
      try {
        const { response } = await RestaurantsService.search();
        this.restaurants = response.data;
        this.restaurants.splice(0, 0, {name: 'すべて', user_id: 0});
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    async exportCSV() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      const params = {
        from_date: this.fromDate,
        to_date: this.toDate,
        restaurant_id: this.restaurantId,
        type_export: this.typeExport,
        type_select_csv: this.typeSelectCsv
      };
      try {
        const restaurantName = this.restaurants.find(element => element.user_id == this.restaurantId).name;
        const {response} = await OrdersService.csvExport(params);
        let nameFile = "";
        if (this.typeSelectCsv == "発注履歴") {
          nameFile = `発注履歴_${restaurantName}_${moment(this.fromDate).format('YYYYMMDD')}_${moment(this.toDate).format('YYYYMMDD')}`
        }
        else {
          nameFile = `${this.typeExport}集計_${restaurantName}_${moment(this.fromDate).format('YYYYMMDD')}_${moment(this.toDate).format('YYYYMMDD')}`;
        }
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(response));
        element.setAttribute('download', `${nameFile}.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        this.error = false;
      } catch (error) {
        this.error = true;
        console.log(error);
      }
    },

    validate() {
      const validator = new Validator();

      validator.checkRequire('fromDate', this.fromDate, '開始日付');
      validator.checkRequire('toDate', this.toDate, '終了日付');

      if (this.typeSelectCsv != "発注履歴") {
        validator.checkRequireSelect('type', this.typeExport, '出力内容');
      }

      if (this.fromDate && this.toDate) {
        validator.checkDateBefore('fromDate', this.fromDate, this.toDate, '開始日付', '終了日付');
      }

      this.errors = validator.errors;
    },

    closeDialogCSV() {
      this.$emit('close-popup', false);
      document.getElementById('exportCsv').classList.remove('router-link-exact-active');
    },
  }
})
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
#exportCsvModal.modal {
  display: block;
}
.form-content {
  width: 100%;
}
.owner_form_label {
  width: 130px;
}
.margin_y {
  margin-top: auto;
  margin-bottom: auto;
}
.change-flex {
  flex: auto !important;
}

.mt-2 {
  margin-top: 2.5%;
}
.w-100 {
  width: 100%;
}
.disabled {
  background-color: #BBBBBB;
  pointer-events: none;
}
</style>
