import { BaseService } from './base.service';
import { ResponseWrapper, ApiError } from './http';

export class InventoryManageHistoriesService extends BaseService {
  static get resourceEndpoint() {
    return 'inventory-manage-histories';
  }

  static async getDetails(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/details`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
  static async updateNote(id, data = {}) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/update_note/${id || ''}`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {      throw new ApiError(error);
    }
  }
}
