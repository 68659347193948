import { NotificationService } from '../../services/notification.service';

const notifications = {
  state: {
    notifications: []
  },
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    }
  },
  actions: {
    async getNotifications({ commit }) {
      try {
        const { response } = await NotificationService.index();
        commit('SET_NOTIFICATIONS', response.data);
      } catch (error) {
        commit('SET_NOTIFICATIONS', []);
      }
    }
  },
  getters: {
    getUnreadNotificationCount: state => {
      return state.notifications.filter(notification => notification.read_status==='unread').length;
    },
  }
};
export default notifications;