<template>
  <footer class="footer footer-owner" :class="{'non_login': login === false}">
    <div class="footer_text">
      bespo, Inc.
    </div>
    <div class="footer_text">
      <a v-if="checkDomain()" href="https://stg.bespo.tech/smartrequest/contract.html" target="_blank"
         class="footer_text"
      >利用規約</a>
      <a v-else href="https://bespo.tech/smartrequest/contract.html" target="_blank"
         class="footer_text"
      >利用規約</a>
      <a v-if="checkDomain()" href="https://stg.bespo.tech/" target="_blank"
         class="footer_text"
      >運営会社</a>
      <a v-else href="https://bespo.tech/" target="_blank"
         class="footer_text"
      >運営会社</a>
    </div>
  </footer>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    login: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      location: window.location
    };
  },
  methods: {
    checkDomain() {
      return this.location.host.includes('stg.smartrequest') || this.location.protocol.includes('http:')  ? true : false;
    },
  }
});
</script>
<style scoped>
</style>
