<template>
  <div>
    <div class="sidebar-menu" :class="changeSideBar()">
      <!-- icon close -->
      <div class="sidebar-menu_icon">
        <div class="sidebar-menu_close" @click="hideSideBar" />
      </div>
      <div class="sidemenu">
        <!-- select -->
        <div v-show="isOwner === true" class="sidemenu_main sidemenu_main_small">
          <p>{{ ownerName }}</p>
          <div class="select_wrapper arrow_down">
            <select id="filter" v-model="userId" name="filter"
                    @change="changeRestaurant"
            >
              <option v-for="(option, i) in restaurants" :key="i" :value="option.user_id">{{ option.name }}</option>
            </select>
          </div>
        
        </div>
        <div v-show="isOwner === false" class="sidemenu_main sidemenu_main_small sidemenu_main_user">
          <p>{{ ownerName }}</p>
          <p>{{ restaurants.name }}</p>
        </div>
        <!-- content -->
        <div class="sidemenu_main">
          <router-link
            :to="{path: '/'}"
            class="sidemenu_content"
            :class="$route.path.includes('/order/') || $route.name === 'dashboard' ? 'router-link-exact-active' : ''"
            :event="''"
            @click.native="redirectTo('/dashboard')"
          >
            <div class="icon order" />
            <div class="icon_text">
              ホーム
            </div>
          </router-link>
          <router-link
            class="sidemenu_content"
            :to="{path: '/order_history'}"
            :class="$route.path.includes('/order_history') ? 'router-link-exact-active' : ''"
            :event="''"
            @click.native="redirectTo('/order_history')"
          >
            <div class="icon order_history" />
            <div class="icon_text">
              発注履歴
            </div>
          </router-link>
          <router-link
            class="sidemenu_content"
            :to="{path: '/order_company'}"
            :class="$route.path.includes('/order_company') || $route.path.includes('/item_create') ? 'router-link-exact-active' : ''"
            :event="''"
            @click.native="redirectTo('/order_company')"
          >
            <div class="icon menu_company" />
            <div class="icon_text">
              発注先・商品管理
            </div>
          </router-link>
          <router-link
            class="sidemenu_content"
            :to="{path: '/memo'}" 
            :class="$route.path.includes('/memo') ? 'router-link-exact-active' : ''"
            :event="''"
            @click.native="redirectTo('/memo')"
          >
            <div v-if="hasNewMemos" class="sidemenu_memo">
              <div class="icon memo" />
              <div v-if="getAllMemos.length <= 99" class="count count_qty count_md" getAllMemos.length>
                <span>{{ getAllMemos.length }}</span>
              </div>
              <div v-else class="count count_qty">
                <span class="">99+</span>
              </div>
            </div>
            <div v-else class="sidemenu_memo">
              <div class="icon memo" />
            </div>
            <div class="icon_text">
              メモ
            </div>
          </router-link>
          <router-link
            class="sidemenu_content"
            to="#"
            :class="showDialogCSV ? 'router-link-exact-active' : ''"
            @click.native="openDialogCSV"
            v-if="isPC"
          >
            <div class="icon order_report" />
            <div class="icon_text">
              発注データ出力
            </div>
          </router-link>
          <router-link
            class="sidemenu_content"
            :to="{path: '/setting'}"
            :class="$route.path.includes('/setting') || $route.path.includes('/order_staff') || $route.path.includes('/delivery_info') ? 'router-link-exact-active' : ''"
            :event="''"
            @click.native="redirectTo('/setting')"
          >
            <div class="icon setting" />
            <div class="icon_text">
              設定
            </div>
          </router-link>
        </div>
        <!-- footer -->
        <div class="sidemenu_main">
          <a target="_blank" href="https://bespo.force.com/help/s/contactsupport" class="no_active">
            運営会社へのご意見・ご要望
          </a>
          <a target="_blank" href="https://bespo.force.com/help/s/" class="no_active">
            サポートサイト
          </a>
          <a v-if="checkDomain()" target="_blank" href="https://stg.bespo.tech/smartrequest/contract.html"
            class="no_active"
          >
            利用規約
          </a>
          <a v-else target="_blank" href="https://bespo.tech/smartrequest/contract.html"
            class="no_active"
          >
            利用規約
          </a>
          <a v-if="checkDomain()" target="_blank" href="https://stg.bespo.tech"
            class="no_active"
          >
            運営会社
          </a>
          <a v-else target="_blank" href="https://bespo.tech"
            class="no_active"
          >
            運営会社
          </a>
          <router-link v-show="isOwner" to="/" class="no_active">
            管理画面へ
          </router-link>
          <router-link :to="{}" class="no_active" @click.native="logout">
            ログアウト
          </router-link>
        </div>
      </div>
    </div>  
    <PopupExportCSV :show-dialog="showDialogCSV" :close-dialog="disableDialogCSV"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RestaurantsService } from '../services/restaurants.service';
import PopupExportCSV from '../components/PopupExportCSV.vue';

export default Vue.extend({
  components: {
    PopupExportCSV
  },
  props: {
    openSideBar: {
      type: Boolean
    },
    closeSideBar: {
      type: Function
    },
    openDialog: {
      type: Function
    }
  },
  data() {
    return {
      restaurants: [],
      restaurant: '',
      userId: '',
      ownerName: '',
      location: window.location,
      showDialogCSV: false,
      isPC: true
    };
  },
  computed: {
    ...mapGetters({
      hasNewMemos: 'hasNewMemos',
      hasOrdersFlag: 'getHasOrdersFlag',
      getAllMemos: 'getAllMemos',
      isOwner: 'isOwner',
      currentRestaurant: 'getRestaurant'
    })
  },
  async mounted() {
    this.userId = this.$store.state.auth.user_id;
    await this.$store.dispatch('getMemos');
    await this.getRestaurantAdministrators();
    this.isPC = screen.width > 1180 ? true : false;
  },
  methods: {
    changeSideBar() {
      if(this.openSideBar == null) {
        return '';
      } else {
        if(this.openSideBar == true) {
          return 'sidebar-menu-open';
        } else {
          return 'sidebar-menu-hide';
        }
      }
    },
    hideSideBar() {
      this.closeSideBar();
    },
    async redirectTo(url) {
      this.closeSideBar();
      if ((this.$router.history.current.path.includes('/order/item_select') ||
            this.$router.history.current.path.includes('/order/order_cart')) &&
            this.hasOrdersFlag) {
        const res = await this.$confirmRedirect();
        if(!res) return;
      }

      return this.$router.push(url, () => {});
    },
    async getRestaurantAdministrators() {
      this.$loading(true);
      try {
        if (this.isOwner === true) {
          const { response } = await RestaurantsService.search();
          this.ownerName = response.owner.name;
          await this.$store.dispatch('getOwnerName', response.owner.name);
          this.restaurants = response.data;
        } else {
          const { response } = await RestaurantsService.index();
          this.ownerName = response.data.owner_name;
          await this.$store.dispatch('getOwnerName', response.data.owner_name);
          this.restaurants = response.data;
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    async changeRestaurant() {
      this.$loading(true);
      try {
        this.$store.commit('SET_USER_ID', this.userId);
        const { response } = await RestaurantsService.index();
        this.$store.commit('SET_RESTAURANT', response.data);
        await this.$store.dispatch('clearOrder');
        this.$loading(false);
        this.restaurantAdministratorId = this.userId;
        if (!response.data.id) {
          this.$router.push('/delivery_info');
        }
        window.location.href = '/dashboard';
        // window.location.reload();
      } catch (error) {
        this.$loading(false);
      }
    },
    checkDomain() {
      return this.location.host.includes('stg.smartrequest') || this.location.protocol.includes('http:')  ? true : false;
    },
    logout() {
      this.closeSideBar();
      this.openDialog();
    },
    openDialogCSV() {
      this.showDialogCSV = true;
      this.closeSideBar();
    },
    disableDialogCSV() {
      this.showDialogCSV = false;
    }
  }
});
</script>
<style scoped>
  .count {
    background-color: #D52C2C !important;
    width: 13px !important;
    height: 13px !important;
    border-radius: 50%;
  }
  .icon_text {
    font-size: 10px;
  }
</style>
