<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <div class="d-flex">
        <div>
          <h1 class="title mb_medium padding_text">
            棚卸の管理
          </h1>
          <p class="small_text mb_large chanel_text_padding">在庫表を登録し、数量・金額を記載した棚卸表を作成します。</p>
        </div>
        <div class="btn_item_right flex_item_center">
          <router-link to="/inventory/items" class="btn primary_border medium btn_redirect_item">在庫確認</router-link>
        </div>
      </div>
      <paging
        v-if="items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th class="w_20">棚卸日付</th>
            <th class="w_40">棚卸担当者</th>
            <th class="w_30">備考</th>
            <th class="w_10"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in items"
              :key="index"
          >
            <td>{{ formatDate(data.date) }}</td>
            <td>{{ data.order_staff_name }}</td>
            <td class="note-pc" :class="data.note ? 'pointer' : ''" @click="openPopupNote(data)">{{ data.note }}</td>
            <td class="is-mobile table-cell">
              <button v-if="data.note" class="btn primary btn_point btn_detail" @click="openPopupNote(data)">
                表示
              </button>
            </td>
            <td class="text-center">
              <button v-if="index===0" class="btn primary btn_point btn_detail is-pc" @click="redirectEdit(data.id)">
                編集
              </button>
              <button v-else class="btn primary_border btn_point btn_detail is-pc" @click="redirectDetail(data.id)">
                詳細
              </button>
              <button v-if="index===0" class="fs-18 next-detail-btn is-mobile" @click="redirectEdit(data.id)">
                &#8250;
              </button>
              <button v-else class="fs-18 next-detail-btn is-mobile" @click="redirectDetail(data.id)">
                &#8250;
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="items.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mt_medium"
        @jumpPage="onPageChange"
        style="margin-bottom: 20px;"
      />
      <div v-else class="text-center mt-28">表示するデータはありません。</div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <a href="javascript:void(0);" class="btn primary_border memo_create" @click="openPopupSubmit">新規登録</a>
      </div>
    </div>
    <div class="bottom_menu" :class="showPopupNote ? 'open' : ''">
      <h2 class="bottom_menu_title">備考</h2>
      <div class="mb_medium">
        <div class="form_input">
          <textarea
            v-model="note"
            placeholder="備考を入力"
            cols="30"
            rows="5"
          />
          <span v-for="error in errors['note']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="btn_area two_items">
        <a class="btn primary router-link-exact-active active" style="margin-right: 20px; cursor: pointer;" @click="onSave">保存</a>
        <a class="btn primary_border router-link-exact-active active" style="cursor: pointer;" @click="showPopupNote = false">キャンセル</a>
      </div>
    </div>
  </main>
</template>

<script>
import utils from '../../common/util.js';
import { InventoryManageHistoriesService } from '../../services/inventory_manage_histories.service';
import Paging from '../../components/Pager';
import Validator from '../../validator';

export default {
  components: {
    Paging
  },
  data() {
    return {
      items: [],
      showPopupNote: false,
      errors: [],
      note: null,
      inventory_id: null,
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
    };
  },

  mounted() {
    this.getInventoryManageHistories();
  },

  methods: {
    ...utils,

    async getInventoryManageHistories() {
      this.$loading(true);
      const params = {
        page: this.pager.page,
        page_size: this.pager.pageSize,
      };
      try {
        const { response } = await InventoryManageHistoriesService.index(params);
        this.items = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getInventoryManageHistories();
      this.scrollTopScreen();
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    redirectDetail(id) {
      this.$router.push(`/inventory/${id}/detail`);
    },
    redirectEdit(id) {
      this.$router.push(`/inventory/${id}/edit`);
    },
    openPopupNote(data) {
      if (!data.note) return;
      this.note = data.note;
      this.inventory_id = data.id;
      this.showPopupNote = true;
    },
    async openPopupSubmit() {
      const value = await this.$confirmSubmit('確認', '棚卸表を新規作成すると、過去の棚卸表の編集ができなくなります。\n新規作成しますか？', '新規作成', 'キャンセル');
      if(value) return this.$router.push(`/inventory/create`);
    },
    async onSave() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      this.$loading(true);
      const params = {
        note: this.note,
      };
      try {
        await InventoryManageHistoriesService.updateNote(this.inventory_id, params);
        this.showPopupNote = false;
        this.items.forEach(item => {
          if (item.id === this.inventory_id) {
            item.note = this.note;
          }
        });
        this.items.push();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    validate() {
      const validator = new Validator();
      validator.checkMaxLength('note', this.note, 255, '登録確認');
      this.errors = validator.errors;
    }
  },
};
</script>

<style lang="scss" scoped>
.is-pc {
  display: unset;
}
.btn_item_right {
  margin-left: auto;
  margin-right: 10px;
  width: 10%;
  min-width: 120px;
}
.flex_item_center {
  display: flex;
  align-items: center;
}
.btn_redirect_item {
  font-size: 16px;
  min-width: 100px;
}
@media screen and (max-width: 480px) {
  .is-pc {
    display: none;
  }
  .note-pc {
    display: none;
  }

  .table-cell {
    display: table-cell;
  }
  .btn_detail {
    padding: 4px 8px;
    max-width: 70px;
  }
}
</style>
