<template>
  <div class="main_area_pc wrapper_password-reset owner_main_area">
    <div class="wrapper_owner_have_button">
      <div class="password-reset">
        <div class="title">パスワード再設定</div>
        <div class="content note_sp">新しいパスワードを入力してください。</div>
        <div class="form_row vertical mb_medium form_reset">
          <label for="" class="owner_form_label">
            新しいパスワード
          </label>
          <div class="input_wrapper">
            <input v-model="password" type="password" placeholder="">
            <p class="owner_text_note form_text_note_margin">半角英数字8文字以上で入力してください</p>
            <span v-for="error in errors['password']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
          <label for="" class="owner_form_label">
            新しいパスワード確認用
          </label>
          <div class="input_wrapper">
            <input v-model="passwordconfilm" type="password" placeholder="">
            <span v-for="error in errors['passwordconfilm']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn_area_wrapper">
        <div class="button_wrapper">
          <router-link :to="{}" class="btn_area_owner btn_area-owner primary goback" @click.native="changePassword">
            送信する
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../../validator';
import { PasswordResetService } from '../../../services/Owner/password_reset.service';
import { PopupDisplayError } from '../../../common/util';

export default {
  data () {
    return {
      password: '',
      passwordconfilm: '',
      token: null,
      errors: {}
    };
  },
  mounted() {
    const token = this.$router.history.current.params.token;
    if (typeof token != 'undefined') {
      this.token = token;
    }
  },
  methods: {
    async changePassword() {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = {
        password: this.password,
        password_confirmation: this.passwordconfilm,
        token: this.token,
      };
      try {
        await PasswordResetService.password_submit(params);
        return this.$router.push({ path: '/password_reset_complete' });
      } catch (error) {
        this.validate();
        this.errors['passwordconfilm'] = ['認証コードが正しく無いか、有効期限が切れました。'];
        console.log(error);
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('password', this.password, '新しいパスワード') &&
        validator.checkAsciiWithoutSpace('password', this.password, '新しいパスワード') &&
          validator.checkMinLength('password', this.password, 8, '新しいパスワード') &&
            validator.checkMaxLength('password', this.password, 50, '新しいパスワード');
      validator.checkRequire('passwordconfilm', this.passwordconfilm, '新しいパスワード確認用') &&
        validator.checkAsciiWithoutSpace('passwordconfilm', this.passwordconfilm, '新しいパスワード確認用') &&
          validator.checkSameValue('passwordconfilm', this.password, this.passwordconfilm, '新しいパスワード確認用');
      this.errors = validator.errors;
    },
  },
};
</script>
<style lang="scss" scoped>
 
</style>
