import { BaseService } from './base.service';
import {ApiError, ResponseWrapper} from './http';

export class AgenciesService extends BaseService {
  static get resourceEndpoint() {
    return 'agencies';
  }

  static async allAgencies(data = {}) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/all`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}