import confirmSubmit from '../components/ConfirmSubmit.vue';

function Install (Vue, options = {}) {
  const property = options.property || '$confirmSubmit';
  delete options.property;

  const Ctor = Vue.extend(Object.assign(confirmSubmit));
  function createDialogCmp (options) {
    const container = document.querySelector('[data-app=true]') || document.body;
    return new Promise(resolve => {
      const cmp = new Ctor(Object.assign({}, {
        propsData: Object.assign({}, Vue.prototype[property].options, options),
        destroyed: () => {
          container.removeChild(cmp.$el);
          resolve(cmp.value);
        }
      }));
      container.appendChild(cmp.$mount().$el);
      var checkExist = setInterval(function() {
        if (document.querySelector('#confirm-screen')) {
          cmp.$mount().$el.firstChild.className += ' open';
          cmp.$mount().$el.lastChild.className += ' open';
          clearInterval(checkExist);
        }
      }, 100);
    });
  }

  function show (title, message, buttonTrueText, buttonFalseText, options = {}) {
    options.title = title;
    options.message = message;
    options.buttonTrueText = buttonTrueText;
    options.buttonFalseText = buttonFalseText;
    return createDialogCmp(options);
  }

  Vue.prototype[property] = show;
  Vue.prototype[property].options = options || {};
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Install);
}

export default Install;
