<template>
  <body class="body_admin">
    <main class="container_admin before_login">
      <div class="login_form_box">
        <div class="login_form">
          <h1 class="login_title login_title_admin">管理画面 ログイン</h1>
          <div class="form_row mb_medium_admin">
            <div class="form_row vertical form_row_admin">
              <label for="ID" class="form_label">ID</label>
              <div class="input_wrapper">
                <input id="ID" v-model="userName" type="text"
                       class="input_admin"
                       placeholder="ID"
                >
                <span v-for="error in errors['usename']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="form_row vertical mb_medium form_row_admin mb_xxxlarge">
            <label for="password" class="form_label">パスワード</label>
            <div class="input_wrapper">
              <input id="password" v-model="passWord" type="password"
                     class="input_admin"
                     placeholder="パスワード"
              >
              <span v-for="error in errors['password']" :key="error" class="error-message">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="btn_area btn_area_admin">
            <button class="btn_admin primary btn_point large" @click="loginAdmin">ログイン</button>
          </div>
        </div>
      </div>
    </main>
  </body>
</template>
<script>

import { AuthService } from '../../../services/admin/auth.service';
import Validator from '../../../validator';

export default {
  data () {
    return {
      userName: '',
      passWord: '',
      errors: {}
    };
  },
  methods: {
    loginAdmin: async function() {
      this.validate();
      if (Object.keys(this.errors).length) return;
      try {
        await AuthService.login({ username: this.userName, password: this.passWord });
        return this.$router.push({ path: '/admin' });
      } catch (error) {
        return this.errors = {password: ['ユーザ名またはパスワードが違います。']};
      }
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('usename', this.userName, 'ID');
      validator.checkRequire('password', this.passWord, 'パスワード');
      this.errors = validator.errors;
    },
  },
};
</script>
