<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">解約アンケートCSVダウンロード</h1>
    </div>
    <ExportForm :export-params="exportParams" @export="exportCancelAnswer" @clearSearch="clearSearch" />
  </div>
</template>

<script>
import {CancelAnswerService} from '../../../services/admin/cancel_answer.service';
import ExportForm from './components/ExportForm.vue';

export default {
  components: {
    ExportForm,
  },
  data() {
    return {
      exportParams: {
        from: '',
        to: '',
      },
    };
  },
  methods: {
    async exportCancelAnswer(params) {
      this.$loading(true);
      try {
        const { response } = await CancelAnswerService.export(params);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(response));
        element.setAttribute('download', '解約アンケート.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    async clearSearch() {
      this.exportParams = {
        from: '',
        to: '',
      };
    },
  },
};
</script>
