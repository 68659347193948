<template>
  <main class="container">
    <div class="main_area smp_content_main">
      <h1 class="notification_title">{{ notification.title }}</h1>
      <ul>
        <div class="date">{{ notification.start_time }}</div>
        <div class="notify_down_line" v-html="notification.content" />
      </ul>
    </div>
    <div class="control_area">
      <router-link to="/notification" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
    </div>
  </main>
</template>

<script>
import { NotificationService } from '../../services/notification.service';

export default {
  data() {
    return {
      notification: {},
    };
  },
  mounted() {
    this.getNotification();
  },
  methods: {
    async getNotification() {
      try {
        const { response } = await NotificationService.get(this.$router.history.current.params.id);
        if(response.result.message == 'not_found') {
          window.location.href = '/notification';
        } else {
          this.notification = response.data;
        }
      } catch(error) {
        console.log(error);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
  .notification_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .date {
    font-size: 16px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 480px){
    .notification_title {
      font-size: 16px;
      margin-bottom: 8px;
    }
    .date {
      font-size: 12px;
    }
  }
</style>
