<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <h1 class="title padding_text admin_line_down background-white">
        利用レポート: 発注先詳細
      </h1>
      <div class="form_row center chanel_text_padding background-white">
        <label class="form_label calendar-title">発注先</label>
        <div class="form_input">
          <div class="select_wrapper arrow_down full_width">
            <select id="staff" v-model="supplierId"
                    class="form_option"
                    @change="changeSupplier"
            >
              <option v-for="(supplier, index) in suppliers" :key="index" :value="supplier.id">
                {{ supplier.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="text-center menu-search loading-page is-pc-type" />
      </div>
      <div class="form_row center chanel_text_padding background-white">
        <label class="form_label calendar-title">表示期間</label>
        <div class="form_input">
          <DateTimePicker v-if="typeSearchTime == searchTime.month" v-model="startTime" format="YYYY年MM月"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input calendar-text"
                          type="month"
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          @input="onChangeYear"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
          <DateTimePicker v-if="typeSearchTime == searchTime.week" v-model="value" format="YYYY年MM月DD日"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input calendar-text"
                          type="week"
                          :lang="lang"
                          range 
                          @input="onChangeWeek"
          >
            <template v-slot:content="slotProps">
              <div>
                <calendar-panel
                  type="week"
                  v-model="value"
                  @select="slotProps.emit"
                  :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                ></calendar-panel>
              </div>
            </template>
            <span slot="icon-calendar" />
          </DateTimePicker>
          <DateTimePicker v-if="typeSearchTime == searchTime.range" v-model="rangeDate" format="YYYY年MM月DD日"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input calendar-text"
                          type="date"
                          range
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          @input="onChangeRange"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
        </div>
        <div class="text-center menu-search loading-page is-pc-type">
          <ul class="btn-choose-type">
            <li>
              <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="month" name="typeSearchTime" />
              <label class="type-search-time" for="月">月</label>
            </li>
            <li>
              <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="week" name="typeSearchTime" />
              <label class="type-search-time" for="週">週</label>
            </li>
            <li>
              <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="range" name="typeSearchTime" />
              <label class="type-search-time" for="日付">日付指定</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-right menu-search loading-page is-mobile-type">
        <ul class="btn-choose-type">
          <li>
            <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="month" name="typeSearchDay" />
            <label class="type-search-time" for="月">月</label>
          </li>
          <li>
            <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="week" name="typeSearchDay" />
            <label class="type-search-time" for="週">週</label>
          </li>
          <li>
            <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="range" name="typeSearchDay" />
            <label class="type-search-time" for="日付">日付指定</label>
          </li>
        </ul>
      </div>
      <div class="tab_area">
        <a
          v-for="tab in tabs"
          :key="tab.id"
          class="tab order_manage btn_point"
          :class="{ active: tab.id === currentTab }"
          @click="changeTab(tab.id)"
        >
          {{ tab.name }}
        </a>
      </div>
      <div class="chanel_padding-table">
        <table class="sr_ad_table background-table no_pointer_td">
          <thead>
            <tr>
              <th>#</th>
              <th class="w_55">商品</th>
              <th class="w_30 text-right" v-if="currentTab == 0">発注回数</th>
              <th class="w_30 text-right" v-else>発注額</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in itemOrderDetails"
                :key="index"
            >
              <td>{{ data.indexData }}</td>
              <td>{{ data.item_name }}</td>
              <td class="text-right" v-if="currentTab == 0">{{ data.count }}</td>
              <td class="text-right" v-else>{{ showPrice(data.total_money) }} 円</td>
            </tr>
          </tbody>
        </table>
        <div v-if="currentTab == 1 &&  itemOrderDetails.length > 0" class="background-white padding-sum-money">
          合計
          <span class="content_right">{{ showPrice(sumMoney) }} 円</span>
        </div>
        <div v-if="paginations.page < paginations.totalPage"
            class="background-white text_center padding_btn-load-more"
        >
          <button class="btn primary_border btn-load-more" @click="jumpPage">
            もっと表示する
          </button>
        </div>
        <div v-if="itemOrderDetails.length === 0" class="text-center mt-28">表示するデータはありません。</div>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/order_report" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
    </div>
  </main>
</template>

<script>
import { OrderReportsService } from '../../services/order_reports.service';
import utils from '../../common/util.js';
import moment from 'moment';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ja";
const { CalendarPanel } = DatePicker;

export default {

  components: { DatePicker, CalendarPanel },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      value: [new Date(), new Date()],
      startTime: new Date(),
      rangeDate: [new Date(), new Date()],
      supplier_name: "",
      typeSearchTime: 'month',
      searchTime: {
        month: 'month',
        week: 'week',
        range: 'range',
      },
      itemOrderDetails: [],
      suppliers: [],
      paginations: {
        page: 1,
        page_size: 10,
        total_page: 0,
        item_page_size: 0,
      },
      supplierId: null,
      startTime: null,
      currentTab: this.$store.state.order_report.type == 0 ? 0 : 1,
      tabs: [
        { id: 1, name:  '発注額'},
        { id: 0, name:  '発注回数'},
      ],
      indexTable: 1,
      sumMoney: null
    };
  },

  async mounted() {
    await this.getAllSuppliers();
    this.supplierId = this.$store.state.order_report.supplier_id ? this.$store.state.order_report.supplier_id :
                      (this.suppliers.length == 0 ? null : this.suppliers[0].id);
    this.startTime = this.$store.state.order_report.time ? new Date(this.$store.state.order_report.time) : new Date();
    this.rangeDate[0] = this.$store.state.order_report.dateStart ? new Date(this.$store.state.order_report.dateStart) : new Date();
    this.rangeDate[1] = this.$store.state.order_report.dateEnd ? new Date(this.$store.state.order_report.dateEnd) : new Date();
    this.value[0] = this.$store.state.order_report.firstDay ? new Date(this.$store.state.order_report.firstDay) : new Date();
    this.typeSearchTime = this.$store.state.order_report.typeSearchTime ? this.$store.state.order_report.typeSearchTime : this.searchTime.month;
    this.value[1] = new Date(moment(this.value[0]).day(7).format('YYYY-MM-DD'));
    await this.searchItemOrderDetail();
  },

  methods: {
    ...utils,
    async onChangeTypeSearchTime() {
      this.$store.commit("SET_TYPE_SEARCH_TIME", this.typeSearchTime);
      if (this.typeSearchTime == this.searchTime.month) {
        await this.onChangeYear();
      }
      else if (this.typeSearchTime == this.searchTime.week) {
        this.value = this.value[0];
        await this.onChangeWeek();
      }
      else {
        await this.onChangeRange();
      }
    },
    async getAllSuppliers() {
      this.$loading(true);
      try {
        const { response } = await OrderReportsService.getAllSuppliers();
        this.suppliers = response.data;
        this.$loading(false);
      } catch(error) {
        this.$loading(false);
        console.log(error);
      }
    },

    async searchItemOrderDetail() {
      this.$loading(true);
      const searchParams = {
        time: moment(this.startTime).format('YYYY-MM'),
        supplier_id: this.supplierId,
        type_search: this.currentTab,
        typeSearchTime: this.typeSearchTime,
        dateStart: moment(this.rangeDate[0]).format('YYYY-MM-DD'),
        dateEnd: moment(this.rangeDate[1]).format('YYYY-MM-DD'),
        firstday: moment(this.value[0]).format('YYYY-MM-DD'),
        lastday: moment(this.value[1]).format('YYYY-MM-DD'),
      }

      let params = {...this.paginations, ...searchParams};

      try {
        const { response } = await OrderReportsService.searchItemOrderDetail(params);
        this.paginations.page = response.pager.page;
        this.paginations.totalPage = response.pager.page_count;
        this.paginations.item_page_size = response.pager.page_size;
        this.sumMoney = response.sum_order_item.sum;
        this.itemOrderDetails = this.itemOrderDetails.concat(response.data);

        this.itemOrderDetails.forEach((element, index) => {
          this.renderIndexTable(index + 1, element, this.itemOrderDetails[index - 1]);
        });

        this.indexTable = 1;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },

    async onChangeYear() {
      this.$store.commit("SET_TIME", this.startTime);
      await this.updateChangeData();
    },

    async onChangeWeek() {
      this.value = moment(this.value).subtract(1, 'day');
      this.value[0] = new Date(moment(this.value).day(1).format('YYYY-MM-DD'));
      this.value[1] = new Date(moment(this.value).day(7).format('YYYY-MM-DD'));
      this.value = [this.value[0], this.value[1]];
      this.$store.commit("SET_FIRST_DAY", this.value[0]);
      this.$store.commit("SET_LAST_DAY", this.value[1]);
      await this.updateChangeData();
    },

    async onChangeRange() {
      this.$store.commit("SET_DATE_START", this.rangeDate[0]);
      this.$store.commit("SET_DATE_END", this.rangeDate[1]);
      await this.updateChangeData();
    },

    async changeSupplier() {
      await this.updateChangeData();
    },

    async changeTab(tab) {
      this.currentTab = tab;
      this.indexTable = 1;
      await this.updateChangeData();
    },

    jumpPage() {
      this.paginations.page = this.paginations.page + 1;
      this.searchItemOrderDetail();
    },

    async updateChangeData() {
      this.paginations.page = 1;
      this.itemOrderDetails = [];
      await this.searchItemOrderDetail();
    },

    renderIndexTable(index, item, previousItem) {
      let i = 1;

      if (item == undefined) {
        return;
      }

      if (previousItem !== undefined) {
        if ((this.currentTab == 0 && item.count != previousItem.count) ||
            (this.currentTab == 1 && item.total_money != previousItem.total_money)) {
          this.indexTable = index;
        }

        i = this.indexTable;
      } else {
        i = index;
      }

      item.indexData = i;
    }
  },
};
</script>
<style lang="scss" scoped>
.tab_area {
  position: unset;
  top: 0px;
  margin-bottom: 0px;
}
.tab_area .tab {
  color: #966544;
}
.sr_ad_table.background-table {
  margin: 0px;
}
.sr_ad_table.background-table thead tr th {
  background: white;
  font-size: 16px;
}
.sr_ad_table.background-table tbody tr td {
  font-size: 16px;
}
.padding_text {
  padding: 24px 24px 16px;
}
.chanel_text_padding {
  padding: 0px 24px 12px;
}
.form_row .form_label {
  font-size: 20px;
  width: 200px;
}
.main_area {
  padding: 0px;
  background-color: #f4f2f2;
}
.text-right {
  text-align: right;
}
.padding-sum-money {
  padding: 7px 12px;
  border-bottom: 1px solid #cfcfcf;
}
.menu-search {
  margin-left: 5px;
  text-align: unset;
  li {
    width: 80px;
  }
}
.type-search-time {
  border: 1px solid #BBBBBB;
  color: unset;
}
.item-left {
  float: left;
}
.mx0 {
  margin: 0;
}
.full_width {
  width: 100%;
}
.detail-day {
  width: 40%;
  max-width: 300px;
  min-width: 245px;
}

@media screen and (max-width: 480px) {
  .tab_area {
    margin: 0px;
  }
  .input_wrapper.medium {
    width: 100%;
  }
  .select_wrapper.medium {
    width: 100%;
  }
  .padding_text {
    padding: 16px;
  }
  .chanel_text_padding {
    padding: 0px 16px 8px 16px;
  }
  .menu-search {
    text-align: right;
    margin: 0px;
    padding-right: 16px;
    li {
      width: 62px;
      height: 40px;
    }
  }
  .calendar-title {
    width: 120px !important;
  }
}
</style>
