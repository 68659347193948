<template>
  <form class="search_form search_form_admin" action="">
    <label :class="isHidden.form ? 'icon_shevrontop': 'icon_shevrondown' " class="form_title after"
           @click="isHidden.form = !isHidden.form"
    >検索条件</label>
    <div v-if="isHidden.form" class="toggle_content">
      <div class="form_content">
        <div class="row flex">
          <div class="column">
            <label for="shopname_search" class="form_label margin-text">法人名</label>
            <input id="shopname_search" v-model="searchParams.name" placeholder="法人名を⼊⼒"
                   type="text"
            >
          </div>
          <div class="column">
            <label for="director_name_search" class="form_label margin-text">法人代表者名</label>
            <input id="director_name_search" v-model="searchParams.director_name" placeholder="法人代表者名を⼊⼒"
                   type="text"
            >
          </div>
          <div class="column">
            <label for="full_address_search" class="form_label margin-text">住所</label>
            <input id="full_address_search" v-model="searchParams.full_address" placeholder="住所を⼊⼒"
                   type="text"
            >
          </div>
        </div>
        <div class="row flex">
          <div class="column">
            <label for="phone_search" class="form_label margin-text">電話番号</label>
            <input id="phone_search" v-model="searchParams.phone" placeholder="電話番号を⼊⼒"
                   type="text"
            >
          </div>
          <div class="column">
            <label for="email_search" class="form_label margin-text">メールアドレス</label>
            <input id="email_search" v-model="searchParams.email" placeholder="メールアドレスを⼊⼒"
                   type="text"
            >
          </div>
          <div class="column">
            <label for="search_status" class="form_label margin-text">状態</label>
            <div class="select-wrapper">
              <select id="search_status" v-model="searchParams.status" class="admin_select_background"
                      @change="statusCheck"
              >
                <option value="">すべて</option>
                <option v-for="(status, index) in OWNERS_STATUS" :key="index" :value="status.value">
                  {{ status.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row flex">
          <div class="column">
            <label for="shop_name_search" class="form_label margin-text">店舗名</label>
            <input id="shop_name_search" v-model="searchParams.shop_name" placeholder="店舗名"
                   type="text"
            >
          </div>
        </div>
        <div class="row flex">
          <div class="column col-is_deleted">
            <div class="flex alignCenter mb_small">
              <input
                id="check_search"
                ref="checkbox"
                v-model="searchParams.is_deleted"
                class="checkbox"
                false-value="0"
                true-value="1"
                type="checkbox"
                @change="changeIsdeleted"
              >
              <label for="check_search" class="checkbox_label" />
              <label for="check_search" class="checkbox_text">解約されている法人を表示する</label>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_area_admin search">
        <button type="button" class="btn_admin admin_primary_border" @click="$emit('clearSearch')">検索条件をクリア
        </button>
        <button type="button" class="btn_admin admin_primary" @click="search">検索</button>
      </div>
    </div>
  </form>
</template>
<script>
import constant from '../../../../common/constant';

export default {
  props: {
    searchParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isHidden: {
        form: true,
      },
      OWNERS_STATUS: constant.OWNERS_STATUS
    };
  },

  methods: {
    async search() {
      this.$emit('updateCurrentPage', 1);
      await this.$emit('refreshTable');
    },
    changeIsdeleted() {
      !this.searchParams.is_deleted;
    },
    statusCheck() {
      if (this.searchParams.status == 'inactive') {
        document.getElementById('check_search').checked = true;
        this.searchParams.is_deleted = 1;
      } else {
        document.getElementById('check_search').checked = false;
        this.searchParams.is_deleted = 0;
      }
    }
  }
};
</script>
<style scoped>
.col-is_deleted {
  flex: 2;
}
</style>