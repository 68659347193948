import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

var ls = new SecureLS();

import memos from './modules/memos';
import auth from './modules/auth';
import orders from './modules/orders';
import restaurants from './modules/restaurants';
import notifications from './modules/notifications';
import restaurant_owner from './modules/owner/restaurants';
import register_owner from './modules/register_owner';
import admins from './modules/admins';
import select_tab from './modules/select_tab';
import select_tab_admin from './modules/select_tab_admin';
import prefectures from './modules/owner/prefectures';
import setting_image from './modules/setting_image';
import active_confirm from './modules/active_confirm';
import order_report from './modules/order_report';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['memos', 'auth', 'orders', 'restaurants', 'isLoading', 'error', 'prefectures','setting_image',
              'active_confirm', 'order_report'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state: {
    isLoading: true,
    error: null,
  },
  modules: {
    memos,
    auth,
    orders,
    restaurants,
    notifications,
    restaurant_owner,
    register_owner,
    admins,
    select_tab,
    select_tab_admin,
    prefectures,
    setting_image,
    active_confirm,
    order_report
  },
  mutations: {
    LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    ERROR(state, error) {
      state.error = error;
    },
  },
});