const order_report = {
    state: {
      time: null,
      supplier_id: null,
      type: null,
      typeSearchTime: null,
      dateStart: null,
      dateEnd: null,
      firstDay: null,
      lastDay: null
    },
    mutations: {
      SET_TIME(state, payload) {
        state.time = payload;
      },

      SET_TYPE_SEARCH_TIME(state, payload) {
        state.typeSearchTime = payload;
      },

      SET_DATE_START(state, payload) {
        state.dateStart = payload;
      },

      SET_DATE_END(state, payload) {
        state.dateEnd = payload;
      },

      SET_FIRST_DAY(state, payload) {
        state.firstDay = payload;
      },

      SET_LAST_DAY(state, payload) {
        state.lastDay = payload;
      },

      SET_SUPPLIER_ID(state, payload) {
        state.supplier_id = payload;
      },

      SET_TYPE(state, payload) {
        state.type = payload;
      },
    },
    actions: {},
    getters: {
      getTime: state => state.time,
      getSupplierId: state => state.supplier_id,
      getType: state => state.type,
      getTypeSearchTime: state => state.typeSearchTime,
      getFirstDay: state => state.firstDay,
      getLastDay: state => state.lastDay,
      getDateStart: state => state.dateStart,
      getDateEnd: state => state.dateEnd
    }
  };
    
  export default order_report;
