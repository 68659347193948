<template>
  <div id="editDetailResModal" class="modal">
    <div class="modal-backdrop" />
    <div class="modal-content">
      <Detail v-show="modalType =='detail'" :restaurant-administrator="restaurantAdministrator" @updateModalType="updateModalType" />
      <Edit v-show="modalType =='edit'" :edit-rest="restaurantAdministrator" @updateModalType="updateModalType"
            @refreshTable="$emit('refreshTable')"
      />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import { RestaurantAdministratorsService } from '../../../../services/admin/restaurant_administrators.service';
import utils from '../../../../common/util';
import Detail from './Detail';
import Edit from './Edit';

export default {
  components: {
    Detail,
    Edit
  },
  props: {
    restId: {
      type: Number,
      require: true,
      default: -1
    },
    modalType: {
      type: String,
      require: true,
      default: 'edit'
    }
  },
  data() {
    return {
      detailModal: true,
      restaurantAdministrator: {},
      password: '********',
      status: {
        active: '利用中',
        admin_cancel: '利用停止中',
        owner_cancel: '利用中',
        inactive: '解約',
      },
      admin_service_status: {
        active: '利用再開',
        admin_cancel: '利用停止',
        owner_cancel: '利用再開',
      },
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
  },

  watch: {
    restId: function (val) {
      if (val > 0) {
        this.getRestaurantAdministrator();
      }
    }
  },
  async mounted() {
    await this.getRestaurantAdministrator();
  },

  methods: {
    ...utils,
    updateModalType(val) {
      this.$emit('updateModalType', val);
    },

    async getRestaurantAdministrator() {
      if (this.restId > 0) {
        this.$loading(true);
        try {
          const { response } = await RestaurantAdministratorsService.get(this.restId);
          this.restaurantAdministrator = response.data;
          this.$loading(false);
        } catch (error) {
          this.$loading(false);
        }
      }
    },
    showDate(date) {
      return date !== null ? this.formatDateWithoutLeadingZeros(date) : '';
    }
  }
};
</script>