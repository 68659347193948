<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <div class="flex alignCenter between mb_medium padding_text">
        <h1 class="title">
          発注先一覧
        </h1>
        <div class="select_wrapper arrow_down small">
          <select
            id="filter"
            v-model="selected"
            name="filter"
            @change="sortSupplier"
          >
            <option v-for="option in sorts" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
      <paging
        v-if="suppliers.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
      <table class="sr_table">
        <thead>
          <tr>
            <th class="w_25">
              登録日付
            </th>
            <th class="w_35">発注先</th>
            <th class="no_pr">
              登録商品数
            </th>
            <th class="w_20" />
          </tr>
        </thead>
        <tbody v-if="suppliers.length > 0">
          <tr
            v-for="supplier in suppliers" :key="supplier.id"
            class="to_detail"
          >
            <td @click="detailRow(supplier)">{{ formatDate(supplier.created_at) }}</td>
            <td @click="detailRow(supplier)">{{ supplier.name }}</td>
            <td @click="detailRow(supplier)">
              {{ supplier.item_qty > 0 ? supplier.item_qty : '未登録' }}
            </td>
            <td class="text_center" @click="detailItem(supplier)">
              <div class="btn primary btn_order_company btn_detail_order_company">
                商品編集
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text_center">表示するデータはありません。</td>
          </tr>
        </tbody>
      </table>
      <paging
        v-if="suppliers.length > 0"
        :page="pager.page"
        :total-page="pager.totalPage"
        :is_restaurant="true"
        styles="mb_medium"
        @jumpPage="onPageChange"
      />
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control search_trigger">
        <router-link to="/order_company/new" class="btn primary_border">新規登録</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { SuppliersService } from '../../services/suppliers.service';
import utils from '../../common/util';
import Paging from '../../components/Pager';

export default {
  components: {
    Paging
  },
  props: {
  },
  data() {
    return {
      suppliers: [],
      sorts: [
        {value: 'most_ordered', text: '注文回数順'},
        {value: 'recently_ordered', text: '最近注文した順'}
      ],
      selected: 'most_ordered',
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
    };
  },
  created() {
    this.getSuppliers();
  },
  methods: {
    ...utils,

    getSuppliers: async function() {
      this.$loading(true);
      try {
        const params = {
          sort: this.selected,
          page: this.pager.page,
          page_size: this.pager.pageSize,
        };

        const { response } = await SuppliersService.index(params);
        this.suppliers = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
      this.$loading(false);
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('body_user');
      elmnt.scrollTo(0, 0);
    },
    onPageChange(page) {
      this.pager.page = page;
      this.getSuppliers();
      this.scrollTopScreen();
    },
    detailRow(supplier) {
      this.$router.push({ path: `/order_company/${supplier.id}/detail` });
    },

    sortSupplier: async function() {
      this.getSuppliers();
    },

    async detailItem(supplier) {
      await this.$store.dispatch('changeTab', 1);
      this.$router.push(`/order_company/${supplier.id}/detail`);
    }
  }
};
</script>

<style scoped>
.btn_order_company {
  padding: 13px;
}

.btn_order_company.btn_detail_order_company {
  max-width: 90px;
  padding: 4px 8px;
  font-weight: normal;
  font-size: 12px;
}

@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
  }
  .btn_order_company.btn_detail_order_company {
    padding: 4px;
  }
  .no_pr {
    padding-right: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .main_area {
    padding: 0px;
  }
  .btn_order_company {
    padding: 7px;
  }
}
</style>
