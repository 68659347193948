import { updateLocale } from "moment";

const getDefaultState = () => {
  return {
    registerForm: {
      login_id: null,
      name: null,
      name_kana: null,
      email: null,
      director_name: null,
      director_name_kana: null,
      staff_name: null,
      staff_name_kana: null,
      postcode: null,
      prefecture_id: null,
      city: null,
      address: null,
      building_name: null,
      phone: null,
      password: null,
      department_name: null,
      payment_type_id: null,

      payment: {
        invoice_send_type: null,
        use_owner_info: null,
        name: null,
        postcode: null,
        prefecture_id: null,
        city: null,
        address: null,
        building_name: null,
        phone: null,
        email: null,
        department_name: null,
        staff_name: null,
        staff_name_kana: null,
        payment_credit_id: null
      },

      agency: {
        agency_id: null,
        code: null
      },
    },
    isConfirm: false,
  };
};

const register_owner = {
  state: {
    registerForm: {
      login_id: null,
      name: null,
      name_kana: null,
      email: null,
      director_name: null,
      director_name_kana: null,
      staff_name: null,
      staff_name_kana: null,
      postcode: null,
      prefecture_id: null,
      city: null,
      address: null,
      building_name: null,
      phone: null,
      password: null,

      payment: {
        invoice_send_type: null,
        use_owner_info: null,
        name: null,
        postcode: null,
        prefecture_id: null,
        city: null,
        address: null,
        building_name: null,
        phone: null,
        email: null,
      },

      agency: {
        agency_id: null,
        code: null
      },
    },
    isConfirm: false,
  },
  mutations: {
    SET_IS_CONFIRM(state, payload) {
      state.isConfirm = payload;
    },
    CLEAR_STATE_REGISTER(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setIsConfirm({ commit }, payload) {
      commit('SET_IS_CONFIRM', payload);
    },
  },
  getters: {
    getPageConfirm: state => state.isConfirm,
    getRegisterForm: state => state.registerForm,
  }
};
export default register_owner;