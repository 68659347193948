<template>
  <main class="container bottomUp">
    <div class="main_area main_area-dashboard">
      <div class="ml_main">
        <h1 class="title mb_large">ページが見つかりませんでした</h1>
        <p>お探しのページは見つかりませんでした。URLをお確かめください。</p>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
    </div>
  </main>
</template>

<script>
export default {

};
</script>
<style scoped lang='scss'>
.ml_main {
  text-align: center;
}
@media screen and (max-width: 480px){
  .main_area-dashboard {
    width: auto;
    left: 0;
  }
}
</style>
