<template>
  <main class="wrapper-registerinfo">
    <div v-if="isCheckcode" class="owner_main_area">
      <div class="main_logo mb_large main_logo-owner" />
      <h3 class="title_after_logo">初めてご利用いただく方へ</h3>
      <div class="wrapper-first wrapper_border">
        <div class="wrapper-title__first">
          <h3>ご利用の流れ</h3>
          <p>ご利用開始の翌月末（最大2ヶ月間）は無料でお試しいただけます。</p>
        </div>
        <div class="wrapper-info">
          <div class="info-child">
            <div class="wrapper-header">
              <p>STEP1
                オーナーアカウント発行</p>
            </div>
            <div class="wrapper-image"><img src="../../../../assets/images/sr_register01.svg" alt="img"></div>
            <div class="wrapper-content">
              はじめに法人情報の登録を行うと、オーナーアカウントが発行されます。
              オーナーアカウント発行時に利用規約とプライバシーポリシーに同意いただいた時点で契約が発生します。
            </div>
          </div>
          <div class="info-child">
            <div class="wrapper-header">
              <p>STEP2
                店舗アカウントの発行</p>
            </div>
            <div class="wrapper-image"><img src="../../../../assets/images/sr_register02.svg" alt="img"></div>
            <div class="wrapper-content">
              STEP1で発行したオーナーアカウントを用いてSMART REQUESTへログインし、店舗アカウントを作成します。
              <p><span>※</span>店舗アカウントのログインIDおよびパスワードはご登録者様が各スタッフの方へご連絡いただくようお願いします。</p>
            </div>
          </div>
          <div class="info-child">
            <div class="wrapper-header">
              <p>STEP3
                ご利用開始</p>
            </div>
            <div class="wrapper-image"><img src="../../../../assets/images/sr_register03.svg" alt="img"></div>
            <div class="wrapper-content">
              オーナーアカウントおよび店舗アカウントのログインID、パスワードを用いてSMART REQUESTへログインしてください。
              発注作業を開始できます！
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-second wrapper_border">
        <div class="wrapper-title__first">
          <h3>アカウントについて</h3>
          <div class="sub_title">
            <p>アカウントはオーナーアカウントと店舗アカウントの2つがございます。</p>
            <p>それぞれのアカウントで役割が異なります。</p>
          </div>
        </div>
        <div class="wrapper-info">
          <div class="info-child">
            <div class="wrapper-image2"><img src="../../../../assets/images/sr_accountType.svg" alt="img"></div>
          </div>
          <div class="info-child">
            <div class="wrapper-content">
              <div class="wrapper-content_child spacing_content_wrapper">
                <div class="wrapper-title mb_medium">【オーナーアカウント】</div>
                <p class="mb_large">オーナー様向けにご提供するアカウントです。<br>
                ご契約情報の管理と全ての店舗の発注画面へ１アカウントでログインできる権限を持ちます。</p>
                <p class="title">■できること</p>
                ・管理画面へのログイン（新規店舗アカウントの発行、アカウントの解約申請、各種登録情報の修正等）<br>
                ・オーナーアカウントに紐づく全ての店舗の発注画面へのログイン
              </div>
            </div>
            <div class="wrapper-content">
              <div class="wrapper-content_child spacing_content_wrapper">
                <div class="wrapper-title mb_medium">【店舗アカウント】</div> 
                <p class="mb_large">店舗スタッフ様向けに提供するアカウントです。<br>
                店舗アカウントは、対象の1店舗のみにログインできる権限を持ちます。
                </p>
                <p class="title">■できること</p>
                ・店舗アカウントに紐づく1つの店舗の発注画面へのログイン
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-third wrapper_border">
        <div class="body_content">
          <h3 class="title">料金について</h3>
          <div class="content">
            <p><span>初期費用は無料</span>。オーナーアカウントは無料！</p>
            <p>店舗アカウントは月額5,000円(税別)のシンプルな料金設定です。</p>
            <p>さらに、<span>ご利用開始の翌月末まで（最大2ヶ月間）無料</span>でお試しいただけます。</p>
          </div>
          <div class="child__one">
            <div class="circle">
              <div class="circle-text">初期費用
                <div class="point">無料</div>
              </div>
            </div>
            <div class="circle_between" />
            <div class="circle">
              <div class="circle-text">月額費用
                <div class="point">5,000<span class="small">円(税別)</span></div>
              </div>
            </div>
          </div>
          <div class="child__two mb_large">
            <h3 class="title">利用ケース</h3>
            <div class="content mb_medium">一店舗だけSMART REQUESTを使用したい場合</div>
            <div class="wrapper-image"><img style="max-height: 180px;" src="../../../../assets/images/case_image01.svg" alt="img"></div>
          </div>
          <div class="child__third mb_large">
            <div class="content mb_medium">二店舗でSMART REQUEST を利用したい場合</div>
            <div class="wrapper-image"><img style="max-height: 380px;" src="../../../../assets/images/case_image02.svg" alt="img"></div>
          </div>
          <div class="text__end">以降は店舗アカウントが1つ増えるごとに月額費用5,000円(税別)が追加されます。</div>
        </div>
      </div>
      <div class="wrapper-four wrapper_border">
        <h3 class="title">お支払いについて</h3>
        <div class="content content__one">
          <p>ご利用料金は、当月末締め、当月払いとなります。</p>
          <p>無料期間が終了し、有料期間に移行されたらご請求開始となります。</p>
          <p>お支払い方法は、<span>クレジットカード払い、請求書払い、口座振替</span>となります</p>
        </div>
        <div class="content content__one">
          <div class="wrapper-title mb_medium wrapper-title-mobile">クレジットカード払いの場合</div>
          <p>クレジットカードは、VISA / MASTER / JCB / AMEX / DINERS の取り扱いとなります。</p>
          <p>決済日はご利用月の2日となります。</p>
          <div class="content_text">
            ※お引き落としのタイミングは、カード会社へお問合せください。<br>
            ※請求書の発行はございません。カード会社発行の利用明細をご確認ください。<br>
            ※決済日までにクレジットカード情報の入力が確認できないなど、<br>
            カード決済ができない場合、請求書払いに変更させていたく場合がございます。<br>
          </div>
        </div>
        <div class="content content__one">
          <div class="wrapper-title mb_medium wrapper-title-mobile">請求書払いの場合</div>
          <p>有料期間が開始後、株式会社ネットプロテクションズよりご請求書をお送りさせていただきます。</p>
          <p>ご利用月の利用料金は、当月末日までのお支払いをお願いします。</p>
          <p>(例):1月のサービスご利用料金のお支払い期限:1月31日</p>
          <div class="content_text">
            法人/個人事業主を対象とした掛け払いサービスです。（月末締め翌月末請求書払い）<br>
            請求書は、株式会社ネットプロテクションズからご購入の翌月第4営業日に発行されます。<br>
            掛け払いのご注文には、同社の提供するNP掛け払いサービスが適用され、ご注文と同時にご注文の商品の代金債権を譲渡します。<br>
            <a href="https://np-kakebarai.com/buy/terms/" target="_blank">
              「掛け払い利用規約及び同社のプライバシーポリシー」
            </a>
            に同意してNP掛け払いをご選択ください。<br>
            与信枠は、個別に設定させていただきます。<br>
            請求書に記載されている銀行口座でお支払いください。<br>
            ※銀行振込の際の振込手数料はお客様にてご負担ください。<br>
            詳細はバナーをクリックしてご確認ください。<br>
          </div>
        </div>
        <div class="wrapper-image" style="padding-bottom: 10px;">
          <a href="https://np-kakebarai.com/buy/" target="_blank">
            <img src="../../../../assets/images/case_image03.png" alt="img">
          </a>
        </div>
        <div class="content content__one" style="padding-bottom: 30px;">
          <div class="wrapper-title mb_medium wrapper-title-mobile">口座振替の場合</div>
          <p>銀行口座からの引き落とし（口座振替）をご利用いただけます。</p>
          <div class="content_text">
            【口座振替のご注意点】<br>
            ネットプロテクションズより発送される口座振替依頼書に必要事項をご記載いただき、ご返送をお願いいたします。<br>
            代金はご購入の翌月27日に引き落とされます。<br>
            ※口座振替適用までは、口座振替依頼書受け付け後から約2か月程お時間が掛かります。<br>
            ※金融機関の審査によっては、通常以上にお時間を要する場合がございます。<br>
            口座振替適用までの間は、ご利用月の翌月第4営業日に株式会社ネットプロテクションズより発送される請求書（銀行）にてお支払いください。<br>
          </div>
        </div>
      </div>
      <div class="btn_area_owner owner_btn_padding">
        <router-link :to="{}" class="btn_owner primary" @click.native="btn_continue">お申し込みへ進む</router-link>
      </div>
    </div>
    <notfound v-else />
  </main>
</template>

<script>
import { AgenciesService } from '../../../services/Owner/agencies.service';
import Notfound from './../NotFound/Index.vue';
export default {
  components: {
    Notfound,
  },
  data(){
    return{
      isChange: null,
      agencie_code: null,
      isCheckcode: true,
      checkdevice: true,
    };
  },
  created() {
    window.addEventListener('resize', this.isMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobile);
  },
  mounted() {
    const id = this.$router.history.current.params.id;
    if (typeof id != 'undefined') {
      this.agencie_code = id;
      this.getInfoAgencies();
    }
    this.isMobile();
  },
  methods: {
    async getInfoAgencies() {
      try {
        const params = {
          code: this.agencie_code,
        };
        const { response } = await AgenciesService.index(params);
        if (response['result'].status != 'OK') {
          this.isCheckcode = false;
        }
      } catch(error) {
        this.isCheckcode = false;
        console.log(error);
      }
    },
    isMobile() {
      if (screen.width > 480) {
        this.checkdevice = true;
      } else {
        this.checkdevice = false;
      }
    },
    btn_continue() {
      if (this.agencie_code == null) {
        window.location.href = '/register';
      } else {
        window.location.href = '/register/'+this.agencie_code;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.spacing_content_wrapper {
  margin-top: 16px;
  padding: 16px;
}
.wrapper-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}
.content_text {
  padding-left: 50px;
  font-size: 11px;
  text-align: left;
  word-break: break-all;
}
@media screen and (max-width: 480px) {
  .content_text {
    padding-left: 0 !important;
  }
}
</style>