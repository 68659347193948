<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <h1 class="title mb_medium padding_text">
        棚卸{{inventoryManageHistoryId ? '編集' : '登録'}}
      </h1>
      <div v-if="inventoryManageHistoryId" class="form_row center chanel_text_padding mb-20">
        <label class="form_label label-title">棚卸日付</label>
        <div>{{formatDate(inventoryManageHistory.date)}}</div>
      </div>
      <div class="form_row center chanel_text_padding mb_medium">
        <label class="form_label label-title">棚卸担当者</label>
        <div class="form_input">
          <div class="select_wrapper arrow_down medium inventory_person">
            <select id="staff" v-model="orderStaffId"
                    class="form_option"
            >
              <option value="null" selected></option>
              <option v-for="(orderStaff, index) in orderStaffs" :key="index" :value="orderStaff.id">
                {{ orderStaff.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="!inventoryManageHistoryId" class="form_row center chanel_text_padding mb_medium">
        <label class="form_label label-title">発注先絞込</label>
        <div class="form_input">
          <div class="select_wrapper arrow_down medium inventory_person">
            <select v-model="supplierId"
                    class="form_option"
                    @change="searchItems()"
            >
              <option value="" selected>全ての発注先</option>
              <option v-for="(supplier, index) in suppliers" :key="index" :value="supplier.id">
                {{ supplier.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form_row center chanel_text_padding mb_medium">
        <label class="form_label label-title">カテゴリー絞込</label>
        <div class="form_input">
          <div class="select_wrapper arrow_down medium inventory_person">
            <select v-model="inventoryCategoryId"
                    class="form_option"
                    @change="searchItems()"
            >
              <option value="" selected>全てのカテゴリー</option>
              <option v-for="(item, index) in inventoryCategories" :key="index" :value="item.id">
                {{ item.name }}
              </option>
              <option value="0">未選択</option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="!inventoryManageHistoryId" class="form_row center chanel_text_padding mb_medium mt_medium">
        <label class="form_label label-title-checkbox">発注商品のみ表示</label>
        <div class="form_input">
          <label class="btn_checkbox">
            <input
              v-model="checkOrder"
              class="owner_padding_checkbox"
              false-value="0"
              true-value="1"
              type="checkbox"
              @change="searchItems()"
            >
            <span class="checkmark_checkbox top-0" />
          </label>
        </div>
      </div>
      <div v-if="!inventoryManageHistoryId" class="input_wrapper search mb_medium input_wrapper-sm chanel_text_padding search_pc">
          <input v-model="text_search" type="text" placeholder="商品を検索" @input="onSearch()">
      </div>
      <table class="sc_table is-pc-table">
        <thead>
          <tr>
            <th class="w_60">発注先/商品名</th>
            <th class="w_20">在庫</th>
            <th class="w_20">カテゴリー</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items"
              :key="index" @click="openDialog(item)">
            <td>
              <div class="form_image">
                <img :src="item.img === null ? noImage : item.img" alt="" />
              </div>
              <div class="mb_medium_right d-flex">
                <div class="form_detail w_30-pt">
                  <div>
                    <label class="form_label_name"><div class="max-line text-font-size">{{ item.name }}</div></label>
                  </div>
                  <div class="form_row">
                    <div class="max-line text-font-size">{{ item.supplier_name || item.supplier_official_name }}</div>
                  </div>
                </div>
                <div class="form_row center chanel_text_padding w_60-pt">
                  <span class="price_span text-font-size">単価（税込）</span>
                  <div class="form_input">
                    <input
                      v-model=item.price
                      type="number"
                      class="form-control price"
                      @keypress="changePrice($event)"
                      @keyup="changePrice($event)"
                    >
                    <span class="text-font-size pc-inline" style="margin-left: 10px;">円</span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <input
                v-model= item.item_qty
                type="text"
                class="form-control item_qty_input"
                placeholder="数量"
                @paste.prevent
                @wheel="$event.target.blur()"
                @keypress="changeQuantity($event, item)"
                @keyup="changeQuantity($event, item)"
                @blur="blurChangeQty($event, item)"
              >
            </td>
            <td>
              <div class="form_input is-pc-type">
                <div class="select_wrapper arrow_down inventory">
                  <select v-model="item.inventory_category_id">
                    <option value=""></option>
                    <option v-for="(item, index) in inventoryCategories" :key="index" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="sc_table is-ipad-table">
        <thead>
          <tr>
            <th class="w_50">発注先/商品名</th>
            <th class="w_25">在庫</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items"
              :key="index" @click="openDialog(item)">
            <td>
              <div class="form_image">
                <img :src="item.img === null ? noImage : item.img" alt="" />
              </div>
              <div class="mb_medium_right">
                <div class="form_detail">
                  <div>
                    <label class="form_label_name"><div class="max-line text-font-size">{{ item.name }}</div></label>
                  </div>
                  <div class="form_row">
                    <div class="max-line text-font-size">{{ item.supplier_name || item.supplier_official_name }}</div>
                  </div>
                </div>
                <div class="form_row center chanel_text_padding">
                  <span class="price_span text-font-size">単価（税込）</span>
                  <div class="form_input">
                    <input
                      v-model=item.price
                      type="number"
                      class="form-control price"
                      @keypress="changePrice($event)"
                      @keyup="changePrice($event)"
                    >
                    <span class="text-font-size pc-inline" style="margin-left: 10px;">円</span>
                  </div>
                  <div class="mobile">
                    <span class="price_span"><span class="text-font-size">{{ showPrice(item.price) }}</span> 円</span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <input
                v-model= item.item_qty
                type="text"
                class="form-control item_qty_input"
                placeholder="数量"
                @paste.prevent
                @wheel="$event.target.blur()"
                @keypress="changeQuantity($event, item)"
                @keyup="changeQuantity($event, item)"
                @blur="blurChangeQty($event, item)"
              >
              <div class="form_input is-pc-type">
                <div class="select_wrapper arrow_down inventory">
                  <select v-model="item.inventory_category_id">
                    <option value=""></option>
                    <option v-for="(item, index) in inventoryCategories" :key="index" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="is-mobile">
                <div class="block-item-center">
                  <label class="text-font-size" style="margin-bottom: 10px">{{ item.item_qty}}</label>
                  <div class="text-font-size" v-for="(value, index) in inventoryCategories" :key="index">
                    <template v-if="value.id === item.inventory_category_id">表{{index + 1}}</template>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="items === null || items.length === 0" class="text-center mt-28">表示するデータはありません。</div>
      <scroll-loader :loader-method="onPageChange" :loader-disable="disable" />
    </div>
    <div class="bottom_menu" :class="is_not_submit ? 'open' : ''">
      <h2 class="bottom_menu_title">確認</h2>
      <div class="mb_large">
        <div>棚卸に登録するデータがありません。</div>
        <div>棚卸内容を編集してください。</div>
      </div>
      <div class="btn_area two_items">
        <a class="btn primary_border router-link-exact-active active" style="cursor: pointer;" @click="is_not_submit = false">閉じる</a>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="is_not_submit ? 'open' : ''"></div>
    <div class="bottom_menu" :class="is_not_back ? 'open' : ''">
      <h2 class="bottom_menu_title">確認</h2>
      <div class="mb_large">
        <div>棚卸の内容が保存されていません。</div>
        <div>保存せずに画面を移動しますか？</div>
      </div>
      <div class="btn_area two_items">
        <router-link to="/inventory" class="btn primary router-link-exact-active active" style="margin-right: 20px;">保存せずに移動する</router-link>
        <a class="btn primary_border router-link-exact-active active" style="cursor: pointer;" @click="is_not_back = false">キャンセル</a>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="is_not_back ? 'open' : ''"></div>
    <div class="bottom_menu is-mobile-block" :class="dialog ? 'open' : ''">
      <h2 class="bottom_menu_title">
        {{name_form}}
      </h2>
      <div class="mb_medium">
        <div class="form_input">
          <label for="company" class="mb_small">単価 （税込）</label>
          <div class="input_wrapper input_suggest">
            <input
              v-model=price_form
              type="number"
              placeholder="単価(税込)を入力"
              class="padding-8px"
              @keypress="changePrice($event, price_form)"
              @keyup="changePrice($event, price_form)"
            > 
          </div>
        </div>
      </div>
      <div class="mb_medium">
        <label for="company" class="mb_small">在庫</label>
        <div class="input_wrapper input_suggest">
          <input
            v-model= qty_form
            type="number"
            placeholder="数量"
            class="padding-8px"
            @keypress="changeQuantity($event)"
            @keyup="changeQuantity($event)"
          >
        </div>
      </div>
      <div class="mb_medium">
        <label for="company" class="mb_small">カテゴリー</label>
        <div class="select_wrapper arrow_down inventory">
          <select v-model="category_id_form" class="form_select padding-8px">
            <option value=""></option>
            <option v-for="(item, index) in inventoryCategories" :key="index" :value="item.id">
              {{item.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary margin-right-10 max-width-250" @click.native="saveForm" >保存</router-link>
        <router-link :to="{}" class="btn primary_border max-width-250" @click.native="closeDialog">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg is-mobile-block" :class="dialog ? 'open' : ''"></div>
    <div class="control_area">
      <a class="icon_circle" @click="onBack">
        <div class="icon back for_controlArea" />
      </a>
      <div class="btn_area control two_items max-width-250">
        <a href="javascript:void(0);" class="btn primary max-width-250" @click="onSave">保存する</a>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialogNote ? 'open' : ''"></div>
    <div class="bottom_menu" :class="dialogNote ? 'open' : ''">
      <h2 class="bottom_menu_title" style="margin-bottom: 0;">
        登録確認
      </h2>
      <div>
        <p>※棚卸表は、業務の特性上、新規作成すると過去の棚卸表の編集ができなくなります</p>
        <p style="margin-bottom: 5px;">備考が必要な場合には入力してください</p>
      </div>
      <div class="mb_medium">
        <div class="form_input">
          <textarea
            id=""
            v-model="note"
            name=""
            placeholder="備考を入力"
            cols="30"
            rows="5"
          />
          <span v-for="error in errors['note']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary margin-right-10 max-width-250" @click.native="onSave" >保存</router-link>
        <router-link :to="{}" class="btn primary_border max-width-250" @click.native="closePopupNote">キャンセル</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import utils from '../../common/util.js';
import { SuppliersService } from '../../services/suppliers.service';
import { OrderStaffsService } from '../../services/order_staffs.service';
import { ItemsService } from '../../services/items.service';
import { InventoryCategoriesService } from '../../services/inventory_categories.service'
import { InventoryManageHistoriesService } from '../../services/inventory_manage_histories.service'
import noImage from '../../../assets/images/no_image.png';
import Vue from 'vue';
import ScrollLoader from 'vue-scroll-loader';
Vue.use(ScrollLoader);

import Validator from '../../validator';
export default {
  data() {
    return {
      disable: true,
      seqSearch: 0,
      errors: [],
      is_not_submit: false,
      is_not_back: false,
      id_item_form: null,
      qty_form: null,
      category_id_form: null,
      name_form: null,
      price_form: null,
      text_search: null,
      inventoryTime: null,
      checkOrder: '0',
      dialog: false,
      items: [],
      listItems: [],
      itemsInventory: [],
      supplierId: '',
      suppliers: [],
      orderStaffs: [],
      orderStaffId: null,
      orderStaffIdOld: null,
      inventoryCategoryId: '',
      inventoryCategories: [],
      qty_val: '',
      noImage: noImage,
      messages: [],
      inventoryManageHistoryId: this.$router.history.current.params.id ? this.$router.history.current.params.id : null,
      inventoryManageHistory: [],
      pager: {
        page: 1,
        totalPage: 0,
        pageSize: 30,
      },
      note: '',
      dialogNote: false,
      errors: []
    };
  },

  async mounted() {
    await this.getSuppliers();
    await this.getOrderStaffs();
    await this.getInventoryCategories();
    this.inventoryManageHistoryId ? await this.getItemsEdit() : await this.getItems();
    this.orderStaffId = this.orderStaffIdOld;
  },

  methods: {
    ...utils,
    openDialog(item) {
      this.name_form = item.name;
      this.id_item_form = item.id;
      this.price_form = item.price;
      this.qty_form = item.item_qty;
      this.category_id_form = item.inventory_category_id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    saveForm() {
      this.qty_form = (Math.floor(this.qty_form * 10) / 10).toString();
      for (let i in this.items) {
        if (this.id_item_form === this.items[i].id) {
          this.items[i].price = this.price_form;
          this.items[i].item_qty = this.qty_form;
          this.items[i].inventory_category_id = this.category_id_form;
        }
      }
      this.dialog = false;
    },
    onPageChange() {
      this.pager.page++;
      this.inventoryManageHistoryId ? this.getItemsEdit() : this.getItems();
    },
    getSuppliers: async function() {
      this.$loading(true);
      try {
        const { response } = await SuppliersService.index({});
        this.suppliers = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }

      this.$loading(false);
    },
    async getInventoryCategories() {
      this.$loading(true);
      try {
        const { response } = await InventoryCategoriesService.index();
        this.inventoryCategories = response.data;

        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    async getOrderStaffs() {
      this.$loading(true);
      try {
        const { response } = await OrderStaffsService.index();
        this.orderStaffs = response.data;

        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    async getItems() {
      this.seqSearch++;
      this.checkItemsInventory();
      this.$loading(true);
      try {
        const params = {
          supplier_id: this.supplierId,
          inventory_category_id: this.inventoryCategoryId,
          keyword: this.text_search,
          page: this.pager.page,
          page_size: this.pager.pageSize,
          check_order: this.checkOrder,
          seq_search: this.seqSearch
        };
        const { response } = await ItemsService.index(params);
        if (this.seqSearch == response.seq_search) {
          this.pager.page = response.pager.page;
          this.pager.totalPage = response.pager.page_count;
          this.disable = this.pager.page === this.pager.totalPage ? true : false;
          this.items = this.pager.page === 1 ? response.data : [...this.items, ...response.data];
          this.mapIsChangeItemQty();
          this.getItemToList();
          this.convertDataItem();
          this.$loading(false);
        }
      } catch (error) {
        this.$loading(false);
      }
    },
    async getItemsEdit() {
      this.$loading(true);
      try {
        const params = {
          id: this.inventoryManageHistoryId,
          category_id: this.inventoryCategoryId,
          page: this.pager.page,
          page_size: this.pager.pageSize,
        };
        const { response } = await InventoryManageHistoriesService.getDetails(params);
        this.inventoryManageHistory = response.data;
        this.pager.page = response.pager.page;
        this.pager.totalPage = response.pager.page_count;
        this.disable = this.pager.page === this.pager.totalPage ? true : false;
        this.items = this.pager.page === 1 ? response.data.items : [...this.items, ...response.data.items];
        this.note = this.inventoryManageHistory.note;
        this.mapItemQty();
        this.getItemToList();
        this.convertDataItem();
        this.orderStaffIdOld = this.inventoryManageHistory.order_staff_id;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    mapIsChangeItemQty() {
      this.items.map(function (item) {
        if (!item.is_change_item_qty) return item.is_change_item_qty = false;
      });
    },
    mapItemQty() {
      this.items.map(function (item) {
        item.item_qty = item.update_calc === 'plus' ? (item.qty_history + item.quantity) : (item.qty_history - item.quantity);
        item.item_qty = Number.isInteger(item.item_qty) ? item.item_qty : item.item_qty.toFixed(1);
        return item.item_qty;
      });
    },
    checkItemsInventory() {
      for (let i in this.items) {
        var item = this.listItems.filter(item => item.id === this.items[i].id);
        if (item.length > 0) {
          if ((this.items[i].price != item[0].price && this.items[i].price != '') ||
          this.items[i].inventory_category_id != item[0].inventory_category_id ||
          this.items[i].item_qty != item[0].item_qty) {
            if (this.itemsInventory.filter(itemInventory => itemInventory.id == this.items[i].id).length === 0) {
              if (this.items[i].item_qty != item[0].item_qty) {
                this.items[i].is_change_item_qty = true;
              }
              this.itemsInventory.push(this.items[i]);
            }
            else if (this.items[i].item_qty == item[0].item_qty) {
              for (let j = 0; j < this.itemsInventory.length; j++) {
                if (this.items[i].id === this.itemsInventory[j].id) {
                  this.itemsInventory[j].is_change_item_qty = false;
                }
              }
            }
          }
          else {
            for (let j = 0; j < this.itemsInventory.length; j++) {
              if (this.items[i].id === this.itemsInventory[j].id) {
                this.itemsInventory.splice(j, 1);
                j--;
              }
            }
          }
        }
      }
    },
    convertDataItem() {
      for (let i in this.items) {
        this.items[i] = this.itemsInventory.filter(
          item => item.id === this.items[i].id
        ).length === 0 ? this.items[i] : this.itemsInventory.filter(item => item.id === this.items[i].id)[0];
      }
    },
    getItemToList() {
      var items = this.items.map(item => new Object({
        id: item.id,
        item_qty: item.item_qty,
        price: item.price,
        inventory_category_id: item.inventory_category_id,
      }));
      for (let i in items) {
        if (this.listItems.filter(item => item.id === this.items[i].id).length === 0) {
          this.listItems.push(items[i]);
        }
      }
    },
    async onSave() {
      this.checkItemsInventory();
      if (this.itemsInventory.length > 0 || (this.inventoryManageHistoryId && this.orderStaffId != this.orderStaffIdOld)) {
        this.validate();
        if (Object.keys(this.errors).length) return;
        
        if (this.itemsInventory.filter(item => item.is_change_item_qty === true).length > 0 ||
          (this.inventoryManageHistoryId && (this.orderStaffId != this.orderStaffIdOld || this.itemsInventory.length > 0))) {
          this.dialogNote = !this.dialogNote;
          if (this.dialogNote) return;
        }

        this.$loading(true);
        try {
          const inventoryManageHistories = this.itemsInventory.map((item) => {
            return {
              item_id: item.id,
              price: item.price,
              item_qty: item.item_qty,
              inventory_category_id: item.inventory_category_id,
              item_qty_history_id: item.item_qty_history_id,
              is_change_item_qty: item.is_change_item_qty
            };
          });
          const params = {
            inventory_manage_histories: inventoryManageHistories,
            order_staff_id: this.orderStaffId,
            note: this.note
          }
          this.inventoryManageHistoryId ? 
            await InventoryManageHistoriesService.update(this.inventoryManageHistoryId, params) : await InventoryManageHistoriesService.create(params);
          this.$router.push('/inventory');
          this.$loading(false);
        } catch (error) {
          this.$loading(false);
        }
      }
      else {
        this.is_not_submit = true;
      }
    },
    searchItems() {
      this.pager.page = 1;
      this.inventoryManageHistoryId ? this.getItemsEdit() : this.getItems();
    },
    onSearch: _.debounce(async function() {
      this.searchItems();
    }, 500),
    onBack() {
      this.checkItemsInventory();
      this.itemsInventory.length > 0 ? this.is_not_back = true : this.$router.push('/inventory');
    },
    changePrice(event) {
      if(event.target.value.length >= 9 || !/\d/.test(event.key)) {
        event.preventDefault();
      }
    },
    blurChangeQty(event, item) {
      if (event.target.value === '') {
        event.target.value = '0';
      }
      item.item_qty = event.target.value;
    },
    changeQuantity(event) {
      if (/^[+-]?\d+(\.\d{0,1})?$/.test(event.target.value) || /^\d{0,6}(?:\.\d{0,1})?$/.test(event.target.value)) {
        this.qty_val = event.target.value;
      } else {
        event.target.value = this.qty_val;
        return event.preventDefault();
      }
      if ((event.target.value.indexOf('.') !== -1 && event.key === '.') || (!/\d/.test(event.key) && event.key !== '.') || event.target.value.length >= 9) {
        event.preventDefault();
      }
    },
    closePopupNote() {
      this.note = this.inventoryManageHistoryId ? this.inventoryManageHistory.note : '';
      this.dialogNote = false;
    },
    validate() {
      const validator = new Validator();
      validator.checkMaxLength('note', this.note, 255, '登録確認');
      this.errors = validator.errors;
    }
  },
};
</script>
<style lang="scss" scoped>
.tab_area {
  position: unset;
  top: 0px;
  margin-bottom: 0px;
}
.form_image {
  width: 40px;
  height: 60px;
  padding: 10px 20px 20px 0;
  float: left;
}
.form-control {
  width: 100%;
  text-align: right;
  height: 40px;
}
.inventory {
  background: #ffffff;
}
.form_select {
  height: 40px;
  padding: 0px;
}
.form_label {
  font-size: 16px;
  width: 160px;
}
.form_detail {
  margin-left: 40px;
  font-size: 20px;
  padding-top: 20px;
  height: 80px;
}
.btn_checkbox input:checked ~ .checkmark_checkbox {
  background-color: #966544;
}
.btn_checkbox .checkmark_checkbox:after {
  border: 1px solid white;
  border-width: 0 3px 3px 0;
}
img {
  width: 40px;
  height: 60px;
  object-fit: contain;
}
.btn_checkbox {
  margin-bottom: 20px;
}
.form-input {
  width: unset;
  width: 100%;
}
.mobile {
  display: none;
}
.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1.2;
}
.pc-inline {
  display: inline;
}
.item_qty_input::placeholder {
  text-align: left;
}

.w_30-pt {
  width: 30%;
}
.w_60-pt {
  width: 60%;
}
.mb-20 {
  margin-bottom: 20px;
}
.select_wrapper select {
  line-height: 1.3;
  padding: 14px;
}
@media screen and (max-width: 1140px) {
  .form-control {
    margin-bottom: 20px;
  }
  .form_image {
    padding: 30px 20px 20px 0;
  }
  .price_span {
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .form_detail {
    padding: 20px 20px;
  }
  .mb_medium_right {
    .form_row.center.chanel_text_padding {
      margin-left: 40px;
      padding-left: 20px;
    }
  }
}
.margin-right-10 {
  margin-right: 10px;
}
@media screen and (max-width: 480px) {
  .pc-inline {
    display: none;
  }
  .select_wrapper.medium {
    width: 100%;
  }
  .input_wrapper.medium {
    width: 100%;
  }
  .sc_table thead tr th.w_50 {
    width: 80%;
  }
  .sc_table thead tr th.w_25 {
    width: 20%;
  }
  .price_span {
    padding-bottom: 0px;
  }
  .mobile {
    display: unset;
  }
  .ipad {
    display: none;
  }
  .form_detail {
    margin-left: 40px;
    font-size: 20px;
    padding: 0 20px 20px 20px;
    height: 50px;
  }
  .form_image {
    padding-top: 0;
  }
  .padding-8px {
    padding: 8px;
  }
  .form_label {
    width: 130px;
  }
  .top-0 {
    top: 0;
  }
  .label-title-checkbox {
    width: 200px;
  }
  .two_items {
    width: 100%;
  }
  .margin-right-10 {
    margin-right: 10px;
  }
  .max-width-250 {
    max-width: 250px !important;
  }
  .select_wrapper select {
    padding: 6px;
  }
}
</style>
