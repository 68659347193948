<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <div class="flex alignCenter mb_medium">
        <h1 class="title padding_text">商品選択</h1>
        <div class="flex justEnd padding_text alignCenter">
          <input
            ref="checkbox"
            type="checkbox"
            class="checkbox btn_point"
            :checked="isFavorite"
          >
          <label class="checkbox_label mr_small btn_point" @click="() => checkIsFavorite()" />
          <label class="btn_point label_normal" @click="() => checkIsFavorite()">お気に入りのみで表示</label>
        </div>
      </div>
      <div v-if="params.supplier_id" class="list_text mb_medium list_text-w chanel_text_padding">
        <p class="list_text"><span class="bold">発注先：</span>{{ supplierName }}</p>
      </div>
      <div class="list_search">
        <div class="input_wrapper search mb_medium input_wrapper-sm chanel_text_padding search_pc">
          <input v-model="params.keyword" type="text" placeholder="商品名で検索"
                 @input="onChange"
          >
        </div>
        <div class="flex justEnd mb_large chanel_text_padding search_pc search_option">
          <div class="select_wrapper arrow_down flex_fourty">
            <select id="filter" v-model="params.sort" name="filter"
                    class="search_select"
                    @change="onChangeOption"
            >
              <option value="new_items">商品登録日順</option>
              <option value="most_ordered">注文回数順</option>
              <option value="recently_ordered">最近注文した順</option>
              <option value="product_no">品番順</option>
              <option value="items_name">商品名順</option>
            </select>
          </div>
        </div>
      </div>
      <OrderSelect
        :supplier-items="supplierItems"
        :active-name="activeName"
        :change-order="changeOrder"
        :setting-image="settingImage"
        :handle-favorite="handleFavorite"
      />
      <scroll-loader :loader-method="handleData" :loader-disable="disable" />
    </div>
    <div class="control_area">
      <div to="" class="icon_circle" @click="back">
        <div class="icon back for_controlArea" />
      </div>
      <div class="btn_area control two_items">
        <router-link :to="{}" class="btn primary" :class="orderSelected.length > 0 ? '' : 'disabled'" @click.native="onSubmit">発注リストに追加する</router-link>
      </div>
      <div class="bottom_menu" :class="dialog ? 'open' : ''">
        <h2 class="bottom_menu_title">
          更新完了
        </h2>
        <div class="mb_medium">
          <p class="mb_small mb_small-left mb_small-md">発注リストの内容を更新しました。</p>
        </div>
        <div class="btn_area two_items">
          <router-link :to="{}" class="btn primary mr-5" @click.native="toOrderCartForce">注文に進む</router-link>
          <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeDialog">閉じる</router-link>
        </div>
      </div>
      <!-- popup err -->
      <div class="bottom_menu" :class="dialogErr ? 'open' : ''">
        <h2 class="bottom_menu_title text_color-reb">
          エラー
        </h2>
        <div class="mb_medium">
          <p class="mb_small-left">発注リストに選択商品がありません。</p>
          <p class="mb_small mb_small-left mb_small-md">発注作成できません。</p>
        </div>
        <div class="btn_area two_items">
          <router-link :to="{}" class="btn primary_border" @click.native="closeDialogErr">閉じる</router-link>
        </div>
      </div>
      <!--  -->
      <div class="bottom_menu" :class="dialogErrConfirm ? 'open' : ''">
        <h2 class="bottom_menu_title">
          更新完了
        </h2>
        <div class="mb_medium">
          <p class="mb_small mb_small-left mb_small-md">発注リストの内容を更新しました。</p>
        </div>
        <div class="btn_area two_items">
          <router-link :to="{}" class="btn primary" @click.native="toOrderCartForce">注文に進む</router-link>
          <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeDialog">閉じる</router-link>
        </div>
      </div>

      <!-- popup min_order_err -->
      <div class="bottom_menu dialog_cart" :class="dialogErrMinOrder ? 'open' : ''">
        <h2 class="bottom_menu_title text_color-reb">
          エラー
        </h2>
        <div class="list_err">
          <p v-for="(supplier_err, index) in listErrMinAmount" :key="index" class="mb_small mb_small-left mb_small-md">
            <router-link :to="{}" @click.native="redirectSelectItem(supplier_err.id)">{{ supplier_err.name }}</router-link> の最低発注金額を満たしていません。
          </p>
        </div>
        <p class="mb_small mb_small-left mb_small-md">※数量の変更のみの場合は発注予定リストから調整可能です。</p>
        <div class="btn_area two_items">
          <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeDialog">閉じる</router-link>
        </div>
      </div>

      <!-- popup search -->
      <div class="bottom_menu search_mb" :class="openSearch ? 'open' : ''">
        <h2 class="bottom_menu_title">
          絞り込み検索
        </h2>
        <div class="mb_medium">
          <label for="company" class="mb_small">商品名検索</label>
          <div class="input_wrapper">
            <input v-model="params.keyword" type="text" placeholder="商品名で検索">
          </div>
        </div>

        <div class="mb_xxlarge">
          <label for="company" class="mb_small">並び替え</label>
          <div class="select_wrapper arrow_down flex_fourty">
            <select id="filter" v-model="params.sort" name="filter">
              <option value="new_items">商品登録日順</option>
              <option value="most_ordered">注文回数順</option>
              <option value="recently_ordered">最近注文した順</option>
              <option value="product_no">品番順</option>
              <option value="items_name">商品名順</option>
            </select>
          </div>
        </div>
        <div class="btn_area two_items">
          <router-link :to="{}" class="btn primary" @click.native="search">検索する</router-link>
          <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="closeSearchPopup">キャンセル</router-link>
        </div>
      </div>
      <!-- --- -->
      <div class="bottom_menu_bg" :class="dialog || openSearch || dialogErr || dialogErrConfirm || dialogErrMinOrder || dialogAddItem ? 'open' : ''" />
      <div to="" class="icon_circle icon_search_mb icon_circle_search" @click="searchItem">
        <div class="icon search for_controlArea icon_search_order" />
      </div>
    </div>
    <!-- popup confirm add item -->
    <div class="bottom_menu" :class="dialogAddItem ? 'open' : ''">
      <h2 class="bottom_menu_title">
        追加完了
      </h2>
      <div class="mb_medium">
        <p class="mb_small-left">発注予定リストに商品を追加しました。</p>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" class="btn primary mr-5" @click.native="toOrderCartForce">注文に進む</router-link>
        <router-link :to="{}" class="btn primary_border" @click.native="closeDialogAddItem">閉じる</router-link>
      </div>
    </div>
    <!--  -->
  </main>
</template>
<script>

import { SupplierItemsService } from '../../services/supplier_items.service';
import { SuppliersService } from '../../services/suppliers.service';
import OrderSelect from './component/OrderSelect.vue';
// import Paging from '../../components/Pager';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import ScrollLoader from 'vue-scroll-loader';

import { CartsService } from '../../services/carts.service';
Vue.use(ScrollLoader);

export default {
  components: {
    OrderSelect,
    // Paging
  },
  data() {
    return {
      totalPage: 0,
      page: 1,
      params: {
        supplier_id:  this.$router.history.current.params.id ?  this.$router.history.current.params.id : '',
        keyword: '',
        sort: 'new_items',
        page: 1,
        page_size: 30
      },
      supplierItems: [],
      supplierName: '',
      activeName: !this.$router.history.current.params.id ? true : false,
      orderSuppliers: [],
      orderSelected: [],
      dialog: false,
      openSearch: false,
      loadMore: true,
      disable: true,
      activeAdd: true,
      dialogErr: false,
      dialogErrConfirm: false,
      listErrMinAmount: [],
      dialogErrMinOrder: false,
      ErrMinOrder: false,
      listSupplierIndex: [],
      isFavorite: false,
      dialogAddItem: false
    };
  },
  computed: {
    ...mapGetters({
      orders: 'getAllOrders',
      OrderFlag: 'getHasOrdersFlag',
      settingImage: 'getSettingimage'
    }),
    disableBtnConfirm() {
      const correctData = this.orderSelected.find(p => { return parseFloat(p.qty) > 0 && !isNaN(parseInt(p.price)); });
      return (this.orders.length === 0 && this.orderSelected.length === 0) || (this.orderSelected.length > 0 && !correctData);
    },
    disableBtn() {
      const correctData = this.orderSelected.find(p => { return parseFloat(p.qty) > 0 && !isNaN(parseInt(p.price)); });
      return (this.orders.length === 0 && this.orderSelected.length === 0) || (this.orderSelected.length > 0 && !correctData) || this.activeAdd == true;
    }
  },
  watch: {
    async page() {
      this.getSupplierItem();
    },
    disableBtn(newVal) {
      this.$store.dispatch('setHasOrdersFlag', newVal? false : true);
    },
    disableBtnConfirm(newVal) {
      this.$store.dispatch('setHasOrdersFlag', newVal? false : true);
    },
    orders() {
      this.handleConfirm();
    },
    isFavorite() {
      this.params.page = 1;
      this.orderSuppliers = [];
      this.getSupplierItem();
    }
  },
  created() {
    this.$store.dispatch('setHasOrdersFlag', false);
    this.$store.dispatch('setOrders', []);
  },
  async mounted() {
    const listOrders = [...this.orders];
    this.orderSelected = listOrders;
    if (this.params.supplier_id) {
      this.getSupplier();
    }
    this.getSupplierItem();
    const { response } = await SupplierItemsService.index();
    this.listSupplierIndex = response.data;
  },
  methods: {
    async getSupplier() {
      this.$loading(true);
      try {
        const { response } = await SuppliersService.get(this.params.supplier_id);
        this.supplierName = response.data.name ? response.data.name : response.data.supplier_official_name;
        this.$loading(false);
      } catch (error) {
        console.log(error);
        this.$loading(false);
      }
    },
    async getSupplierItem() {
      this.$loading(true);
      try {
        const { response } = await SupplierItemsService.index({ ...this.params, is_favorite: this.isFavorite });
        this.params.page = response.pager.page;
        this.totalPage = response.pager.page_count;
        if(this.params.page == 1) {
          if(this.params.page == this.totalPage) {
            this.disable = true;
          } else {
            this.disable = false;
          }
          this.orderSuppliers = response.data;
        } else {
          this.orderSuppliers = [...this.orderSuppliers, ...response.data];
        }
        this.hanlderSupplierItem();
        this.$loading(false);
      } catch (error) {
        console.log(error);
        this.$loading(false);
      }
    },
    hanlderSupplierItem() {
      const list = this.orderSuppliers.reduce((list, item) => {
        const ordered = this.orderSelected.find(p => p.id === item.id && p.supplier_id === item.supplier_id);
        if (ordered) {
          list = [...list, {
            ...item,
            qty: ordered.qty,
            price: ordered.price,
            message: ordered.message,
            sendNow: ordered.sendNow,
            scheduledTime: ordered.scheduledTime,
            delivery_date: ordered.delivery_date
          }];
        } else {
          const index = this.orders.findIndex((p) => p.id === item.id && p.supplier_id === item.supplier_id);
          if (index > -1) {
            list = [...list, { ...item, qty: this.orders[index].qty, float_err: false, int_err: false }];
          } else {
            list = [...list, { ...item, qty: '', float_err: false, int_err: false }];
          }
        }
        return list;
      }, []);
      this.supplierItems = list;
    }, /* Get and show item data */
    onChange: _.debounce(async function() {
      this.params.page = 1;
      this.getSupplierItem();
    }, 500),
    onChangeOption() {
      this.params.page = 1;
      this.getSupplierItem();
    },
    changeOrder(supplier_item) {
      const idx = this.orderSelected.findIndex(p => p.id === supplier_item.id && p.supplier_id === supplier_item.supplier_id);
      if (idx === -1 && supplier_item.qty !== '' && parseFloat(supplier_item.qty) >= 0) {
        this.orderSelected.push(supplier_item);
      } else if(idx !== -1) {
        if (supplier_item.qty.toString() == '' || parseFloat(supplier_item.qty) <= 0) {
          this.orderSelected.splice(idx, 1);
        } else {
          this.orderSelected[idx] = supplier_item;
        }
      }
      this.handleConfirm();
    },
    async toOrderCart() {
      await this.updateCart();
      if(this.orderSelected.length == 0) {
        this.setOrderSelected();
        this.dialogErr = true;
        this.dialog = false;
      } else {
        this.setOrderSelected();
        if(!this.dialogErrMinOrder) {
          return this.$router.push('/order/confirm');
        }
      }
    },
    async toOrderCartForce() {
      return this.$router.push('/order/order_cart');
    },
    async setOrderSelected() {
      this.listErrMinAmount = [];
      var grouped = _.mapValues(_.groupBy(this.orderSelected, 'supplier_id'),
        clist => {
          const totalAmount = clist.reduce((accumulator, item) => accumulator + (item.qty * item.price), 0);
          return {total_amount: totalAmount};
        });
      for (let key in grouped) {
        const supplier = this.listSupplierIndex.find(supplier => supplier.supplier_id == key);
        if(supplier && supplier.min_order_amount > grouped[key].total_amount) {
          this.dialogErrMinOrder = true;
          this.listErrMinAmount.push({ id: supplier.supplier_id, name: supplier.supplier_name });
        }
      }
      if(this.dialogErrMinOrder) {
        return;
      }

      this.orderSelected.forEach((item) => {
        item['delivery_date'] = item.delivery_date;
        item['message'] = item.message;
      });
      await this.$store.dispatch('setOrders', this.orderSelected);
    },
    async updateCart() {
      this.listErrMinAmount = [];
      var grouped = _.mapValues(_.groupBy(this.orderSelected, 'supplier_id'),
        clist => {
          const totalAmount = clist.reduce((accumulator, item) => accumulator + (item.qty * item.price), 0);
          return {total_amount: totalAmount};
        });

      for (let key in grouped) {
        const supplier = this.orderSuppliers.find(supplier => supplier.supplier_id == key);
        if(supplier && supplier.min_order_amount > grouped[key].total_amount) {
          this.ErrMinOrder = true;
          this.listErrMinAmount.push({ id: supplier.supplier_id, name: supplier.supplier_name });
        }
      }

      this.orderSelected.forEach((item) => {
        item['delivery_date'] = item.delivery_date;
        item['message'] = item.message;
      });
      await this.$store.dispatch('setOrders', this.orderSelected);
    },
    async confirmRedirect() {
      await this.updateCart();
      this.dialog = true;
      if(this.orders.length == 0) {
        this.dialogErrConfirm = true;
      } else {
        this.dialog = true;
      }
    },
    closeDialog() {
      this.hanlderSupplierItem();
      this.dialog = false;
      this.dialogErrConfirm = false;
      this.dialogErrMinOrder = false;
    },
    async back() {
      if (!this.disableBtn) {
        const res = await this.$confirmRedirect();
        if(!res) return;
      }
      if (this.$router.history.current.path == '/order/item_select') {
        return this.$router.push({path: '/dashboard'});
      } else {
        return  this.$router.push('/order/select_company');
      }
    },
    async changeErrQty(supplier_item) {
      this.supplierItems = this.supplierItems.map((obj) => {
        if (supplier_item.id == obj.id) {
          return obj = supplier_item;
        } else {
          return obj;
        }
      });
    },
    searchItem() {
      this.openSearch = true;
    },
    closeSearchPopup() {
      this.openSearch = false;
    },
    search() {
      this.params.page = 1;
      this.getSupplierItem();
      this.openSearch = false;
    },
    handleData() {
      this.params.page = this.params.page + 1;
      this.getSupplierItem();
      if(this.params.page == this.totalPage) {
        return this.disable = true;
      }
    },
    closeDialogErr() {
      this.dialogErr = false;
    },
    handleConfirm() {
      const init_orders = [...this.orders];
      if(init_orders.length !== this.orderSelected.length) {
        this.activeAdd = false;
      } else {
        for (var i = 0; i < init_orders.length; i++) {
          const order_equal = this.orderSelected.findIndex(p => p.id == init_orders[i].id &&
                                                                p.supplier_id == init_orders[i].supplier_id &&
                                                                p.qty != init_orders[i].qty);
          const order_exist = this.orderSelected.find(p => p.id == init_orders[i].id &&
                                                           p.supplier_id == init_orders[i].supplier_id &&
                                                           p.qty == init_orders[i].qty);

          if(!order_exist && order_equal == -1) {
            this.activeAdd = false;
            break;
          } else if(order_equal != -1) {
            this.activeAdd = false;
            break;
          } else {
            this.activeAdd = true;
          }
        }       
      }
    },
    redirectSelectItem(id) {
      this.dialogErrMinOrder = false;
      window.location.href =`/order/item_select/${id}`;
    },
    async handleFavorite(index, supplier_item_id) {
      this.$loading(true);
      const params = {
        id: supplier_item_id,
        is_favorite: !this.supplierItems[index].is_favorite
      };
      try {
        await SupplierItemsService.update_favorite(params);
        this.supplierItems[index].is_favorite = !this.supplierItems[index].is_favorite;
        this.search();
      } catch (error) {
        console.log(error);
      }
      this.$loading(false);
    },
    checkIsFavorite() {
      this.isFavorite = !this.isFavorite;
    },

    async onSubmit() {
      const orderSelect = this.orderSelected
      const carts = []
      const result = _.mapValues(_.groupBy(orderSelect, 'supplier_id'),
                          clist => clist.map(orderSelect => _.omit(orderSelect, 'supplier_id')));

      Object.keys(result).forEach(function(key) {
        const cart_items = []

        result[key].forEach(element => {
          cart_items.push({
            item_id: element.id,
            item_qty: element.qty
          })
        });

        carts.push({
          supplier_id: key,
          remark: null,
          cart_items_attributes: cart_items
        })
      });

      const params = { carts: carts }
      
      try {
        await CartsService.create(params);

        await this.cartItem();
        this.dialogAddItem = true;
      } catch (error) {
        console.log(error);
      }
    },
    closeDialogAddItem() {
      this.orderSelected = [];
      this.dialogAddItem = false;
      this.$store.dispatch('setOrders', []);
      this.hanlderSupplierItem();
    },
    async cartItem() {
      const { response } = await CartsService.index();
      let count = 0;

      for (const cart_item of response.data) {
        count += cart_item.cart_items.length;
      }

      this.$store.dispatch('setCountItemSelected', count);
    },
  }
};
</script>
<style lang="scss" scoped>
  .btn_cancel {
    background: unset;
    color: #acacac;
    border-color: #acacac;
  }
  .item_list_content_input {
    display: grid !important;
    grid-template-columns: 60px 1fr 50px!important;
  }
  .btn_area .btn {
    cursor: pointer;
  }
  .list_text-w {
    word-break: break-all;
  }
  .search_mb {
    display: none;
  }
  .icon_search_mb {
    display: none;
  }
  .dialog_cart {
    max-height: 50%;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    .list_err {
      overflow-y: scroll;
    }
  }
  .label_normal {
    font-weight: normal;
    font-size: 20px;
  }
  .search_pc {
    height: fit-content;
  }
  .search_select {
    padding: 17px;
  }
  .mr-5{
    margin-right: 10px;
  }
  @media screen and (max-width: 480px){
    .label_normal {
      font-size: 12px;
    }
    .main_area {
      padding: 0px;
    }
    .search_pc {
      display: none;
    }
    .search_mb {
      display: block;
    }
    .icon_search_mb {
      display: flex;
    }
    .icon_search_order {
      width: 20px;
      height: 20px;
    }
    .btn_two_line {
      padding: 7px 8px;
    }
    .dialog_cart {
      padding: 40px 30px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .input_wrapper {
    &.search {
      &::after {
        right: 40px;
        top: 47%;
      }
    }
    .bottomUp {
      padding: 0 0 120px !important;
    }
    .two_items {
      display: unset !important;
      .btn {
        max-width: unset !important;
      }
      .ml_medium {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    .control_area {
      padding: 15px 24px;
    }
  }
}
</style>
