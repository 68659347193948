import { OWNER_ROLE } from '../../common/variables';

const auth = {
  state: {
    token: '',
    role: null,
    user_id: null,
    login_owner_flag: false,
    cancel_at: null,
    restaurant_cancel_at: null,
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_ROLE(state, payload) {
      state.role = payload;
    },
    SET_USER_ID(state, payload) {
      state.user_id = payload;
    },
    SET_LOGIN_OWNER_FLAG(state, payload) {
      state.login_owner_flag = payload;
    },
    SET_CANCEL_AT(state, payload) {
      state.cancel_at = payload;
    },
    SET_RESTAURANT_CANCEL_AT(state, payload) {
      state.restaurant_cancel_at = payload;
    },
  },
  actions: { },
  getters: {
    getLoginOwnerFlag: state => state.login_owner_flag,
    isOwner: state => state.role === OWNER_ROLE,
    getCancelAt: state => state.cancel_at,
    getRestaurantCancelAt: state => state.restaurant_cancel_at,
  }
};
export default auth;
