const setting_image = {
  state: {
    active: true,
  },
  mutations: {
    CHANGE_OPTION_IMAGE(state, payload) {
      state.active = payload;
    },
  },
  actions: {
    async changeSettingImage({ commit }, data) {
      try {
        commit('CHANGE_OPTION_IMAGE', data);
      } catch (error) {
        commit('CHANGE_OPTION_IMAGE', {});
      }
    },
  },
  getters: {
    getSettingimage: state => state.active
  }
};
  
export default setting_image;
  