import { BaseService } from './base.service';
import { ResponseWrapper, ApiError } from './http';

export class OwnersService extends BaseService {
  static get resourceEndpoint() {
    return 'owners';
  }

  static async resetid(data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/forgot_login_id`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
