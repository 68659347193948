import {BaseService} from './base.service';
import {ApiError, ResponseWrapper} from '../http';

export class NotificationIndividualService extends BaseService {
  static get resourceEndpoint() {
    return 'notification-individual';
  }

  static async get_read_shop_list(id, params) {
    try {
      const response = await this.request().get(`notification/${id}/read-shop-list`, {params});
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

}