<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <div class="wrapper-body_dashboard">
        <h2 class="mb_medium">アカウント管理</h2>
        <div>
          <div class="owner_title_table mb_medium">オーナーアカウント</div>
          <table class="sr_table">
            <thead>
              <tr>
                <th class="large" :class="checkdevice == 'Mobile' ? 'w_35' : ''">法人名</th>
                <th class="">契約日</th>
                <th class="">契約更新日</th>
                <th class="">解約予定日</th>
              </tr>
            </thead>
            <tbody class="to_detail">
              <tr @click="detailOwner">
                <td class="down_line owner_color-blue">{{ owner.name }}</td>
                <td class="">
                  {{ owner.created_at != null ? formatDateWithoutLeadingZeros(owner.created_at) : "" }}
                </td>
                <td class="">
                  {{ owner.contract_update_at != null ? formatDateWithoutLeadingZeros(owner.contract_update_at) : "" }}
                </td>
                <td class="text_color-reb down_line">
                  {{ owner.contract_end_at == null ? "" : formatDateWithoutLeadingZeros(owner.contract_end_at) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex alignCenter between mb_small mt_large">
          <div class="title_owner owner_title_table">店舗アカウント</div>
          <div class="btn_area control">
            <button v-if="owner.status == 'active' && owner.contract_end_at == null" class="btn primary_border fz_medium btn_pointer" @click="newRestaurant">
              店舗の新規登録
            </button>
          </div>
        </div>

        <div v-if="checkdevice == 'PC' || checkdevice == 'Ipad'">
          <table class="sr_table">
            <thead>
              <tr>
                <th class="w_30">店舗名</th>
                <th class="">契約日</th>
                <th class="">契約更新日</th>
                <th class="" :class="checkdevice == 'PC' ? 'w_20' : 'w_19'">解約日</th>
                <th class="">ステータス</th>
                <th class="" :class="checkdevice == 'PC' ? 'w_165px' : 'w_85px'" />
              </tr>
            </thead>
            <tbody class="to_detail">
              <tr v-for="(restaurantAdministrator, index) in restaurantAdministrators" :key="index">
                <td class="down_line owner_color-blue" @click="selectRow(restaurantAdministrator.id)">
                  {{ restaurantAdministrator.shop_name }}
                </td>
                <td class="" @click="selectRow(restaurantAdministrator.id)">
                  {{ restaurantAdministrator.created_at != null ? formatDateWithoutLeadingZeros(restaurantAdministrator.created_at) : "" }}
                </td>
                <td class="" @click="selectRow(restaurantAdministrator.id)">
                  {{ checkUpdateAt(restaurantAdministrator) }}
                </td>
                <td class="text_color-reb down_line" @click="selectRow(restaurantAdministrator.id)">
                  {{ checkCancelAt(restaurantAdministrator) }}
                </td>
                <td class="" :class="restaurantAdministrator.status == 'inactive' || restaurantAdministrator.status == 'admin_cancel' ? 'text_color-reb' : ''"
                    @click="selectRow(restaurantAdministrator.id)"
                >
                  {{ checkStatus(restaurantAdministrator) }}
                </td>
                <td v-if="checkdevice == 'PC'" class="to_detail_btn">
                  <button v-if="restaurantAdministrator.status == 'active' || restaurantAdministrator.status == 'owner_cancel'"
                          class="btn primary btn_row btn_pointer"
                          @click="openSmart(restaurantAdministrator)"
                  >
                    発注書作成画面へ
                  </button>
                  <div v-else class="btn btn_row" />
                </td>
                <td v-if="checkdevice == 'Ipad'" class="to_detail_btn">
                  <button v-if="restaurantAdministrator.status == 'active' || restaurantAdministrator.status == 'owner_cancel'"
                          class="btn primary btn_row btn_pointer"
                          @click="openSmart(restaurantAdministrator)"
                  >
                    発注へ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="checkdevice == 'Mobile'">
          <table class="sr_table">
            <thead>
              <tr>
                <th class="large">店舗名</th>
                <th class="w_25">ステータス</th>
                <th class="w_70px" />
              </tr>
            </thead>
            <tbody class="to_detail">
              <tr v-for="(restaurantAdministrator, index) in restaurantAdministrators" :key="index">
                <td class="down_line owner_color-blue" @click="selectRow(restaurantAdministrator.id)">
                  {{ restaurantAdministrator.shop_name }}
                </td>
                <td class="" :class="restaurantAdministrator.status == 'inactive' || restaurantAdministrator.status == 'admin_cancel' ? 'text_color-reb' : ''"
                    @click="selectRow(restaurantAdministrator.id)"
                >
                  {{ checkStatus(restaurantAdministrator) }}
                </td>
                <td class="to_detail_btn">
                  <button v-if="restaurantAdministrator.status == 'active' || restaurantAdministrator.status == 'owner_cancel'"
                          class="btn primary btn_row btn_pointer"
                          @click="openSmart(restaurantAdministrator)"
                  >
                    発注へ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-show="restaurantAdministrators.length === 0" class="text-center text_24">表示するデータはありません。</div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import { RestaurantAdministratorsService } from '../../../services/Owner/restaurant_administrators.service';
import { OwnersService } from '../../../services/Owner/owners.service';
import utils from '../../../common/util';
import { mapGetters } from 'vuex';
import NewRevisionMixin from '../../../common/mixins/NewRevisionMixin';
export default {
  mixins: [
    NewRevisionMixin,
  ],
  data() {
    return {
      restaurantAdministrators: [],
      checkdevice: '',
      owner: {},
      restaurantAdministrator: {}
    };
  },
  computed: {
    ...mapGetters({
      restaurantDefault: 'getRestaurant'
    })
  },
  created() {
    window.addEventListener('resize', this.isMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobile);
    window.removeEventListener('resize', this.isresize);
    this.unresize();
  },
  async mounted() {
    this.isMobile();
    this.getOwnerShop();
    this.getOwners();
  },
  updated() {
    this.isresize();
    window.addEventListener('resize', this.isresize);
  },
  methods: {
    ...utils,
    checkmobile() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('android') > -1) {
        return true;
      } else {
        return false;
      }
    },
    isresize() {
      const wdth = window.screen.width;
      if (wdth < 480 && this.checkmobile()) {
        const height_container = document.getElementsByClassName('owner_container')[0].clientHeight;
        if ((window.innerHeight - 110) > height_container) {
          document.getElementById('body_wrapper_owner').style.minHeight = (window.innerHeight + 10) + 'px';
        } else {
          document.getElementById('body_wrapper_owner').style.minHeight = window.innerHeight + 'px';
        }
      }
    },
    unresize() {
      const wdth = window.screen.width;
      if (wdth < 480 && this.checkmobile()) {
        document.getElementById('body_wrapper_owner').style.height = '100%';
      }
    },
    isMobile() {
      if( screen.width > 1366) {
        this.checkdevice = 'PC';
      } else if(screen.width > 480 && screen.width <= 1366){
        this.checkdevice = 'Ipad';
      }
      else {
        this.checkdevice = 'Mobile';
      }
    },
    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOwnerShop() {
      try {
        const { response }  = await RestaurantAdministratorsService.index();
        this.restaurantAdministrators = response.data;
      }catch(error){
        console.log(error);
      }
    },
    checkStatus(restaurant) {
      let status;
      if(restaurant.status == 'active' || restaurant.status == 'owner_cancel'){
        status = '利用中';
      } else if (restaurant.status == 'inactive'){
        status = '解約済';
      } else if (restaurant.status == 'admin_cancel'){
        status = '利用停止中';
      }
      return status;
    },
    checkUpdateAt(restaurant) {
      let updateAt;

      if(restaurant.contract_update_at == null || (restaurant.contract_end_at == !null &&
         restaurant.contract_start_at == null &&
         restaurant.contract_update_at == !null)) {
        updateAt = '';
      } else if(['active', 'owner_cancel', 'admin_cancel', 'inactive'].includes(restaurant.status) ){
        updateAt = this.formatDateWithoutLeadingZeros(restaurant.contract_update_at);
      }
      return updateAt;
    },
    checkCancelAt(restaurant) {
      let cancelAt;
      if (restaurant.status != 'inactive' && restaurant.contract_end_at != null){
        cancelAt = moment(restaurant.contract_end_at, 'YYYY/MM/DD').format('YYYY/M/D') + 'まで利用可能';
      } else if (restaurant.status == 'inactive') {
        cancelAt = this.formatDateWithoutLeadingZeros(restaurant.contract_end_at);
      }
      return cancelAt;
    },
    selectRow(id) {
      return this.$router.push({ path: `/owner/restaurant/${id}/detail`});
    },
    async openSmart(restaurant) {
      // 改定お知らせ
      await this.getRestaurantAdministrator(restaurant.id);

      if (this.restaurantAdministrator.new_revision_policy && Object.keys(this.restaurantAdministrator.new_revision_policy).length) {
        const isAgreeRevision = await this.noticeNewRevision(this.restaurantAdministrator.new_revision_policy);
        if (isAgreeRevision) {
          await this.agreeRevision(restaurant.id, this.restaurantAdministrator.new_revision_policy);
        } else {
          return;
        }
      }

      if (this.restaurantAdministrator.new_revision_term && Object.keys(this.restaurantAdministrator.new_revision_term).length) {
        const isAgreeRevisionTerm = await this.noticeNewRevisionTerm(this.restaurantAdministrator.new_revision_term);
        if (isAgreeRevisionTerm) {
          await this.agreeRevision(restaurant.id, this.restaurantAdministrator.new_revision_term);
        } else {
          return;
        }
      }

      if(parseInt(this.restaurantDefault.user_id) != parseInt(restaurant.id)) {
        await this.$store.dispatch('clearOrder');
      }
      await this.$store.commit('SET_USER_ID', restaurant.id);
      await this.$store.commit('SET_RESTAURANT', restaurant.restaurant);
      window.location.href = '/dashboard';
    },
    newRestaurant() {
      return this.$router.push({ path: '/owner/restaurant/create'});
    },
    detailOwner() {
      return this.$router.push({ path: '/owner/detail'});
    },
    async getRestaurantAdministrator(id) {
      this.$loading(true);
      try {
        const { response } = await RestaurantAdministratorsService.get(id);
        this.restaurantAdministrator = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.control{
  width: 18%;
}
.primary_border {
  padding: 16px;
}
.mt_small {
  margin-top: 2px;
}
.fz_medium{
  font-size: 16px;
}
.to_detail_btn {
  text-overflow: unset !important;
}
.mt_large {
  margin-top: 40px;
}
.btn_area {
  height: 52px;
}
@media screen and (max-width: 740px) {
  .control{
    width: 50%;
  }
  .primary_border {
    padding: 8px;
  }
  .fz_medium{
  font-size: 12px;
  }
  .to_detail_btn {
    text-overflow: unset !important;
  }
  .mt_large {
    margin-top: 30px;
  }
  .btn_area {
    height: 32px;
  }
}
@media screen and ( max-width: 1366px) and ( min-width: 720px){
  .control{
    width: 25%;
  }
  .primary_border {
    padding: 12px;
  }
  .mt_large {
    margin-top: 30px;
  }
  .btn_area {
    height: 44px;
  }
}
</style>
