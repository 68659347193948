<template>
  <form class="search_form search_form_admin">
    <label :class="isHidden.form ? 'icon_shevrontop': 'icon_shevrondown'" class="form_title after"
           @click="isHidden.form = !isHidden.form"
    >検索条件</label>
    <div v-if="isHidden.form" class="toggle_content">
      <div class="form_content ">
        <div class="row flex">
          <div class="column">
            <label class="form_label margin-text" for="start_time">公開日時</label>
            <div class="input_wrapper calender">
              <div class="input-group">
                <flat-pickr
                  id="start_time"
                  v-model="searchParams.start_time"
                  :config="config"
                  placeholder="公開日時を入力"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <label class="form_label margin-text" for="end_time">&nbsp;</label>
            <div class="input_wrapper calender">
              <div class="input-group">
                <flat-pickr
                  id="end_time"
                  v-model="searchParams.end_time"
                  :config="config"
                  placeholder="公開日時を入力"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <label class="form_label margin-text" for="title">タイトル</label>
            <input id="title" v-model="searchParams.title" placeholder="タイトルを入力"
                   type="text"
            >
          </div>
        </div>
        <div class="row flex">
          <div class="column">
            <label class="form_label margin-text" for="status">状態</label>
            <div class="select-wrapper">
              <select id="status" v-model="searchParams.status">
                <option value="">すべて</option>
                <option value="1">公開</option>
                <option value="0">非公開</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_area_admin search">
        <a class="btn_admin admin_primary_border" href="#" @click="$emit('clearSearch')">検索条件をクリア</a>
        <a class="btn_admin admin_primary" href="#" @click="search">検索</a>
      </div>
    </div>
  </form>
</template>

<script>

import {Japanese} from 'flatpickr/dist/l10n/ja.js';

export default {
  props: {
    searchParams: {
      type: Object,
      require: true
    },
    paginationParams: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      isHidden: {
        form: true
      },
      config: {
        enableTime: false,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d'
      },
    };
  },
  methods: {
    async search() {
      this.paginationParams.page = 1;
      await this.$emit('refreshTable');
    }
  }
};
</script>

<style scoped>

.input_wrapper.calender::after {
  right: 32px;
}

.modal .input_wrapper.calender::after {
  right: 16px;
}

</style>


