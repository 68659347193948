<template>
  <div>
    <div class="bottom_menu" :class="showDialog ? 'open' : ''">
      <div class="flex mb_medium">
        <div class="title-export margin_y">出力種別</div>
        <div class="select_wrapper arrow_down width-90">
          <select id="filter" v-model="typeSelectCsv" name="filter">
            <option value="発注集計">発注集計</option>
            <option value="発注履歴">発注履歴</option>
          </select>
        </div>
      </div>
      <div class="flex mb_medium">
        <div class="title-export margin_y">店舗名</div>
        <div class="select_wrapper arrow_down width-90" v-if="isOwner">
          <select id="filter" v-model="restaurantId" name="filter">
            <option v-for="(option, i) in restaurants" :key="i" :value="option.user_id">{{ option.name }}</option>
          </select>
        </div>
        <div v-else>{{ restaurantName }}</div>
      </div>
      <div class="flex mb_medium">
        <div class="title-export margin_y">出力期間</div>
        <div class="alignCenter width-90">
          <div class="flex">
            <div class="width-90">
              <DateTimePicker 
                v-model="fromDate" format="YYYY/MM/DD"
                :editable="false" :clearable="false"
                class="input_wrapper calender full_width" input-class="calendar__input"
                :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                value-type="YYYY-MM-DD"
              >
                <span slot="icon-calendar" />
              </DateTimePicker>
              <span v-for="error in errors['fromDate']" :key="error" class="error-message">{{ error }}</span>
            </div>
            <div class="ml_small mr_small margin_y"
                :class="errors['fromDate'] || errors['toDate'] ? 'mt-small' : ''"
            >
              〜
            </div>
            <div class="width-90">
              <DateTimePicker 
                v-model="toDate" format="YYYY/MM/DD"
                :editable="false" :clearable="false"
                class="input_wrapper calender full_width" input-class="calendar__input"
                :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                value-type="YYYY-MM-DD"
              >
                <span slot="icon-calendar" />
              </DateTimePicker>
              <span v-for="error in errors['toDate']" :key="error" class="error-message">{{ error }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb_xlarge flex">
        <div class="title-export margin_y">出力内容</div>
        <div class="width-90">
          <div class="select_wrapper arrow_down">
            <select id="filter" v-model="typeExport" name="filter" :class="typeSelectCsv == '発注履歴' ? 'disabled' : ''">
              <option v-for="(option, i) in items" :key="i" :value="option.name">{{ option.name }}</option>
            </select>
          </div>
          <span v-for="error in errors['type']" :key="error" class="error-message">{{ error }}</span>
        </div>
      </div>
      <div class="btn_area two_items mt_medium">
        <router-link :to="{}" class="btn primary" @click.native="exportCSV">CSV出力</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="disableDialogCSV">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="showDialog ? 'open' : ''" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RestaurantsService } from '../services/restaurants.service';
import { OrdersService } from '../services/orders.service.js';
import Validator from '../validator';
import moment from 'moment';

export default Vue.extend({
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function
    },
  },
  data() {
    return {
      restaurants: [],
      items: [
        {name: '店舗'},
        {name: '発注先'},
        {name: '商品'}
      ],
      typeExport: null,
      restaurantId: null,
      fromDate: null,
      toDate: null,
      errors: [],
      restaurantName: null,
      typeSelectCsv: "発注集計"
    };
  },

  computed: {
    ...mapGetters({
      isOwner: 'isOwner',
    })
  },

  watch: {
    typeSelectCsv: {
      handler(value) {
        if (value == "発注履歴") {
          this.typeExport = null;
        }
      }
    }
  },

  async mounted() {
    this.restaurantId = this.$store.state.auth.user_id;
    await this.getRestaurantAdministrators();
  },

  methods: {
    async getRestaurantAdministrators() {
      this.$loading(true);
      try {
        if (this.isOwner) {
          const { response } = await RestaurantsService.search();
          this.restaurants = response.data;
          this.restaurants.splice(0, 0, {name: 'すべて', user_id: 0});
        } else {
          const { response } = await RestaurantsService.index();
          this.restaurantName = response.data.name;
          this.restaurantId = response.data.user_id;
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    async exportCSV() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      const params = {
        from_date: this.fromDate,
        to_date: this.toDate,
        restaurant_id: this.restaurantId,
        type_export: this.typeExport,
        type_select_csv: this.typeSelectCsv
      };
      try {
        const restaurantName = this.isOwner ? this.restaurants.find(element => element.user_id == this.restaurantId).name : this.restaurantName;
        const {response} = await OrdersService.csvExport(params);
        let nameFile = "";
        if (this.typeSelectCsv == "発注履歴") {
          nameFile = `発注履歴_${restaurantName}_${moment(this.fromDate).format('YYYYMMDD')}_${moment(this.toDate).format('YYYYMMDD')}`
        }
        else {
          nameFile = `${this.typeExport}集計_${restaurantName}_${moment(this.fromDate).format('YYYYMMDD')}_${moment(this.toDate).format('YYYYMMDD')}`;
        }

        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(response));
        element.setAttribute('download', `${nameFile}.csv`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        this.error = false;
      } catch (error) {
        this.error = true;
        console.log(error);
      }
    },

    disableDialogCSV() {
      this.errors = [];
      this.restaurantId = this.$store.state.auth.user_id;
      this.typeExport = null;
      this.fromDate = null;
      this.toDate = null;
      this.closeDialog();
    },

    validate() {
      const validator = new Validator();

      validator.checkRequire('fromDate', this.fromDate, '開始日付');
      validator.checkRequire('toDate', this.toDate, '終了日付');

      if (this.typeSelectCsv != "発注履歴") {
        validator.checkRequireSelect('type', this.typeExport, '出力内容');
      }

      if (this.fromDate && this.toDate) {
        validator.checkDateBefore('fromDate', this.fromDate, this.toDate, '開始日付', '終了日付');
      }

      this.errors = validator.errors;
    },
  }
});
</script>

<style scoped>
.width-90 {
  width: 90%;
}
.title-export {
  width: 10%;
  font-weight: bold;
}
.margin_y {
  margin-top: auto;
  margin-bottom: auto;
}
.mt-small {
  margin-top: 14px;
}
.disabled {
  background-color: #BBBBBB;
  pointer-events: none;
}
</style>