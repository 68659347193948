<template>
  <div>
    <div class="flex">
      <h1 class="title_admin">お知らせ管理 個別</h1>
      <a class="btn_admin admin_primary mt-28 mb_medium" href="#"
         @click="selectedId=-1;$openModal('createEditNotificationIndividual')"
      >お知らせ作成</a>
    </div>
    <SearchForm :accordion-td="accordionTd" :pagination-params="paginationParams"
                :restaurant-administrators="restaurantAdministrators" :search-params="searchParams"
                @clearSearch="clearSearch" @refreshTable="getNotifications"
                @resetAccordion="resetAccordion"
    />
    <Table :accordion-td="accordionTd" :notifications="notifications" :pagination-params="paginationParams"
           :selected-id="selectedId" @refreshTable="getNotifications" @resetAccordion="resetAccordion"
           @updateCurrentPage="updateCurrentPage" @updateSelectedNotification="updateSelectedNotification"
    />
    <Modals :notification-id="selectedId" :restaurant-administrators="restaurantAdministrators" @refreshTable="getNotifications"
            @updateSelectedNotification="updateSelectedNotification"
    />
  </div>
</template>

<script>
import Modals from './components/Modals';
import Table from './components/Table';
import SearchForm from './components/SearchForm';
import {NotificationIndividualService} from '../../../services/admin/notifications-individual.service';
import {RestaurantAdministratorsService} from '../../../services/admin/restaurant_administrators.service';
import './../../../shared/vue-select.scss';

export default {
  components: {
    SearchForm,
    Modals,
    Table
  },
  data() {
    return {
      selectedId: -1,
      notifications: [],
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      searchParams: {
        restaurant_id: '',
        status: ''
      },
      restaurantAdministrators: [],
      accordionTd: null
    };
  },
  async mounted() {
    const params = {
      status: ['active', 'admin_cancel', 'owner_cancel'],
    };
    const {response} = await RestaurantAdministratorsService.index(params);
    this.restaurantAdministrators = response.data;
  },
  methods: {
    async getNotifications() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams, ...this.searchParams};
        const {response} = await NotificationIndividualService.index(params);
        this.notifications = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    async clearSearch() {
      this.searchParams = {
        restaurant_id: '',
        status: ''
      };
      await this.getNotifications();
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedNotification(id) {
      this.selectedId = id;
    },
    resetAccordion() {
      let aTd = [];
      for (let i = 0; i < this.paginationParams['page_size']; i++) aTd[i] = false;
      this.accordionTd = Object.assign({}, this.accordionTd, aTd);
    },
  },
};
</script>
