import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class PasswordSettingService extends BaseService {
  static get resourceEndpoint() {
    return 'owners';
  }

  static async password_setup(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/password_setup`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {      
      throw new ApiError(error);
    }
  }

  static async password_setup_submit(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/password_setup_submit`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
