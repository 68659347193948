<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">代理店一覧</h1>
      <a class="btn_admin admin_primary mt-28 mb_medium ml_medium" href="#" @click="updateSelectedAgency(null, true); $openModal('agencyForm')">代理店作成</a>
    </div>
    <Table :agencies="agencies" :pagination-params="paginationParams" :selected-id="selectedId"
           @refreshTable="getAgencies" @updateCurrentPage="updateCurrentPage"
           @updateSelectedAgency="updateSelectedAgency"
    />
    <FormModal :agency-id="selectedId"
               @refreshTable="getAgencies" @updateSelectedAgency="updateSelectedAgency"
    />
  </div>
</template>

<script>
import Table from './components/Table.vue';
import FormModal from './components/FormModal.vue';
import { AgenciesService } from '../../../services/admin/agencies.service';

export default {
  components: {
    Table,
    FormModal,
  },
  data() {
    return {
      revisionHistories: [],
      agencies: [],
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      searchParams: {},
      selectedId: null,
    };
  },
  async mounted() {
    this.getAgencies();
  },
  methods: {
    async getAgencies() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams};

        const {response} = await AgenciesService.allAgencies(params);
        this.agencies = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedAgency(id) {
      this.selectedId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
