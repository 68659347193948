<template>
  <div id="agencyForm" class="modal modal-revision-history">
    <div class="modal-backdrop" />
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <h1 class="title_admin">代理店登録</h1>
          <div class="icon close" />
        </div>
      </div>
      <!--body-->
      <div class="modal-body">
        <div class="admin_content">
          <form action="">
            <div class="form-row">
              <label for="publish_at" class="form_label form-20">代理店名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <div class="input_wrapper">
                  <div class="input-group">
                    <input id="name" v-model="agencyName" type="text" />
                  </div>
                </div>
                <span v-for="error in errors['agencyName']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="revise_type" class="form_label form-20">代理店コード<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <div class="input_wrapper">
                  <div class="input-group">
                    <input id="name" :class="agencyId ? 'disabled' : ''" v-model="agencyCode" type="text" />
                  </div>
                </div>
                <span v-for="error in errors['agencyCode']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="revise_at" class="form_label form-20">ステータス<span class="require">必須</span></label>
              <div class="flex-row form-50">
                <label v-for="(type, index) in reviseTypes" :key="index" class="radio_btn">
                  {{ type.label }}
                  <input :id="`revise_type_${index}`" v-model="activeFlag"
                         type="radio"
                         :value="type.value"
                  >
                  <span class="checkmark" />
                </label>
                <span v-for="error in errors['activeFlag']" :key="error" class="error-message ">
                  {{ error }}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <div>
          <a class="btn_admin admin_primary" href="#" @click="handleSave()">{{ agencyId ? '更新' : '登録' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../../../validator';
import { AgenciesService } from '../../../../services/admin/agencies.service';

export default {
  props: {
    agencyId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      agencyCode: null,
      agencyName: null,
      reviseType: null,
      activeFlag: null,
      version: null,
      noticeText: null,
      errors: [],
      reviseTypes: [
        {
          value: 'active',
          label: '有効',
        },
        {
          value: 'inactive',
          label: '無効',
        },
      ],
    };
  },
  watch: {
    agencyId: async function (val) {
      if (val) {
        this.$loading(true);
        try {
          const {response} = await AgenciesService.get(val);
          this.setFormData(response.data);
          this.$loading(false);
        } catch (error) {
          console.log(error);
          this.$loading(false);
        }
      } else {
        this.setFormData({});
      }
    },
  },
  methods: {
    setFormData(agency) {
      this.errors = [];
      this.agencyName = agency.name;
      this.agencyCode = agency.code;
      this.activeFlag = agency.active_flag;
    },
    onModalClose() {
      this.$emit('updateSelectedAgency', null);
      this.setFormData({});
    },
    handleSave: async function () {
      this.errors = [];
      this.validate();
      if (Object.keys(this.errors).length) return;

      const params = {
        name: this.agencyName,
        code: this.agencyCode,
        active_flag: this.activeFlag,
      };
      this.$loading(true);
      try {
        if (this.agencyId) {
          await AgenciesService.update(this.agencyId, params);
        } else {
          await AgenciesService.create(params);
        }
        this.$loading(false);
        this.$closeModal('agencyForm');
        this.$emit('refreshTable');
      } catch (error) {
        this.$loading(false);
      }
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire('agencyName', this.agencyName, '代理店名') &&
        validator.checkMaxLength('agencyName', this.agencyName, 255, '代理店名');

      validator.checkRequire('activeFlag', this.activeFlag, 'ステータス');

      validator.checkRequire('agencyCode', this.agencyCode, '代理店コード') &&
        validator.checkAlphanumeric('agencyCode', this.agencyCode, '代理店コード') &&
        validator.checkMaxLength('agencyCode', this.agencyCode, 255, '代理店コード');

      this.errors = validator.errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-revision-history .modal-content {
  min-width: 800px;
  width: 800px;
}
.radio_btn {
  width: 50%;
}

.checkmark {
  &.disabled {
    color: #ffffff;
    cursor: not-allowed;
    pointer-events: none;
    border: 2px solid #ACACAC;
    &:after {
          background: #ACACAC;
    }
  }
}

.disabled {
  border: 1px solid #BBBBBB;
  color: #BBBBBB;
  pointer-events: none;
}
</style>

