<template>
  <main class="container bottomUp">
    <div class="main_area smp_content_main">
      <h1 class="title mb_medium">
        その他の設定
      </h1>
      <div class="form_row vertical">
        <div class="group_rb">
          <label for="invoice_send_type" class="owner_form_label">商品の画像表示</label>
          <div class="radio_position">
            <label class="radio_btn_setting">
              <input
                v-model="activeImg"
                type="radio"
                :checked="true"
                :value="true"
                class="rb_border"
              >
              <span class="rb_checkmark" />
              <label class="rb_label">画像あり</label>
            </label>
            <label class="radio_btn_setting">
              <input
                v-model="activeImg"
                type="radio"
                :value="false"
                class="rb_border"
              >
              <span class="rb_checkmark" />
              <label class="rb_label">画像なし</label>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/setting" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link :to="{}" class="btn primary" @click.native="settingImageDisplay">更新する</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      activeImg: ''
    };
  },
  computed: {
    ...mapGetters({
      settingImage: 'getSettingimage'
    }),
  },
  mounted() {
    this.activeImg = this.settingImage;
  },
  methods: {
    async settingImageDisplay() {
      await this.$store.dispatch('changeSettingImage', this.activeImg);
      return this.$router.push({ path: '/setting' });
    }
  }
};
</script>
<style scoped>
</style>
