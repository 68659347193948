<template>
  <div v-show="isActive" class="order_manage_area chanel_padding" :class="{ active: isActive }">
    <h1 class="title mb_large">
      発注先編集
    </h1>
    <form action="">
      <div class="form_row vertical mb_medium">
        <label for="supplier_official_name" class="form_label">
          発注先企業名<span class="require">必須</span>
        </label>
        <div class="input_wrapper">
          <input id="supplier_official_name" v-model="supplierOfficialName" type="text"
                 placeholder="発注先企業名"
          >
          <span v-for="error in errors['supplierOfficialName']" :key="error" class="error-message">
            {{ error }}
          </span>
          <p class="small_text mt_small">発注書に表示される項目です。正式名称でご記入ください。</p>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="name" class="form_label">
          発注先企業名（略称）
        </label>
        <div class="input_wrapper">
          <input id="name" v-model="name" type="text"
                 placeholder="発注先企業名（略称）"
          >
          <span v-for="error in errors['name']" :key="error" class="error-message">
            {{ error }}
          </span>
          <p class="small_text mt_small">SMART REQUEST内で表示される項目です。</p>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="tel" class="form_label">電話番号</label>
        <div class="input_wrapper medium">
          <input id="tel" v-model="tel" type="tel"
                 placeholder="電話番号"
          >
          <span v-for="error in errors['tel']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="fax" class="form_label">FAX番号<span v-if="orderType == 'fax'" class="require">必須</span></label>
        <div class="input_wrapper medium">
          <input id="fax" v-model="fax" type="tel"
                 placeholder="FAX番号"
          >
          <span v-for="error in errors['fax']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="mail" class="form_label">
          メールアドレス<span v-if="orderType == 'email'" class="require">必須</span>
        </label>
        <SupplierEmailsInput v-on:getInputs="getEmailInputs" :supplier-emails="supplierEmails" :change-value-emails="changeValueEmails"></SupplierEmailsInput>
        <span v-for="error in errors['email']" :key="error" class="error-message">
          {{ error }}
        </span>
      </div>
      <div class="form_row vertical mb_medium">
        <div class="group_rb">
          <label for="invoice_send_type" class="owner_form_label">発注方法</label>
          <div class="radio_position">
            <label class="radio_btn_setting">
              <input
                v-model="orderType"
                type="radio"
                value="email"
                class="rb_border"
              >
              <span class="rb_checkmark" />
              <label class="rb_label">メール</label>
            </label>
            <label class="radio_btn_setting">
              <input
                v-model="orderType"
                type="radio"
                value="fax"
                class="rb_border"
              >
              <span class="rb_checkmark" />
              <label class="rb_label">FAX</label>
            </label>
            <label class="radio_btn_setting">
              <input
                v-model="orderType"
                type="radio"
                value="line"
                class="rb_border"
              >
              <span class="rb_checkmark" />
              <label class="rb_label">LINE</label>
            </label>
          </div>
        </div>
      </div>
      <div v-if="orderType == 'fax'" class="form_row vertical mb_medium">
        <label for="order_template" class="form_label form-20">発注書テンプレート</label>
        <div class="form_input">
          <div class="select_wrapper arrow_down medium">
            <select id="order_template" v-model="mediaSheetClass">
              <option value="MediaSheet:Smartrequest">SmartRequestデフォルト</option>
              <option value="MediaSheet:Ryque">RYQUE（リクエ）フォーマット</option>
            </select>
          </div>
          <p class="small_text mt_small">発注書テンプレートをRYQUEに変更する場合、既存商品の商品番号も登録必要です。</p>
        </div>
      </div>
      <div v-if="orderType == 'fax' && mediaSheetClass == 'MediaSheet:Ryque'">
        <div class="form_row vertical mb_medium">
          <label for="orderNo" class="form_label">
            注文用紙番号<span class="require">必須</span></label>
          <div class="input_wrapper medium">
            <input id="orderNo" v-model="orderNo" type="tel"
                    placeholder="数字3桁"
            >
            <span v-for="error in errors['orderNo']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form_row vertical mb_medium">
          <label for="customerNo" class="form_label">
            お客様NO<span class="require">必須</span></label>
          <div class="input_wrapper medium">
            <input id="customerNo" v-model="customerNo" type="tel"
                    placeholder="数字6桁"
            >
            <span v-for="error in errors['customerNo']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="minimum_price" class="form_label">最低発注金額</label>
        <div class="input_wrapper medium">
          <input
            id="minimum_price"
            v-model="minimumPrice"
            type="tel"
            placeholder="最低発注金額（任意）"
            class="custom_input"
          > 円
          <span v-for="error in errors['minimumPrice']" :key="error" class="error-message">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="note" class="form_label">
          備考欄
        </label>
        <div class="form_input">
          <div class="input_wrapper">
            <textarea id="note" v-model="note"
                      cols="30" rows="5" placeholder="何か記載内容があればご記載ください。"
            />
            <span v-for="error in errors['note']" :key="error" class="error-message">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { SuppliersService } from '../../../services/suppliers.service';
import { CorrectNumber } from '../../../common/util';
import Validator from '../../../validator';
import SupplierEmailsInput from './../../../components/SupplierEmailsInput.vue';

export default {
  components: {
    SupplierEmailsInput
  },
  name: 'Supplier',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: Object,
      default: () => {}
    },
    closeDialog: {
      type: Function
    },
    openDialog: {
      type: Function
    },
    disableShowDialog: {
      type: Function
    },
    displayDialog: {
      type: Function
    },
    scrollTopScreen: {
      type: Function
    }
  },
  data () {
    return {
      supplierEmails: [],
      id: null,
      name: '',
      email: '',
      tel: '',
      fax: '',
      orderType: 'email',
      minimumPrice: '',
      errors: {},
      note: null,
      dialog: false,
      supplierOfficialName: '',
      mediaSheetClass: '',
      orderNo: '',
      customerNo: '',
      changeValueEmails: false,
    };
  },

  watch: {
    supplier: function(newVal) {
      this.id = newVal.id;
      this.name = newVal.name;
      this.email = newVal.email;
      this.tel = CorrectNumber(newVal.tel);
      this.fax = CorrectNumber(newVal.fax);
      this.orderType = newVal.order_type;
      this.minimumPrice = newVal.min_order_amount;
      this.note = newVal.note;
      this.supplierOfficialName = newVal.supplier_official_name;
      this.mediaSheetClass = newVal.media_sheet_class;
      this.orderNo = newVal.order_no;
      this.customerNo = newVal.customer_no;
      this.supplierEmails = newVal.supplier_emails.length === 0 && this.email ? [{email: this.email}] : newVal.supplier_emails;
      this.changeValueEmails = true;
    },
    tel() {
      if(this.tel) {
        this.tel = this.tel.replace(/[^0-9]+/g, '');
      }
    },
    fax() {
      if(this.fax) {
        this.fax = this.fax.replace(/[^0-9]+/g, '');
      }
    },
    minimumPrice() {
      if(this.minimumPrice) {
        this.minimumPrice = this.minimumPrice.toString().replace(/[^0-9]+/g, '');
      }
    },
    orderNo() {
      if (this.orderNo) {
        this.orderNo = this.orderNo.replace(/[^0-9]+/g, '');
      }
    },
    customerNo() {
      if (this.customerNo) {
        this.customerNo = this.customerNo.replace(/[^0-9]+/g, '');
      }
    }
  },
  methods: {
    getEmailInputs(inputs) {
      this.supplierEmails = inputs.supplierEmailsEdit;
    },
    checkValid() {
      this.email = this.supplierEmails.filter(supplierEmail => supplierEmail._destroy === false).length === 0 ? 
        null : this.supplierEmails.filter(supplierEmail => supplierEmail._destroy === false)[0].email;
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        this.displayDialog();
        return;
      }

      this.openDialog();
    },
    handleSave: async function() {
      this.openDialog();

      const params = {
        name: this.name,
        email: this.email,
        tel: CorrectNumber(this.tel),
        fax: CorrectNumber(this.fax),
        order_type: this.orderType,
        min_order_amount: this.minimumPrice,
        note: this.note,
        supplier_official_name: this.supplierOfficialName == '' ? null : this.supplierOfficialName,
        media_sheet_class: this.orderType == 'email' || this.orderType == 'line' ? 'MediaSheet:Smartrequest' : this.mediaSheetClass
      };

      params.supplier_emails_attributes = this.supplierEmails

      this.$loading(true);
      try {
        if (this.orderType == 'fax' && this.mediaSheetClass == 'MediaSheet:Ryque') {
          params.order_no = this.orderNo;
          params.customer_no = this.customerNo;
        }
        await SuppliersService.update(this.id, params);
        this.$router.push('/order_company');
        this.$loading(false);
      } catch (error) {
        console.log(error);
        this.closeDialog();
        this.$loading(false);
        const errorMessage = error.response.result.message[0];
        await this.$popupMessage('エラー', errorMessage, { type: 'error' });
      }
    },

    validate() {
      const validator = new Validator();
      validator.checkMaxLength('name', this.name, 255, '表示名');
      if (this.orderType == 'email') {
        validator.checkRequire('email', this.email, 'メールアドレス');
      }
      this.supplierEmails.filter(supplierEmail => !supplierEmail._destroy).forEach(supplierEmail => {
        if (!validator.errors.email) {
          validator.checkEmail('email', supplierEmail.email, 'メールアドレス');
          validator.checkMaxLength('email', supplierEmail.email, 255, 'メールアドレス');
          if (this.supplierEmails.filter(supplierEmailSearch => !supplierEmailSearch._destroy && supplierEmailSearch.email == supplierEmail.email).length > 1) {
            validator.errors.email = ['メールがすでに登録されています。']
          }
        }
      });

      if (this.tel) {
        const tel =  CorrectNumber(this.tel);
        validator.checkRequire('tel', tel, '電話番号') &&
          validator.checkMaxLength('tel', tel, 11, '電話番号') &&
          validator.checkMinLength('tel', tel, 10, '電話番号');
        if (!validator.errors.tel) {
          validator.checkPhone('tel', CorrectNumber(this.tel), '電話番号');
        }
      }

      if (this.orderType == 'fax') {
        validator.checkRequire('fax', CorrectNumber(this.fax), 'FAX番号');
      }

      if (this.fax) {
        const fax =  CorrectNumber(this.fax);
        validator.checkMaxLength('fax', fax, 11, 'FAX番号') &&
          validator.checkMinLength('fax', fax, 10, 'FAX番号');
        if (!validator.errors.fax) {
          validator.checkPhone('fax', fax, 'FAX番号');
        }
      }

      if (this.minimumPrice) {
        validator.checkDigit('minimumPrice', this.minimumPrice, '最低発注金額') &&
          validator.checkMaxNumber('minimumPrice', this.minimumPrice, 9, '最低発注金額');
      }

      validator.checkMaxLength('note', this.note, 255, '備考欄');
      validator.checkRequire('supplierOfficialName', this.supplierOfficialName, '発注書会社名') &&
        validator.checkMaxLength('supplierOfficialName', this.supplierOfficialName, 255, '発注書会社名');

      if (this.orderType == 'fax' && this.mediaSheetClass == 'MediaSheet:Ryque') {
        validator.checkRequire('orderNo', this.orderNo, '注文用紙番号') &&
          validator.checkDigit('orderNo', this.orderNo, '注文用紙番号') &&
          validator.checkLength('orderNo', this.orderNo, 3, '注文用紙番号');

        validator.checkRequire('customerNo', this.customerNo, 'お客様NO') &&
          validator.checkDigit('customerNo', this.customerNo, 'お客様NO') &&
          validator.checkLength('customerNo', this.customerNo, 6, 'お客様NO');
      }

      this.errors = validator.errors;
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer !important;
}
.custom_input {
    width: 50%;
  }
@media screen and (max-width: 480px){
  .chanel_padding {
    padding: 80px 16px 0 16px;
  }
  .custom_input {
    width: 90%;
  }
  input {
    width: 90%;
  }
  textarea {
    width: 90%;
  }
  #orderNo, #customerNo{
    padding: 8px 40px 8px 8px;
    font-size: 16px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .chanel_padding {
    padding: 100px 24px 0 24px;
  }
}
</style>