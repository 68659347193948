<template>
  <main class="owner_main_area main_area_pc main_complete">
    <CompleteStep step="2" class="owner_margin_top_35" />
    <div class="wrapper_owner_have_button">
      <div class="owner_margin_buttom">
        <div class="color_owner_text">
          <h1 class="owner_text_title mb_large">パスワード設定用のメールを送信しました。</h1>
          <span>法人情報・請求書の受取先情報の登録が完了しました。</span><br>
          <span>ご登録頂いたメールアドレスにパスワード設定用のURLを記載したメールを送信しました。24時間以内にパスワード設定を行ってください。</span>
        </div>
      </div>
      <div class="btn_area_owmer control owner_btn_padding btn_button owner_posstion_button">
        <router-link :to="{}" class="btn_owner primary_border btn_border_radius" @click.native="goBack">ログイン画面へ</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import CompleteStep from '../../../../components/CompleteStep.vue';

export default {
  components: {
    CompleteStep,
  },
  mounted() {
    window.onpopstate = function () {
      this.$store.commit('CLEAR_STATE_REGISTER');
    }.bind(this);
  },
  methods: {
    goBack() {
      this.$store.commit('CLEAR_STATE_REGISTER');
      return this.$router.push('/');
    },
  },
};
</script>
<style lang="scss" scoped>
.owner_posstion_button {
  width: 50%;
  max-width: 480px;
  margin-left: 25%;
}
@media screen and (max-width: 480px) {
  // .main_area_pc {
  //   min-height: calc(100vh - 50px) !important;
  // }
}
</style>
