import { RestaurantsService } from '../../services/restaurants.service';

const restaurants = {
  state: {
    restaurant: {},
    ownerName: ''
  },
  mutations: {
    SET_RESTAURANT(state, payload) {
      state.restaurant = payload;
    },
    SET_OWNER_NAME(state, payload) {
      state.ownerName = payload;
    },
    CLEAR_RESTAURANT(state) {
      state.restaurant = {};
      state.ownerName = '';
    },
  },
  actions: {
    async getRestaurant({ commit }) {
      try {
        const { response } = await RestaurantsService.index();
        commit('SET_RESTAURANT', response.data);
        commit('SET_USER_ID', response.data.user_id);
      } catch (error) {
        commit('SET_RESTAURANT', {});
        commit('SET_USER_ID', null);
      }
    },
    getOwnerName({ commit }, data) {
      try {
        commit('SET_OWNER_NAME', data);
      } catch (error) {
        commit('SET_OWNER_NAME', {});
      }
    }
  },
  getters: {
    getLoadingStatus: state => !state.restaurant || Object.keys(state.restaurant).length < 1,
    getRestaurant: state => { return state.restaurant; },
    getOwnerName: state => { return state.ownerName; }
  }
};

export default restaurants;
