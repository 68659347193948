import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './stores/index';

import Layout from './shared/Layout.vue';
import Dashboard from './pages/Dashboard/Index.vue';
import Setting from './pages/Setting/Index.vue';
import Restaurant from './pages/Restaurant/Index.vue';

import OrderStaffIndex from './pages/OrderStaff/Index.vue';
import OrderStaffEdit from './pages/OrderStaff/Edit.vue';
import OrderStaffCreate from './pages/OrderStaff/Create.vue';

import Memo from './pages/Memo/Index.vue';
import MemoDetail from './pages/Memo/Show.vue';

import OrderCompanyIndex from './pages/OrderCompany/Index.vue';
import OrderCompanyCreate from './pages/OrderCompany/Create.vue';
import OrderCompanyShow from './pages/OrderCompany/Show.vue';
import OrderCompanyEdit from './pages/OrderCompany/Edit.vue';

import ItemCompanyCreate from './pages/OrderCompany/Item/Create.vue';
import ItemCompanyEdit from './pages/OrderCompany/Item/Edit.vue';

import OrderCompanyMessage from './pages/Message/Index.vue';

import OrderHistoryIndex from './pages/OrderHistory/Index.vue';
import OrderHistoryShow from './pages/OrderHistory/Show.vue';
import OrderHistoryCheck from './pages/OrderHistory/Check.vue';

import OrderCompanySelect from './pages/Order/CompanySelect.vue';
import OrderItemSelect from './pages/Order/Index.vue';
import OrderCart from './pages/Order/Cart.vue';

import OrderComplete from './pages/Order/Complete.vue';
import Notify from './pages/Notify/Index.vue';
import NotifyDetail from './pages/Notify/Detail.vue';
import UploadIndex from './pages/Upload/Index.vue';
import UploadCreate from './pages/Upload/Create.vue';
import UploadComplete from './pages/Upload/Complete.vue';
import SettingImage from './pages/SettingImage/Index.vue';

import OwnerLayout from './shared/Owner/Layout.vue';
import NotfoundLayout from './shared/Owner/LayoutNotfound.vue';
import Login from './pages/Login/Index.vue';
import LoginidReset from './pages/ResetAccountUser/Index.vue';
import OwnerDashboard from './pages/Owner/Dashboard/Index.vue';
import OwnerRestaurantCreate from './pages/Owner/Restaurant/Create.vue';
import OwnerDelete from './pages/Owner/Owners/Delete.vue';
import OwnerDeleteComplete from './pages/Owner/Owners/Complete.vue';
import OwnerDetail from './pages/Owner/Owners/Detail.vue';
import OwnerRestaurantShow from './pages/Owner/Restaurant/Show.vue';
import OwnerRestaurantEdit from './pages/Owner/Restaurant/Edit.vue';
import OwnerRestaurantDelete from './pages/Owner/Restaurant/Delete.vue';

import AdminLayout from './shared/AdminLayout.vue';
import AdminLogin from './pages/Admin/Login/Index.vue';
import AdminNotification from './pages/Admin/Notification/Index.vue';
import AdminNotificationIndividual from './pages/Admin/NotificationIndividual/Index.vue';
import AdminSupplier from './pages/Admin/Supplier/Index.vue';
import AdminItem from './pages/Admin/Item/Index.vue';
import AdminOcr from './pages/Admin/Ocr/Index.vue';
import AdminOcrDetail from './pages/Admin/Ocr/Detail.vue';
import AdminRevisionHistoryItem from './pages/Admin/RevisionHistory/Index.vue';

import NotFoundPage from './pages/404/Index.vue';
import OwnerPaymentEdit from './pages/Owner/Payment/Edit.vue';
import Register from './pages/Owner/Registation/Index.vue';
import PasswordSetting from './pages/Owner/PasswordSetting/Index.vue';
import RegistrationSuccess from './pages/Owner/RegistrationSuccess/Index.vue';
import RegisterInfo from './pages/Owner/Registation/RegisterInfo.vue';
import NotFound from './pages/Owner/NotFound/Index.vue';
import RegisterComplete from './pages/Owner/Registation/components/Complete.vue';
import NotFoundPageAdmin from './pages/Admin/404/Index.vue';
import OwnerEdit from './pages/Owner/Owners/Edit.vue';
import OwnerPayment from './pages/Owner/Payment/Index.vue';
import PasswordReset from './pages/Owner/ResetPasswordUser/Index.vue';
import PasswordResetAuth from './pages/Owner/ResetPasswordUser/AcceptAuth.vue';
import changepassword from './pages/Owner/ResetPasswordUser/ChangePassword.vue';
import changepasswordcomplete from './pages/Owner/ResetPasswordUser/ChangeCompleted.vue';

import AdminRestaurantAdministrator from './pages/Admin/RestaurantAdministrator/Index.vue';
import AdminOwner from './pages/Admin/Owner/Index.vue';
import AdminOwnerCancel from './pages/Admin/Owner/Cancel.vue';
import AdminExportCsv from './pages/Admin/ExportCsv/Index.vue';
import AdminCancelAnswerExport from './pages/Admin/CancelAnswer/Export.vue';
import Analysis from './pages/Admin/Analysis/Index.vue';
import OrderReport from './pages/OrderReport/Index.vue';
import OrderReportDetail from './pages/OrderReport/Detail.vue';
import Agency from './pages/Admin/Agency/Index.vue';

import Inventory  from './pages/Inventory/Index.vue';
import InventoryDetail  from './pages/Inventory/Detail.vue';
import InventoryForm  from './pages/Inventory/InventoryForm.vue';
import InventoryItem from './pages/Inventory/Item.vue';
import SuccessUrl from './pages/Owner/Payment/Success.vue';
import CancelUrl from './pages/Owner/Payment/Cancel.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      component: OwnerLayout,
      children: [
        { path: '/', meta: { title: 'ログイン', hideForAuth: true }, name: 'login', component: Login },
        { path: '/register', component: Register, meta: { title: '会員登録', hideForAuth: true } },
        { path: '/register/:id', component: Register, meta: { title: '会員登録', hideForAuth: true } },
        { path: '/register_info', meta: { title: 'スマリク説明', hideForAuth: true }, name: 'register_info_1', component: RegisterInfo },
        { path: '/register_info/:id', meta: { title: 'スマリク説明', hideForAuth: true }, name: 'register_info', component: RegisterInfo },
        { path: '/not_found_data', component: NotFound, meta: { title: 'エラー ', hideForAuth: true } },
        { path: '/password_setting/:token', component: PasswordSetting, meta: { title: 'パスワード設定', hideForAuth: true } },
        { path: '/register_success', component: RegistrationSuccess, meta: { title: 'パスワード設定完了', hideForAuth: true } },
        { path: '/complete_register', component: RegisterComplete, meta: { title: '会員登録完了 ', hideForAuth: true } },
        { path: '/password_reset', meta: { title: 'パスワード再設定', hideForAuth: true }, name: 'passwordreset', component: PasswordReset },
        { path: '/password_reset_auth', meta: { title: '認証コード入力', hideForAuth: true }, name: 'passwordresetauth', component: PasswordResetAuth },
        { path: '/password_reset_input/:token', meta: { title: '新しいパスワード入力', hideForAuth: true }, name: 'changepassword', component: changepassword },
        { path: '/password_reset_complete', meta: { title: 'パスワード再設定完了', hideForAuth: true }, name: 'changepasswordcomplete', component: changepasswordcomplete },
        { path: '/ownerid_reset', meta: { title: 'オーナーID忘れ', hideForAuth: true }, name: 'idreset', component: LoginidReset },
      ]
    },
    {
      path: '/owner',
      component: OwnerLayout,
      children: [
        { path: '/ownerid_reset', meta: { title: 'オーナーID忘れ', hideForAuth: true }, name: 'owneridreset', component: LoginidReset },
        { path: '', component: OwnerDashboard, name: 'restaurant_dashboard', meta: { title: 'アカウント管理' } },
        { path: 'payment', component: OwnerPayment, meta: { title: '支払管理' } },
        { path: 'payment/edit', component: OwnerPaymentEdit, meta: { title: '支払管理' } },
        { path: 'restaurant/create', component: OwnerRestaurantCreate, meta: { title: '店舗の新規登録' } },
        { path: 'delete', component: OwnerDelete, name: 'owner_delete', meta: { title: '法人情報詳細削除確認' } },
        { path: 'detail', component: OwnerDetail, name: 'owner_detail', meta: { title: '法人情報管理' } },
        { path: 'edit', component: OwnerEdit, name: 'owner_edit', meta: { title: '法人情報管理' } },
        { path: 'restaurant/:id/detail', component: OwnerRestaurantShow, meta: { title: '店舗詳細' } },
        { path: 'restaurant/:id/edit', component: OwnerRestaurantEdit, meta: { title: '店舗詳細' } },
        { path: 'restaurant/:id/delete', component: OwnerRestaurantDelete, meta: { title: '店舗解約確認' } },
        { path: 'complete_delete_owner', name: 'complete_delete_owner', component: OwnerDeleteComplete, meta: { title: '法人情報変更 アカウント削除完了' } },
        { path: 'payment/card_complete', component: SuccessUrl, meta: { title: '店舗詳細' } },
        { path: 'payment/card_cancel', component: CancelUrl, meta: { title: '店舗詳細' } }
      ]
    },
    {
      path: '/',
      component: NotfoundLayout,
      children: [
        { path: '/owner/*', component: NotFound, meta: { title: 'エラー '} },
      ]
    },
    // Admin
    {
      path: '/admin',
      component: AdminLayout,
      children: [
        { path: '', component: AdminOwner, meta: { title: '法人情報管理' } },
        { path: 'ocr', name:'ocr', component: AdminOcr, meta: { title: 'お申し込み一覧' } },
        {
          path: 'ocr/detail/:page',
          component: AdminOcrDetail,
          props:true,
          name:'ocr-detail',
          meta: { title: 'お申し込み詳細' }
        },
        { path: 'restaurant_admin/:id/suppliers', component: AdminSupplier, meta: { title: '発注先管理' } },
        { path: 'restaurant_admin/:id/suppliers/:supplier_id/items', component: AdminItem, meta: { title: '商品管理' } },
        { path: 'notifications', component: AdminNotification, meta: { title: 'お知らせ一覧' } },
        { path: 'notifications-individual', component: AdminNotificationIndividual, meta: { title: 'お知らせ管理 個別' } },
        { path: 'owners/:id/list_shop', component: AdminRestaurantAdministrator, meta: { title: '法人情報管理' } },
        // { path: 'owners/:id/detail', component: AdminOwnerDetail, meta: { title: '法人詳細' } },
        // { path: 'owners/:id/edit', component: AdminOwnerEdit, meta: { title: ' 法人管理 編集' } },
        { path: 'owners/:id/cancel', component: AdminOwnerCancel, meta: { title: '法人詳細 解約確認' } },
        { path: 'export_csv', component: AdminExportCsv, meta: { title: 'ご請求CSV出力' } },
        { path: 'cancel-answers/export', component: AdminCancelAnswerExport, meta: { title: '解約アンケート' } },
        { path: 'revision-histories', component: AdminRevisionHistoryItem, meta: { title: '改定履歴' } },
        { path: 'analysis', component: Analysis, meta: { title: '分析' } },
        { path: 'agencies', component: Agency, meta: { title: '代理店一覧' } },
      ]
    },
    {
      path: '/admin/login', component: AdminLogin, name: 'AdminLogin', meta: { title: '発注書作成画面' }
    },
    { path: '/admin/*', name: '404_admin', component: NotFoundPageAdmin },
    { path: '/admin/not_found', name: '404_admin_router', component: NotFoundPageAdmin },

    // User
    {
      path: '/',
      component: Layout,
      children: [
        { path: '/dashboard', component: Dashboard, name: 'dashboard', meta: { title: '発注書作成画面' } },
        { path: '/setting', component: Setting, meta: { title: '設定画面' } },
        {
          path: '/delivery_info',
          component: Restaurant,
          meta: { title: '届け先情報' },
        },
        {
          path: '/order_staff',
          component: OrderStaffIndex,
          meta: {
            title: '担当者管理',
          },
        },
        {
          path: '/order_staff/:id/edit',
          component: OrderStaffEdit,
          meta: { title: '担当者編集' },
        },
        {
          path: '/order_staff/new',
          component: OrderStaffCreate,
          meta: { title: '担当者登録' },
        },
        { path: '/memo', component: Memo, meta: { title: 'メモ一覧' } },
        { path: '/memo/:id', component: MemoDetail, meta: { title: 'メモ詳細' } },
        {
          path: '/order_company',
          component: OrderCompanyIndex,
          meta: { title: '発注先一覧' },
        },
        {
          path: '/order_company/new',
          component: OrderCompanyCreate,
          meta: { title: '発注先登録' },
        },
        {
          path: '/order_company/:id/detail',
          component: OrderCompanyShow,
          meta: { title: '発注先詳細' },
          name: 'order_company_detail',
        },
        {
          path: '/order_company/:id/edit',
          component: OrderCompanyEdit,
          meta: { title: '発注先・商品管理' },
          name: 'order_company_edit',
        },
        {
          path: '/message',
          component: OrderCompanyMessage,
          meta: { title: 'メッセージを送信' },
        },
        { path: '/order_company/:id/create_item', component: ItemCompanyCreate, meta: { title: '商品マスタ登録' } },
        { path: '/order_company/:id/edit_item/:item_id', component: ItemCompanyEdit, meta: { title: '商品マスタ登録' } },

        { path: '/order_history', component: OrderHistoryIndex, meta: { title: '発注履歴一覧' } },
        { path: '/order_history/:id', component: OrderHistoryShow, meta: { title: '発注履歴詳細' } },
        { path: '/order_history/:id/check', component: OrderHistoryCheck, meta: { title: '発注履歴詳細' } },
        { path: '/order/select_company', component: OrderCompanySelect, meta: { title: '発注先選択' } },
        { path: '/order/item_select/:id', component: OrderItemSelect, meta: { title: '商品選択' } },
        { path: '/order/item_select', component: OrderItemSelect, meta: { title: '商品選択' } },
        { path: '/order/order_cart', component: OrderCart, meta: { title: '発注予定リスト' } },
        { path: '/order/complete', component: OrderComplete, meta: { title: '発注完了' } },
        { path: '/notification', component: Notify, meta: { title: 'お知らせ' } },
        { path: '/notification/:id', component: NotifyDetail, meta: { title: 'お知らせ詳細' } },
        { path: '/upload', component: UploadIndex, meta: { title: '登録代行履歴一覧' } },
        { path: '/upload/new', component: UploadCreate, meta: { title: '代理登録' } },
        { path: '/upload/complete', component: UploadComplete, meta: { title: '登録代行依頼完了' } },
        { path: '/setting_image', component: SettingImage, meta: { title: 'その他の設定' } },
        { path: '/order_report', component: OrderReport, meta: { title: '利用レポート' } },
        { path: '/order_report/detail', component: OrderReportDetail, meta: { title: '利用レポート' } },
        { path: '/inventory', component: Inventory, meta: { title: '棚卸の管理' } },
        { path: '/inventory/:id/detail', component: InventoryDetail, meta: { title: '棚卸詳細' } },
        { path: '/inventory/create', component: InventoryForm, meta: { title: '棚卸登録' } },
        { path: '/inventory/:id/edit', component: InventoryForm, meta: { title: '棚卸編集' } },
        { path: '/inventory/items', component: InventoryItem, meta: { title: '在庫一覧' } },
        { path: '*', name: '404', component: NotFoundPage }
      ]
    },
  ],
  linkActiveClass: 'active',
});

const DEFAULT_TITLE = 'Smart Request';

router.beforeEach(async (to, from, next) => {
  const hideForAuth = to.matched.some(record => record.meta.hideForAuth);
  if (to.path.split('/')[1] === 'admin') {
    if (!localStorage.getItem('csrf') && !['/admin/login'].includes(to.path)) {
      next('/admin/login');
    } else {
      if ([
        '/admin/login',
      ].includes(to.path)) {
        const data = await store.dispatch('getAdmin', { csrf: localStorage.getItem('csrf') });
        if (data && data.status == 401) {
          next();
          return;
        } else {
          window.location.href = '/admin';
        }
      } else {
        next();
        return;
      }
    }
  } else if (!store.state.auth.login_owner_flag) { /* not signin */
    if (!hideForAuth) { /* router is not required authorized */
      next({ path: '/', query: { from: to.path } });
    } else { /* router is required authorized */
      next();
    }
  } else if (hideForAuth && store.state.auth.login_owner_flag) { /* router is not required authorized and logged */
    if (store.getters.isOwner) {
      window.location.href = '/owner';
    } else {
      next('/Dashboard');
    }
  } else if (!['owner', 'admin'].includes(to.path.split('/')[1])) { /* router with path is not of owner, admin and logged */
    if (store.state.auth.login_owner_flag && !store.state.auth.user_id) {
      next('/owner');
    }
    const query = router.history.pending.query;
    if (query.token) {
      store.commit('SET_TOKEN', query.token);
      store.commit('SET_RESTAURANT', {});
      store.commit('SET_MEMOS', []);
      store.commit('SET_ORDERS', []);
    }
    if (Object.keys(store.state.restaurants.restaurant).length === 0) {
      await store.dispatch('getRestaurant');
    }

    if (Object.keys(store.state.restaurants.restaurant).length > 0) {
      if (store.state.restaurants.restaurant.id || to.path === '/delivery_info') {
        next();
      } else {
        next('/delivery_info');
      }
    } else {
      next();
    }
  } else { /* Other path and logged */
    if (!store.getters.isOwner) {
      next('/dashboard');
    } else {
      next();
    }
  }

  if (to.path.split('/')[1] !== 'order_report') {
    store.commit("SET_SUPPLIER_ID", null);
    store.commit("SET_TYPE", null);
    store.commit("SET_TYPE_SEARCH_TIME", null);
    store.commit("SET_TIME", null);
    store.commit("SET_DATE_START", null);
    store.commit("SET_DATE_END", null);
    store.commit("SET_FIRST_DAY", null);
    store.commit("SET_LAST_DAY", null);
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });
});

export default router;
