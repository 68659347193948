<template>
  <main class="container bottomUp smart_main_area">
    <div id="body_user" class="main_area tablet_main_area">
      <div class="tab_area">
        <a
          v-for="tab in tabs"
          :key="tab"
          class="tab order_manage btn_point"
          :class="{ active: tab === currentTab }"
          @click="currentTab = tab"
        >
          {{ tab }}
        </a>
      </div>
      <supplier-detail ref="supplier" :is-active="currentTab === tabs[0]" :supplier="supplier" />
      <items ref="items" :is-active="currentTab === tabs[1]" :supplier="supplier"
             :items="items" :action="'showAction'" :setting-image="settingImage"
      />
    </div>
    <div v-if="currentTab === tabs[0]" class="control_area">
      <router-link to="/order_company" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control two_items">
        <router-link :to="{}" class="btn" :class="supplier.has_scheduled_order ? 'disabled' : 'primary_border'"
                     @click.native="$router.push({ path: `/order_company/${ supplier.id }/edit` })"
        >編集する</router-link>
        <router-link :to="{}" class="btn ml_medium" :class="supplier.has_scheduled_order ? 'disabled' : 'delete'"
                     @click.native="handleDelete"
        >削除する</router-link>
      </div>
    </div>
    <div v-else class="control_area">
      <router-link to="/order_company" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link :to="`/order_company/${id}/create_item`" class="btn" :class="supplier.has_scheduled_order ? 'disabled' : 'primary_border'">
          新規商品登録
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { SuppliersService } from '../../services/suppliers.service';
import { SupplierItemsService } from '../../services/supplier_items.service';
import Items from './Item/Index.vue';
import SupplierDetail from './components/SupplierDetail.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SupplierDetail,
    Items
  },
  data() {
    return {
      currentTab: this.$router.history.current.params.isItemTab ? '商品管理' : '発注先管理',
      tabs: ['発注先管理', '商品管理'],
      id: this.$router.history.current.params.id,
      supplier: {},
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      tabActive: 'getTab',
      settingImage: 'getSettingimage'
    })
  },
  watch: {
    async currentTab(val) {
      if( val == '発注先管理' ) {
        await this.$store.dispatch('changeTabDefault');
      }
    }
  },
  created() {
    this.getSupplier();
    this.getSupplierItems();
    if(this.tabActive !== null ) {
      this.currentTab = '商品管理';
    }
  },
  async destroyed() {
    await this.$store.dispatch('changeTabDefault');
  },
  methods: {
    getSupplier: async function() {
      this.$loading(true);
      try {
        const { response } = await SuppliersService.get(this.id);
        this.supplier = response.data;
        if (this.supplier.order_type == 'fax') {
          if (this.supplier.order_template == null) {
            this.supplier.order_template = 'SmartRequest'; 
          }
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    getSupplierItems: async function() {
      this.$loading(true);
      try {
        const params = {
          supplier_id: this.id
        };
        const { response } = await SupplierItemsService.index(params);
        this.items = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
    handleDelete: async function() {
      const res = await this.$confirm('削除確認', '発注先を削除します。\n本当によろしいですか？');
      if (!res) return;
      this.$loading(true);
      try {
        await SuppliersService.delete(this.id);
        await this.$store.dispatch('removeOrderWithItem', { supplier_id: this.id });
        this.$router.push('/order_company');
        this.$loading(false);
      } catch (error) {
        console.log(error);
        this.$loading(false);
        const errorMessage = error.response.result.message[0];
        await this.$popupMessage('エラー', errorMessage, { type: 'error' });
      }
    },
  }
};
</script>
<style scoped>
@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
  }
}
</style>
