<template>
  <main class="owner_main_area main_area_pc">
    <CompleteStep step="1" />
    <Confirm v-if="isConfirm" />
    <Register v-else />
  </main>
</template>

<script>
import Register from './components/Register.vue';
import Confirm from './components/Confirm.vue';
import CompleteStep from '../../../components/CompleteStep.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Confirm,
    Register,
    CompleteStep
  },
  computed: {
    ...mapGetters({
      isConfirm: 'getPageConfirm',
    }),
  },
  watch: {
    'isConfirm': {
      handler() {
        this.scrollToTop();
        if (this.isConfirm) {
          document.title = '会員登録確認';
        } else {
          document.title = '会員登録';
        }
      }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
