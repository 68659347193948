<template>
  <main class="container no_padding smart_main_area ">
    <div class="main_area tablet_main_area pd_only_bottom">
      <h1 class="title mb_large padding_text">
        お知らせ
      </h1>
      <ul class="memo_list">
        <li v-for="(notification, index) in notifications"
            :key="index" class="noti_item"
            @click="onClickRow(notification.id,notification.read_status)"
        >
          <div class="flex alignCenter wrap to_detail">
            <div>
              <div v-if="notification.read_status==='unread'" class="memo_label new">
                NEW
              </div>
              <div class="notification_date memo_date-list">
                {{ notification.start_time }}
              </div>
              <div class="memo_text notify_down_line">{{ notification.title }}</div>
            </div>
          </div>
        </li>
        <div v-show="notifications.length === 0" class="text-center">表示するデータはありません。</div>
      </ul>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
    </div>
  </main>
</template>

<script>
import { NotificationService } from '../../services/notification.service';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    ...mapGetters({
      getUnreadNotificationCount: 'getUnreadNotificationCount',
    })
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    async getNotifications() {
      try {
        const { response } = await NotificationService.index();
        this.notifications = response.data;
      } catch(error){
        console.log(error);
      }
    },
    async onClickRow(id,read_status) {
      try {
        if(read_status==='unread'){
          await NotificationService.read(id);
          await this.$store.dispatch('getNotifications');
        }

        const { response } = await NotificationService.get(id);
        if(response.result.message === 'not_found') {
          const value = await this.$popupMessage('エラー', 'お知らせは公開されていません。');
          if(!value) return;

          window.location.href = '/notification';
        } else {
          this.notification = response.data;
          this.$router.push({ path: `/notification/${id}` });
        }
      } catch(error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 480px){
  .main_area {
    padding: 16px;
    width: 100%;
  }
}
</style>
