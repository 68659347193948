<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area tablet_main_area">
      <div class="title background-white">
        <h1 class="title padding_text">
          利用レポート
        </h1>
      </div>
      <div class="form_row center chanel_text_padding background-white">
        <label for="date" class="form_label calendar-title">表示期間</label>
        <div class="form_input">
          <DateTimePicker v-if="typeSearchTime == searchTime.month" v-model="startTime" format="YYYY年MM月"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input calendar-text"
                          type="month"
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          @input="onChangeYear"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
          <DateTimePicker v-if="typeSearchTime == searchTime.week" v-model="value" format="YYYY年MM月DD日"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input calendar-text"
                          type="week"
                          :lang="lang"
                          range 
                          @input="onChangeWeek"
          >
            <template v-slot:content="slotProps">
              <div>
                <calendar-panel
                  type="week"
                  v-model="value"
                  @select="slotProps.emit"
                  :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                ></calendar-panel>
              </div>
            </template>
            <span slot="icon-calendar" />
          </DateTimePicker>
          <DateTimePicker v-if="typeSearchTime == searchTime.range" v-model="rangeDate" format="YYYY年MM月DD日"
                          :editable="false" :clearable="false"
                          class="input_wrapper calender full_width" input-class="calendar__input calendar-text"
                          type="date"
                          range
                          :disabled-date="(date) => date > (new Date().setHours(0, 0, 0, 0))"
                          @input="onChangeRange"
          >
            <span slot="icon-calendar" />
          </DateTimePicker>
        </div>
        <div class="text-center menu-search loading-page is-pc-type">
          <ul class="btn-choose-type">
            <li>
              <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="month" name="typeSearchTime" />
              <label class="type-search-time" for="月">月</label>
            </li>
            <li>
              <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="week" name="typeSearchTime" />
              <label class="type-search-time" for="週">週</label>
            </li>
            <li>
              <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="range" name="typeSearchTime" />
              <label class="type-search-time" for="日付">日付指定</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-right menu-search loading-page is-mobile-type">
        <ul class="btn-choose-type">
          <li>
            <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="month" name="typeSearchDay" />
            <label class="type-search-time" for="月">月</label>
          </li>
          <li>
            <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="week" name="typeSearchDay" />
            <label class="type-search-time" for="週">週</label>
          </li>
          <li>
            <input type="radio" v-model="typeSearchTime" v-on:change="onChangeTypeSearchTime" value="range" name="typeSearchDay" />
            <label class="type-search-time" for="日付">日付指定</label>
          </li>
        </ul>
      </div>
      <div class="information-statistics">
        <div v-if="typeSearchTime == searchTime.month" class="infomation-by-month">
          <div class="order-statistical-container"> 
            <button class="btn-month pre actived" @click="preMonth">&#8249;</button>
            <div class="detail-month">
              <div class="month">
                <div class="content-month">
                  <p class="title-time">{{ dateDefault }}</p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content font_text-bold">{{ countThisMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content font_text-bold">{{ showPrice(sumThisMonth) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <div class="detail-last-month is-pc-type">
              <div class="last-month mr_xsmall">
                <div class="content-month">
                  <p class="title-time">前月分 <span class="time">{{ dateLastMonth }}</span></p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content">{{ countLastMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastMonth) }}</span>円</span></p>
                </div>
              </div>
              <div class="last-month">
                <div class="content-month">
                  <p class="title-time">前年分 <span class="time">{{ dateLastYear }}</span></p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content">{{ countLastYear }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastYear) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <button class="btn-month next" :class="isActiveBtnNextMonth() ? 'actived' : 'disabled'" @click="nextMonth">
              &#8250;
            </button>
            <div class="detail-last-month is-mobile">
              <div class="last-month mr_xsmall">
                <div class="content-month">
                  <p class="title-time">前月分 <span class="time">{{ dateLastMonth }}</span></p>
                  <p class="count">回数 <span class="content_right"><span class="text_content">{{ countLastMonth }}</span>回</span></p>
                  <p>金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastMonth) }}</span>円</span></p>
                </div>
              </div>
              <div class="last-month">
                <div class="content-month">
                  <p class="title-time">前年分 <span class="time">{{ dateLastYear }}</span></p>
                  <p class="count">回数 <span class="content_right"><span class="text_content">{{ countLastYear }}</span>回</span></p>
                  <p>金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastYear) }}</span>円</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="typeSearchTime == searchTime.week" class="infomation-by-week is-pc">
          <div class="order-statistical-container">
            <button class="btn-month pre actived" @click="preWeek">&#8249;</button>
            <div class="detail-month">
              <div class="month">
                <div class="content-month">
                  <p class="title-time week">{{ firstday }}- {{ lastday}}</p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content font_text-bold">{{ countThisMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content font_text-bold">{{ showPrice(sumThisMonth) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <div class="detail-last-month">
              <div class="last-month mr_xsmall">
                <div class="content-month">
                  <p class="title-time">先週分 <span class="time week">{{ dateStartLastWeek }}- {{ dateEndLastWeek }}</span></p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content">{{ countLastMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastMonth) }}</span>円</span></p>
                </div>
              </div>
              <div class="last-month">
                <div class="content-month">
                  <p class="title-time">先々週分 <span class="time week">{{ dateStartTooLastWeek }}- {{ dateEndTooLastWeek }}</span></p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content">{{ countLastYear }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastYear) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <button class="btn-month next actived" :class="isActiveBtnNextWeek() ? 'actived' : 'disabled'" @click="nextWeek">&#8250;</button>
          </div>
        </div>
        <div v-if="typeSearchTime == searchTime.week" class="infomation-by-week is-mobile">
          <div class="order-statistical-container">
            <button class="btn-month pre actived" @click="preWeek">&#8249;</button>
            <div class="detail-month">
              <div class="month">
                <div class="content-month">
                  <p class="title-time week">{{ firstday }}- {{ lastday}}</p>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content font_text-bold">{{ countThisMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content font_text-bold">{{ showPrice(sumThisMonth) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <button class="btn-month next actived" :class="isActiveBtnNextWeek() ? 'actived' : 'disabled'" @click="nextWeek">&#8250;</button>
            <div class="detail-last-month">
              <div class="last-month mr_xsmall">
                <div class="content-month">
                  <div class="item-left content-week">
                    <p class="title-time">先週分</p>
                  </div>
                  <div class="content-week">
                    <span class="time">{{ dateStartLastWeek }}-</span><br>
                    <span class="time">{{ dateEndLastWeek }}</span>
                  </div> 
                  <p class="count">回数 <span class="content_right"><span class="text_content">{{ countLastMonth }}</span>回</span></p>
                  <p>金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastMonth) }}</span>円</span></p>
                </div>
              </div>
              <div class="last-month ">
                <div class="content-month">
                  <div class="item-left content-week">
                    <p class="title-time">先々週分</p>
                  </div>
                  <div class="content-week">
                    <span class="time">{{ dateStartTooLastWeek }}-</span><br>
                    <span class="time">{{ dateEndTooLastWeek }}</span>
                  </div> 
                  <p class="count">回数 <span class="content_right"><span class="text_content">{{ countLastYear }}</span>回</span></p>
                  <p>金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastYear) }}</span>円</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="typeSearchTime == searchTime.week" class="infomation-by-week is-ipad">
          <div class="order-statistical-container">
            <button class="btn-month pre actived" @click="preWeek">&#8249;</button>
            <div class="detail-month">
              <div class="month">
                <div class="content-month week">
                  <div><p class="title-time week">{{ firstday }}-</p></div>
                  <div><p class="title-time week">{{ lastday }}</p></div>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content font_text-bold">{{ countThisMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content font_text-bold">{{ showPrice(sumThisMonth) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <div class="detail-last-month">
              <div class="last-month mr_xsmall">
                <div class="content-month week">
                  <div class="item-left content-week">
                    <p class="title-time week">先週分</p>
                  </div>
                  <div class="content-week">
                    <div><span class="time week">{{ dateStartLastWeek }}-</span></div>
                    <div><span class="time week">{{ dateEndLastWeek }}</span></div>
                  </div>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content">{{ countLastMonth }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastMonth) }}</span>円</span></p>
                </div>
              </div>
              <div class="last-month">
                <div class="content-month week">
                  <div class="item-left content-week">
                    <p class="title-time week">先々週分</p>
                  </div>
                  <div class="content-week">
                    <div><span class="time week">{{ dateStartTooLastWeek }}-</span></div>
                    <div><span class="time week">{{ dateEndTooLastWeek }}</span></div>
                  </div>
                  <p class="count">発注回数 <span class="content_right"><span class="text_content">{{ countLastYear }}</span>回</span></p>
                  <p>発注金額 <span class="content_right"><span class="text_content">{{ showPrice(sumLastYear) }}</span>円</span></p>
                </div>
              </div>
            </div>
            <button class="btn-month next actived" :class="isActiveBtnNextWeek() ? 'actived' : 'disabled'" @click="nextWeek">&#8250;</button>
          </div>
        </div>
        <div v-if="typeSearchTime == searchTime.range" class="infomation-by-day">
          <div class="order-statistical-container">
            <div class="detail-month mx0">
              <div class="month full_width">
                <div class="content-month">
                  <p class="title-time">{{ dateStart }} - {{ dateEnd }}</p>
                  <div class="detail-day">
                    <p class="count">発注回数 <span class="content_right range_date"><span class="text_content font_text-bold">{{ countThisMonth }}</span>回</span></p>
                    <p>発注金額 <span class="content_right range_date"><span class="text_content font_text-bold">{{ showPrice(sumThisMonth) }}</span>円</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center loading-page mt_xsmall">
          <ul class="donate-now">
            <li>
              <input type="radio" v-model="typeSearch" v-on:change="onChangeTypeSearch" value="1" name="amount" />
              <label class="type-count-order" for="発注額">発注額</label>
            </li>
            <li>
              <input type="radio" v-model="typeSearch" v-on:change="onChangeTypeSearch" value="0" name="amount" />
              <label class="type-count-amount" for="発注回数">発注回数</label>
            </li>
          </ul>
        </div>
        <div v-if="chartData.datasets[0].data.length === 0" class="text-center mt-28">表示するデータはありません。</div>
        <div v-else>
          <Bar
            class="barchart"
            :chart-data="chartData" 
            :chart-options="chartOptions"
          />
          <table class="sr_ad_table background-table no_pointer_td" style="margin: 0px">
            <thead class="">
              <tr>
                <th class="w_10">#</th>
                <th class="w_45">発注先</th>
                <th class="w_25 text-right" v-if="typeSearch == 0">発注回数</th>
                <th class="w_25 text-right" v-else>発注額</th>
                <th class="w_20 text-right">詳細</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in tableDatas"
                  :key="index"
                  class="btn_point"
              >
                <td class="w_10">{{ data.indexData }}</td>
                <td class="change_table">{{ data.supplier_name }}</td>
                <td class="w_25 text-right" v-if="typeSearch == 0">{{ data.count }}</td>
                <td class="w_25 text-right" v-else>{{ showPrice(data.sum) }} 円</td>
                <td class="w_20 text-right">
                  <button class="fs-18 next-detail-btn" @click="showDetail(data.supplier_id)">
                    &#8250;
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="paginationParams.page < paginationParams.totalPage"
              class="background-white text_center padding_btn-load-more"
          >
            <button class="btn primary_border btn-load-more" @click="jumpPage">
              もっと表示する
            </button>
          </div>
          <div class="text-center loading-page mt_xsmall">
            <ul class="donate-now">
              <li>
                <input type="radio" v-model="typeSearch" v-on:change="onChangeTypeSearch" value="1" name="typeSearch" />
                <label class="type-count-order" for="発注額">発注額</label>
              </li>
              <li>
                <input type="radio" v-model="typeSearch" v-on:change="onChangeTypeSearch" value="0" name="typeSearch" />
                <label class="type-count-amount" for="発注回数">発注回数</label>
              </li>
            </ul>
          </div>
          <div class="group_chart">
            <div class="canvas">
              <Doughnut
                class="doughnut_chart"
                :chart-data="chartDoughnutData"
                :chart-options="chartDoughnutOptions"
              />
            </div>
            <div class="note">
              <ul v-for="(label, index) in chartDoughnutData.labels"
                  :key="index"
              >
                <li class="label_doughnut_chart">
                  <div class="box_label" :class="renderClassChart(index + 1)"/>
                  <div>{{ label }}<span class="text_label">{{ percentageData[index] }}</span></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/dashboard" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
    </div>
  </main>
</template>

<script>
import { OrderReportsService } from '../../services/order_reports.service';
import { Bar, Doughnut } from 'vue-chartjs/legacy';
import vSelect from 'vue-select';
import { Chart as ChartJS, BarElement, ArcElement, Legend, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import utils from '../../common/util.js';
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ja";
const { CalendarPanel } = DatePicker;

ChartJS.register(BarElement, ArcElement, Legend, CategoryScale, LinearScale, ChartDataLabels)

export default {
  props: {},
  components: { Bar, Doughnut, vSelect, DatePicker, CalendarPanel },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      searchTime: {
        month: 'month',
        week: 'week',
        range: 'range',
      },
      order_staffs: [],
      noData: false,
      value: [new Date(), new Date()],
      startTime: new Date(),
      rangeDate: [new Date(), new Date()],
      firstday: moment().format('YYYY年MM月DD日'),
      lastday: moment().format('YYYY年MM月DD日'),
      dateStart: moment().format('YYYY年MM月DD日'),
      dateEnd: moment().format('YYYY年MM月DD日'),
      typeSearch: 1,
      typeSearchTime: 'month',
      dateDefault: moment().format('YYYY年MM月'),
      dateLastMonth: moment().subtract(1, 'month').format('YYYY年MM月'),
      dateLastYear: moment().subtract(1, 'year').format('YYYY年MM月'),
      dateStartLastWeek: moment().subtract(1, 'week').format('YYYY年MM月DD日'),
      dateEndLastWeek: moment().subtract(1, 'week').format('YYYY年MM月DD日'),
      dateStartTooLastWeek: moment().subtract(2, 'week').format('YYYY年MM月DD日'),
      dateEndTooLastWeek: moment().subtract(2, 'week').format('YYYY年MM月DD日'),
      chartData: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        datasets: [
          {
            backgroundColor: '#966544',
            data: []
          }
        ]
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: false
          }
        },
        responsive: true,
        scales: {
          y: {
            ticks: {
              precision: 0,
              beginAtZero: true
            },
            grid: {
              borderWidth: 0,
            },
          },
          x: {
            grid: {
              display: false
            }
          }
        },
        maintainAspectRatio: false,
      },
      tableDatas: [],
      countLastYear: null,
      countLastMonth: null,
      countThisMonth: null,
      sumLastYear: null,
      sumLastMonth: null,
      sumThisMonth: null,
      chartDoughnutData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#fcb50a", "#e27e01", "#e81123", "#ec008c", "#68217a",
                              "#00188f", "#00bcf2", "#00b294", "#009e49", "#808080"],
            data: [],
          }
        ],
      },
      chartDoughnutOptions: {
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: 'white',
            font: {
              size: 16
            },
            padding: 4,
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              let dataIndexChar = [];
              let percentage = 0;

              dataArr.forEach((element, index) => {
                sum += element;
                if (element > 0) {
                  dataIndexChar.push(index);
                }
              });

              if (ctx.dataIndex === dataIndexChar[dataIndexChar.length - 1]) {
                percentage = (100 - parseFloat(this.sumDataChar)).toFixed(1);
              } else {
                percentage = (value / sum * 100).toFixed(1);
                this.sumDataChar = this.sumDataChar + parseFloat(percentage);
              }

              if (percentage > 0) {
                return percentage + "%";
              } else {
                return '';
              }
            }
          },
        },
        hover: {mode: null},
        cutout: '60%',
        responsive: true,
        borderWidth: 0
      },
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0,
        item_page_size: 0,
      },
      percentageData: [],
      sumDataChar: 0,
      indexTable: 1,
      indexChart: 1,
    };
  },
  async mounted() {
    if (typeof window !== 'undefined') {
      window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
    }
    this.startTime = this.$store.state.order_report.time ? new Date(this.$store.state.order_report.time) : new Date();
    this.rangeDate[0] = this.$store.state.order_report.dateStart ? new Date(this.$store.state.order_report.dateStart) : new Date();
    this.rangeDate[1] = this.$store.state.order_report.dateEnd ? new Date(this.$store.state.order_report.dateEnd) : new Date();
    this.value[0] = this.$store.state.order_report.firstDay ? new Date(this.$store.state.order_report.firstDay) : new Date();
    this.typeSearchTime = this.$store.state.order_report.typeSearchTime ? this.$store.state.order_report.typeSearchTime : this.searchTime.month;
    this.value[1] = new Date(moment(this.value[0]).day(7).format('YYYY-MM-DD'));
    await this.updateChangeDate();
    await this.searchSupplierOrder();
    await this.searchItemCategoryOrder();
  },
  methods: {
    ...utils,
    async preMonth() {
      this.startTime = new Date(moment(this.startTime).subtract(1, 'month'));
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async nextMonth() {
      this.startTime = new Date(moment(this.startTime).add(1, 'month'));
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async preWeek() {
      this.value[0] = new Date(moment(this.value[0]).subtract(1, 'week'));
      this.value[1] = new Date(moment(this.value[1]).subtract(1, 'week'));
      this.value = [this.value[0], this.value[1]];
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async nextWeek() {
      this.value[0] = new Date(moment(this.value[0]).add(1, 'week'));
      this.value[1] = new Date(moment(this.value[1]).add(1, 'week'));
      this.value = [this.value[0], this.value[1]];
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async fetchCountOrder() {
      this.resetDataCountOrder();
      const params = {
        typeSearchTime: this.typeSearchTime,
        dateStart: moment(this.rangeDate[0]).format('YYYY-MM-DD'),
        dateEnd: moment(this.rangeDate[1]).format('YYYY-MM-DD'),
        firstday: moment(this.value[0]).format('YYYY-MM-DD'),
        lastday: moment(this.value[1]).format('YYYY-MM-DD'),
        time: moment(this.startTime).format('YYYY-MM'),
      }
      try {
        this.$loading(true);
        
        const { response } = await OrderReportsService.index(params);
        this.countThisMonth = response.data.count_this_month;
        this.sumThisMonth = response.data.sum_this_month;

        this.countLastMonth = response.data.count_last_month;
        this.sumLastMonth = response.data.sum_last_month;;

        this.countLastYear = response.data.count_last_year;
        this.sumLastYear = response.data.sum_last_year;

        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },

    async onChangeYear() {
      this.$store.commit("SET_TIME", this.startTime);
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async onChangeWeek() {
      this.value = moment(this.value).subtract(1, 'day');
      this.value[0] = new Date(moment(this.value).day(1).format('YYYY-MM-DD'));
      this.value[1] = new Date(moment(this.value).day(7).format('YYYY-MM-DD'));
      this.value = [this.value[0], this.value[1]];
      this.$store.commit("SET_FIRST_DAY", this.value[0]);
      this.$store.commit("SET_LAST_DAY", this.value[1]);
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async onChangeRange() {
      this.$store.commit("SET_DATE_START", this.rangeDate[0]);
      this.$store.commit("SET_DATE_END", this.rangeDate[1]);
      await this.updateChangeDate();
      await this.updateChangeData();
    },

    async onChangeTypeSearch() {
      await this.updateChangeData();
    },

    async onChangeTypeSearchTime() {
      if (this.typeSearchTime == this.searchTime.month) {
        await this.onChangeYear();
      }
      else if (this.typeSearchTime == this.searchTime.week) {
        this.value = this.value[0];
        await this.onChangeWeek();
      }
      else {
        await this.onChangeRange();
      }
    },

    async updateChangeData () {
      this.paginationParams.page = 1;
      await this.searchSupplierOrder();
      await this.searchItemCategoryOrder();
    },

    async updateChangeDate () {
      this.dateDefault = moment(this.startTime).format('YYYY年MM月'),
      this.dateLastMonth = moment(this.startTime).subtract(1, 'month').format('YYYY年MM月'),
      this.dateLastYear = moment(this.startTime).subtract(1, 'year').format('YYYY年MM月'),
      this.firstday = moment(this.value[0]).format('YYYY年MM月DD日');
      this.lastday = moment(this.value[1]).format('YYYY年MM月DD日');
      this.dateStartLastWeek = moment(this.value[0]).subtract(1, 'week').format('YYYY年MM月DD日'),
      this.dateEndLastWeek = moment(this.value[1]).subtract(1, 'week').format('YYYY年MM月DD日'),
      this.dateStartTooLastWeek = moment(this.value[0]).subtract(2, 'week').format('YYYY年MM月DD日'),
      this.dateEndTooLastWeek = moment(this.value[1]).subtract(2, 'week').format('YYYY年MM月DD日'),
      this.dateStart = moment(this.rangeDate[0]).format('YYYY年MM月DD日');
      this.dateEnd = moment(this.rangeDate[1]).format('YYYY年MM月DD日');
      await this.fetchCountOrder();
    },

    resetDataCountOrder() {
      this.countThisMonth = 0;
      this.countLastMonth = 0;
      this.countLastYear = 0;
      this.sumThisMonth = 0;
      this.sumLastMonth = 0;
      this.sumLastYear = 0;
    },

    async searchSupplierOrder() {
      try {
        this.$loading(true);
        const searchParams = {
          time: moment(this.startTime).format('YYYY-MM'),
          type_search: this.typeSearch,
          typeSearchTime: this.typeSearchTime,
          dateStart: moment(this.rangeDate[0]).format('YYYY-MM-DD'),
          dateEnd: moment(this.rangeDate[1]).format('YYYY-MM-DD'),
          firstday: moment(this.value[0]).format('YYYY-MM-DD'),
          lastday: moment(this.value[1]).format('YYYY-MM-DD'),
        }

        let params = {...this.paginationParams, ...searchParams};

        let arrValue = [];
        const { response } = await OrderReportsService.searchSupplierOrder(params);
        const dataChartLength = 10;
        if(response.data.length > 0) {
          for (let index = 0; index < dataChartLength; index++) {
            if (response.data[index] === undefined) { break; }

            this.typeSearch == 0 ? arrValue.push(response.data[index].count) : arrValue.push(response.data[index].sum);
          }
        }

        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.paginationParams.item_page_size = response.pager.page_size;
        this.tableDatas = response.data;
        this.chartData.datasets[0].data = arrValue;

        this.chartData.labels = [];

        for (let index = 0; index < dataChartLength; index++) {
          let i = 1;
          if (arrValue[index - 1] !== undefined) {
            if (arrValue[index] != arrValue[index - 1]) {
              this.indexChart = index + 1;
            }

            i = this.indexChart;
          } else {
            i = index + 1;
          }

          this.renderIndexTable(index + 1, this.tableDatas[index], this.tableDatas[index - 1])

          this.chartData.labels.push(i)
        }

        this.indexTable = 1;
        this.indexChart = 1;

        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },

    showDetail(id) {
      this.$store.commit("SET_SUPPLIER_ID", id);
      this.$store.commit("SET_TYPE", this.typeSearch);
      this.$store.commit("SET_TYPE_SEARCH_TIME", this.typeSearchTime);
      this.$store.commit("SET_TIME", this.startTime);
      this.$store.commit("SET_DATE_START", this.rangeDate[0]);
      this.$store.commit("SET_DATE_END", this.rangeDate[1]);
      this.$store.commit("SET_FIRST_DAY", this.value[0]);
      this.$store.commit("SET_LAST_DAY", this.value[1]);
      this.$router.push(`/order_report/detail`);
    },

    async jumpPage() {
      this.paginationParams.page = this.paginationParams.page + 1;
      const searchParams = {
        time: moment(this.startTime).format('YYYY-MM'),
        typeSearchTime: this.typeSearchTime,
        dateStart: moment(this.rangeDate[0]).format('YYYY-MM-DD'),
        dateEnd: moment(this.rangeDate[1]).format('YYYY-MM-DD'),
        firstday: moment(this.value[0]).format('YYYY-MM-DD'),
        lastday: moment(this.value[1]).format('YYYY-MM-DD'),
        type_search: this.typeSearch
      }

      let params = {...this.paginationParams, ...searchParams};

      try {
        this.$loading(true);
        const { response } = await OrderReportsService.searchSupplierOrder(params);
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.paginationParams.item_page_size = response.pager.page_size;
        this.tableDatas = this.tableDatas.concat(response.data);
        
        this.tableDatas.forEach((element, index) => {
          this.renderIndexTable(index + 1, element, this.tableDatas[index - 1])
        });
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },

    async searchItemCategoryOrder() {
      this.sumDataChar = 0;
      try {
        this.$loading(true);
        const searchParams = {
          time: moment(this.startTime).format('YYYY-MM'),
          type_search: this.typeSearch,
          typeSearchTime: this.typeSearchTime,
          dateStart: moment(this.rangeDate[0]).format('YYYY-MM-DD'),
          dateEnd: moment(this.rangeDate[1]).format('YYYY-MM-DD'),
          firstday: moment(this.value[0]).format('YYYY-MM-DD'),
          lastday: moment(this.value[1]).format('YYYY-MM-DD'),
        }

        let arrLabel = [];
        let arrValue = [];
        const { response } = await OrderReportsService.searchItemCategoryOrder(searchParams);
        if (response.data.length > 0) {
          this.cayegories = response.data;
          response.data.forEach(element => {
            arrLabel.push(element.category_name);
            this.typeSearch == 0 ? arrValue.push(element.count) : arrValue.push(element.total_money);
          });
        }
        this.chartDoughnutData.labels = arrLabel;
        this.chartDoughnutData.datasets[0].data = arrValue;

        this.caculatorPercentage();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },

    caculatorPercentage() {
      this.percentageData = [];
      let sum = 0;
      let dataArr = this.chartDoughnutData.datasets[0].data;
      let dataIndexChar = [];
      let sumDataChar = 0;

      dataArr.forEach((element, index) => {
        sum += element;
        if (element > 0) {
          dataIndexChar.push(index);
        }
      });

      dataArr.forEach((element, index) => {
        if (index == dataIndexChar[dataIndexChar.length - 1]) {
          this.percentageData.push((100 - sumDataChar).toFixed(1) + "%");
        } else {
          let dataFloat = (element * 100 / sum).toFixed(1);
          this.percentageData.push((element * 100 / sum).toFixed(1) + "%");
          sumDataChar = sumDataChar + parseFloat(dataFloat);
        }
      });
    },

    renderClassChart(id) {
      return `box_chart-${id}`;
    },

    isActiveBtnNextMonth() {
      return moment(this.startTime).format('YYYY/MM') == moment().format('YYYY/MM') ? false : true;
    },
    isActiveBtnNextWeek() {
      return moment(this.value[1]).format('YYYY/MM/DD') >= moment().format('YYYY/MM/DD') ? false : true;
    },

    renderIndexTable(index, item, previousItem) {
      let i = 1;

      if (item == undefined) {
        return;
      }

      if (previousItem !== undefined) {
        if ((this.typeSearch == 0 && item.count != previousItem.count) ||
            (this.typeSearch == 1 && item.sum != previousItem.sum)) {
          this.indexTable = index;
        }

        i = this.indexTable;
      } else {
        i = index;
      }

      item.indexData = i;
    }
  },
};
</script>
<style lang="scss" scoped>
.main_area {
  padding: 0px;
  background-color: #f4f2f2;
}
.form_row .form_label {
  font-size: 20px;
  width: 200px;
}
.padding_text {
  padding: 24px 24px 16px;
}
.chanel_text_padding {
  padding: 0px 24px 12px;
}
.fs-18 {
  font-size: 1.8rem;
}
.sr_ad_table.background-table {
  margin-bottom: 0;
}
.sr_ad_table.background-table thead tr th {
  background: white;
  font-size: 16px;
  padding: 12px;
}
.sr_ad_table.background-table tbody tr td {
  font-size: 16px;
}
.text-right {
  text-align: right;
}
.menu-search {
  margin-left: 5px;
  text-align: unset;
  li {
    width: 80px;
  }
}
.type-search-time {
  border: 1px solid #BBBBBB;
  color: unset;
}
.item-left {
  float: left;
}
.mx0 {
  margin: 0;
}
.full_width {
  width: 100%;
}
.detail-day {
  width: 40%;
  max-width: 300px;
  min-width: 245px;
}

@media screen and (max-width: 1290px) {
  .time.week {
    font-size: 14px;
  }
  .title-time {
    font-size: 16px;
  }
}

@media screen and (max-width: 1140px) {
  .content-week {
    font-size: 14px;
  }
  .item-left {
    margin-right: 7px;
    padding-top: 6px;
  }
  .content-month.week {
    padding-top: 6px;
  }
}

@media screen and (max-width: 480px) {
  .form_row .form_input {
    width: 100%;
  }
  .form_row .form_label {
    width: 160px;
  }
  .input_wrapper.medium {
    width: 100%;
  }
  .padding_text {
    padding: 16px;
  }
  .chanel_text_padding {
    padding: 0px 16px 8px 16px;
  }
  .menu-search {
    text-align: right;
    margin: 0px;
    padding-right: 16px;
    li {
      width: 62px;
      height: 40px;
    }
  }
  .content-week {
    font-size: 11px;
  }
  .item-left {
    margin-right: 7px;
    padding-top: 5px;
  }
  .calendar-title {
    width: 120px !important;
  }
}
</style>
