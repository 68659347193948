import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class OwnerAnswersService extends BaseService {
  static get resourceEndpoint() {
    return 'owners';
  }

  static async create(data = []) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/question_answers`,
        { question_answers: data }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}