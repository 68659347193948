<template>
  <div>
    <div class="flex mb_small">
      <h1 class="title_admin">{{ supplier.name || supplier.supplier_official_name }} 商品管理</h1>
      <a class="btn_admin admin_primary_border mt-28 mb_medium" href="#" @click="csv_export">CSVエクスポート</a>
      <a class="btn_admin admin_primary_border mt-28 mb_medium ml_medium" href="#" @click="$openModal('itemImport')">CSVアップロード</a>
      <a class="btn_admin admin_primary mt-28 mb_medium ml_medium" href="#"
         @click="updateSelectedItem(null, true); $openModal('itemForm')"
      >商品新規登録</a>
    </div>
    <Table :items="items" :pagination-params="paginationParams" :selected-id="selectedId"
           @refreshTable="getItems" @updateCurrentPage="updateCurrentPage"
           @updateSelectedItem="updateSelectedItem"
    />
    <div class="btn_area_admin bottom_control btn_back">
      <router-link :to="`/admin/restaurant_admin/${restaurant_administrator_id}/suppliers`"
                   class="btn_admin admin_primary_border"
      >
        戻る
      </router-link>
    </div>
    <FormModal :item-id="selectedId" :is-editing="isEditing"
               :restaurant-administrator="restaurant_administrator" :supplier="supplier"
               @refreshTable="getItems" @updateSelectedItem="updateSelectedItem"
    />
    <ImportModal :restaurant-administrator="restaurant_administrator" :supplier="supplier" @refreshTable="getItems" />
  </div>
</template>

<script>
import {SupplierItemsService} from '../../../services/admin/supplier_items.service';
import {SuppliersService} from '../../../services/admin/suppliers.service';
import Table from './components/Table';
import FormModal from './components/FormModal';
import ImportModal from './components/ImportModal';
import utils from '../../../common/util';

export default {
  components: {
    Table,
    FormModal,
    ImportModal,
  },
  data() {
    return {
      supplier: {},
      items: [],
      restaurant_administrator: {},
      paginationParams: {
        page: 1,
        page_size: 10,
        total_page: 0
      },
      searchParams: {
        supplier_id: this.$router.history.current.params.supplier_id,
      },
      restaurant_administrator_id: this.$router.history.current.params.id,
      selectedId: null,
      isEditing: false,
    };
  },
  async mounted() {
    this.getItems();
    this.getSupplier();
  },
  methods: {
    async getItems() {
      this.$loading(true);
      try {
        let params = {...this.paginationParams, ...this.searchParams};

        const {response} = await SupplierItemsService.index(params);
        this.items = response.data;
        this.paginationParams.page = response.pager.page;
        this.paginationParams.totalPage = response.pager.page_count;
        this.restaurant_administrator = response.restaurant_administrator;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    async getSupplier() {
      try {
        const {response} = await SuppliersService.get(this.searchParams.supplier_id);
        this.supplier = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    updateCurrentPage(page) {
      this.paginationParams.page = page;
    },
    updateSelectedItem(id, isEditing = false) {
      this.selectedId = id;
      this.isEditing = isEditing;
    },

    async csv_export() {
      let params = {supplier_id: this.$router.history.current.params.supplier_id};
      try {
        const {response} = await SupplierItemsService.csvExport(params);
        utils.csvExport(`${this.supplier.name || this.supplier.supplier_official_name}_商品管理`, response);
        this.error = false;
      } catch (error) {
        this.error = true;
        console.log(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
