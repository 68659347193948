<template>
  <main class="container bottomUp smart_main_area">
    <div class="main_area">
      <h1 class="title mb_medium padding_text">発注予定リスト</h1>
      <template v-if="carts.length > 0">
        <div class="form_row mb_medium chanel_text_padding alignStart">
          <label for="" class="form_label">届け先</label>
          <div class="form_text display_content">
            <div class="bold form_text-name">{{ restaurant.name }}</div>
            <span>
              <div class="tooltip"><span class="icon icon_info" />
                <div class="form_main form_main_tooltip tooltiptext">
                  <div class="small bold">住所</div>
                  <div class="small">〒{{ showZipcode(restaurant.postcode) }}</div>
                  <div class="small bold" />
                  <div class="small change_distance_8">{{ restaurant.address }}</div>
                  <div class="small bold">電話番号</div>
                  <div class="small">{{ formatPhone() }}</div>
                </div>
              </div>
            </span>
          </div>
        </div>

        <template v-for="(cart, index) in carts">
          <div v-if="cart.cart_items.filter(cartItem => !cartItem._destroy).length > 0">
            <div class="cart_title mb_medium">
              <label class="btn_checkbox padding-btn-checkbox">
                <input
                  v-model="cart.selected"
                  class="owner_padding_checkbox"
                  type="checkbox"
                  @change="selectAllItem(cart)"
                >
                <span class="checkmark_checkbox" :class="reorderId != null ? 'disabled_checkbox' : ''"/>
              </label>
              <span style="margin-left: 10px;">発注先：</span>
              <span style="font-weight: bold; margin-left: 20px;">{{ cart.supplier_name }}</span>
              <span>
                <div class="tooltip"><span class="icon icon_info margin_icon_info" />
                  <div class="form_main form_main_tooltip tooltiptext tooltiptext_two">
                    <div class="small bold">発注方法</div>
                    <div v-if="cart.order_type == 'email'" class="small">メール</div>
                    <div v-else class="small">{{ cart.order_type.toUpperCase() }}</div>
                    <div class="small" />
                    <div v-if="cart.order_type == 'email'" class="small change_distance_8">{{ cart.supplier_email }}</div>
                    <div v-if="cart.order_type == 'fax'" class="small change_distance_8">{{ cart.supplier_fax }}</div>
                  </div>
                </div>
              </span>
            </div>
            <div v-if="cart.order_type == 'fax'" class="form_row center chanel_text_padding change_distance_8">
              <label for="date" class="form_label">発注時間</label>
              <div class="form_input">
                <div class="radio_position">
                  <label class="radio_btn_setting mr_medium">
                    <input
                      v-model="cart.sendNow"
                      type="radio"
                      class="rb_border"
                      :value="true"
                      @change="changeRadioButton(cart.supplier_id, $event)"
                    >
                    <span class="rb_checkmark" />
                    <label class="rb_label">今すぐ送信</label>
                  </label>
                  <label class="radio_btn_setting">
                    <input
                      v-model="cart.sendNow"
                      type="radio"
                      class="rb_border"
                      :value="false"
                      @change="changeRadioButton(cart.supplier_id, $event)"
                    >
                    <span class="rb_checkmark" />
                    <label class="rb_label">日時指定</label>
                  </label>
                  <DateTimePicker v-show="!cart.sendNow"
                                  v-model="cart.scheduledTime" format="YYYY/MM/DD(dd) HH:mm"
                                  :editable="false" :clearable="false"
                                  class="input_wrapper calender small" input-class="calendar__input small"
                                  type="datetime" :minute-step="5"
                                  :disabled-date="(date) => date < (new Date().setHours(0, 0, 0, 0))"
                  >
                    <span slot="icon-calendar" />
                  </DateTimePicker>
                </div>
                <div>
                  <span v-for="error in errors[`${index}_scheduledTime`]" v-show="!cart.sendNow" :key="error"
                        class="error-message ml_small" style="text-align: left;"
                  >
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form_row center mb_medium chanel_text_padding">
              <label for="staff" class="form_label">発注担当者</label>
              <div class="form_input">
                <div class="select_wrapper arrow_down medium">
                  <select id="staff" v-model="cart.orderStaffId" name="staff"
                          class="form_option"
                  >
                    <option v-for="(staff, index) in orderStaffs" :key="index" :value="staff.id">{{ staff.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form_row center chanel_text_padding change_distance_8">
              <label for="date" class="form_label">納品希望日</label>
              <div class="form_input">
                <DateTimePicker v-model="cart.delivery_date" format="YYYY/MM/DD(dd)"
                                :editable="false" :clearable="false"
                                class="input_wrapper calender medium" input-class="calendar__input"
                                :disabled-date="(date) => date < (new Date().setHours(0, 0, 0, 0))"
                                @input="changeDateInput(cart.supplier_id, cart.delivery_date)"
                >
                  <span slot="icon-calendar" />
                </DateTimePicker>
                <span v-for="error in errors[`${index}_deliveryDate`]" v-show="!cart.sendNow" :key="error"
                      class="error-message ml_small"
                >
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form_row center mb_large chanel_text_padding">
              <label for="date" class="form_label" />
              <div class="form_input">
                <div class="form_main">
                  <div class="small smp_font_size">土日祝・年末年始は、発注先業者様のご都合により前後する可能性がございます。</div>
                </div>
              </div>
            </div>
            <div class="form_row center mb_large chanel_text_padding">
              <item-select
                :cart-items="cart.cart_items"
                :setting-image="settingImage"
                :is-reorder="reorderId != null"
                @selectCartItem="selectCartItem"
                @checkErrQty="checkErrQty"
                @calculatePrice="calculatePrice"
                @changeItemQty="changeItemQty"
              >
              </item-select>
            </div>
            <div class="form_row center mb_large chanel_text_padding">
              <div style="display: flex; margin-left: auto;">
                小計
                <div class="ml_large">
                  {{ showPrice(cart.total_amount) }}円<span class="summary_small">（税抜：{{ showPrice(cart.tax_amount) }}円）</span>
                </div>
              </div>
            </div>
            <div class="form_row chanel_text_padding change_distance_24">
              <label for="comment" class="form_label">備考欄</label>
              <div class="form_input">
                <div class="input_wrapper">
                  <textarea id="" v-model="cart.remark" :name="`${index}_comment`"
                            cols="30" rows="5" placeholder="発注先へお伝えしたいことがあればご記入ください。"
                            :index="cart.supplier_id" @input="onchangeInputOrder"
                  />
                  <span v-for="error in errors[`${index}_comment`]" :key="error" class="error-message">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="form_row chanel_text_padding" style="border-top: 2px solid #966544;">
          <div class="text-price">
            合計金額
            <div class="ml_large">
              {{ showPrice(total_amount) }}円<span class="summary_small_total">（税抜：{{ showPrice(tax_amount) }}円）</span>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="form_row mb_medium chanel_text_padding alignStart">
        <div style="margin: auto;">表示するデータはありません。</div>
      </div>
    </div>
    <div class="control_area">
      <div class="icon_circle" @click="back">
        <div class="icon back for_controlArea" />
      </div>
      <div class="btn_area control two_items">
        <router-link
          :to="{}"
          v-if="reorderId == null"
          class="btn btn_two_line primary_border"
          :class="carts.length > 0 ? '' : 'disabled'"
          @click.native="onUpdateCarts"
        >
          保存する
        </router-link>
        <button
          class="btn primary ml_medium"
          :class="isSelectedItem && !errMinQty ? '' : 'disabled'"
          @click="onConfirm"
        >
          発注する
        </button>
      </div>
    </div>
    <div class="bottom_menu" :class="showModalConfirm ? 'open' : ''">
      <h2 class="bottom_menu_title">
        確認
      </h2>
      <div class="mb_medium">
        <div class="mb_small mb_small-left mb_small-md">
          <span v-if="isDuplicateScheduleDate" class="admin_color-alert">
            送信日時と納品希望日が同一です。このまま送信しますか？
          </span>
          <span v-else>チェックのついている商品を発注します。よろしいですか？</span>
          <span v-show="showFaxSendMaintenance" class="alert-text pre-formatted">
            ※重要
            10月1日(日) 20:00 ～ 10月2日（月）0:00に、FAX送信機能のメンテナンスを行います。
            上記時間帯はFAXの送信が行えません。
            ・FAXの送信結果は、[発注履歴を確認する]からご確認いただけます。
            ・FAX送信日時の指定機能を使いますと、発注書の送信予約ができますのでお試しください。<br>
            <a href="https://bespo.force.com/help/s/article/1134" target="_blank"> >>詳細へ</a>
          </span>
        </div>
      </div>
      <div class="btn_area two_items">
        <router-link :to="{}" v-if="!showFaxSendMaintenance" class="btn primary" @click.native="onSubmitOrder">決定</router-link>
        <router-link :to="{}" class="btn primary_border cancel ml_medium" @click.native="showModalConfirm = false">キャンセル</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="showModalConfirm ? 'open' : ''" />
    <div class="bottom_menu" :class="dialogAddItem ? 'open' : ''">
      <h2 class="bottom_menu_title">
        保存完了
      </h2>
      <div class="mb_medium">
        <p class="mb_small-left">発注予定リストに商品を保存しました。</p>
      </div>
      <div class="btn_area two_items">

        <router-link :to="{}" class="btn primary_border" @click.native="dialogAddItem = false">閉じる</router-link>
      </div>
    </div>
    <div class="bottom_menu_bg" :class="dialogAddItem ? 'open' : ''" />
  </main>
</template>

<script>
import ItemSelect from './component/ItemSelect.vue';
import { RestaurantsService } from '../../services/restaurants.service.js';
import utils from '../../common/util.js';
import Validator from '../../validator';
import moment from 'moment';
import { OrderStaffsService } from '../../services/order_staffs.service';
import { CartsService } from '../../services/carts.service';
import { OrdersService } from '../../services/orders.service.js';
import { mapGetters } from 'vuex';
import { SuppliersService } from '../../services/suppliers.service';
import { SupplierItemsService } from '../../services/supplier_items.service';
import { TaxsService } from '../../services/taxs.service';
import _ from 'lodash';

export default {
  components: {
    ItemSelect
  },

  data() {
    return {
      restaurant: {},
      orderStaffs: [],
      carts: [],
      errors: [],
      isSelectedItem: false,
      reorderId: null,
      errMinQty: false,
      total_amount: 0,
      tax_reduced: null,
      tax_standard: null,
      tax_amount: 0,
      showModalConfirm: false,
      isDuplicateScheduleDate: false,
      isChangeData: false,
      dialogAddItem: false
    };
  },
  computed: {
    ...mapGetters({
      settingImage: 'getSettingimage'
    }),
    showFaxSendMaintenance() {
      const hasFaxOrder = this.carts.some(cart => cart.order_type === 'fax');
      const inMaintenaceTime = moment().isBetween('2023-10-01 20:00', '2023-10-02 00:00');
      return hasFaxOrder && inMaintenaceTime;
    },
  },

  async mounted() {
    this.$store.dispatch('setHasOrdersFlag', false);
    this.reorderId = this.$route.query.reorder_id;
    this.getRestaurant();
    this.getOrderStaffs();
    await this.findTaxReducedAndStandard();
    if (this.$route.query.reorder_id) {
      this.getOrderToReorder(this.$route.query.reorder_id);
    } else {
      this.getCarts();
    }
  },

  watch: {
    carts(newVal, oldVal) {
      if (oldVal.length !== 0 && newVal.length !== 0 && !this.reorderId) {
        this.isChangeData = true;
        this.$store.dispatch('setHasOrdersFlag', true);
      }
    }
  },

  methods: {
    ...utils,

    async getRestaurant() {
      this.$loading(true);
      try {
        const { response } = await RestaurantsService.get();
        this.restaurant = response.data;
        this.phone = response.data.tel;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    formatPhone() {
      if (this.phone != null) {
        if (this.phone.length == 10) {
          return this.phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        } else if (this.phone.length == 11) {
          return this.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
      }
    },

    async getOrderStaffs() {
      this.$loading(true);
      try {
        const { response } = await OrderStaffsService.index();
        this.orderStaffs = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    async getCarts() {
      this.$loading(true);
      try {
        const { response } = await CartsService.index();
        this.carts = response.data;
        this.defaultDataCart();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    defaultDataCart() {
      this.carts.forEach(cart => {
        cart.delivery_date = moment().add(1, 'day').toDate();
        cart.total_amount = 0;
        cart.tax_amount = 0;
        cart.selected = true;
        this.selectAllItem(cart)

        if (cart.order_type === 'fax') {
          cart.sendNow = true
        }
      });
      this.carts.push()
    },

    changeRadioButton(index, e) {
      this.carts.map(function(order) {
        if(order.supplier_id == parseInt(index)){
          order.sendNow = (e.target.value === "true");
          if (e.target.value === "true") order.scheduledTime = null;
        }
        return order;
      });
      this.carts.push()
    },

    changeDateInput(index, value) {
      this.carts.push()
    },

    onchangeInputOrder() {

    },

    onConfirm() {
      this.validate();
      if (Object.keys(this.errors).length) return;

      this.showModalConfirm = true;
    },

    async onSubmitOrder() {
      this.$loading(true);
      try {
        const ordered_ids = [];
        for (const cart of this.carts) {
          const orderStaff = this.orderStaffs.filter(staff => staff.id === cart.orderStaffId);
          const params = {
            delivery_date: this.formatDate(cart.delivery_date),
            scheduled_time: cart.sendNow ? null : moment(cart.scheduledTime).format('YYYY/MM/DD HH:mm'),
            message: cart.remark,
            order_staff_id: cart.orderStaffId,
            order_staff_name: orderStaff.length > 0 ? orderStaff[0].name : null,
            supplier_id:  cart.supplier_id,
            supplier_name:  cart.supplier_name,
          };
          const myOrder = cart.cart_items.filter(cartItem => cartItem.selected).map(cart_item => {
            return {
              item_id: cart_item.item_id,
              item_name: cart_item.item_name,
              item_unit: cart_item.item_unit,
              item_product_no: cart_item.item_product_no,
              item_qty: cart_item.item_qty,
              unit_price: cart_item.item_price
            };
          });
          params.order_items = myOrder;
          const cartParams = {
            id: cart?.id,
          }
          if (myOrder.length === cart.cart_items.length) {
            cartParams._destroy = true;
          }
          const cartItemParams = cart.cart_items.filter(cartItem => cartItem.selected).map(cart_item => {
            return {
              id: cart_item.id,
              _destroy: true
            }
          })

          cartParams.cart_items_attributes = cartItemParams;
          params.carts = cartParams

          if (myOrder.length > 0) {
            const { response } = await OrdersService.create(params);
            ordered_ids.push(response.data.id);
          }
        }

        if (!this.$router.history.current.query.id) {
          await this.$store.dispatch('clearOrder');
        }
        await this.$store.dispatch('clearOrderedIds');
        await this.$store.dispatch('setOrderedIds', ordered_ids);
        await this.cartItem();
        this.$router.push({ path: '/order/complete' });
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        return;
      }
    },

    async onUpdateCarts() {
      let carts = [];
      this.carts.forEach(cart => {
        const _destroy = cart.cart_items.filter(cart_item => !cart_item._destroy)
        carts.push({
          id: cart.id,
          supplier_id: cart.supplier_id,
          remark: cart.remark,
          cart_items_attributes: cart.cart_items,
          _destroy: _destroy.length > 0 ? false : true
        })
      });
      const params = {carts: carts}
      this.$loading(true);
      try {
        await CartsService.create(params);
        await this.cartItem();
        this.dialogAddItem = true;
        this.isChangeData = false;
        this.$store.dispatch('setHasOrdersFlag', false);
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },

    selectAllItem(cart) {
      this.carts.forEach(element => {
        if (cart.id == element.id) {
          element.cart_items.filter(item => !item._destroy).forEach(cartItem => {
            cartItem.selected = cart.selected;
          })
          element.cart_items.push()
        }
      });
      this.checkErrQty();
      this.calculatePrice();
    },

    selectCartItem() {
      this.carts.forEach(cart => {
        const totalSelect = cart.cart_items.filter(cartItem => cartItem.selected).length;
        cart.selected = totalSelect == cart.cart_items.filter(item => !item._destroy).length;

        cart.cart_items.push()
      })
      this.carts.push();
      this.checkErrQty();
      this.calculatePrice();
    },

    validate() {
      this.errors = {};
      const validator = new Validator();
      for (let i = 0; i < this.carts.length; i++) {
        const cart = this.carts[i];
        if (cart.cart_items.filter(cartItem => cartItem.selected).length > 0) {
          validator.checkMaxLength(`${i}_comment`, cart.remark, 255, '備考欄');
          if (cart.order_type === 'fax' && !cart.sendNow) {
            validator.checkRequireSelect(`${i}_scheduledTime`, cart.scheduledTime, '送信日時');
            validator.checkTimeBefore(`${i}_scheduledTime`, cart.scheduledTime, moment(), '送信日時', '現在の日時');
            cart.scheduledTime && validator.checkDateBeforeTime(`${i}_deliveryDate`, cart.delivery_date, cart.scheduledTime, '納品希望日', '発注時間(日時指定) ');
          }
        }
        this.errors = { ...this.errors, ...validator.errors };
      }
    },
    async getOrderToReorder(orderId) {
      try {
        const { response: {data: order} } = await OrdersService.get(orderId);
        const supplier = (await this.getSupplier()).find(p => p.id === parseInt(order.supplier_id));
        const itemIds = order.order_items.map(item => item.item_id);
        const { response: {data: supplierItems} } = await SupplierItemsService.search({ supplier_id: order.supplier_id, item_ids: itemIds });
        const orderItems = [];

        order.order_items.forEach(item => {
          const supplierItem = supplierItems.find(p => { return p.item_id === item.item_id; } );
          if (supplierItem) {
            orderItems.push({
              item_id: item.item_id,
              item_name: supplierItem.item_name,
              item_unit: item.unit,
              item_product_no: item.product_no,
              item_qty: item.item_qty,
              item_price: supplierItem.price,
              item_img: item.item_img,
              selected: true
            });
          }
        });

        const amount = await this.getAmount(orderItems);

        this.carts.push({
          supplier_email: supplier?.email,
          supplier_fax: supplier?.fax,
          orderStaffId: order.order_staff_id,
          supplier_id: supplier?.id,
          supplier_name: supplier?.name,
          order_type: supplier?.order_type,
          remark: order.message,
          delivery_date: moment(order.delivery_date).toDate(),
          scheduled_time: order.scheduled_time,
          sendNow: supplier?.order_type == 'fax' ? true : false,
          cart_items: orderItems,
          selected: true,
          total_amount: amount?.total_amount,
          tax_amount: amount?.tax_amount
        })


        this.total_amount = this.carts.reduce((n, {total_amount}) => n + total_amount, 0);
        this.tax_amount = this.carts.reduce((n, {tax_amount}) => n + tax_amount, 0);

        this.isSelectedItem = true
      } catch (error) {
        console.log(error);
        this.$loading(false);
      }
    },
    async getSupplier() {
      const { response } = await SuppliersService.index();
      return response.data;
    },
    async getAmount(orderItems) {
      const params = orderItems.map(item => {
        return {
          item_id: item.item_id,
          item_qty: parseFloat(item.item_qty),
          unit_price: item.item_price,
          error: null
        };
      });
      const { response } = await OrdersService.confirm({ order_items: params });
      return response.data;
    },

    async cartItem() {
      const { response } = await CartsService.index();
      let count = 0;

      for (const cart_item of response.data) {
        count += cart_item.cart_items.length;
      }

      this.$store.dispatch('setCountItemSelected', count);
    },

    checkErrQty() {
      let checkMin = false;
      let total = 0
      this.carts.forEach(cart => {
        cart.cart_items.filter(cartItem => cartItem.selected).forEach(cartItem => {
          if (cartItem.item_qty <= 0) {
            checkMin = true;
          }
        })

        const totalSelect = cart.cart_items.filter(cartItem => cartItem.selected).length;
        total += totalSelect;
      })

      this.isSelectedItem = total > 0;
      this.errMinQty = checkMin;
    },

    calculatePrice() {
      this.total_amount = 0;
      this.tax_amount = 0;
      this.carts.forEach(cart => {
        let total_amount = 0;
        let amount_reduced = 0;
        let amount_standard = 0;
        let tax_amount = 0;
        cart.cart_items.filter(cartItem => cartItem.selected).forEach(cartItem => {
          if (cartItem.tax_type === 'reduced' && this.tax_reduced) {
            amount_reduced += cartItem.item_price * cartItem.item_qty;
          }
          else {
            amount_standard += cartItem.item_price * cartItem.item_qty;
          }
          total_amount += cartItem.item_price * cartItem.item_qty;
        })
        if (this.tax_reduced) {
          tax_amount = Math.round(amount_standard * parseFloat(this.tax_standard.tax_percent / 100) /  parseFloat(this.tax_standard.tax_percent / 100 + 1)) +
                        Math.round(amount_reduced * parseFloat(this.tax_standard.tax_percent / 100) /  parseFloat(this.tax_standard.tax_percent / 100 + 1))
        } else {
          tax_amount = Math.round(amount_standard * parseFloat(this.tax_standard.tax_percent / 100) /  parseFloat(this.tax_standard.tax_percent / 100 + 1))
        }

        cart.total_amount = total_amount;
        cart.tax_amount = total_amount - tax_amount;

        this.total_amount += cart.total_amount;
        this.tax_amount += cart.tax_amount;
      })
    },

    changeItemQty () {
      this.isChangeData = true;
      this.$store.dispatch('setHasOrdersFlag', true);
    },

    async findTaxReducedAndStandard() {
      this.$emit('loading', true);
      try {
        const { response } = await TaxsService.find_tax_reduced_and_standard();
        const tax = response.data;
        this.tax_reduced = tax.tax_reduced;
        this.tax_standard = tax.tax_standard;
      } catch (error) {
        console.log(error);
      }
    },

    cal_tax(cartItem) {
      let total_amount = 0;
      let amount_reduced = 0;
      let amount_standard = 0;
      let tax_amount = 0;
      if (cartItem.item_category == 'reduced') {
        amount_reduced += Math.floor((parseInt(cartItem.price) * parseFloat(cartItem.item_qty)))
      } else {
        amount_standard += Math.floor((parseInt(cartItem.price) * parseFloat(cartItem.item_qty)))
      }

      if (tax_reduced != null) {
        tax_amount = Math.round(amount_standard * parseFloat(tax_standard.tax_percent / 100) /  parseFloat(tax_standard.tax_percent / 100 + 1)) +
                      Math.round(amount_reduced * parseFloat(tax_standard.tax_percent / 100) /  parseFloat(tax_standard.tax_percent / 100 + 1))
      } else {
        tax_amount = Math.round(amount_standard * parseFloat(tax_standard.tax_percent / 100) /  parseFloat(tax_standard.tax_percent / 100 + 1))
      }

      return { total_amount: total_amount, tax_amount: total_amount - tax_amount }
    },
    async back() {
      if (!this.isChangeData || this.reorderId) return this.$router.go(-1);
      const res = await this.$confirmRedirect();
      if (!res) return;
      this.$router.go(-1);
      return this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
 .mb_small {
  margin-bottom: 30px;
  text-align: right;
 }
 .form_main {
   display: grid;
   grid-template-columns: auto 1fr;
   grid-column-gap: 10px;
 }
 .btn__disabled {
   opacity: 0.5;
 }
 .text-noti {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
 }
.btn__confirm button {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, YuGothic, "Yu Gothic", "Helvetica Neue", sans-serif;
}
.form_option {
  word-break: break-word;
  white-space: pre-line;
}
.sr_table-r {
  text-align: right;
  text-overflow: unset !important;
}
.sr_table-name {
  width: 55% !important;
}
.sr_table-price {
  width: 25% !important;
}
.sr_table-qty {
  width: 20% !important;
}
.company_name-lager {
  font-size: 16px;
}
.form_input-supplier {
  word-break: break-all;
}
.mb_small-left {
  text-align: left;
}
.mb_small-md {
  font-weight: unset;
}
.radio_position .error-message {
  width: auto;
}

.text-price {
  display: flex;
  margin: auto;
  margin-top: 50px;
  font-weight: bold;
  font-size: 24px;
}
.summary_small_total {
  font-size: 18px;
}

@media screen and (max-width: 980px) {
  .radio_position {
    flex-wrap: wrap;
  }
  .input_wrapper {
    width: 85% !important;
  }
}
@media screen and (max-width: 480px) {
  .text-price {
    font-size: 16px;
    margin-top: 16px;
  }
  .summary_small_total {
    font-size: 12px
  }
  .input_wrapper {
    width: 85% !important;
  }
  .select_wrapper {
    width: 85% !important;
  }
  .radio_position {
    flex-wrap: wrap;
  }
}
</style>
<style scoped>
.form_main_tooltip {
  grid-column-gap: 20px;
}
.display_content {
  display: inline-flex;
}
.icon {
  width: 19px;
  margin-top: 1px;
  height: 19px;
}
.error-message {
    text-align: left;
    font-size: 14px;
    padding-bottom: 10px;
  }
.close_tooltips {
  visibility: hidden;
  -webkit-backface-visibility:hidden;
  -moz-backface-visibility:hidden;
  -ms-backface-visibility:hidden;
  -o-backface-visibility:hidden;
  backface-visibility:hidden;
}
@media screen and (max-width: 480px){
  .main_area {
    padding: 0px;
    z-index: 98;
  }
  .medium {
    width: 100%;
  }
  select {
    font-size: 16px;
  }
  .smp_font_size {
    font-size: 12px;
  }
  .icon {
    width: 16px;
    height: 16px;
  }
  .margin_icon_info {
    margin-bottom: -2px;
  }
}
.btn_checkbox {
  margin-bottom: 20px;
}
.checkmark_checkbox {
  top: 0px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.btn_checkbox input:checked ~ .checkmark_checkbox {
  background-color: #966544;
}
.btn_checkbox .checkmark_checkbox:after {
  left: 7px;
  top: 3px;
  width: 6px;
  border: 1px solid white;
  border-width: 0 3px 3px 0;
}
.cart_title {
  background-color: #E5D7CD;
  display: flex;
  align-items: center;
  padding: 12px 16px;
}
.btn.primary_border.disabled {
  border: none;
}
.disabled_checkbox {
  background: #ACACAC !important;
  border-color: #ACACAC !important;
  pointer-events: none;
}
.summary_small {
  font-size: 12px;
}
</style>
