<template>
  <main class="owner_main_area main_area_pc main_password_setting">
    <Continue step="4" />
    <Success />
  </main>
</template>

<script>
import Success from './components/Success.vue';
import Continue from '../../../components/CompleteStep.vue';

export default {
  components: {
    Success,
    Continue
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .main_area_pc {
    // min-height: calc(100vh - 50px) !important;
  }
}
</style>
