<template>
  <div v-if="checkdevice">
    <div class="wrapper_owner">
      <div class="radio_position">
        <label class="radio_btn_owner checkbox_owner_margin_60">
          <hr :class="checkstep(1) ? 'owner_line_checkbox' : 'owner_line_no_checkbox'">
          <input
            v-model="isChange"
            type="checkbox"
            :class="checkstep(1) ? 'checkbox_owner' : ''"
            :checked="true"
            value="0"
          >
          <span :class="checkstepdone(1)" />
        </label>
      </div>
      <div class="radio_position">
        <label class="radio_btn_owner checkbox_owner_margin_60">
          <hr :class="checkstep(2) ? 'owner_line_checkbox' : 'owner_line_no_checkbox'">
          <input
            v-model="isChange"
            type="checkbox"
            :class="checkstep(2) ? 'checkbox_owner' : ''"
            :checked="true"
            value="0"
          >
          <span :class="checkstepdone(2)" />
        </label>
      </div>
      <div class="radio_position">
        <label class="radio_btn_owner checkbox_owner_margin_60">
          <hr :class="checkstep(3) ? 'owner_line_checkbox' : 'owner_line_no_checkbox'">
          <input
            v-model="isChange"
            type="checkbox"
            :class="checkstep(3) ? 'checkbox_owner' : ''"
            :checked="true"
            value="0"
          >
          <span :class="checkstepdone(3)" />
        </label>
      </div>
      <div class="radio_position">
        <label class="radio_btn_owner checkbox_owner_margin_60">
          <input
            v-model="isChange"
            type="checkbox"
            :class="checkstep(4) ? 'checkbox_owner' : ''"
            :checked="true"
            value="0"
          >
          <span :class="checkstepdone(4)" />
        </label>
      </div>

      <div :class="step == 1 ? '' : 'color_owner_text_no_check'">
        <label class="owner_title_12">
          法人情報<br>
          請求書受け取り先情報<br>
          登録
        </label>
      </div>
      <div :class="step == 2 ? '' : 'color_owner_text_no_check'">
        <label class="owner_title_12">
          法人情報<br>
          請求書受け取り先情報<br>
          完了
        </label>
      </div>
      <div :class="step == 3 ? '' : 'color_owner_text_no_check'">
        <label class="owner_title_12">
          法人情報<br>
          パスワード設定<br>
          登録<br>
        </label>
      </div>
      <div :class="step == 4 ? '' : 'color_owner_text_no_check'">
        <label class="owner_title_12">
          法人情報<br>
          パスワード設定<br>
          完了
        </label>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="input_owner">
      <div class="radio_position owner_margin_button_step">
        <label class="radio_btn_owner_smp header_owner_mobile">
          <div v-if="step == 1" class="owner_title">
            <div class="title_text">法人情報・請求書受取先情報登録</div>
            <div class="owner_text_note title_note">
              次のSTEP：法人情報・請求書受取先情報完了
            </div>
          </div>
          <div v-if="step == 2" class="owner_title">
            <div>法人情報・請求書受取先情報完了</div>
            <div class="owner_text_note title_note owner_font_weight">
              次のSTEP：法人情報・パスワード設定登録
            </div>
          </div>
          <div v-if="step == 3" class="owner_title">
            <div class="title_text">法人情報・パスワード設定登録</div>
            <div class="owner_text_note title_note">
              次のSTEP：法人情報・パスワード設定完了
            </div>
          </div>
          <div v-if="step == 4" class="owner_title">
            <div class="title_text">法人情報・パスワード設定完了</div>
            <div class="owner_text_note title_note invisible">
              次のSTEP：法人情報・請求書受取先情報確認
            </div>
          </div>
          <span class="checkmark_owner_smp">
            <div class="checkmark_owner_step">
              <p class="owner_text_checkbox">STEP</p>
              <p>
                <span>{{ step }}</span>/4
              </p>
            </div>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: null,
  },
  data() {
    return {
      isChange: null,
      checked: true,
      checkdevice: true,
    };
  },
  created() {
    window.addEventListener('resize', this.isMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobile);
  },
  mounted() {
    this.isMobile();
  },
  methods: {
    isMobile() {
      if (screen.width > 480) {
        this.checkdevice = true;
      } else {
        this.checkdevice = false;
      }
    },
    checkstep(steps) {
      return steps < this.step || this.step == 4 ? true : false;
    },
    checkstepdone(steps) {
      return steps < this.step || this.step == 4
        ? 'checkmark_continue_done'
        : steps == this.step
          ? 'checkmark_continue'
          : 'checkmark_continue_no_check';
    },
  },
};
</script>
<style lang="scss" scoped>
.owner_text_checkbox {
  font-size: 9px;
  margin-bottom: 2px;
}
</style>