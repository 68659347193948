<template>
  <main class="main_item">
    <ul>
      <li v-for="(supplier_item, index) in supplierItems" :key="index" class="item_list"
          :class="parseFloat(supplier_item.qty) > 0 ? 'item_active' : ''"
      >
        <div class="item_list_wrapper">
          <div class="item_list_content" :class="settingImage == true ? '' : 'item_list_content-no_img'">
            <div class="item_list_favorite" @click="changeFavorite(index, supplier_item.supplier_item_id)">
              <div class="item_favorite" :class="supplier_item.is_favorite == true ? 'active' : 'not_active'" />
            </div>
            <div v-show="settingImage == true" v-lazy-container="{ selector: 'img' }" class="item_list_image ml_medium">
              <img :data-src="supplier_item.img">
            </div>
            <div style="width: -webkit-fill-available" class="item_list-title">
              <p v-if="activeName === true" class="item_list-supplier" style="font-weight: 600">{{ supplier_item.supplier_name }}</p>
              <div class="item_name item_list-name">{{ supplier_item.name }}</div>
              <p class="item_product_no">
                {{ supplier_item.product_no ? `品番：${supplier_item.product_no}` : '&nbsp;' }}
              </p>
              <p class="item_price">
                単価（税込）{{ showPrice(supplier_item.price) }} 円
              </p>
            </div>
            <div class="sp_align">
              <div class="item_list_content_input item_list_option mb_small">
                <div class="ml_small order_unit">数量（{{ supplier_item.unit }}）</div>
                <div class="input_wrapper controller large ml_small input_wrapper-sm">
                  <button class="minus" :disabled="parseFloat(supplier_item.qty) === 0 || supplier_item.qty.length <= 0 ? true : false" @click="changeQty(supplier_item, '-')" />
                  <input
                    ref="qty"
                    :value="supplier_item.qty"
                    placeholder="0"
                    type="number"
                    maxlength="10"
                    @paste.prevent
                    @wheel="$event.target.blur()"
                    @keypress="changeQuantity($event, supplier_item)"
                    @keyup="changeQuantity($event, supplier_item)"
                    @click="clickQuantity($event, supplier_item)"
                    @blur="blurChangeQty($event, supplier_item, index)"
                    @input="replaceInput($event, supplier_item, index)"
                  >
                  <button class="plus" @click="changeQty(supplier_item, '+')" />
                </div>
              </div>
              <div v-if="supplier_item.price.length === 0 && supplier_item.qty > 0" class="alert text_center mb_small is_valid">単価を入力してください。</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-show="supplierItems.length <= 0" class="text-center">表示するデータはありません。</div>
  </main>
</template>
<script>
import utils from '../../../common/util.js';
export default {
  props: {
    supplierItems: {
      type: Array,
      required: true
    },
    activeName: {
      type: Boolean,
      required: false
    },
    changeOrder: {
      type: Function,
      require: true
    },
    changeErrQty: {
      type: Function,
      require: true
    },
    settingImage: {
      type: Boolean,
      required: false
    },
    handleFavorite: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      old_val: '',
      qty_val: '',
      price_val: '',
      total_change: 0
    };
  },
  methods: {
    ...utils,
    changeQty(supplier_item, type) {
      if (type === '-') {
        if (supplier_item.qty > 1 ) {
          if (supplier_item.qty == parseInt(supplier_item.qty)){
            supplier_item.qty = parseInt(supplier_item.qty) - 1;
          } else {
            supplier_item.qty = parseFloat(parseFloat(supplier_item.qty) - 1.0).toFixed(1);
          }
        }
        else {
          supplier_item.qty = '';
        }
      } else  {
        if (!supplier_item.qty) {
          supplier_item.qty = 1;
        } else if ((supplier_item.qty) < 999999999) {
          if (supplier_item.qty == parseInt(supplier_item.qty)){
            supplier_item.qty = parseInt(supplier_item.qty) + 1;
          } else {
            supplier_item.qty = parseFloat(parseFloat(supplier_item.qty) + 1.0).toFixed(1);
          }
        }
      }
      return this.changeOrder(supplier_item);
    },
    changeQuantity(event, supplier_item) {
      if(event.target.value.length == 9 ) {
        event.preventDefault();
      }
      if (!/^[+-]?\d+(\.\d{0,1})?$/.test(event.target.value) == false || /^\d{0,6}(?:\.\d{0,1})?$/.test(event.target.value)) {
        this.qty_val = event.target.value;
      } else {
        event.target.value = this.qty_val;
        return event.preventDefault();
      }
      if (event.target.value.indexOf('.') != -1 && event.key == '.') {
        event.preventDefault();
      }
      if (!/\d/.test(event.key) && event.key !== '.') {
        event.preventDefault();
      }
      supplier_item.qty = event.target.value;
      this.changeOrder(supplier_item);
    },
    isFloat(supplier_item) {
      if (supplier_item.qty && !/^\d+(\.\d+)?$/.test(supplier_item.qty)) {
        return true;
      } else {
        return false;
      }
    },

    blurChangeQty(evt, supplier_item, index) {
      if (evt.target.value == '' || parseFloat(evt.target.value) <= 0) {
        supplier_item.qty = '';
        evt.target.value == '';
        this.$refs['qty'][index].value = '';
        this.changeOrder(supplier_item);
      } else {
        supplier_item.qty = evt.target.value;
        this.changeOrder({ ...supplier_item, qty: evt.target.value });
      }
    },
    clickQuantity(evt) {
      this.qty_val = evt.target.value;
    },
    replaceInput(evt, supplier_item) {
      if(evt.target.value < 0) {
        evt.target.value = 0;
      }
      if (evt.target.value != '' || parseFloat(evt.target.value) > 0) {
        supplier_item.qty = evt.target.value;
        this.changeOrder({ ...supplier_item, qty: evt.target.value });
      }
    },
    changeFavorite(index, supplier_item_id) {
      this.handleFavorite(index, supplier_item_id);
    }
  },
};
</script>
<style lang="scss" scoped>
  .is_valid {
    margin-top: 8px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
    white-space: nowrap;
  }
  .sp_align {
    display: grid;
    justify-items: flex-end;
  }
  .item_list {
    padding: 18px;
  }
  .item_list_content_input p {
    white-space: nowrap;
  }
  .item_active {
    background-color: #fff4eb;
  }
  .item_name {
    width: unset !important;
  }
  .item_product_no {
    font-size: 16px;
    padding-bottom: 4px;
  }
  .order_unit {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5em;
  }
  .item_list-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .item_list-supplier {
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 16px;
    word-break: break-all;
  }
  .item_list_option {
    display: grid !important;
    grid-template-columns: unset !important;
    grid-template-rows: auto auto;
    grid-row-gap: 5px;
  }
  .item_list_label {
    justify-self: end;
  }
  .item_list_image img {
    object-fit: scale-down !important;
  }
  .main_item {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 10px;
      .item_list {
        padding-left: 0;
      }
      li {
        border: 1px solid #BBBBBB;
        .item_list_content {
          padding-left: 0;
          display: grid !important;
          grid-template-columns: auto auto 1fr auto;
          align-items: center;
          &-no_img {
            grid-template-columns: auto 1fr max-content;
          }
        }
        .item_name {
          margin: 0 !important;
          line-height: 1.2em;
          height: 2.4em;
        }
      }
    }
    .item_list_image {
      width: 60px;
      height: 60px;
      margin: 0 10px 0 0 !important;
    }
  }
  .item_name {
    margin-bottom: 16px !important;
  }
  @media screen and (max-width: 480px) {
    .item_list {
      padding: 10px;
    }
    .item_list_wrapper {
      position: relative;
      .item_list-title {
        // position: absolute;
        top: 0;
        left: 120px;
        width: unset !important;
        padding-top: 10px;
        p {
          padding-right: 0;
          line-height: 1.3;
        }
        div {
          padding-right: 0;
        }
      }
    }
    .item_list_content {
      width: 100%;
      padding-left: 0 !important;
      padding-top: 15px;
      .input_wrapper-sm {
        width: 160px !important;
        button {
          width: 35px !important;
          height: 40px !important;
        }
        input {
          height: 40px;
          padding: 0 41px !important;
          font-size: 16px;
        }
      }
      .item_list_option {
        margin-bottom: 0;
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        input {
          padding: 0px 15px;
          font-size: 16px;
          width: 160px;
        }
      }
    }
    .item_list_label {
      justify-self: start;
    }
    .main_item {
      ul {
        li {
          .item_name {
            font-size: 12px;
            margin: 0 !important;
            line-height: 1.2em;
            height: 2.4em;
          }
          .item_product_no {
            font-size: 10px;
            padding-bottom: 0;
          }
          .item_price {
            font-size: 12px;
          }
          .input_wrapper-sm {
            width: 120px !important;
            input {
              font-size: 16px !important;
              width: 120px;
              padding: 0 35px;
            }
          }
          .item_list-supplier {
            font-size: 12px;
          }
          .item_list-title {
            padding-top: 0;
          }
          .item_list_content {
            padding-top: 0;
          }
        }
      }
      .is_valid {
        width: 130px;
        white-space: normal;
        word-break: break-all;
        font-size: 12px;
      }
      .item_list_image {
        width: 40px;
        height: 40px;
      }
    }
  }
  @media screen and (max-width: 1025px) {
    .main_item {
      ul {
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 0px;
        li {
          border: unset;
          border-bottom: 1px solid #BBBBBB;
          .item_list_content {
            padding-left: 0;
          }
        }
        li:first-child {
          border-top: 1px solid #BBBBBB;
        }
      }
    }
  }
</style>