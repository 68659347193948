import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class RestaurantAnswersService extends BaseService {
  static get resourceEndpoint() {
    return 'owners/restaurant_administrators/question_answers';
  }

  static async create(id, data = {}) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}/${id}`,
        { question_answers: data }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}