<template>
  <main class="owner_container">
    <delete-form
      v-if="screenName === 'delete'"
      :restaurant-id="$router.history.current.params.id"
      @change_screen="onChangeScreenName"
    />
    <delete-complete v-if="screenName === 'complete'" />
  </main>
</template>

<script>
import DeleteForm from './components/Delete/Form.vue';
import DeleteComplete from './components/Delete/Complete.vue';

const SCREEN_NAMES = Object.freeze({
  delete: '店舗解約確認',
  complete: '店舗解約完了'
});
export default {
  components: {
    DeleteForm,
    DeleteComplete,
  },

  data() {
    return {
      screenName: 'delete',
    };
  },
  watch: {
    screenName() {
      document.title = SCREEN_NAMES[this.screenName];
    },
  },
  methods: {
    onChangeScreenName(value) {
      this.screenName = value;
    }
  },
};
</script>
