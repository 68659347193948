import Vue from 'vue';
import VueLazyload from 'vue-lazyload';

import store from '../stores';
import router from '../routes';
import VueLoading from '../plugins/loading';
import Confirm from '../plugins/confirm';
import InputDialog from '../plugins/input_dialog';
import PopupMessage from '../plugins/popup_message';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFlatPickr from 'vue-flatpickr-component';
import App from '../App.vue';
require('sweetalert2/dist/sweetalert2.min.css');
import ConfirmRedirect from '../plugins/confirm_redirect';
import ConfirmSubmit from '../plugins/confirm_submit'
import Swal from 'sweetalert2';
import TableTdMenu from '../plugins/table_td_menu';
import ModalHelper from '../plugins/modal';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
Vue.component('DateTimePicker', DatePicker);

Vue.prototype.$swal = Swal;

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    render: (h) => h(App),
    router,
    store,
  }).$mount();

  document.body.appendChild(app.$el);
});

Vue.use(VueLoading);
Vue.use(Confirm);
Vue.use(InputDialog);
Vue.use(PopupMessage);
Vue.use(VueSweetalert2);
Vue.use(VueFlatPickr);
Vue.use(ConfirmRedirect);
Vue.use(ConfirmSubmit);
Vue.use(TableTdMenu);
Vue.use(ModalHelper);

const loadimage = require('../../../app/assets/images/blank_image.png');
const errorimage = require('../../../app/assets/images/no_image.png');

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
});

Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
  }
});
