import { BaseService } from './base.service';
import {ApiError, ResponseWrapper} from '../http';

export class CancelAnswerService extends BaseService {
  static get resourceEndpoint() {
    return 'cancel-answers';
  }

  static async export(params) {
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/export`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}