<template>
  <main class="container bottomUp">
    <div class="main_area smp_content_main">
      <h1 class="title mb_medium">
        登録代行のご依頼
      </h1>
      <p class="attention">アップロードの際は、必ずページ下部の<a href="#point">「撮影のポイント・注意点」</a>をご確認ください。</p>
      <p>登録代行は、発注書や納品書の画像・リストをアップロードいただくことで、発注先・商品の登録をSMART REQUESTが代行する機能です。</p>
      <p>既にSMART REQUESTにご登録済みの発注先に商品を追加したい場合は、以下から「登録済みの発注先」を選択し、「発注先名」の選択肢から対象の発注先を選択してください。</p>
      <p class="mb_large">今回新しく登録する発注先の場合は「新規の発注先」を選択してください。</p>
      <div class="group_upload mb_medium">
        <div>
          <label for="invoice_send_type" class="owner_form_label">発注先</label>
        </div>
        <div>
          <div class="group_rb">
            <div class="radio_position">
              <label class="radio_btn_setting upload mr_medium">
                <input
                  v-model="oldSupplier"
                  type="radio"
                  class="rb_border"
                  :value="false"
                >
                <span class="rb_checkmark" />
                <label class="rb_label">新規の発注先</label>
              </label>
              <label class="radio_btn_setting upload">
                <input
                  v-model="oldSupplier"
                  type="radio"
                  class="rb_border"
                  :value="true"
                  :checked="true"
                >
                <span class="rb_checkmark" />
                <label class="rb_label mg-b-0">登録済みの発注先</label>
                <p v-if="oldSupplier" class="note-rb">既に登録がある発注先に商品を追加します</p>
              </label>
            </div>
            <p v-if="oldSupplier" class="note-rb_mobile">既に登録がある発注先に商品を追加します</p>
          </div>
          <div v-click-outside="blurSuggest" class="input_suggest mt_medium">
            <input v-if="oldSupplier"
                   v-model="suggestKeyword"
                   type="text"
                   name="supplier_name"
                   placeholder="発注先名"
                   @input="onChange"
                   @click="suggestShow = true; fetchSuggestList();"
            >
            <ul v-show="suggestShow" class="input_suggest_list">
              <li
                v-for="(supplier, index) in suggestSuppliers"
                :key="index"
                class="input_suggest_item"
                :class="chosenSupplier && supplier.id == chosenSupplier.id ? 'active' : ''"
                @click="chooseSuggest(supplier)"
              >
                {{ supplier.name }}
              </li>
            </ul>
            <template v-if="!oldSupplier">
              <div class="form_row vertical mb_medium mt_medium">
                <label for="supplier_official_name" class="form_label">
                  発注先企業名<span class="require">必須</span>
                </label>
                <div class="input_wrapper">
                  <input id="supplier_official_name" v-model="supplier_form.supplier_official_name" type="text"
                         placeholder="発注先企業名"
                  >
                  <span v-for="error in errors['supplier_official_name']" :key="error" class="error-message">
                    {{ error }}
                  </span>
                  <p class="small_text mt_small">発注書に表示される項目です。正式名称でご記入ください。</p>
                </div>
              </div>
              <!-- order_type -->
              <div class="form_row vertical mb_medium">
                <div class="form_row vertical">
                  <div class="group_rb">
                    <label for="invoice_send_type" class="owner_form_label">発注方法</label>
                    <div class="radio_position">
                      <label class="radio_btn_setting">
                        <input
                          v-model="supplier_form.order_type"
                          type="radio"
                          value="email"
                          class="rb_border"
                        >
                        <span class="rb_checkmark" />
                        <label class="rb_label">メール</label>
                      </label>
                      <label class="radio_btn_setting">
                        <input
                          v-model="supplier_form.order_type"
                          type="radio"
                          value="fax"
                          class="rb_border"
                        >
                        <span class="rb_checkmark" />
                        <label class="rb_label">FAX</label>
                      </label>
                      <label class="radio_btn_setting">
                        <input
                          v-model="supplier_form.order_type"
                          type="radio"
                          value="line"
                          class="rb_border"
                        >
                        <span class="rb_checkmark" />
                        <label class="rb_label">LINE</label>
                      </label>
                    </div>
                  </div>
                </div>
                <div v-if="supplier_form.order_type === 'fax'" style="width: 100%" class="error-message">
                  <span>
                    発注書送信方法がFAXの場合、FAX番号 を入力 してください。
                  </span>
                </div>
              </div>
              <div v-if="supplier_form.order_type == 'fax'" class="form_row vertical mb_medium">
                <label for="order_template" class="form_label form-20">発注書テンプレート</label>
                <div class="form_input">
                  <div class="select_wrapper arrow_down medium">
                    <select id="order_template" v-model="supplier_form.mediaSheetClass">
                      <option value="MediaSheet:Smartrequest">SmartRequestデフォルト</option>
                      <option value="MediaSheet:Ryque">RYQUE（リクエ）フォーマット</option>
                    </select>
                  </div>
                  <p class="small_text mt_small">発注書テンプレートをRYQUEに変更する場合、既存商品の商品番号も登録必要です。</p>
                </div>
              </div>
              <div v-if="supplier_form.order_type == 'fax' && supplier_form.mediaSheetClass == 'MediaSheet:Ryque'">
                <div class="form_row vertical mb_medium">
                  <label for="order_no" class="form_label">
                    注文用紙番号<span class="require">必須</span></label>
                  <div class="input_wrapper medium">
                    <input id="order_no" v-model="supplier_form.order_no" type="tel"
                            placeholder="数字3桁"
                    >
                    <span v-for="error in errors['order_no']" :key="error" class="error-message">
                      {{ error }}
                    </span>
                  </div>
                </div>
                <div class="form_row vertical mb_medium">
                  <label for="customer_no" class="form_label">
                    お客様NO<span class="require">必須</span></label>
                  <div class="input_wrapper medium">
                    <input id="customer_no" v-model="supplier_form.customer_no" type="tel"
                            placeholder="数字6桁"
                    >
                    <span v-for="error in errors['customer_no']" :key="error" class="error-message">
                      {{ error }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- email -->
              <div class="form_row vertical mb_medium">
                <label for="mail" class="form_label">
                  メールアドレス<span v-if="supplier_form.order_type === 'email'" class="require">必須</span>
                </label>
                <SupplierEmailsInput v-on:getInputs="getEmailInputs"></SupplierEmailsInput>
                <span v-for="error in errors['email']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
              <!--fax-->
              <div class="form_row vertical mb_medium">
                <label for="fax" class="form_label">FAX番号<span v-if="supplier_form.order_type === 'fax'"
                                                               class="require"
                >必須</span></label>
                <div class="input_wrapper medium">
                  <input id="fax" v-model="supplier_form.fax" type="tel"
                         placeholder="FAX番号"
                  >
                  <span v-for="error in errors['fax']" :key="error" class="error-message">
                    {{ error }}
                  </span>
                </div>
              </div>
              <!--note-->
              <div class="form_row vertical mb_medium">
                <label for="note" class="form_label">登録代行へのメッセージ</label>
                <div class="input_wrapper">
                  <textarea id="note" v-model="supplier_form.note"
                            cols="30" rows="5" placeholder="お申し付け事項があればご記入ください。"
                  />
                  <span v-for="error in errors['note']" :key="error" class="error-message">
                    {{ error }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div @drop.prevent="addFile" @dragover.prevent>
        <div class="file_upload_image upload_file no_image" @click="$refs.file.click()">
          <div class="file_upload_image_main">
            <div v-if="file.length === 0">
              <div class="icon add_file" />
              <div class="file_upload_image_text">このエリアをクリックして</div>
              <div class="file_upload_image_text">画像やエクセルファイルをアップロードできます。</div>
            </div>
            <div v-else>
              <div class="file_upload_image_label">アップロードファイル</div>
              <div class="file_upload_image_text_large">
                {{ fileName }}
                <span class="file_upload_image_label">({{ fileSize }})</span>
              </div>
              <div class="file_upload_image_link">ファイルの選択をやり直す</div>
            </div>
          </div>
        </div>
        <div>
          <input id="file_uploader" ref="file" type="file"
                 class="file_uploader"
                 hidden @change="previewFiles"
          >
        </div>
      </div>
      <span v-show="imageError" class="error-message">
        アップロードできるファイルの形式はpng、jpg、jpeg、csv、xls、pdf、tiffのみとなります。ファイルの形式をご確認ください。
      </span>
      <span v-show="imageErrorSize" class="error-message">
        ファイルは10MB以下でアップロードしてください
      </span>
      <p>※対応しているファイルの形式は png、jpg、jpeg、csv、xls、pdf、tiffです。</p>
      <p>※アップロード可能なサイズは10MBまで、アップロード可能なファイルの数は1つまでです。</p>
      <!-- note -->
      <div class="upload_file_note mt_small">
        <div id="point" class="upload_file_title">
          <div class="bold upload_file_head">撮影のポイント・注意点</div>
          <div>
            <div>「発注先業者名、発注先業者のFAX番号もしくはメールアドレス、商品名、店舗名」の4点が写るように撮影してください。</div>
            <div>
              アップロードいただいた商品のリストは機械で読み取るため、できるだけ綺麗に写真を撮影し、アップロードしてください。以下に例を記載しておりますので、ご確認お願いいたします。
            </div>
            <div> 読み取れない場合、再度アップロードをお願いする場合がございますのでご了承ください。</div>
          </div>
        </div>
      </div>
      <!-- instruct -->
      <div class="upload_file_instruct">
        <div class="upload_file_card">
          <p class="bold">良い例 </p>
          <img src="../../../assets/images/upload_6.png" alt="img">
        </div>
      </div>
      <div class="upload_file_instruct">
        <div class="upload_file_card">
          <p class="bold mb_medium">悪い例 </p>
          <p class="bold">撮影の角度が斜めになっている </p>
          <p>読み取りの精度が悪くなり、誤変換が起きる可能性があります。</p>
          <img src="../../../assets/images/upload_1.png" alt="img">
        </div>
        <div class="upload_file_card">
          <p class="bold">卸会社の情報が切れてしまっている</p>
          <p>卸会社の情報が読み込めないと登録ができません。</p>
          <img src="../../../assets/images/upload_2.png" alt="img">
        </div>
      </div>
      <div class="upload_file_instruct">
        <div class="upload_file_card">
          <p class="bold">店舗名が切れてしまっている</p>
          <p>どの店舗からの依頼か不明のため登録できません。</p>
          <img src="../../../assets/images/upload_3.png" alt="img">
        </div>
        <div class="upload_file_card">
          <p class="bold">商品名が切れてしまっている </p>
          <p>商品名が読み込めないと登録ができません。 </p>
          <br class="text_none">
          <img src="../../../assets/images/upload_4.png" alt="img">
        </div>
      </div>
      <div class="upload_file_instruct">
        <div class="upload_file_card">
          <p class="bold">影や光の反射がある</p>
          <p>読み取りの精度が悪くなり誤変換が起きる可能性があります。</p>
          <img src="../../../assets/images/upload_5.png" alt="img">
        </div>
      </div>
    </div>
    <div class="control_area">
      <router-link to="/upload" class="icon_circle">
        <div class="icon back for_controlArea" />
      </router-link>
      <div class="btn_area control">
        <router-link
          :to="{}"
          class="btn primary"
          :disabled="!file || imageErrorSize || imageError "
          :class="!file || imageErrorSize || imageError  ? 'disabled' : ''"
          @click.native="upload"
        >
          アップロードする
        </router-link>
      </div>
    </div>
    <PopupDuplicateFile :show-dialog="showDialog"
                        :close-dialog="disableShowDialog"
                        @uploadDuplicateImage="uploadDuplicateImage"
    />
  </main>
</template>

<script>
import {RestaurantUploadFilesService} from '../../services/restaurant_upload_files.service';
import {SuppliersService} from '../../services/suppliers.service';
import _ from 'lodash';
import Validator from '../../validator';
import {CorrectNumber} from '../../common/util';
import PopupDuplicateFile from './../../components/PopupDuplicateFile.vue';
import SupplierEmailsInput from './../../components/SupplierEmailsInput.vue';

export default {
  components: {
    PopupDuplicateFile, SupplierEmailsInput
  },
  data() {
    return {
      supplierEmails: [],
      showDialog: false,
      fileName: '',
      file: '',
      fileSize: '',
      contentType: '',
      imageError: '',
      errors: {},
      uploadDuplicate: false,
      imageErrorSize: false,
      oldSupplier: false,
      errorSupplier: true,
      supplier_form: {
        email: '',
        fax: '',
        note: '',
        order_type: 'email',
        supplier_official_name: '',
        mediaSheetClass: 'MediaSheet:Smartrequest',
        customer_no: '',
        order_no: '',
      },
      suggestKeyword: '',
      suggestShow: false,
      suggestSuppliers: [],
      chosenSupplier: {},
      regFile: new RegExp(/tiff|png|jpg|csv|xls|pdf|xlsx|jpeg$/, 'ig'),
      listFile: ['image/png', 'image/tiff', 'image/jpeg', 'text/csv', 'text/xlsx', 'text/xls', 'application/pdf']
    };
  },
  watch: {
    suggestKeyword() {
      this.checkRequiredField();
    },
    oldSupplier() {
      this.suggestKeyword = '';
      this.suggestSuppliers = [];
      this.chosenSupplier = {};
      this.supplier_form = {};
      this.checkRequiredField();
    },
    chosenSupplier() {
      this.checkRequiredField();
    },
    fax() {
      if (this.supplier_form.fax) {
        this.fax = this.supplier_form.fax.replace(/[^0-9]+/g, '');
      }
    },
    'supplier_form.email': function () {
      this.checkRequiredField();
    },
    'supplier_form.fax': function () {
      this.checkRequiredField();
    },
    'supplier_form.supplier_official_name': function () {
      this.checkRequiredField();
    },
    'supplier_form.order_type': function () {
      this.checkRequiredField();
    },
    file() {
      this.checkRequiredField();
    },
    imageError() {
      this.checkRequiredField();
    },
    imageErrorSize() {
      this.checkRequiredField();
    },
  },
  methods: {
    getEmailInputs(inputs) {
      this.supplierEmails = inputs.supplierEmailsEdit;
    },
    disableShowDialog() {
      this.showDialog = false;
    },
    onChange: _.debounce(function () {
      this.fetchSuggestList();
    }, 500),
    blurSuggest() {
      if (this.chosenSupplier && Object.keys(this.chosenSupplier).length == 0) {
        this.suggestKeyword = '';
      }
      this.suggestShow = false;
    },
    chooseSuggest(supplier) {
      this.suggestShow = false;
      this.suggestKeyword = supplier.name;
      this.suggestSuppliers = [supplier];
      this.chosenSupplier = supplier;
    },
    async fetchSuggestList() {
      try {
        if (!this.suggestKeyword) this.chosenSupplier = {};
        const params = {keyword: this.suggestKeyword};
        const {response} = await SuppliersService.index(params);
        this.suggestSuppliers = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    addFile(event) {
      this.$loading(true);
      event.preventDefault();
      this.fileName = '';
      this.file = '';
      this.fileSize = '';
      this.contentType = '';
      let file = event.dataTransfer.files[0];
      this.fileSize = file.size;
      this.findTypeFileUpload(file);
      if (event.dataTransfer.files.length < 1) {
        return this.$loading(false);
      }

      if (file.size > 10 * 1024 * 1024) { // 10MB is max size
        this.imageErrorSize = true;
      }
      this.$loading(false);
    },
    previewFiles(event) {
      const file = event.target.files[0];
      if (file) {
         this.$loading(true);
        this.imageError = false;
        this.imageErrorSize = false;
        this.fileSize = file.size;
        this.findTypeFileUpload(file);

        if (event.target.files.length < 1) {
          return this.$loading(false);
        }

        if (file.size < 1024) {
          this.fileSize = file.size + 'B';
        } else if (file.size < 1024 * 1024) {
          let convertSize = file.size / 1024;
          this.fileSize = Math.floor(convertSize) + 'KB';
        } else {
          let convertSize = file.size / (1024 * 1024);
          this.fileSize = Math.floor(convertSize) + 'MB';
        }

        if (file.size > 10 * 1024 * 1024) { // 10MB is max size
          this.imageErrorSize = true;
        }
        this.$loading(false);
      }
    },
    createBase64Image(file) {
      this.fileName = file.name;
      this.contentType = file.type;
      const reader = new FileReader();

      reader.onload = (e) => {
        this.file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async upload() {
      this.$loading(true);
      this.supplier_form.email = this.supplierEmails.filter(supplierEmail => supplierEmail._destroy === false).length === 0 ?
        null : this.supplierEmails.filter(supplierEmail => supplierEmail._destroy === false)[0].email;
      let params = {
        chosenSupplierId: this.chosenSupplier.id,
        supplier_form: {
          email: this.supplier_form.email,
          fax: CorrectNumber(this.supplier_form.fax),
          note: this.supplier_form.note,
          order_type: this.supplier_form.order_type,
          supplier_official_name: this.supplier_form.supplier_official_name,
          media_sheet_class: this.supplier_form.orderType == 'email' || this.supplier_form.orderType == 'line' 
            ? 'MediaSheet:Smartrequest' : this.supplier_form.mediaSheetClass
        }
      };
      params['supplier_form'].supplier_emails_attributes = this.supplierEmails
      if (this.supplier_form.order_type == 'fax' && this.supplier_form.mediaSheetClass == 'MediaSheet:Ryque') {
        params['supplier_form'].order_no = this.supplier_form.order_no;
        params['supplier_form'].customer_no = this.supplier_form.customer_no;
      }
      if (!this.oldSupplier) {
        this.validate();
      } else {
        this.errors = {};
      }
      if (Object.keys(this.errors).length
      ) {
        this.$loading(false);
        return;
      } else {
        if (this.file && this.fileName) {
          params['file'] = {
            name: this.fileName,
            content: this.file,
            contentType: this.contentType
          };
        }
        if (this.oldSupplier) {
          params['supplier_name'] = this.chosenSupplier.name ? this.chosenSupplier.name : this.chosenSupplier.supplier_official_name;
        }
        if (await this.checkDuplicateImage(params) || this.uploadDuplicate) {
          try {
            await RestaurantUploadFilesService.create(params);
            this.$loading(false);
            this.uploadDuplicate = false;
            return this.$router.push({path: '/upload/complete'});
          } catch (error) {
            this.$loading(false);
          }
        } else {
          this.showDialog = true;
          this.$loading(false);
        }
      }
    },
    findTypeFileUpload(file) {
      const xlsxFile = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const csvFile = "text/csv";
      const xlsFile = "application/vnd.ms-excel";

      const typeFile = file.type;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      return fileReader.onloadend = (e) => {
        const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        let header = '';
        let type = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        switch (header) {
          case '89504e47':
            if(typeFile == 'image/png') {
              type = 'image/png';
              break;
            } else {
              type = 'unknown';
              break;
            }
          case '49492a0':
            if(typeFile == 'image/tiff') {
              type = 'image/tiff';
              break;
            } else {
              type = 'unknown';
              break;
            }
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe3':
          case 'ffd8ffe8':
            if(typeFile == 'image/jpeg') {
              type = 'image/jpeg';
              break;
            } else {
              type = 'unknown';
              break;
            }
          case '25504446':
            if(typeFile == 'application/pdf') {
              type = 'application/pdf';
              break;
            } else {
              type = 'unknown';
              break;
            }
          case '504b34':
          case '504b56':
          case '504b78':
            if(typeFile == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              type = 'text/xlsx';
              break;
            } else {
              type = 'unknown';
              break;
            }
          case 'd0cf11e0':
            if(typeFile == 'application/vnd.ms-excel') {
              type = 'text/xls';
              break;
            } else {
              type = 'unknown';
              break;
            }
          default:
            if(typeFile == "text/csv") {
              type = 'text/csv';
              break;
            } else {
              type = 'unknown';
              break;
            }
        }
        this.checkErrorFile(type, file)
      }
    },
    checkErrorFile(type, file) {
      let checkFormatFile = false;
      this.imageError = false;
      if (this.listFile.includes(type)) {
        checkFormatFile = true;
      }
      if (!checkFormatFile) {
        this.imageError = true;
        this.createBase64Image(file);
      } else {
        this.imageError = false;
        this.createBase64Image(file);
      }
    },
    validate() {
      const validator = new Validator();
      if (this.supplier_form.order_type == 'email') {
        validator.checkRequire('email', this.supplier_form.email, 'メールアドレス');
      }
      this.supplierEmails.filter(supplierEmail => !supplierEmail._destroy).forEach(supplierEmail => {
        if (!validator.errors.email) {
          validator.checkEmail('email', supplierEmail.email, 'メールアドレス');
          validator.checkMaxLength('email', supplierEmail.email, 255, 'メールアドレス');
          if (this.supplierEmails.filter(supplierEmailSearch => !supplierEmailSearch._destroy && supplierEmailSearch.email == supplierEmail.email).length > 1) {
            validator.errors.email = ['メールがすでに登録されています。']
          }
        }
      });
      if (this.supplier_form.fax) {
        const fax = CorrectNumber(this.supplier_form.fax);
        validator.checkMaxLength('fax', fax, 11, 'FAX番号') &&
        validator.checkMinLength('fax', fax, 10, 'FAX番号');
        if (!validator.errors.fax) {
          validator.checkPhone('fax', fax, 'FAX番号');
        }
      }
      if (this.supplier_form.order_type === 'fax') {
        validator.checkRequire('fax', CorrectNumber(this.supplier_form.fax), 'FAX番号');
      }
      if (this.supplier_form.order_type == 'fax' && this.supplier_form.mediaSheetClass == 'MediaSheet:Ryque') {
        validator.checkRequire('order_no', this.supplier_form.order_no, '注文用紙番号') &&
          validator.checkDigit('order_no', this.supplier_form.order_no, '注文用紙番号') &&
          validator.checkLength('order_no', this.supplier_form.order_no, 3, '注文用紙番号');

        validator.checkRequire('customer_no', this.supplier_form.customer_no, 'お客様NO') &&
          validator.checkDigit('customer_no', this.supplier_form.customer_no, 'お客様NO') &&
          validator.checkLength('customer_no', this.supplier_form.customer_no, 6, 'お客様NO');
      }
      validator.checkMaxLength('note', this.supplier_form.note, 3000, '登録代行へのメッセージ');
      validator.checkRequire('supplier_official_name', this.supplier_form.supplier_official_name, '発注書会社名') &&
      validator.checkMaxLength('supplier_official_name', this.supplier_form.supplier_official_name, 255, '発注書会社名');
      this.errors = validator.errors;
    },
    checkRequiredField() {
      if (this.oldSupplier == false) {
        if (this.supplier_form.order_type == 'email' && this.supplier_form.email && this.supplier_form.supplier_official_name) {
          this.errorSupplier = false;
        } else if (this.supplier_form.order_type == 'fax' && this.supplier_form.fax && this.supplier_form.supplier_official_name) {
          this.errorSupplier = false;
        } else if (this.supplier_form.order_type == 'line' && this.supplier_form.supplier_official_name) {
          this.errorSupplier = false;
        } else {
          this.errorSupplier = true;
        }
      } else if (this.chosenSupplier && Object.keys(this.chosenSupplier).length > 0) {
        this.errorSupplier = false;
      } else {
        this.errorSupplier = true;
      }
    },
    async checkDuplicateImage(params) {
      const res = await RestaurantUploadFilesService.checkDuplicate(params);
      if (res.response.result.is_duplicate === false) {
        return true;
      } else {
        return false;
      }
    },
    async uploadDuplicateImage() {
      this.uploadDuplicate = true;
      await this.upload();
    },
  }
}
;
</script>
<style lang="scss" scoped>
.attention {
  color: #D52C2C;
  font-weight: bold;
  margin-bottom: 16px;
}

.group_upload {
  grid-template-columns: auto 1fr;
  grid-column-gap: 100px;
}

.rb_label {
  font-weight: normal;
}

@media screen and (max-width: 480px) {
  .group_upload {
    grid-template-columns: auto;
    grid-column-gap: 20px;
  }

  .note-rb {
    display: none;
    &_mobile {
      font-size: 16px;
      color: red;
      display: block !important;
      text-align: center;
      font-weight: bold;
    }
  }
}

.error-message {
  font-size: 16px;
}

.chose_disable {
  opacity: 0.5;
}
.mg-b-0 {
  margin-bottom: 0 !important;
}

.note-rb {
  font-size: 16px;
  color: red;
  &_mobile {
    display: none;
  }
}
</style>
