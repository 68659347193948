<template>
  <div class="owner_main_area">
    <div class="wrapper-body_dashboard">
      <h1 class="title_owner mb_medium">店舗アカウント解約申請 </h1>
      <div>
        <div class="note_sp">「{{ restaurant.shop_name }}」の解約申請を行います。</div>
        <div class="note_sp text_color-reb">
          解約後、店舗アカウントのデータ・発注先・商品の情報等は削除されます。復旧はできませんのでご注意ください。
        </div>
        <div class="note_sp text_color-reb">
          送信済みの発注書について何か問題が発生した場合は、直接お取引業者様とご連絡の上、ご対応いただきますようお願いします。
        </div>
        <div class="note_sp text_color-reb">
          本解約申請後、ご契約期間が終了する{{ yearAt }}年{{ monthAt }}月末まで継続してご利用いただけます。
        </div>
        <div v-if="restaurant.contract_start_at && restaurant.count_restaurant_owner > 1" class="note_sp text_color-reb">
          {{ yearAt }}年{{ monthAt }}月までのご利用料金は発生いたしますので、予めご了承ください。
        </div>
        <div class="note_sp mb_medium">
          よろしければ、以下のアンケートにお答えいただき、「解約申請する」ボタンを押してください。
        </div>
      </div>
      <label class="owner_form_label mb_12">解約アンケート</label>
      <div
        v-for="(question, index) in questions"
        :key="index" class="ml_small "
        :class="index < questions.length - 1 ? 'mb_large' : ''"
      >
        <label class="owner_form_label mb_12 down_line">
          <span v-html="cuttingStrings(question)[0]" />
          <span v-show="question.is_require" class="require">必須</span> <br>
          <span v-if="cuttingStrings(question)[1] != undefined" v-html="cuttingStrings(question)[1]" />
        </label>
        <div v-if="question.type === 'checkbox'">
          <div v-for="(answer, i) in question.answers" :key="i">
            <div :class="['text', 'textarea', 'datetime'].includes(answer.remark) ? 'mb_large' : ''">
              <div class="btn_checkbox">
                <input
                  :id="'checkbox_' + answer.id"
                  v-model="answer.checked"
                  type="checkbox"
                  class="owner_padding_checkbox"
                  @change="onChangeChangeBoxAnswer(answer)"
                >
                <label class="checkmark_checkbox btn_pointer" :for="'checkbox_' + answer.id" />
                <label class="down_line text_label btn_pointer" :for="'checkbox_' + answer.id" v-html="answer.answer" />
              </div>

              <div v-if="answer.remark ==='text'" class="width_input_75 input_padding">
                <input
                  v-model="answer.answer_content"
                  type="text"
                  maxlength="3000"
                  @input="changeValueInputCheckBox(answer)"
                >
              </div>

              <div v-if="answer.remark == 'textarea'" class="width_input_75 input_padding">
                <textarea
                  v-model="answer.answer_content"
                  class="owner_textarea"
                  cols="20"
                  rows="4"
                  maxlength="3000"
                  @input="changeValueInputCheckBox(answer)"
                />
              </div>

              <div v-if="answer.remark == 'datetime'" class="input_wrapper calender width_input_75 input_padding">
                <flat-pickr
                  v-model="answer.answer_content"
                  :config="config"
                  @input="changeValueInputCheckBox(answer)"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="question.type == 'radio'" class="down_line">
          <div>
            <label v-for="(answer, i) in question.answers" :key="i" class="radio_btn_owner">
              <input
                v-model="question.radio_value"
                type="radio"
                :value="answer.id"
                class="owner_padding_checkbox"
              >
              <span class="checkmark_owner" />
              <div class="owner_radio_text"> {{ answer.answer }}</div>
            </label>
          </div>
        </div>

        <div v-if="question.type == 'text'">
          <input v-model="question.answer_content" type="text" maxlength="3000">
        </div>

        <div v-if="question.type == 'datetime'">
          <div class="input_wrapper calender">
            <flat-pickr
              v-model="question.answer_content"
              :config="config"
            />
          </div>
        </div>
        <span v-show="errors[question.type] && errors[question.type].length > 0" class="error-message owner">
          {{ errors[question.type] }}
        </span>
      </div>

      <div class="btn_area_owner two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn_owner delete"
          @click.native="handleDelete"
        >
          解約申請する
        </router-link>
        <router-link :to="`/owner/restaurant/${restaurantId}/detail`" class="btn_owner primary_border ml_medium">
          キャンセル
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { RestaurantQuestionsService } from '../../../../../services/Owner/restaurant_questions.service';
import { RestaurantAdministratorsService } from '../../../../../services/Owner/restaurant_administrators.service';
import { RestaurantAnswersService } from '../../../../../services/Owner/restaurant_answers.service';
import flatPickr from 'vue-flatpickr-component';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

const message = 'この質問に答えてください。';
export default {
  components: {
    flatPickr
  },
  props: {
    restaurantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      restaurant: {},
      config: {
        enableTime: true,
        time_24hr: true,
        locale: Japanese,
        dateFormat: 'Y-m-d H:i',
      },
      errors: {},
      yearAt: '',
      monthAt: '',
    };
  },
  async created() {
    await this.getRestaurantQuestions();
    await this.getRestaurant();
    await this.formatDate();
  },

  methods: {
    changeValueInputCheckBox(answer) {
      answer.checked = answer.answer_content !== '';
    },
    onChangeChangeBoxAnswer(answer) {
      if(!answer.checked) {
        answer.answer_content = '';
      }
    },
    async getRestaurantQuestions() {
      this.$loading(true);
      try {
        const { response } = await RestaurantQuestionsService.index();
        this.questions = response.data;
        this.$loading(false);
      } catch(error) {
        this.$loading(false);
      }
    },
    async getRestaurant() {
      this.$loading(true);
      try {
        const { response } = await RestaurantAdministratorsService.get(this.restaurantId);
        this.$loading(false);
        if (!response.data.is_cancel) {
          return this.$router.push('/owner');
        }
        this.restaurant = response.data;
        this.$loading(false);
      } catch(error) {
        this.$loading(false);
      }
    },
    async handleDelete() {
      this.validateForm();

      if (Object.keys(this.errors).length) return;

      this.$loading(true);
      try {
        if(this.restaurant.is_cancel){
          this.popupDelete();
        }else{
          this.popupCannotDelete();
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    validateForm() {
      this.errors = {};
      this.questions.forEach((p) => {
        if (p.type === 'checkbox') {
          const element = p.answers.find(e => e.checked === true);
          this.setErrorMessage(p, element);
        } else if (p.type === 'radio') {
          this.setErrorMessage(p, p.radio_value);
        } else {
          this.setErrorMessage(p, p.answer_content);
        }
      });
    },

    setErrorMessage(question, value) {
      if (!value && question.is_require) {
        this.errors = { ...this.errors, ...{ [question.type]: message } };
      }
    },

    async popupDelete() {
      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>店舗アカウントの解約を申請します。</p>
               <p>よろしいですか？</p>`,
        showCancelButton: true,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });

      if (confirm.isConfirmed) {
        try {
          const { response } = await RestaurantAnswersService.create(this.restaurantId, this.fetchParamsQuestionAnswer());
          this.$store.commit('SET_RESTAURANT_CANCEL_AT', response.data.cancel_at);
          this.$emit('change_screen', 'complete');
        } catch(error) {
          this.popupCheckDelete(error.response.result.message);
        }
      }
    },
    fetchParamsQuestionAnswer() {
      const questions_answers = [];
      this.questions.forEach(element => {
        if (element.type == 'text' || element.type == 'datetime') {
          questions_answers.push({
            restaurant_cancel_question_id: element.id,
            restaurant_cancel_question_answer_id: null,
            answer_content: element.answer_content,
            question_no: element.question_no
          });
        } else if (element.type == 'radio') {
          questions_answers.push({
            restaurant_cancel_question_id: element.id,
            restaurant_cancel_question_answer_id: element.radio_value,
            answer_content: null,
            question_no: element.question_no
          });
        } else if (element.type == 'checkbox') {
          element.answers.forEach(answer => {
            if (answer.checked == true) {
              questions_answers.push({
                restaurant_cancel_question_id: element.id,
                restaurant_cancel_question_answer_id: answer.id,
                answer_content: answer.answer_content,
                question_no: element.question_no
              });
            }
          });
        }
      });
      return questions_answers;
    },
    async popupCannotDelete() {
      await this.$swal.fire({
        title: '削除ができません。',
        html: `<p>解約可能の期間が過ぎています。</p>
               <p>お手数ですが、翌月の1日に解約を実行してください。</p>`,
        cancelButtonText: '閉じる',
        showCloseButton: false,
        showConfirmButton: false,
        showCancelButton: true,
        customClass: {
          cancelButton: 'cancel-popup-owner',
          title: 'title-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });
    },
    formatDate() {
      let cancelAt;
      cancelAt = moment(this.restaurant.update_contract, 'YYYY/MM/DD').subtract(1, 'days').format('YYYY/MM/DD');
      this.yearAt = moment(cancelAt, 'YYYY/MM/DD').format('YYYY');
      this.monthAt = moment(cancelAt, 'YYYY/MM/DD').format('MM');
    },
    cuttingStrings(questions){
      let input = questions.question;
      let fields = input.split('</br>');
      return fields;
    },
    popupCheckDelete(errorMessage) {
      this.$swal.fire({
        title: 'アラート',
        html: `<p>${errorMessage}</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },
  }
};
</script>

<style scoped>
.text_label {
  font-weight: normal;
  display: inline;
}
.mb_12 {
  margin-bottom: 12px;
}
.radio_btn_owner {
  width: unset;
  margin-right: 24px;
  margin-bottom: 16px;
  height: 25px;
}
.owner_form_label {
  display: block;
}
.owner_form_label .require {
  vertical-align: bottom;
}
.btn_checkbox {
  cursor: unset;
}
</style>
