<template>
  <div id="itemForm" class="modal modal-item">
    <div class="modal-backdrop" />
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <div class="modal-menu flex">
          <h1 class="title_admin">{{ supplier.name || supplier.supplier_official_name }} 商品登録</h1>
          <div class="icon close" />
        </div>
      </div>
      <!--body-->
      <div class="modal-body">
        <div v-if="isEditing" class="admin_content">
          <form action="">
            <div class="form-row">
              <label class="form_label form-20">画像</label>
              <div class="flex-column form-50">
                <div>
                  <label for="file_uploader" class="button_upload_img">
                    <div v-show="image" class="file_upload_image admin">
                      <img :src="image">
                    </div>
                    <div v-show="!image" class="upload_placeholder">
                      <p class="icon add_image admin" />
                      <p class="mt_small">こちらをクリックまたはドラッグ＆ドロップで<br>画像をアップロード</p>
                    </div>
                  </label>
                  <input id="file_uploader" type="file" class="file_uploader"
                         accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff,image/bmp"
                         hidden @change="previewImage"
                  >
                </div>
                <span v-for="error in errors['image']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="form-row">
              <label for="name" class="form_label form-20">商品名<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="name" v-model="name" type="text"
                       placeholder="商品名（例：たまご（1箱100個入り））を入力"
                >
                <span v-for="error in errors['name']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="unit" class="form_label form-20">単位<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <input id="unit" v-model="unit" type="text"
                       placeholder="単位（例：本、箱、個、パック、kg）を入力"
                >
                <span v-for="error in errors['unit']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="price" class="form_label form-20">価格<span class="require">必須</span></label>
              <div class="flex-column form-50">
                <div class="flex alignCenter">
                  <input id="price" v-model="price" type="number"
                         placeholder="価格（税込の金額）を入力"
                  >
                </div>
                <span v-for="error in errors['price']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
              <div class="flex-column ml_small" style="line-height: 34px; font-size: 12px;">円</div>
            </div>
            <div class="form-row">
              <label for="product_no" class="form_label form-20">
                品番<span v-if="supplier.media_sheet_class == 'MediaSheet:Ryque'" class="require">必須</span>
              </label>
              <div class="flex-column form-50">
                <input id="product_no" v-model="productNo" type="text"
                       placeholder="品番を入力"
                >
                <span v-for="error in errors['productNo']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="item_category" class="form_label form-20">
                カテゴリー<span class="require">必須</span>
              </label>
              <div class="flex-column form-50">
                <div class="select_wrapper arrow_down">
                  <select id="item_category" v-model="itemCategory">
                    <option v-for="option in itemCategories" :key="option.value" :value="option.id">{{ option.name }}{{ `（${option.tax_percent}%）` }}</option>
                  </select>
                </div>
                <span v-for="error in errors['itemCategory']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <label for="note" class="form_label form-20">備考欄</label>
              <div class="flex-column form-50">
                <textarea id="note" v-model="note"
                          rows="6" placeholder="何か記載する内容があればご記載ください。"
                />
                <span v-for="error in errors['note']" :key="error" class="error-message">
                  {{ error }}
                </span>
              </div>
            </div>
          </form>
        </div>
        <div v-if="!isEditing" class="admin_content">
          <div class="form-row">
            <label class="form_label form-20">画像</label>
            <div class="form_label form-50">
              <div v-show="image" v-lazy-container="{ selector: 'img' }" class="item_image">
                <img :data-src="image">
              </div>
              <div v-show="!image" class="item_image no_image">
                <img :src="noImage">
              </div>
            </div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">商品名</label>
            <div class="form_label form-50">{{ name }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">単位</label>
            <div class="form_label form-50">{{ unit }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">価格</label>
            <div class="form_label form-50">{{ price }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">品番</label>
            <div class="form_label form-50">{{ productNo }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">カテゴリー</label>
            <div class="form_label form-50">{{ prettyShowItemCategory(itemCategory) }}</div>
          </div>
          <div class="form-row">
            <label class="form_label form-20">備考欄</label>
            <div class="form_label form-50">{{ note }}</div>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer">
        <div v-if="isEditing">
          <a class="btn_admin admin_primary" href="#" @click="handleSave()">{{ itemId ? '更新' : '登録' }}</a>
        </div>
        <div v-if="!isEditing">
          <a class="btn_admin admin_primary" href="#" @click="$emit('updateSelectedItem', itemId, true);">編集</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Validator from '../../../../validator';
import { ItemsService } from '../../../../services/admin/item.service';
import { ItemCategoriesService } from '../../../../services/admin/item_categories.service';
import noImage from '../../../../../assets/images/no_image.png';

export default {
  props: {
    itemId: {
      type: Number,
      require: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    restaurantAdministrator: {
      type: Object,
      require: true,
    },
    supplier: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      image: null,
      imageSize: null,
      imageName: null,
      name: null,
      unit: null,
      price: null,
      productNo: null,
      itemCategory: null,
      note: null,
      supplierItemId: null,
      errors: [],
      itemCategories: [],
      noImage: noImage,
      restaurant_administrator_id: this.$router.history.current.params.id,
      supplier_id: this.$router.history.current.params.supplier_id,
    };
  },
  watch: {
    itemId: async function (val) {
      if (val) {
        this.$loading(true);
        try {
          const {response} = await ItemsService.get(val);
          this.setFormData(response.data);
          this.$loading(false);
        } catch (error) {
          console.log(error);
          this.$loading(false);
        }
      } else {
        this.setFormData({});
      }
    },
  },
  async mounted() {
    await this.getItemCategories();
    if(this.itemCategory === null) this.itemCategory = this.itemCategories[0].id;
  },
  methods: {
    async getItemCategories() {
      try {
        const { response } = await ItemCategoriesService.index();
        this.itemCategories = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    prettyShowItemCategory(id) {
      const category = this.itemCategories.find(e => e.id === id);
      if (!category) return '';
      return `${category.name}（${category.tax_percent}%）`;
    },
    setFormData(item) {
      this.image = item.img;
      this.name = item.name;
      this.unit = item.unit;
      this.price = item.default_price;
      this.productNo = item.product_no;
      this.itemCategory = item.item_category_id || this.itemCategories[0].id;
      this.note = item.note;
      this.supplierItemId = item.supplier_item_id;
    },
    onModalClose() {
      this.$emit('updateSelectedItem', null);
      this.setFormData({});
    },
    handleSave: async function () {
      this.validate();
      if (Object.keys(this.errors).length) return;

      const params = {
        name: this.name,
        unit: this.unit,
        default_price: this.price,
        product_no: this.productNo,
        item_category_id: this.itemCategory,
        note: this.note,
      };
      if (this.image && this.imageName) {
        params['image'] = {
          name: this.imageName,
          content: this.image
        };
      }
      if(this.itemId) {
        params['supplier_item'] = {
          id: this.supplierItemId,
          price: this.price,
        };
      } else {
        params['supplier_item'] = {
          supplier_id: this.supplier_id,
          price: this.price,
        };
      }

      this.$loading(true);
      try {
        if (this.itemId) {
          await ItemsService.update(this.itemId, params);
        } else {
          await ItemsService.create(params);
        }
        this.$loading(false);
        this.$closeModal('itemForm');
        this.$emit('refreshTable');
      } catch (error) {
        console.log(error);
        this.$loading(false);
      }
    },
    validate() {
      const validator = new Validator();

      if (this.imageSize && this.imageSize > 3 * 1024 * 1024) { // max 3MB
        validator.errors['image'] = ['添付ファイルのサイズが許容制限エラーを超えています。'];
      }

      validator.checkRequire('name', this.name, '商品名') &&
        validator.checkMaxLength('name', this.name, 255, '商品名');

      validator.checkRequire('unit', this.unit, '単位') &&
        validator.checkMaxLength('unit', this.unit, 4, '単位');

      validator.checkRequire('price', this.price, '価格') &&
        validator.checkDigit('price', this.price, '価格') &&
        validator.checkMaxNumber('price', this.price, 9, '価格');

      validator.checkMaxLength('productNo', this.productNo, 255, '品番');

      validator.checkRequire('itemCategory', this.itemCategory, 'カテゴリー');

      validator.checkMaxLength('note', this.note, 255, '備考欄');

      if (this.supplier.media_sheet_class == 'MediaSheet:Ryque') {
        validator.checkRequire('productNo', this.productNo, '品番') &&
          validator.checkDigit('productNo', this.productNo, '品番') &&
          validator.checkLength('productNo', this.productNo, 5, '品番');
      }

      this.errors = validator.errors;
    },
    previewImage(event) {
      if (event.target.files.length < 1) {
        return;
      }

      const file = event.target.files[0];
      this.imageSize = file.size;
      this.imageName = file.name;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-item .modal-content {
  min-width: 800px;
  width: 800px;
}

.item_image {
  img {
    height: 120px;
    display: inline-block;
  }
  &.no_image {
    img {
      max-height: 24px;
    }
  }
}
</style>

