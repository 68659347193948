import { PrefecturesService } from '../../../services/Owner/prefectures.service';

const prefectures = {
  state: {
    prefectures: [],
  },
  mutations: {
    SET_PREFECTURES(state, payload) {
      state.prefectures = payload;
    }
  },
  actions: {
    async getPrefectures({ commit }) {
      try {
        const { response } = await PrefecturesService.index();
        commit('SET_PREFECTURES', response.data);
      } catch (error) {
        commit('SET_PREFECTURES', []);
      }
    }
  },
  getters: {
    getAllPrefectures: state => state.prefectures,
  }
};

export default prefectures;